import React, { useState } from "react";
import InputField from "@/components/InputField";
import Button from "@/components/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "@/utils/yup";
import { useDispatch, useSelector } from "react-redux";
import { AuthActions, confirmEmailCode } from "@/store/auth/auth.actions";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";
import AlertMessage from "@/components/AlertMessage";
import { isInProcess } from "@/store/pending/pending.reducer";
import "./RegisterForm.scss";

const codeConfirmationSchema = yup.object({
  confirmation_code: yup.string().max(255).required()
});

const CodeConfirmationForm = ({ onAfterSubmit }) => {
  const isLoading = useSelector((state) =>
    isInProcess(state, AuthActions.CONFIRM_EMAIL_CODE)
  );

  const [feedback, setFeedback] = useState(null);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(codeConfirmationSchema),
    defaultValues: {
      confirmation_code: ""
    }
  });

  // eslint-disable-next-line camelcase
  const onSubmit = async ({ confirmation_code }) => {
    setFeedback(null);

    try {
      const email = searchParams.get("email") ?? "";
      await dispatch(
        confirmEmailCode({
          // eslint-disable-next-line camelcase
          confirmation_code,
          email
        })
      );

      setFeedback({ type: "success", text: "Registration successful." });

      if (onAfterSubmit) {
        setTimeout(() => {
          onAfterSubmit();
        }, 2 * 1000);
      }
      // eslint-disable-next-line no-empty
    } catch (e) {
      setFeedback({
        type: "error",
        text: e?.data?.message || "Confirmation failed. Something went wrong."
      });
    }
  };

  return (
    <form className="register-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="register-form__head">
        <div className="register-form__title">Hi there!</div>
        <div className="register-form__text">
          Please fill in confirmation code sent to your email
        </div>
      </div>
      <div className="fields-group">
        <InputField
          label="Confirmation code"
          name="confirmation_code"
          type="text"
          errors={errors}
          {...register("confirmation_code")}
        />
      </div>
      <div className="fields-group">
        <Button
          isLoading={isLoading}
          text="Confirm code"
          className="btn-default btn-fullwidth"
          type="submit"
        />
      </div>
      {feedback && (
        <div className="register-form__error-message">
          <AlertMessage text={feedback?.text} type={feedback?.type} />
        </div>
      )}
    </form>
  );
};

CodeConfirmationForm.propTypes = {
  onAfterSubmit: PropTypes.func
};

export default CodeConfirmationForm;
