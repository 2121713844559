import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import InputField from "@/components/InputField";
import PhoneField from "@/components/PhoneField";
import PanelHeader from "@/components/ProjectDetails/components/PanelHeader";
import { CheckCircleSuccessIcon } from "@/icons";
import { OrderStatuses } from "@/constants/orderStatuses";
import { routes } from "@/constants";
import { clearOrderDetails } from "@/store/order/order.actions";
import { Mobile } from "@/components/Responsive";
import cn from "classnames";

export const CompanyDetailsNavItem = ({ tabIndex, handleTabNavClick }) => {
  return (
    <button
      className={cn(
        "project-details__nav-item",
        tabIndex === "company-details" && "active"
      )}
      onClick={() => handleTabNavClick("company-details")}
      type="button">
      <CheckCircleSuccessIcon className="project-details__nav-icon" />
      Company details
    </button>
  );
};

const CompanyDetails = ({ orderDetails, tabIndex, handleTabNavClick }) => {
  const { control } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    id,
    application_name,
    organization_name,
    organization_website,
    address,
    contact_name,
    contact_email,
    contact_phone,
    status: { code },
    previously_worked,
    nylas_google_api
  } = orderDetails;

  const onCompanyDetailsClick = async () => {
    await dispatch(clearOrderDetails());

    navigate(generatePath(routes.ORDER_CASA_BY_ID, { id }));
  };

  return (
    <>
      <Mobile>
        <CompanyDetailsNavItem
          tabIndex={tabIndex}
          handleTabNavClick={handleTabNavClick}
        />
      </Mobile>
      {tabIndex === "company-details" && (
        <div className="project-details__tab">
          <PanelHeader
            title="Company details"
            description="Please provide the necessary information to proceed with CASA."
            icon={<CheckCircleSuccessIcon />}
            hasButton={code === OrderStatuses.CREATED && !application_name}
            buttonText="Continue"
            onClick={onCompanyDetailsClick}
          />
          <div className="project-details__section">
            <div className="fields-group">
              <div className="fields-group-title">Company details</div>
              {organization_name && (
                <InputField
                  label="Company name <sup>*</sup>"
                  name="organization_name"
                  readonlyValue={organization_name}
                />
              )}
              {organization_website && (
                <InputField
                  label="Company website URL <sup>*</sup>"
                  name="organization_website"
                  readonlyValue={organization_website}
                />
              )}
              {address && (
                <InputField
                  label="Company address <sup>*</sup>"
                  name="address"
                  readonlyValue={address}
                />
              )}
            </div>
            <div className="fields-group">
              <div className="fields-group-title">Point of contact</div>
              {contact_name && (
                <InputField
                  label="Contact name <sup>*</sup>"
                  readonlyValue={contact_name}
                  name="contact_name"
                />
              )}
              {contact_email && (
                <InputField
                  label="Contact email <sup>*</sup>"
                  readonlyValue={contact_email}
                  name="contact_email"
                />
              )}
              {contact_phone && (
                <PhoneField
                  readonlyValue={contact_phone}
                  control={control}
                  fieldName="contact_phone"
                  label="Contact phone number <sup>*</sup>"
                />
              )}
              <InputField
                label="Have you or your company previously worked with Leviathan Security Group? <sup>*</sup>"
                readonlyValue={previously_worked ? "Yes" : "No"}
                name="previously_worked"
              />
              <InputField
                label="Are you currently using Nylas for integration with Google API? <sup>*</sup>"
                readonlyValue={nylas_google_api ? "Yes" : "No"}
                name="nylas_google_api"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

CompanyDetails.propTypes = {
  orderDetails: PropTypes.object.isRequired,
  tabIndex: PropTypes.string,
  handleTabNavClick: PropTypes.func
};

CompanyDetailsNavItem.propTypes = {
  tabIndex: PropTypes.string,
  handleTabNavClick: PropTypes.func
};

export default CompanyDetails;
