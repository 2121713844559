import { ApiClient } from "@/services/api";

class AuthService extends ApiClient {
  register(data) {
    return this.apiClient.post("/auth/register", data);
  }

  confirmEmailCode(data) {
    return this.apiClient.post("/auth/confirm", data);
  }

  async login(credentials) {
    const res = await this.apiClient.post("/auth/login", credentials);
    this.initRequestInterceptors(res?.token);
    return res;
  }

  validateToken(cancelToken) {
    return this.apiClient.get("/auth/check", {
      cancelToken
    });
  }

  sendResetCode(email) {
    return this.apiClient.post("/auth/send-reset-code", {
      email
    });
  }

  resetPassword(params) {
    return this.apiClient.post("/auth/reset-password", params);
  }

  associateMFASoftwareToken({ session }) {
    return this.apiClient.post("/auth/associate-software-token", {
      session
    });
  }

  verifyMFASoftwareToken({ session, code }) {
    return this.apiClient.post("/auth/verify-software-token", {
      session,
      code
    });
  }

  async respondToAuthChallenge({ session, code }) {
    const res = await this.apiClient.post("/auth/respond-to-auth-challenge", {
      session,
      code
    });
    this.initRequestInterceptors(res?.token);

    return res;
  }
}

export default new AuthService();
