import React, { useMemo } from "react";
import { decode } from "html-entities";
import { useSelector } from "react-redux";
import { getOrderDetails } from "@/store/order/order.reducer";
import ApplicationInfoForm from "./ApplicationInfoForm";

const ApplicationInfo = () => {
  const order = useSelector(getOrderDetails);

  const predefinedValues = useMemo(
    () => ({
      application_name: decode(order?.application_name),
      package_name: decode(order?.package_name),
      has_login: order?.has_login,
      is_not_relized: order?.is_not_relized,
      is_paid: order?.is_paid,
      app_login: order?.app_login_decrypted,
      app_password: order?.app_password_decrypted,
      app_filename: order?.app_filename
    }),
    [order]
  );

  if (!order) {
    return null;
  }

  return <ApplicationInfoForm predefinedValues={predefinedValues} />;
};

export default ApplicationInfo;
