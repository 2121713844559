import React from "react";
import "./PurchasePlanCard.scss";
import PropTypes from "prop-types";
import cn from "classnames";

const PurchasePlanCard = ({
  text,
  active,
  price,
  hasError,
  options,
  className,
  selectedBadge
}) => {
  return (
    <div
      className={cn(
        "purchase-card",
        { "has-error": hasError, active },
        className,
        selectedBadge && "purchase-card_selected"
      )}>
      {selectedBadge && (
        <div className="purchase-card__badge">{selectedBadge}</div>
      )}
      <div className="purchase-card__intro">
        <h4 className="purchase-card__title">{text}</h4>
        <span className="purchase-card__price">$ {price}</span>
      </div>
      {options && (
        <ul className="purchase-card__benefits benefits">
          {options.map((option) => (
            <li key={option} className="benefits__item">
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

PurchasePlanCard.defaultProps = {
  hasError: false,
  active: false
};

PurchasePlanCard.propTypes = {
  options: PropTypes.array,
  text: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  hasError: PropTypes.bool,
  active: PropTypes.bool,
  className: PropTypes.string,
  selectedBadge: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export default PurchasePlanCard;
