import React, { useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import ErrorMessage from "@/components/ErrorMessage";
import { EyeCrossedIcon, EyeIcon } from "@/icons";
import "./InputPasswordField.scss";

// eslint-disable-next-line react/display-name
const InputPasswordField = React.forwardRef(
  (
    {
      wrapperClassName,
      inputClassName,
      placeholder,
      name,
      label,
      errors,
      onFocus,
      onChange,
      onBlur,
      ...props
    },
    ref
  ) => {
    const [isPassword, setIsPassword] = useState(true);
    const [isPasswordLength, setIsPasswordLength] = useState(false);

    const togglePasswordVisibility = () => setIsPassword((prev) => !prev);

    const handleInput = (event) => {
      setIsPasswordLength(event.target.value);
    };

    return (
      <div
        className={cn(
          "form-group",
          wrapperClassName,
          errors[name] ? "has-error" : ""
        )}>
        {label && <div className="form-label">{label}</div>}
        <div className="form-field">
          <input
            {...props}
            className={cn("form-control", inputClassName)}
            ref={ref}
            type={isPassword ? "password" : "text"}
            name={name}
            onInput={handleInput}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            placeholder={placeholder}
          />
          <button
            tabIndex={-1}
            className={cn(
              "btn-icon show-password-button",
              isPasswordLength && "active"
            )}
            onClick={togglePasswordVisibility}
            type="button">
            {isPassword ? <EyeIcon /> : <EyeCrossedIcon />}
          </button>
        </div>
        <ErrorMessage errors={errors} name={name} />
      </div>
    );
  }
);

InputPasswordField.defaultProps = {
  errors: {}
};

InputPasswordField.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  errors: PropTypes.object
};

export default InputPasswordField;
