import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { routes } from "@/constants";
import { AsideLayout } from "@/layouts";
import LoginForm from "@/components/LoginForm";
import RegisterForms from "@/components/RegisterForms";
import ForgotPasswordFlow from "@/components/ForgotPasswordFlow";
import ExternalLoginHandler from "@/components/ExternalLoginHandler";
import MFA from "@/components/MFA";
import SetupMFASuccess from "@/components/MFA/SetupMFASuccess";

const AuthPage = () => {
  return (
    <Routes>
      <Route element={<AsideLayout />}>
        <Route index element={<Navigate to={routes.LOGIN} />} />
        <Route path="login" element={<LoginForm />} />
        <Route path="register/*" element={<RegisterForms />} />
        <Route path="forgot-password/*" element={<ForgotPasswordFlow />} />
        <Route path="mfa" element={<MFA />} />
        <Route path="mfa-setup-success" element={<SetupMFASuccess />} />
      </Route>
      <Route path="external-sign-in/*" element={<ExternalLoginHandler />} />
    </Routes>
  );
};

export default AuthPage;
