import React from "react";
// eslint-disable-next-line
import Button from "@/components/Button";
import { TabletDesktop, Mobile } from "@/components/Responsive";
import PropTypes from "prop-types";

const PanelHeader = ({
  title,
  description,
  icon,
  hasButton = true,
  onClick,
  buttonText,
  children
}) => {
  return (
    <div className="project-details__panel">
      <div className="project-details__panel-wrap">
        <div className="project-details__panel-main">
          <TabletDesktop>
            <div className="project-details__panel-icon">{icon}</div>
            <h2 className="project-details__panel-title">{title}</h2>
          </TabletDesktop>
          <div className="project-details__panel-text">{description}</div>
          <Mobile>
            {hasButton && (
              <div className="project-details__panel-footer">
                <Button
                  onClick={onClick}
                  text={buttonText}
                  className="project-details__panel-button btn-default"
                />
              </div>
            )}
          </Mobile>
        </div>
        {hasButton && (
          <div className="project-details__panel-aside">
            <TabletDesktop>
              <Button
                onClick={onClick}
                text={buttonText}
                className="project-details__panel-button btn-default"
              />
            </TabletDesktop>
          </div>
        )}
      </div>
      {children && (
        <div className="project-details__panel-footer">{children}</div>
      )}
    </div>
  );
};

PanelHeader.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
    .isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
  hasButton: PropTypes.bool,
  children: PropTypes.node
};

export default PanelHeader;
