/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import purify from "dompurify";
import PropTypes from "prop-types";
import {
  generatePath,
  useParams,
  createSearchParams,
  useNavigate
} from "react-router-dom";
import Button from "@/components/Button";
import { routes } from "@/constants";
import { InfoIcon } from "@/icons";
import cn from "classnames";
import "./PaymentChooser.scss";

const images = require.context("@/assets/images/products", true);

const PaymentChooser = ({
  orderDetails,
  products,
  title,
  description,
  recommended,
  popular,
  useIcons,
  selectedPlan,
  setSelectedPlan
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedPlanInternal, setSelectedPlanInternal] = useState();
  if (!selectedPlan) {
    selectedPlan = selectedPlanInternal;
  }

  if (!setSelectedPlan) {
    setSelectedPlan = setSelectedPlanInternal;
  }
  const recommendedPlan = recommended || 1;
  const popularPlan = popular || 1;
  const recommendedPlanCasa = orderDetails
    ? orderDetails.plane.filter(
        (plan) => plan.product.name.toLowerCase() === "standard"
      )
    : null;
  const [sortedPlans, setSortedPlans] = useState();

  useEffect(() => {
    if (orderDetails) {
      setSortedPlans(
        orderDetails.plane.slice().sort((a, b) => {
          return b.price - a.price;
        })
      );

      if (recommendedPlanCasa && recommendedPlanCasa.length) {
        setSelectedPlan(recommendedPlanCasa[0].product_id);
      }
      if (orderDetails.plane.length === 1) {
        setSelectedPlan(orderDetails.plane[0].product_id);
      }
    }

    if (products) {
      if (products[recommendedPlan]) {
        setSelectedPlan(products[recommendedPlan].id);
      }
      /*
      if (products.length === 1) {
        setSelectedPlan(products[0].id);
      }
      */
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContinue = () => {
    const path = generatePath(
      orderDetails ? routes.ORDER_CASA_AGREEMENT : routes.ORDER_PAYMENT,
      { id }
    );
    const params = `?${createSearchParams({ product_id: selectedPlan })}`;

    navigate({
      pathname: path,
      search: params
    });
  };

  const handleChoosePlan = (planId) => {
    setSelectedPlan(planId);
  };

  return (
    <div className="payment-chooser">
      {title && <h2 className="project-details__panel-title">{title}</h2>}
      {description && (
        <div className="project-details__panel-text">
          {useIcons && <InfoIcon />}
          <span>{description}</span>
        </div>
      )}
      {/* CASA */}
      {orderDetails && sortedPlans && (
        <div
          className={cn(
            "payment-chooser__wrap",
            `payment-chooser__wrap_col-${orderDetails.plane.length}`
          )}>
          {sortedPlans.map((item) => (
            <div key={item.product_id} className="payment-chooser__col">
              <div
                className={cn(
                  "payment-chooser__item",
                  selectedPlan === item.product_id && "active",
                  recommendedPlanCasa.length &&
                    recommendedPlanCasa[0].product.name === item.product.name &&
                    "selected"
                )}
                onClick={() => handleChoosePlan(item.product_id)}>
                {recommendedPlanCasa[0] &&
                  recommendedPlanCasa[0].product.name === item.product.name && (
                    <div className="payment-chooser__item-badge">
                      Recommended for you
                    </div>
                  )}
                <div className="payment-chooser__item-title">
                  {item.product.name}
                </div>
                {item.product.description && (
                  <ul className="payment-chooser__options">
                    {[item.product.description].map((option) => (
                      <li
                        key={option}
                        className="payment-chooser__options-item"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: purify.sanitize(option)
                        }}
                      />
                    ))}
                  </ul>
                )}
                <div className="payment-chooser__item-footer">
                  <div className="payment-chooser__item-price">
                    ${item.price / 100}
                  </div>
                  <Button
                    text={`Select ${item.product.name}`}
                    className={cn(
                      "payment-chooser__item-button btn-fullwidth",
                      recommendedPlanCasa.length &&
                        recommendedPlanCasa[0].product.name ===
                          item.product.name
                        ? "btn-default"
                        : "btn-light",
                      (!selectedPlan || selectedPlan !== item.product_id) &&
                        "payment-chooser__item-button_disabled"
                    )}
                    onClick={handleContinue}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* MASA */}
      {products && (
        <div className="payment-chooser__wrap">
          {products.map((item, index) => (
            <div
              key={`${item.id}-${item.name}`}
              className="payment-chooser__col">
              <div
                className={cn(
                  "payment-chooser__item",
                  selectedPlan === item.id && "active",
                  recommendedPlan === index && "selected"
                )}
                onClick={() => handleChoosePlan(item.id)}>
                {recommendedPlan === index && (
                  <div className="payment-chooser__item-badge">
                    Recommended for you
                  </div>
                )}
                {useIcons && (
                  <div className="payment-chooser__item-image">
                    <img
                      alt="logo"
                      src={images(
                        `./${item.name.toLowerCase().replace(" ", "_")}.svg`
                      )}
                    />
                  </div>
                )}
                <div className="payment-chooser__item-title">
                  {item.name}
                  {popularPlan === index && (
                    <div className="payment-chooser__item-most-popular">
                      Most popular
                    </div>
                  )}
                </div>
                {item.description && (
                  <ul className="payment-chooser__options">
                    {item.description.map((option) => (
                      <li
                        key={`${item.name}-${option.text}`}
                        className="payment-chooser__options-item"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: purify.sanitize(option.text)
                        }}
                      />
                    ))}
                  </ul>
                )}
                <div className="payment-chooser__item-footer">
                  <div className="payment-chooser__item-price">
                    ${item.amount / 100}
                  </div>
                  <Button
                    text={`Select ${item.name}`}
                    className={cn(
                      "payment-chooser__item-button btn-fullwidth",
                      recommendedPlan === index ? "btn-default" : "btn-light",
                      (!selectedPlan || selectedPlan !== item.id) &&
                        "payment-chooser__item-button_disabled"
                    )}
                    onClick={handleContinue}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

PaymentChooser.propTypes = {
  orderDetails: PropTypes.object,
  products: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.string,
  recommended: PropTypes.number,
  popular: PropTypes.number,
  useIcons: PropTypes.bool,
  selectedPlan: PropTypes.string,
  setSelectedPlan: PropTypes.func
};

export default PaymentChooser;
