import React, { useEffect, useState } from "react";
import { CheckCircleDisabledIcon, CheckCircleSuccessIcon } from "@/icons";
import "./PasswordValidityRules.scss";
import PropTypes from "prop-types";

const passwordRule1 = /(?=.*[0-9])/;
const passwordRule2 = /(?=.*[\^$*.[\]{}()?\-"!@#%&/,><':;_~`])/;
const passwordRule3 = /(?=.*[A-Z])/;
const passwordRule4 = /(?=.*[a-z])/;

const ValidityIcon = ({ isValid, ...props }) => {
  return isValid ? (
    <CheckCircleSuccessIcon {...props} />
  ) : (
    <CheckCircleDisabledIcon {...props} />
  );
};

ValidityIcon.propTypes = {
  isValid: PropTypes.bool,
  className: PropTypes.string
};

const PasswordValidityRules = ({ password }) => {
  const [isFirstValid, setIsFirstValid] = useState();
  const [isSecondValid, setIsSecondValid] = useState();
  const [isThirdValid, setIsThirdValid] = useState();
  const [isFourthValid, setIsFourthValid] = useState();

  useEffect(() => {
    setIsFirstValid(passwordRule1.test(password));
    setIsSecondValid(passwordRule2.test(password));
    setIsThirdValid(passwordRule3.test(password));
    setIsFourthValid(passwordRule4.test(password));
  }, [password]);

  return (
    <div className="password-validity">
      <div className="password-validity-item">
        <div className="password-validity-item__icon-wr">
          <ValidityIcon
            isValid={isFirstValid}
            className="password-validity-item__icon"
          />
        </div>
        <span className="password-validity-item__text">
          Contains at least 1 number
        </span>
      </div>
      <div className="password-validity-item">
        <div className="password-validity-item__icon-wr">
          <ValidityIcon
            isValid={isSecondValid}
            className="password-validity-item__icon"
          />
        </div>
        <span className="password-validity-item__text">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Contains at least 1 special character (^ $ * . [ ] {} ( ) ? - " ! @ #
          {/* eslint-disable-next-line react/no-unescaped-entities */}% & / \ ,{" "}
          {"> <"} ' : ; | _ ~ ` + =)
        </span>
      </div>
      <div className="password-validity-item">
        <div className="password-validity-item__icon-wr">
          <ValidityIcon
            isValid={isThirdValid}
            className="password-validity-item__icon"
          />
        </div>
        <span className="password-validity-item__text">
          Contains at least 1 uppercase letter
        </span>
      </div>
      <div className="password-validity-item">
        <div className="password-validity-item__icon-wr">
          <ValidityIcon
            isValid={isFourthValid}
            className="password-validity-item__icon"
          />
        </div>
        <span className="password-validity-item__text">
          Contains at least 1 lowercase letter
        </span>
      </div>
    </div>
  );
};

PasswordValidityRules.propTypes = {
  password: PropTypes.string.isRequired
};

export default PasswordValidityRules;
