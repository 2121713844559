import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExternalLinks, routes, lsKeys } from "@/constants";
import { useNavigate } from "react-router-dom";
import InputField from "@/components/InputField";
import Button from "@/components/Button";
import { isInProcess } from "@/store/pending/pending.reducer";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "@/utils/yup";
import {
  AccountActions,
  preEnableMFA,
  enableMFA
} from "@/store/account/account.actions";
import CircleSpinner from "@/components/CircleSpinner";

const schema = yup.object({
  code: yup.string().nullable().min(6).max(6).required()
});

const EnableMFA = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mfaDetails, setMfaDetails] = useState();

  const isLoading = useSelector((state) =>
    isInProcess(state, AccountActions.POST_ENABLE_MFA)
  );

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    (async () => {
      try {
        const details = await dispatch(preEnableMFA());
        setMfaDetails(details);
      } catch (err) {
        console.log(err);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data) => {
    try {
      const res = await dispatch(
        enableMFA({
          code: data.code
        })
      );

      if (res && res.message) {
        localStorage.setItem(lsKeys.MFA_SESSION, true);
        navigate(routes.MFA_ENABLE_SUCCESS, { replace: true });
      }
    } catch (error) {
      setError("code", {
        type: "custom",
        message:
          error.data?.code?.join("\n") || error.data?.message || "Invalid code"
      });
    }
  };

  return (
    <div className="mfa">
      <div className="mfa__steps">
        <div className="fields-group">
          <p className="mfa__step-text">
            {/* eslint-disable-next-line */}
            1. Please download{" "}
            <a
              target="_blank"
              rel="noreferrer noopener"
              href={ExternalLinks.GOOGLE_AUTHENTICATOR}>
              Google Authenticator
            </a>{" "}
            ,{" "}
            <a
              target="_blank"
              rel="noreferrer noopener"
              href={ExternalLinks.MICROSOFT_AUTHENTICATOR}>
              Microsoft Authenticator
            </a>{" "}
            or use your preferred MFA application from your phone&apos;s store.
          </p>
        </div>
        <div className="fields-group">
          <p className="mfa__step-text">
            2. Scan the QR code below or enter key manually using the
            application:
          </p>
          {mfaDetails ? (
            <>
              {mfaDetails.qr && (
                <div className="mfa__qr-code-image-wrapper">
                  <img
                    className="mfa__qr-code-image"
                    src={mfaDetails.qr}
                    alt="QR code"
                  />
                </div>
              )}
              {mfaDetails.secret_code && (
                <div className="mfa__secret">
                  <div className="mfa__secret-label">Secret:</div>
                  <div className="mfa__secret-value">
                    {mfaDetails.secret_code}
                  </div>
                </div>
              )}
            </>
          ) : (
            <CircleSpinner />
          )}
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="mfa__steps">
          <div className="fields-group">
            <p className="mfa__step-text">
              3. Type the 6-digit code from the authenticator application to
              verify your setup:
            </p>
            <InputField
              setValue={setValue}
              otpInput
              name="code"
              errors={errors}
              type="text"
              placeholder="Code"
              {...register("code")}
            />
          </div>
          <Button
            isLoading={isLoading}
            type="submit"
            className="btn-default btn-fullwidth"
            text="Set up multi-factor authentication"
          />
        </form>
      </div>
    </div>
  );
};

export default EnableMFA;
