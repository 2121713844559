import React from "react";
import {
  createSearchParams,
  useNavigate,
  Routes,
  Route
} from "react-router-dom";
import { routes } from "@/constants";
import RegisterForm from "./RegisterForm";
import CodeConfirmationForm from "./CodeConfirmationForm";

const RegisterForms = () => {
  const navigate = useNavigate();

  const onAfterRegisterSubmit = (email) => {
    const params = `?${createSearchParams({ email })}`;
    navigate({
      pathname: routes.REGISTER_CONFIRMATION,
      search: params
    });
  };

  const onAfterCodeConfirmationSubmit = () => {
    navigate(routes.LOGIN);
  };

  return (
    <Routes>
      <Route
        index
        element={<RegisterForm onAfterSubmit={onAfterRegisterSubmit} />}
      />
      <Route
        path="confirmation"
        element={
          <CodeConfirmationForm onAfterSubmit={onAfterCodeConfirmationSubmit} />
        }
      />
    </Routes>
  );
};

export default RegisterForms;
