import { createReducer } from "@reduxjs/toolkit";
import { AuthActions } from "@/store/auth/auth.actions";
import { AuthTypes, lsKeys } from "@/constants";
import { reduceSuccessResponse } from "@/utils/getSuccessResponse";
import { isFailure, isSuccess } from "@/utils/createAsyncAction";
import parseJwt from "@/utils/parseJwtToken";

const initialState = {
  authType: localStorage.getItem(lsKeys.AUTH_TYPE),
  isLoggedIn: !!localStorage.getItem(lsKeys.AUTHORIZATION_TOKEN),
  mfaSetupDetails: null,
  account: localStorage.getItem(lsKeys.AUTHORIZATION_TOKEN)
    ? parseJwt(localStorage.getItem(lsKeys.AUTHORIZATION_TOKEN))
    : null,
  registerRequest: {},
  confirmEmailCodeRequest: {}
};

export const auth = createReducer(initialState, {
  [AuthActions.REGISTER]: (state, action) => {
    state.registerRequest = action.payload;
  },
  [AuthActions.CONFIRM_EMAIL_CODE]: (state, action) => {
    return {
      ...state,
      confirmEmailCodeRequest: action.payload
    };
  },
  [AuthActions.LOGIN]: (state, action) =>
    reduceSuccessResponse(state, action, (res) => {
      if (res?.token) {
        localStorage.setItem(lsKeys.AUTHORIZATION_TOKEN, res.token);
      }
      localStorage.setItem(lsKeys.AUTH_TYPE, AuthTypes.EMAIL_PASSWORD);

      return {
        ...state,
        authType: AuthTypes.EMAIL_PASSWORD,
        isLoggedIn: !!res?.token,
        account: res?.token ? parseJwt(res?.token) : null
      };
    }),
  [AuthActions.VERIFY_MFA_SOFTWARE_TOKEN]: (state, action) =>
    reduceSuccessResponse(state, action, (res) => {
      if (res?.token) {
        localStorage.setItem(lsKeys.AUTHORIZATION_TOKEN, res.token);
      }
      localStorage.setItem(lsKeys.AUTH_TYPE, AuthTypes.EMAIL_PASSWORD);

      return {
        ...state,
        authType: AuthTypes.EMAIL_PASSWORD,
        isLoggedIn: !!res?.token,
        account: res?.token ? parseJwt(res?.token) : null
      };
    }),
  [AuthActions.RESPOND_TO_AUTH_CHALLENGE]: (state, action) =>
    reduceSuccessResponse(state, action, (res) => {
      if (res?.token) {
        localStorage.setItem(lsKeys.AUTHORIZATION_TOKEN, res.token);
      }
      localStorage.setItem(lsKeys.AUTH_TYPE, AuthTypes.EMAIL_PASSWORD);

      return {
        ...state,
        authType: AuthTypes.EMAIL_PASSWORD,
        isLoggedIn: !!res?.token,
        account: res?.token ? parseJwt(res?.token) : null
      };
    }),
  [AuthActions.LOGOUT]: (state) => {
    localStorage.clear();
    state.authType = null;
    state.isLoggedIn = false;
    state.account = null;
    state.mfaSetupDetails = null;
  },
  [AuthActions.VALIDATE_TOKEN]: (state, action) => {
    if (
      isFailure(action) &&
      action.payload?.errorData?.code !== "ECONNABORTED" &&
      action.payload?.errorData?.code !== "ERR_CANCELED"
    ) {
      state.isLoggedIn = false;
      state.user = false;
      state.authType = null;
      state.mfaSetupDetails = null;

      localStorage.clear();

      return state;
    }

    if (isSuccess(action)) {
      state.isLoggedIn = true;
      state.user = parseJwt(localStorage.getItem(lsKeys.AUTHORIZATION_TOKEN));
    }

    return state;
  },
  [AuthActions.LOGIN_USING_SOCIAL]: (
    state,
    { payload: { authenticationFlowType, accessToken, idToken } }
  ) => {
    localStorage.setItem(lsKeys.AUTHORIZATION_TOKEN, accessToken);
    state.isLoggedIn = true;
    state.authType = authenticationFlowType;
    state.user = parseJwt(idToken);
  },
  [AuthActions.ASSOCIATE_MFA_SOFTWARE_TOKEN]: (state, action) =>
    reduceSuccessResponse(state, action, (res) => {
      return {
        ...state,
        mfaSetupDetails: res
      };
    }),
  [AuthActions.REMOVE_MFA_SETUP_DETAILS]: (state) => {
    state.mfaSetupDetails = null;
  }
});

export const getIsAuthenticated = (state) => state.auth.isLoggedIn;

export const getMFASetupDetails = (state) => state.auth.mfaSetupDetails;

export const getAuthType = (state) => state.auth.authType;

export const getRegisterErrorData = (state) =>
  state.auth.registerRequest?.errorData;

export const getCodeConfirmationErrorData = (state) =>
  state.auth.confirmEmailCodeRequest?.errorData;

export default auth;
