import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ScrollToTop from "@/components/ScrollToTop/ScrollToTop";
import "@/assets/index.scss";
import { MainLayout, DrawerLayout } from "@/layouts";

import RequireAuth from "@/components/RequireAuth";
import OrderPage from "@/pages/OrderPage";
import AuthPage from "@/pages/AuthPage";
import RootPage from "@/pages/RootPage";
import Amplify from "aws-amplify";
import { setLocale } from "yup";
import NewOrderPage from "@/pages/NewOrderPage";
import NewOrderCasaPage from "@/pages/NewOrderCasaPage";
import StartOrderPage from "@/pages/StartOrderPage";
import OrderCasaPage from "@/pages/OrderCasaPage";
import {
  ChangePasswordForm,
  ChangePasswordSuccess
} from "@/components/ChangePassword";
import EnableMFA from "@/components/MFASetup/EnableMFA";
import DisableMFA from "@/components/MFASetup/DisableMFA";
import EnableMFASuccess from "@/components/MFASetup/EnableMFASuccess";
import DisableMFASuccess from "@/components/MFASetup/DisableMFASuccess";

const REDIRECT_SIGN_IN_URLS = process.env.REACT_APP_AWS_COGNITO_IDP_SIGNIN_URL;
const REDIRECT_SIGN_OUT_URLS = process.env.REACT_APP_AWS_COGNITO_IDP_SIGNIN_URL;

const [LOCAL_REDIRECT_SIGN_IN_URL, PRODUCTION_REDIRECT_SIGN_IN_URL] =
  REDIRECT_SIGN_IN_URLS.split(",");

const [LOCAL_REDIRECT_SIGN_OUT_URL, PRODUCTION_REDIRECT_SIGN_OUT_URL] =
  REDIRECT_SIGN_OUT_URLS.split(",");

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const amplifyConfig = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    identityPoolRegion: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_AWS_COGNITO_CLIENT_DOMAIN_NAME,
      redirectSignIn: isLocalhost
        ? LOCAL_REDIRECT_SIGN_IN_URL
        : PRODUCTION_REDIRECT_SIGN_IN_URL,
      redirectSignOut: isLocalhost
        ? LOCAL_REDIRECT_SIGN_OUT_URL
        : PRODUCTION_REDIRECT_SIGN_OUT_URL,
      responseType: process.env.REACT_APP_AWS_COGNITO_IDP_GRANT_FLOW // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }
};

Amplify.configure(amplifyConfig);

setLocale({
  mixed: {
    default: "Required field",
    required: "Required field"
  },
  string: {
    length: ({ length }) => `Value length should be ${length}`,
    min: ({ min }) => `Value cannot be less than ${min} symbols`,
    max: ({ max }) => `Value cannot be more than ${max} symbols`
  },
  number: {
    // eslint-disable-next-line no-template-curly-in-string
    min: "Minimum value is ${min}"
  }
});

const App = () => {
  return (
    <div className="root">
      <Suspense fallback={<p>Loading...</p>}>
        <ScrollToTop />
        <Routes>
          <Route element={<RequireAuth />}>
            {/* private routes */}
            <Route path="start-order/*" element={<StartOrderPage />} />

            <Route path="order/*" element={<OrderPage />} />
            <Route path="new-order/*" element={<NewOrderPage />} />

            <Route path="order-casa/*" element={<OrderCasaPage />} />
            <Route path="new-order-casa/*" element={<NewOrderCasaPage />} />

            <Route element={<MainLayout />}>
              <Route path="/*" element={<RootPage />} />
              {/* <Route path="test" element={<TestPage />} /> */}
            </Route>
            <Route element={<DrawerLayout pageState="settings" />}>
              <Route path="change-password" element={<ChangePasswordForm />} />
              <Route
                path="change-password/success"
                element={<ChangePasswordSuccess />}
              />
              <Route path="/mfa-enable" element={<EnableMFA />} />
              <Route path="/mfa-disable" element={<DisableMFA />} />
              <Route
                path="/mfa-enable-success"
                element={<EnableMFASuccess />}
              />
              <Route
                path="/mfa-disable-success"
                element={<DisableMFASuccess />}
              />
            </Route>
          </Route>

          <Route path="auth/*" element={<AuthPage />} />

          {/* Missing routes */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
    </div>
  );
};

export default App;
