import PanelHeader from "@/components/ProjectProgress/components/PanelHeader";
import {
  CheckCircleSuccessIcon,
  CircleWarningIcon,
  PaymentAbsentIcon,
  PaymentSuccessIcon
} from "@/icons";
import React from "react";
import PropTypes from "prop-types";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { routes } from "@/constants";
import { useSelector } from "react-redux";
import {
  getIsDevAndAppInfoFilled,
  getIsOrderPaid
} from "@/store/order/order.reducer";

export const PaymentPanelHeader = ({ active }) => {
  const isPaidOrder = useSelector(getIsOrderPaid);
  const isDevAndAppInfoFilled = useSelector(getIsDevAndAppInfoFilled);
  const { id } = useParams();
  const navigate = useNavigate();
  const onPaymentClick = () => {
    navigate(
      generatePath(routes.AGREEMENT, {
        id
      })
    );
  };

  return (
    <PanelHeader
      title="Payment"
      description={
        isPaidOrder
          ? "We've successfully received your payment"
          : "Choose your plan for the assessment"
      }
      icon={isPaidOrder ? <CheckCircleSuccessIcon /> : <CircleWarningIcon />}
      active={active}
      hasButton={isDevAndAppInfoFilled ? !isPaidOrder : false}
      buttonText="Pay"
      onClick={onPaymentClick}
    />
  );
};

PaymentPanelHeader.propTypes = {
  active: PropTypes.bool.isRequired
};

// eslint-disable-next-line
export const PaymentPanelBody = ({ orderDetails }) => {
  const isPaidOrder = useSelector(getIsOrderPaid);

  return (
    <div className="progress-accordion__body inner row-info-wrapper">
      <div className="row-info-wrapper__icon">
        {isPaidOrder ? <PaymentSuccessIcon /> : <PaymentAbsentIcon />}
      </div>
      <div className="row-info-wrapper__info">
        <div className="row-info-wrapper__title">
          {isPaidOrder
            ? "Successfully paid"
            : "Choose assessment type and place an order"}
        </div>
        <div className="row-info-wrapper__text">
          {isPaidOrder
            ? "You can find your receipt on email"
            : "To begin the assessment process, please choose if you need express or regular testing and finish your order."}
        </div>
      </div>
    </div>
  );
};

PaymentPanelBody.propTypes = {
  orderDetails: PropTypes.object.isRequired
};
