import * as yup from "yup";

yup.setLocale({
  mixed: {
    default: "Required field",
    required: "Required field"
  },
  string: {
    length: ({ length }) => `String length should be ${length}`,
    min: ({ min }) => `String cannot contain less than ${min} symbols`,
    max: ({ max }) => `String cannot contain more than ${max} symbols`,
    email: () => `Should be a valid email`,
    url: "Should be a valid URL",
    lowercase: "String must be lowercase",
    uppercase: "String must be uppercase"
  },
  number: {
    min: ({ min }) => `Number cannot be less than ${min}`,
    max: ({ max }) => `Number cannot be larger than ${max}`
  },
  boolean: {},
  date: {},
  object: {},
  array: {}
});

export default yup;
