import React, { useEffect } from "react";
import Button, { ButtonLink } from "@/components/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "@/utils/yup";
import Checkbox from "@/components/Checkbox";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { routes } from "@/constants";
import "./Agreement.scss";
import {
  getOrderDetailsById,
  OrderActions,
  postAgreement
} from "@/store/order/order.actions";
import { useDispatch, useSelector } from "react-redux";
import { isInProcess } from "@/store/pending/pending.reducer";

const schema = yup.object({
  agree: yup.bool().oneOf([true], "Field must be checked")
});

const Agreement = () => {
  const isLoading = useSelector((state) =>
    isInProcess(state, OrderActions.POST_AGREEMENT)
  );
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (id) {
      dispatch(getOrderDetailsById(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSubmit = async ({ agree }) => {
    await dispatch(postAgreement({ id, agree }));
    const path = generatePath(routes.PROJECT_DETAILS, { id });
    navigate(path);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="agreement">
      <div className="agreement__content">
        <p className="agreement__text fields-group">
          PLEASE READ THIS AGREEMENT CAREFULLY AS IT GOVERNS YOUR USE OF THE
          SERVICES (AS DEFINED BELOW), UNLESS WE HAVE EXECUTED A SEPARATE
          WRITTEN AGREEMENT WITH YOU FOR THAT PURPOSE. BY CLICKING ON THE “I
          AGREE” OR SIMILAR BUTTON OR BY UTILIZING THE SERVICES, YOU ACCEPT ALL
          THE TERMS AND CONDITIONS OF THIS AGREEMENT. IF YOU ARE ENTERING INTO
          THIS AGREEMENT ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU
          REPRESENT THAT YOU HAVE THE LEGAL AUTHORITY TO BIND THE COMPANY OR
          OTHER LEGAL ENTITY TO THIS AGREEMENT. WE MAY MODIFY THIS AGREEMENT
          FROM TIME TO TIME, SUBJECT TO SECTION (CHANGES TO THIS AGREEMENT).
          YOUR CONTINUED USE OF THE SERVICES FOLLOWING THE POSTING OF CHANGES
          CONSTITUTES AGREEMENT TO AND ACCEPTANCE OF THESE CHANGES.
        </p>
        <p className="agreement__text fields-group">
          Services: Company will conduct a Mobile Application Security
          Assessment (MASA). Company will identify application design and
          implementation flaws from both authenticated and unauthenticated
          perspectives. Company will identify potential vulnerabilities in
          application(s) using the Open Web Application Security Project (OWASP)
          Mobile Application Security Verification Standard (MASVS). In
          accordance with the OWASP Mobile Security Testing Guide (MSTG).
          Company will use manual and automated testing to assess the in-scope
          web application and underlying architecture against the following
          controls:
        </p>
        <ul className="agreement__list">
          <li>
            <strong>
              V1: Architecture, Design and Threat Modeling Requirements
            </strong>
          </li>
          <li>
            <strong>V2: Data Storage and Privacy Requirements</strong>
          </li>
          <li>
            <strong>V3: Cryptography Requirements</strong>
          </li>
          <li>
            <strong>
              V4: Authentication and Session Management Requirements
            </strong>
          </li>
          <li>
            <strong>V5: Network Communication Requirements</strong>
          </li>
          <li>
            <strong>V6: Platform Interaction Requirements</strong>
          </li>
          <li>
            <strong>V7: Code Quality and Build Setting Requirements</strong>
          </li>
          <li>
            <strong>V8: Resilience Requirements</strong>
          </li>
        </ul>
        <p className="agreement__text fields-group">
          The purpose of this assessment is to validate whether the in-scope
          application meets or exceeds the MASA guidelines as laid out above and
          should not be read as a comprehensive penetration test or maturity
          assessment.
        </p>
        <p className="agreement__text fields-group">
          Client understands that security testing may emulate tactics used by
          outside attackers with and without knowledge of specific applications,
          and with malicious intent, however, no such tactic shall intentionally
          interrupt services without notice to client. Company will use
          reasonable, industry-standard precautions to prevent or minimize any
          risks to the application or underlying architecture that may be
          associated with such testing. Client understands that it is their sole
          responsibility to arrange for complete backups of application data and
          to plan for system redundancy as applicable. Company shall be held
          harmless for impacts to performance of the application during the
          testing period.
        </p>
        <p className="agreement__text fields-group">
          IN NO EVENT SHALL EITHER PARTY BE LIABLE, WHETHER IN CONTRACT, TORT OR
          OTHERWISE, FOR ANY INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR
          INDIRECT DAMAGES, LOSSES, EXPENSES OR COSTS OF ANY KIND COSTS OF
          PROCUREMENT OF SUBSTITUTE OR REPLACEMENT GOODS OR SERVICES, LOST
          BUSINESS PROFITS OR REVENUE OR LOST OR CORRUPTED DATA ARISING OUT OF
          THIS AGREEMENT OR USE OF THE SERVICES OR DELIVERABLES, EVEN IF ADVISED
          OF THE POSSIBILITY.
        </p>
        <p className="agreement__text fields-group">
          THE COMPANY’S TOTAL LIABILITY UNDER THIS AGREEMENT SHALL BE LIMITED TO
          THE FEES PAID BY CLIENT FOR THE SERVICES HEREUNDER. NOTHING IN THIS
          AGREEMENT SHALL LIMIT THE CLIENT’S LIABILITY FOR ANY DAMAGE THAT
          CANNOT BE EXCLUDED BY LAW.
        </p>
        <p className="agreement__text fields-group">
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, COMPANY’S TOTAL
          CUMULATIVE LIABILITY TO CUSTOMER OR ANY THIRD PARTY UNDER THIS
          AGREEMENT, FROM ALL CAUSES OF ACTION AND ALL THEORIES OF LIABILITY,
          WILL BE LIMITED TO AND WILL NOT EXCEED THE FEES ACTUALLY PAID FOR THE
          MASA TEST.
        </p>
        <p className="agreement__text fields-group">
          Governing Law. You agree that all matters relating to your access to
          or use of the Services, including all disputes, will be governed by
          the laws of the United States and by the laws of the State of
          Washington without regard to its conflicts of laws provisions. You
          agree to the personal jurisdiction by and venue in the state and
          federal courts in King County, Washington, and waive any objection to
          such jurisdiction or venue. Any claim under these Terms of Use must be
          brought within one (1) year after the cause of action arises, or such
          claim or cause of action is barred. Claims made under the separate
          terms and conditions of purchase for goods and services are not
          subject to this limitation. No recovery may be sought or received for
          damages other than out-of-pocket expenses, except that the prevailing
          party will be entitled to costs and attorneys’ fees. In the event of
          any controversy or dispute between arising out of or in connection
          with your use of the Site, the parties shall attempt, promptly and in
          good faith, to resolve any such dispute. If we are unable to resolve
          any such dispute within a reasonable time (not to exceed thirty (30)
          days), then either party may submit such controversy or dispute to
          mediation. If the dispute cannot be resolved through mediation, then
          the parties shall be free to pursue any right or remedy available to
          them under applicable law.
        </p>
        <p className="agreement__text fields-group">
          CLIENT AGREES THAT REMEDIES, OBLIGATIONS AND WARRANTIES HEREIN ARE
          EXCLUSIVE AND IN LIEU OF ANY OTHER REPRESENTATION, REMEDY OR WARRANTY
          INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTY OF MERCHANTABILITY
          OR FITNESS FOR A PARTICULAR PURPOSE.
        </p>
      </div>
      {/* <div className="fields-group">
        <a
          className="btn-text-link agreement__download-btn"
          href={ExternalLinks.TERMS}
          target="_blank"
          rel="noreferrer">
          Open full terms of use
        </a>
      </div> */}
      <div className="fields-group">
        <Checkbox
          text="I grant authorization for testing my application using the credentials and URLs provided."
          name="agree"
          errors={errors}
          {...register("agree")}
        />
      </div>
      <div className="main-layout__drawer-footer">
        <div className="main-layout__drawer-buttons">
          <div className="main-layout__drawer-buttons-item">
            <ButtonLink
              to={generatePath(routes.ORDER_APPLICATION_INFO, { id })}
              text="Back"
              className="btn-light btn-fullwidth"
            />
          </div>
          <div className="main-layout__drawer-buttons-item">
            <Button
              isLoading={isLoading}
              type="submit"
              text="Continue"
              className="btn-default btn-fullwidth"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default Agreement;
