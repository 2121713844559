import React, { useEffect, useState } from "react";
import Header from "@/components/Header";
import PageSidebar from "@/components/PageSidebar";
import Footer from "@/components/Footer";
import Notifications from "@/components/Notifications";
import { Desktop } from "@/components/Responsive";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { getAllOrders } from "@/store/order/order.actions";
import { getOrderList } from "@/store/order/order.reducer";
import cn from "classnames";
import { routes } from "@/constants";
import "./MainLayout.scss";

const MainLayout = () => {
  const dispatch = useDispatch();
  const orderList = useSelector(getOrderList);
  const location = useLocation();
  const [isOpenedNotifications, setIsOpenedNotifications] = useState(false);
  const isMainRoute = routes.ROOT === location.pathname;

  const handleToggleNotifications = () => {
    setIsOpenedNotifications(!isOpenedNotifications);
  };

  useEffect(() => {
    dispatch(getAllOrders());
  }, [dispatch]);

  return (
    <div
      className={cn("main-layout", {
        "main-layout_white-bg":
          isMainRoute && !(orderList && orderList.data.length)
      })}>
      <div className="main-layout__header">
        <Header handleClickNotifications={handleToggleNotifications} />
      </div>
      <Desktop>
        <div className="main-layout__aside">
          <PageSidebar />
        </div>
      </Desktop>
      <div className="main-layout__body" id="main-layout-body">
        <Outlet />
      </div>
      <div className="main-layout__footer">
        <Footer />
      </div>
      {isOpenedNotifications && (
        <Notifications handleClick={handleToggleNotifications} />
      )}
    </div>
  );
};

export default MainLayout;
