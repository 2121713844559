import React from "react";
import MediaQuery from "react-responsive";

const MIN_DESKTOP_WIDTH = 1024;
const MIN_TABLET_WIDTH = 768;
const MIN_LG_WIDTH = 1440;
const MIN_MD_WIDTH = 1260;

const Desktop = (props) => (
  <MediaQuery minWidth={MIN_DESKTOP_WIDTH} {...props} />
);

const Tablet = (props) => (
  <MediaQuery
    minWidth={MIN_TABLET_WIDTH}
    maxWidth={MIN_DESKTOP_WIDTH - 1}
    {...props}
  />
);

const Mobile = (props) => (
  <MediaQuery maxWidth={MIN_TABLET_WIDTH - 1} {...props} />
);

const TabletDesktop = (props) => (
  <MediaQuery minWidth={MIN_TABLET_WIDTH} {...props} />
);

const MobileTablet = (props) => (
  <MediaQuery maxWidth={MIN_DESKTOP_WIDTH - 1} {...props} />
);

const TabletMd = (props) => (
  <MediaQuery
    minWidth={MIN_TABLET_WIDTH}
    maxWidth={MIN_LG_WIDTH - 1}
    {...props}
  />
);

const Lg = (props) => <MediaQuery minWidth={MIN_LG_WIDTH} {...props} />;
const MdLg = (props) => <MediaQuery minWidth={MIN_MD_WIDTH} {...props} />;

export {
  Desktop,
  Tablet,
  Mobile,
  TabletDesktop,
  MobileTablet,
  TabletMd,
  Lg,
  MdLg
};
