import React from "react";
import purify from "dompurify";
import { PropTypes } from "prop-types";
import AnimationImage from "@/components/AnimationImage";
import "./SuccessBox.scss";

const SuccessBox = ({ title, subtitle, button }) => {
  return (
    <div className="success-box">
      <div className="success-box__wrap">
        <div className="success-box__image">
          <AnimationImage title="success" />
        </div>
        {title && <div className="success-box__title">{title}</div>}
        {subtitle && (
          <div
            className="success-box__text"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: purify.sanitize(subtitle) }}
          />
        )}
      </div>
      {button && (
        <div className="main-layout__drawer-footer">
          <div className="main-layout__drawer-buttons">
            <div className="main-layout__drawer-buttons-item">
              <a className="btn btn-default btn-fullwidth" href={button.url}>
                {button.label}
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

SuccessBox.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  button: PropTypes.object
};

export default SuccessBox;
