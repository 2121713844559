import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

const ChatwootWidget = ({ user, attribs, baseUrl, chatwootToken }) => {
  const [unreadCount, setUnreadCount] = useState(
    parseInt(localStorage.getItem("unreadCount") || 0, 10)
  );

  const isMounted = useRef(false);

  useEffect(() => {
    // Set isMounted to true when the component is mounted
    isMounted.current = true;

    // Cleanup function that will run when the component unmounts
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Initialize Chatwoot SDK
  useEffect(() => {
    if (window.$chatwoot) {
      // Set a small timeout to ensure the reset completes before re-initializing
      setTimeout(() => {
        // Set the new user after reset
        window.$chatwoot.setUser(user.id, user);
        window.$chatwoot.setCustomAttributes(attribs);
        window.$chatwoot.toggleBubbleVisibility("show");
      }, 500); // You can adjust the delay based on your app's behavior
    }

    const BASE_URL = baseUrl; // Change to your Chatwoot URL
    const websiteToken = chatwootToken; // Replace with your actual token
    const t = "script";
    const scriptId = "chatwoot";

    const g = document.createElement(t);
    const s = document.getElementsByTagName(t)[0];
    g.id = scriptId;
    g.src = `${BASE_URL}/packs/js/sdk.js`;
    g.defer = true;
    g.async = true;
    s.parentNode.insertBefore(g, s);
    g.onload = function () {
      console.log("loading the chat");
      window.chatwootSDK.run({
        websiteToken,
        baseUrl: BASE_URL
      });

      window.addEventListener("chatwoot:ready", function () {
        console.log("initing the chat");
        window.$chatwoot.setUser(user.id, user);
        window.$chatwoot.setCustomAttributes(attribs);

        /*
        window.$chatwoot.getUnreadCount().then((count) => {
          setUnreadCount(count);
          localStorage.setItem("unreadCount", count);
        });
        */
        setUnreadCount(0);
        localStorage.setItem("unreadCount", 0);

        document
          .getElementsByClassName("woot-widget-bubble")?.[0]
          .addEventListener("click", () => {
            setUnreadCount(0);
            localStorage.setItem("unreadCount", 0);
          });
      });

      // Listen for new messages
      window.addEventListener("chatwoot:on-message", () => {
        const newUnreadCount = 0; // unreadCount + 1;
        setUnreadCount(newUnreadCount);
        localStorage.setItem("unreadCount", newUnreadCount);
      });
    };

    return () => {
      if (!isMounted.current) {
        console.log("resetting the chat");
        // Clean up event listeners when the component unmounts
        window.removeEventListener("chatwoot:ready", () => {});
        window.removeEventListener("chatwoot:on-message", () => {});

        if (window.$chatwoot) {
          window.$chatwoot.toggle("close");
          window.$chatwoot.toggleBubbleVisibility("hide");
          window.$chatwoot.reset();
        }
      }
    };
  }, [unreadCount, baseUrl, chatwootToken, attribs, user]);

  // Update unread count on every render
  useEffect(() => {
    // Update the chat bubble with the unread count
    const updateUnreadBubble = () => {
      const chatBubble = document.querySelector(".woot-widget-bubble");
      if (!chatBubble) return;

      // Remove existing badge
      const existingBadge = chatBubble.querySelector(".unread-count-badge");
      if (existingBadge) {
        existingBadge.remove();
      }

      if (unreadCount > 0) {
        const badge = document.createElement("div");
        badge.className = "unread-count-badge";
        badge.textContent = unreadCount;

        // Style the badge
        badge.style.position = "absolute";
        badge.style.top = "5px";
        badge.style.right = "5px";
        badge.style.backgroundColor = "red";
        badge.style.color = "white";
        badge.style.borderRadius = "50%";
        badge.style.padding = "5px 10px";
        badge.style.fontSize = "12px";

        // Append the badge to the chat bubble
        chatBubble.appendChild(badge);
      }
    };
    updateUnreadBubble();
  }, [unreadCount]);

  return null;
};

ChatwootWidget.propTypes = {
  user: PropTypes.object.isRequired,
  attribs: PropTypes.object.isRequired,
  baseUrl: PropTypes.string.isRequired,
  chatwootToken: PropTypes.string.isRequired
};

export default ChatwootWidget;
