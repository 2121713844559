import React, { useEffect, useState } from "react";
import purify from "dompurify";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";
import ErrorMessage from "@/components/ErrorMessage";
import cn from "classnames";
import GooglePlacesAutocomplete, {
  geocodeByAddress
} from "react-google-places-autocomplete";

// eslint-disable-next-line react/display-name
const PlacesAutocompleteControl = (props) => {
  const {
    errors,
    name,
    defaultPlaceId,
    defaultValue,
    id,
    control,
    label,
    placeholder
  } = props;

  const {
    field: { onChange, value: searchSelection, ref }
  } = useController({
    name,
    control,
    defaultValue
  });

  const [searchValue, setSearchValue] = useState("");

  const handleSearchInput = (e, { action }) => {
    if (
      action === "menu-close" ||
      action === "input-blur" ||
      action === "set-value"
    ) {
      return;
    }

    setSearchValue(e);
  };

  const handleSearchSelection = (selection) => {
    setSearchValue(selection ? selection.label : "");
    onChange(selection);
  };

  const customSelectStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#ffffff" : "#272C3F",
      fontWeight: "500",
      transition: "0.2s background-color ease-in-out",
      cursor: "pointer"
    }),
    menu: () => ({
      position: "absolute",
      zIndex: "10",
      top: "100%",
      left: "0",
      right: "0",
      marginTop: "5px",
      backgroundColor: "#ffffff",
      borderRadius: "2px",
      border: "1px solid #d8dbe1",
      boxShadow: "0 4px 11px hsl(0deg 0% 0% / 10%)"
    }),
    control: (_, { selectProps: { hasError } }) => {
      return {
        display: "flex",
        alignItems: "center",
        height: "46px",
        borderRadius: "4px",
        fontSize: "16px",
        opacity: "1!important",
        fontWeight: "600",
        color: "#999999",
        border: `1px solid ${(hasError && "#e85b5b") || "#d1d1d9"}`,
        backgroundColor: "#ffffff",
        cursor: "text"
      };
    },
    singleValue: (styles) => {
      return {
        ...styles,
        display: "none!important",
        color: "#999999"
      };
    },
    input: (styles) => {
      return {
        ...styles,
        display: "flex",
        opacity: "1!important",
        "& input": {
          opacity: "1!important",
          fontWeight: "600"
        }
      };
    },
    valueContainer: (styles) => {
      return {
        ...styles,
        color: "#999999",
        padding: "2px 15px",
        fontSize: "16px",
        fontWeight: "600"
      };
    },
    indicatorContainer: () => ({
      display: "none"
    }),
    indicatorSeparator: () => ({
      display: "none"
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "16px",
      fontWeight: "normal",
      lineHeight: "1.1",
      color: "#717786"
    }),
    dropdownIndicator: (provided) => {
      const display = "none";
      return { ...provided, display };
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setSearchValue(defaultValue);
      geocodeByAddress(defaultValue).then((results) => {
        const [address] = results;

        onChange({
          label: address.formatted_address,
          value: {
            description: address.formatted_address,
            place_id: address.place_id,
            types: address.types
          }
        });
      });
    }
    // eslint-disable-next-line
  }, [defaultValue, defaultPlaceId]);

  return (
    <div className="form-group">
      {label && (
        <span
          className="form-label"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: purify.sanitize(label) }}
        />
      )}
      <GooglePlacesAutocomplete
        autocompletionRequest={{
          types: ["address"]
        }}
        ref={ref}
        debounce={300}
        name={name}
        selectProps={{
          isClearable: true,
          isSearchable: true,
          controlShouldRenderValue: false,
          noOptionsMessage: () => null,
          onChange: (e) => handleSearchSelection(e),
          onInputChange: (e, action) => handleSearchInput(e, action),
          name,
          hasError: !!errors[name],
          styles: customSelectStyles,
          inputValue: searchValue,
          value: searchSelection,
          className: cn({ "has-error": !!errors[name] }),
          id,
          inputId: `places-autocomplete-${name}`,
          instanceId: id,
          placeholder
        }}
      />

      <ErrorMessage name={name} errors={errors} />
    </div>
  );
};

PlacesAutocompleteControl.defaultProps = {
  errors: {},
  id: "select"
};

PlacesAutocompleteControl.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  defaultPlaceId: PropTypes.string,
  label: PropTypes.string
};

export default PlacesAutocompleteControl;
