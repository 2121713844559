import { createAsyncAction } from "@/utils/createAsyncAction";
import OrderService from "@/services/OrderService";
import { createAction } from "@reduxjs/toolkit";

export const OrderActions = {
  CREATE_ORDER: "CREATE_ORDER",
  GET_ORDER_DETAILS_BY_ID: "GET_ORDER_DETAILS_BY_ID",
  POST_APPLICATION_INFO: "POST_APPLICATION_INFO",
  APP_INFO_FILE_UPLOAD_SUCCESS: "APP_INFO_FILE_UPLOAD_SUCCESS",
  GET_ALL_ORDERS: "GET_ALL_ORDERS",
  GET_ALL_ORDERS_MORE: "GET_ALL_ORDERS_MORE",
  GET_ORDERS_TABLE: "GET_ORDERS_TABLE",
  UPDATE_DEVELOPER_INFO: "UPDATE_DEVELOPER_INFO",
  UPDATE_QUESTIONNAIRE: "UPDATE_QUESTIONNAIRE",
  START_QUESTIONNAIRE: "START_QUESTIONNAIRE",
  SEND_VERIFICATION_INFO_MANUAL: "SEND_VERIFICATION_INFO_MANUAL",
  SEND_VERIFICATION_INFO_AUTO: "SEND_VERIFICATION_INFO_AUTO",
  POST_AGREEMENT: "POST_AGREEMENT",
  RESEND_OTP_CODE: "RESEND_OTP_CODE",
  VERIFY_OTP_CODE: "VERIFY_OTP_CODE",
  CREATE_PAYMENT: "CREATE_PAYMENT",
  FETCH_ORDER_PRODUCTS: "FETCH_ORDER_PRODUCTS",
  CLEAR_ORDERS_TABLE: "CLEAR_ORDERS_TABLE",
  CLEAR_ORDER_DETAILS: "CLEAR_ORDER_DETAILS",
  FETCH_ORDER_TABLE_COUNT: "FETCH_ORDER_TABLE_COUNT",
  FETCH_MITIGATIONS_RECHECK: "FETCH_MITIGATIONS_RECHECK",
  CLEAR_ORDERS_REDUCER: "CLEAR_ORDERS_REDUCER",
  GET_ALL_SERVICES: "GET_ALL_SERVICES",
  POST_ASSESMENT_INFO: "POST_ASSESMENT_INFO",
  POST_SOURCE_CODE_INFO: "POST_SOURCE_CODE_INFO",
  READY_FOR_REVALIDATION: "READY_FOR_REVALIDATION",
  POST_PURCHASE_ORDER: "POST_PURCHASE_ORDER",
  POST_INVOICE: "POST_INVOICE",
  POST_REMOVE_ASSESMENT_FILE: "POST_REMOVE_ASSESMENT_FILE",
  POST_REMOVE_SOURCE_CODE_FILE: "POST_REMOVE_SOURCE_CODE_FILE",
  POST_REMOVE_PROJECT: "POST_REMOVE_PROJECT",

  GET_NEW_ORDERS: "GET_NEW_ORDERS",
  GET_IN_PROGRESS_ORDERS: "GET_IN_PROGRESS_ORDERS",
  GET_COMPLETED_ORDERS: "GET_COMPLETED_ORDERS",
  CLEAR_NEW_ORDERS: "CLEAR_NEW_ORDERS",
  CLEAR_IN_PROGRESS_ORDERS: "CLEAR_IN_PROGRESS_ORDERS",
  CLEAR_COMPLETED_ORDERS: "CLEAR_COMPLETED_ORDERS"
};

// CASA Order
export const createOrderCasa = createAsyncAction(
  OrderActions.CREATE_ORDER,
  async (params) => OrderService.createOrderCasa(params)
);

export const updateDeveloperInfoCasa = createAsyncAction(
  OrderActions.UPDATE_DEVELOPER_INFO,
  async (params) => OrderService.updateDeveloperInfoCasa(params)
);

export const getOrderCasaDetailsById = createAsyncAction(
  OrderActions.GET_ORDER_DETAILS_BY_ID,
  async (id) => OrderService.getOrderCasaDetails(id)
);

export const postApplicationInfoCasa = createAsyncAction(
  OrderActions.POST_APPLICATION_INFO,
  async ({ id, data }) => OrderService.sendApplicationInfoCasa({ id, data })
);

export const postAssesmentInfo = createAsyncAction(
  OrderActions.POST_ASSESMENT_INFO,
  async ({ id, file, data }) =>
    OrderService.sendAssesmentInfo({ id, file, data })
);

export const postSourceCodeInfo = createAsyncAction(
  OrderActions.POST_SOURCE_CODE_INFO,
  async ({ id, file, data }) =>
    OrderService.sendSourceCodeInfo({ id, file, data })
);

export const createPaymentCasa = createAsyncAction(
  OrderActions.CREATE_PAYMENT,
  // eslint-disable-next-line camelcase
  async ({ id, plane_id }) => OrderService.fetchPaymentCasa({ id, plane_id })
);

export const postReadyForRevalidationCasa = createAsyncAction(
  OrderActions.READY_FOR_REVALIDATION,
  async (id) => OrderService.readyForRevalidationCasa(id)
);

export const postPurchaseOrder = createAsyncAction(
  OrderActions.POST_PURCHASE_ORDER,
  async ({ id, file, data }) =>
    OrderService.sendPurchaseOrder({ id, file, data })
);

export const postInvoiceCasa = createAsyncAction(
  OrderActions.POST_INVOICE,
  async ({ id, data }) => OrderService.sendInvoiceCasa({ id, data })
);

export const postRemoveAssesmentFileCasa = createAsyncAction(
  OrderActions.POST_REMOVE_ASSESMENT_FILE,
  async (id) => OrderService.removeAssesmentFileCasa(id)
);

export const postRemoveSourceCodeFileCasa = createAsyncAction(
  OrderActions.POST_REMOVE_SOURCE_CODE_FILE,
  async (id) => OrderService.removeSourceCodeFileCasa(id)
);

export const postRemoveProjectCasa = createAsyncAction(
  OrderActions.POST_REMOVE_PROJECT,
  async (id) => OrderService.removeProjectCasa(id)
);

export const postAgreementCasa = createAsyncAction(
  OrderActions.POST_AGREEMENT,
  async ({ id, agree }) => OrderService.sendAgreementCasa({ id, agree })
);
// CASA Order

export const getAllServices = createAsyncAction(
  OrderActions.GET_ALL_SERVICES,
  async () => OrderService.getServices()
);

export const createOrder = createAsyncAction(
  OrderActions.CREATE_ORDER,
  async (params) => OrderService.createOrder(params)
);

export const updateDeveloperInfo = createAsyncAction(
  OrderActions.UPDATE_DEVELOPER_INFO,
  async (params) => OrderService.updateDeveloperInfo(params)
);

export const updateQuestionnaire = createAsyncAction(
  OrderActions.UPDATE_QUESTIONNAIRE,
  async (params) => OrderService.updateQuestionnaire(params)
);

export const startQuestionnaire = createAsyncAction(
  OrderActions.START_QUESTIONNAIRE,
  async (params) => OrderService.startQuestionnaire(params)
);

export const uploadFileSuccessful = createAction(
  OrderActions.APP_INFO_FILE_UPLOAD_SUCCESS
);

export const getOrderDetailsById = createAsyncAction(
  OrderActions.GET_ORDER_DETAILS_BY_ID,
  async (id) => OrderService.getOrderDetails(id)
);

export const postApplicationInfo = createAsyncAction(
  OrderActions.POST_APPLICATION_INFO,
  async ({ id, file, data }) =>
    OrderService.sendApplicationInfo({ id, file, data })
);

export const sendVerificationInfoManual = createAsyncAction(
  OrderActions.SEND_VERIFICATION_INFO_MANUAL,
  (data) => OrderService.sendVerificationInfo(data)
);

export const sendVerificationInfoAuto = createAsyncAction(
  OrderActions.SEND_VERIFICATION_INFO_AUTO,
  (data) => OrderService.sendVerificationInfo(data)
);

export const postAgreement = createAsyncAction(
  OrderActions.POST_AGREEMENT,
  async ({ id, agree }) => OrderService.sendAgreement({ id, agree })
);

export const resendOtpCode = createAsyncAction(
  OrderActions.RESEND_OTP_CODE,
  async (id) => OrderService.resendOtpVerification(id)
);

export const verifyOtpCode = createAsyncAction(
  OrderActions.VERIFY_OTP_CODE,
  async (id) => OrderService.verifyOtpCode(id)
);

export const clearOrdersTable = createAction(OrderActions.CLEAR_ORDERS_TABLE);

export const getAllOrders = createAsyncAction(
  OrderActions.GET_ALL_ORDERS,
  async (_page, _status) => OrderService.getOrders(_page, _status)
);

export const getAllOrdersMore = createAsyncAction(
  OrderActions.GET_ALL_ORDERS_MORE,
  async (_page) => OrderService.getOrders(_page)
);

export const getOrdersTable = (page, status) => async (dispatch) => {
  const fetchOrders = createAsyncAction(
    OrderActions.GET_ORDERS_TABLE,
    async (_page, filter) => OrderService.getOrders(_page, filter)
  );

  dispatch(clearOrdersTable());
  return dispatch(fetchOrders(page, status));
};

export const clearOrderDetails = createAction(OrderActions.CLEAR_ORDER_DETAILS);

export const createPayment = createAsyncAction(
  OrderActions.CREATE_PAYMENT,
  // eslint-disable-next-line camelcase
  async ({ id, product_id }) => OrderService.fetchPayment({ id, product_id })
);

export const fetchOrderTableCount = createAsyncAction(
  OrderActions.FETCH_ORDER_TABLE_COUNT,
  async () => OrderService.fetchOrderTableCount()
);

export const fetchOrderProducts = createAsyncAction(
  OrderActions.FETCH_ORDER_PRODUCTS,
  async () => OrderService.getProducts()
);

export const fetchMitigationsRecheck = createAsyncAction(
  OrderActions.FETCH_MITIGATIONS_RECHECK,
  async ({ id }) => OrderService.sendMitigationsRecheck({ id })
);

export const clearOrdersReducer = createAction(
  OrderActions.CLEAR_ORDERS_REDUCER
);

export const postRemoveProjectMasa = createAsyncAction(
  OrderActions.POST_REMOVE_PROJECT,
  async (id) => OrderService.removeProjectMasa(id)
);

export const postInvoice = createAsyncAction(
  OrderActions.POST_INVOICE,
  async ({ id, data }) => OrderService.sendInvoice({ id, data })
);

export const postReadyForRevalidation = createAsyncAction(
  OrderActions.READY_FOR_REVALIDATION,
  async (id) => OrderService.readyForRevalidation(id)
);
