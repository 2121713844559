import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import "./CircleSpinner.scss";

const CircleSpinner = ({ type }) => {
  return (
    <div
      className={cn(
        "circle-spinner-wrapper",
        `circle-spinner-wrapper-${type}`
      )}>
      <div className={cn("circle-spinner", `circle-spinner-${type}`)}>
        <div className="circle-spinner__item" />
      </div>
    </div>
  );
};

CircleSpinner.propTypes = {
  type: PropTypes.string // screen | container
};

export default CircleSpinner;
