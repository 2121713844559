import { useEffect } from "react";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import { loginUsingSocial, validateToken } from "@/store/auth/auth.actions";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "@/constants";

const ExternalLoginHandler = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || routes.ROOT;

  useEffect(() => {
    (async () => {
      try {
        const details = await Auth.currentAuthenticatedUser({
          bypassCache: false
        });
        const {
          authenticationFlowType,
          signInUserSession: {
            accessToken: { jwtToken: accessToken },
            idToken: { jwtToken: idToken }
          }
        } = details;

        await dispatch(
          loginUsingSocial({ authenticationFlowType, accessToken, idToken })
        );
        await dispatch(validateToken());
        navigate(from, { replace: true });
      } catch (err) {
        navigate(routes.ROOT);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from]);

  return null;
};

export default ExternalLoginHandler;
