import { createReducer } from "@reduxjs/toolkit";
import { reduceSuccessResponse } from "@/utils/getSuccessResponse";
import { CountriesActions } from "@/store/countries/countries.actions";

export const countries = createReducer(
  {
    countries: []
  },
  {
    [CountriesActions.GET_COUNTRIES_AND_STATES]: (state, action) =>
      reduceSuccessResponse(state, action, (res) => ({
        countries: res
      }))
  }
);

export const getCountries = (state) => {
  return state.countries.countries.map((item) => ({
    label: item.name,
    value: item.alpha2Code
  }));
};

export default countries;
