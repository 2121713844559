import { createAction } from "@reduxjs/toolkit";

const RequestState = {
  pending: "pending",
  success: "success",
  failure: "failure"
};

function createAsyncAction(type, payloadCreator, trackPending = true) {
  return (...props) => {
    const request = createAction(type, () => ({
      payload: {
        requestState: RequestState.pending,
        trackPending
      }
    }));
    const success = createAction(type, (response) => ({
      payload: {
        response,
        requestState: RequestState.success,
        trackPending
      }
    }));
    const failure = createAction(type, (errorData) => ({
      payload: {
        errorData,
        requestState: RequestState.failure,
        trackPending
      }
    }));

    return async (dispatch) => {
      dispatch(request());
      try {
        const response = await payloadCreator(...props);
        dispatch(success(response));

        return Promise.resolve(response);
      } catch (errorData) {
        dispatch(failure(errorData));

        return Promise.reject(errorData);
      }
    };
  };
}

function parseRequestAction(action, responseBodyParser) {
  let responseBody;

  if (action.payload.requestState === RequestState.success) {
    responseBody = responseBodyParser(action.payload.response);
  }

  return responseBody;
}

function isPending(action) {
  return action.payload.requestState === RequestState.pending;
}

function isSuccess(action) {
  return action.payload.requestState === RequestState.success;
}

function isFailure(action) {
  return action.payload.requestState === RequestState.failure;
}

function isTracked(action) {
  return action.payload && action.payload.trackPending;
}

function getRequestState(action) {
  const { requestState } = action.payload;

  switch (requestState) {
    case RequestState.pending:
      return { pending: true };
    case RequestState.success:
      return { success: true };
    case RequestState.failure:
      return {
        failure: true,
        errorData: action.payload.errorData
      };
    default:
      return { pending: true };
  }
}

export {
  RequestState,
  createAsyncAction,
  parseRequestAction,
  isPending,
  isSuccess,
  isFailure,
  getRequestState,
  isTracked
};
