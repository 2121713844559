import React, { useEffect } from "react";
import OrderStart from "@/components/OrderStart";
import { Routes, Route } from "react-router-dom";
import { DrawerLayout } from "@/layouts";
import { useDispatch, useSelector } from "react-redux";
import { getAllServices } from "@/store/order/order.actions";
import { getServicesList } from "@/store/order/order.reducer";

const StartOrderPage = () => {
  const dispatch = useDispatch();
  const services = useSelector(getServicesList);

  useEffect(() => {
    dispatch(getAllServices());
  }, [dispatch]);

  return (
    <Routes>
      <Route element={<DrawerLayout />}>
        <Route index element={<OrderStart services={services} />} />
      </Route>
    </Routes>
  );
};

export default StartOrderPage;
