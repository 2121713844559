import React, { useState } from "react";
import InputField from "@/components/InputField";
import Button from "@/components/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "@/utils/yup";
import AlertMessage from "@/components/AlertMessage";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  AccountActions,
  changePassword
} from "@/store/account/account.actions";
import { isInProcess } from "@/store/pending/pending.reducer";
import PasswordValidityRules from "@/components/PasswordValidityRules";
import { routes } from "@/constants";

const changePasswordFormSchema = yup.object({
  password_current: yup.string().nullable().max(255).required(),
  password: yup
    .string()
    .min(8)
    .max(99)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-"!@#%&/,><':;_~`])\S{8,99}$/,
      "Not appropriate password format"
    )
    .required(),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required()
});

const ChangePasswordForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isFocused, setIsFocused] = useState(false);
  const isLoading = useSelector((state) =>
    isInProcess(state, AccountActions.CHANGE_PASSWORD)
  );
  const [feedback, setFeedback] = useState(null);
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(changePasswordFormSchema)
  });
  const passwordWatch = watch("password", "");

  const onSubmit = async (data) => {
    setFeedback(null);
    try {
      await dispatch(changePassword({ ...data }));
      navigate(routes.CHANGE_PASSWORD_SUCCESS);

      // eslint-disable-next-line no-empty
    } catch (e) {
      setFeedback({
        type: "error",
        text:
          e?.data?.message || "Change password failed. Something went wrong."
      });
    }
  };

  return (
    <form className="change-password-form" onSubmit={handleSubmit(onSubmit)}>
      {feedback && (
        <div className="fields-group">
          <AlertMessage type={feedback?.type} text={feedback?.text} />
        </div>
      )}
      <div className="fields-group">
        <InputField
          label="Current password"
          name="password_current"
          type="password"
          errors={errors}
          {...register("password_current")}
        />
      </div>
      <div className="fields-group">
        <InputField
          name="password"
          type="password"
          onFocus={() => setIsFocused(true)}
          label="Create new password"
          errors={errors}
          {...register("password")}
        />
        {isFocused && <PasswordValidityRules password={passwordWatch} />}
      </div>
      <div className="fields-group">
        <InputField
          name="password_confirmation"
          label="Confirm new password"
          type="password"
          errors={errors}
          {...register("password_confirmation")}
        />
      </div>
      <div className="main-layout__drawer-footer">
        <div className="main-layout__drawer-buttons">
          <div className="main-layout__drawer-buttons-item">
            <Button
              isLoading={isLoading}
              text="Confirm new password"
              className="btn-default btn-fullwidth"
              type="submit"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default ChangePasswordForm;
