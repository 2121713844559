import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import InputField from "@/components/InputField";
import PhoneField from "@/components/PhoneField";
import PanelHeader from "@/components/ProjectDetails/components/PanelHeader";
import { CheckCircleSuccessIcon, CircleWarningIcon } from "@/icons";
import { routes } from "@/constants";
import { getIsDeveloperInfoFilled } from "@/store/order/order.reducer";
import { clearOrderDetails } from "@/store/order/order.actions";
import { OrderStatuses } from "@/constants/orderStatuses";
import { Mobile } from "@/components/Responsive";
import cn from "classnames";

export const CompanyDetailsNavItem = ({ tabIndex, handleTabNavClick }) => {
  return (
    <button
      className={cn(
        "project-details__nav-item",
        tabIndex === "company-details" && "active"
      )}
      onClick={() => handleTabNavClick("company-details")}
      type="button">
      <CheckCircleSuccessIcon className="project-details__nav-icon" />
      Company details
    </button>
  );
};

const CompanyDetails = ({ orderDetails, tabIndex, handleTabNavClick }) => {
  const { control } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDevInfoFilled = useSelector(getIsDeveloperInfoFilled);

  const {
    id,
    organization_name,
    developer_name,
    address,
    contact_name,
    contact_title,
    contact_email,
    contact_phone,
    status: { code }
  } = orderDetails;

  const onCompanyDetailsClick = async () => {
    await dispatch(clearOrderDetails());

    navigate(
      generatePath(routes.ORDER_BY_ID, {
        id
      })
    );
  };

  return (
    <>
      <Mobile>
        <CompanyDetailsNavItem
          tabIndex={tabIndex}
          handleTabNavClick={handleTabNavClick}
        />
      </Mobile>
      {tabIndex === "company-details" && (
        <div className="project-details__tab">
          <PanelHeader
            title="Company details"
            description="Information regarding your company and the primary contact."
            icon={
              isDevInfoFilled ? (
                <CheckCircleSuccessIcon />
              ) : (
                <CircleWarningIcon />
              )
            }
            hasButton={
              code === OrderStatuses.CREATED ||
              code === OrderStatuses.PENDING_EMAIL_VERIFICATION
            }
            buttonText="Update"
            onClick={onCompanyDetailsClick}
          />
          <div className="project-details__section">
            <div className="fields-group">
              <div className="fields-group-title">Company details</div>
              {developer_name && (
                <InputField
                  label="Developer name <sup>*</sup>"
                  name="developer_name"
                  readonlyValue={developer_name}
                />
              )}
              {organization_name && (
                <InputField
                  label="Organization name <sup>*</sup>"
                  name="organization_name"
                  readonlyValue={organization_name}
                />
              )}
              {address && (
                <InputField
                  label="Address <sup>*</sup>"
                  name="address"
                  readonlyValue={address}
                />
              )}
            </div>
            <div className="fields-group">
              <div className="fields-group-title">Point of contact</div>
              {contact_name && (
                <InputField
                  label="Contact name <sup>*</sup>"
                  name="contact_name"
                  readonlyValue={contact_name}
                />
              )}
              {contact_title && (
                <InputField
                  label="Contact title <sup>*</sup>"
                  name="contact_title"
                  readonlyValue={contact_title}
                />
              )}
              {contact_email && (
                <InputField
                  label="Contact e-mail address <sup>*</sup>"
                  name="contact_email"
                  readonlyValue={contact_email}
                />
              )}
              {contact_phone && (
                <PhoneField
                  readonlyValue={contact_phone}
                  control={control}
                  fieldName="contact_phone"
                  label="Contact phone number <sup>*</sup>"
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

CompanyDetails.propTypes = {
  orderDetails: PropTypes.object.isRequired,
  tabIndex: PropTypes.string,
  handleTabNavClick: PropTypes.func
};

CompanyDetailsNavItem.propTypes = {
  tabIndex: PropTypes.string,
  handleTabNavClick: PropTypes.func
};

export default CompanyDetails;
