import {
  CheckCircleDisabledIcon,
  CheckCircleSuccessIcon,
  CircleWarningIcon,
  FixIcon
} from "@/icons";
import React, { useMemo, useRef, useState } from "react";
import purify from "dompurify";
import PropTypes from "prop-types";
import PanelHeader from "@/components/ProjectProgress/components/PanelHeader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "@/utils/yup";
import RadioButton from "@/components/RadioButton";
import Button from "@/components/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsCurrentStatusMitigationsNeeded,
  getIsMitigationsNeeded,
  getIsMitigationsNeededSuccessful,
  getLastMitigationsNeededItem
} from "@/store/order/order.reducer";
import {
  OrderActions,
  fetchMitigationsRecheck,
  uploadFileSuccessful
} from "@/store/order/order.actions";
import { useParams } from "react-router-dom";
import { isInProcess } from "@/store/pending/pending.reducer";
import ResumableFileUploader from "@/components/ResumableFileUploader";
import { MAX_APK_FILE_SIZE_IN_MEGABYTES } from "@/constants";

export const MitigationsHeader = ({ active }) => {
  const isMitigationsSuccessful = useSelector(getIsMitigationsNeededSuccessful);
  const isMitigationsNeeded = useSelector(getIsMitigationsNeeded);
  return (
    <PanelHeader
      title="Mitigations needed"
      description="We found vulnerabilities or misconfigurations that need to be fixed to complete the assessment"
      icon={
        // eslint-disable-next-line no-nested-ternary
        isMitigationsSuccessful ? (
          <CheckCircleSuccessIcon />
        ) : isMitigationsNeeded ? (
          <CircleWarningIcon />
        ) : (
          <CheckCircleDisabledIcon />
        )
      }
      report={isMitigationsNeeded?.report}
      active={active}
      hasButton={false}
    />
  );
};

MitigationsHeader.propTypes = {
  active: PropTypes.bool.isRequired
};

const schema = yup.object({
  is_recheck: yup.string().nullable().oneOf(["1", "0"]).required(),
  app_filename: yup
    .mixed()
    .nullable()
    .when("is_recheck", {
      is: "0",
      then: yup
        .mixed()
        .nullable()
        .test("ifString", "Required", (value) => {
          if (typeof value === "string") {
            return !!value;
          }

          return true;
        })
        .test(
          "size",
          `Size of file can't be larger than ${MAX_APK_FILE_SIZE_IN_MEGABYTES} Mb`,
          (value) => {
            if (typeof value === "string") {
              return true;
            }
            // eslint-disable-next-line no-unsafe-optional-chaining
            const sizeMB = value?.size / 1024 / 1024;
            return sizeMB <= MAX_APK_FILE_SIZE_IN_MEGABYTES;
          }
        )
        .test("type", "Only .apk file format is allowed", (value) => {
          if (typeof value === "string") {
            return !!value;
          }

          return (
            value?.type === "application/vnd.android.package-archive" ||
            value?.type === "application/octet-stream" ||
            value?.name.endsWith(".apk")
          );
        })
    })
});

const MitigationsForm = () => {
  const isLoading = useSelector((state) =>
    isInProcess(state, OrderActions.FETCH_MITIGATIONS_RECHECK)
  );
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    resetField,
    watch,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const fileUploader = useRef();

  const [isFileLoading, setIsFileLoading] = useState(false);

  const isRecheckWatch = watch("is_recheck");

  const fileUploadURL = useMemo(() => {
    return `${process.env.REACT_APP_API_URL}/api/v1/order/${id}/mitigations-needed/file`;
  }, [id]);

  const onFileUploadSuccess = (_file, orderDetails) => {
    setIsFileLoading(false);

    dispatch(uploadFileSuccessful(orderDetails));
  };

  const onSubmit = async () => {
    if (isRecheckWatch === "0") {
      if (fileUploader.current?.resumable.files.length) {
        fileUploader.current?.startUploading();
      }
    } else {
      await dispatch(fetchMitigationsRecheck({ id }));
    }
  };

  return (
    <form className="mitigations-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="fields-group">
        <RadioButton
          isDefault
          errors={errors}
          value={1}
          text="Recheck in Play market"
          name="is_recheck"
          {...register("is_recheck")}
        />
        <RadioButton
          isDefault
          value={0}
          errors={errors}
          text="Upload new APK file"
          name="is_recheck"
          {...register("is_recheck")}
        />
      </div>

      {isRecheckWatch === "0" && (
        <div className="fields-group">
          <ResumableFileUploader
            name="app_filename"
            resetField={resetField}
            setValue={setValue}
            target={fileUploadURL}
            ref={fileUploader}
            errors={errors}
            onFileUploadPause={() => setIsFileLoading(false)}
            onFileProgress={() => setIsFileLoading(true)}
            onAfterFileSuccess={onFileUploadSuccess}
            onAfterFileError={() => {
              setIsFileLoading(false);
            }}
            control={control}
          />
        </div>
      )}

      <Button
        isLoading={isLoading || isFileLoading}
        className="btn-default"
        size="lg"
        type="submit"
        text="Confirm fixes"
      />
    </form>
  );
};

// eslint-disable-next-line
export const MitigationsBody = ({ orderDetails }) => {
  const isCurrentStatusMitigations = useSelector(
    getIsCurrentStatusMitigationsNeeded
  );

  const lastMitigationsItem = useSelector(getLastMitigationsNeededItem);

  return (
    <div className="progress-accordion__body">
      <div className="inner">
        <div className="row-info-wrapper__icon">
          <FixIcon />
        </div>
        <div className="row-info-wrapper__info">
          <div className="fields-group">
            <div className="row-info-wrapper__title">Outstanding items</div>
            <div className="row-info-wrapper">
              {lastMitigationsItem ? (
                <div
                  key={lastMitigationsItem.created_at}
                  className="row-info-wrapper__html-text"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: purify.sanitize(lastMitigationsItem.text)
                  }}
                />
              ) : (
                <div className="row-info-wrapper__list-item">
                  Download your current report with the identified
                  vulnerabilities and misconfigurations, as well as mitigation
                  strategies
                </div>
              )}
            </div>
          </div>
          {isCurrentStatusMitigations &&
            !lastMitigationsItem.is_application_recheck_sended && (
              <div>
                <MitigationsForm />
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

MitigationsBody.propTypes = {
  orderDetails: PropTypes.object.isRequired
};
