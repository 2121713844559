import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  getIsAssessmentInProgress,
  getIsAssessmentSuccessful
} from "@/store/order/order.reducer";
import PanelHeader from "@/components/ProjectDetails/components/PanelHeader";
import {
  CheckCircleSuccessIcon,
  CircleProgressIcon,
  CheckCircleDisabledIcon
} from "@/icons";
import cn from "classnames";
import { OrderStatuses } from "@/constants/orderStatuses";
import { Mobile } from "@/components/Responsive";
import AnimationImage from "@/components/AnimationImage";
import moment from "moment";
import { formatString } from "@/utils/formatString";

export const AssessmentNavItem = ({
  tabIndex,
  handleTabNavClick,
  orderDetails
}) => {
  const isAssessmentInProgress = useSelector(getIsAssessmentInProgress);
  const isAssessmentSuccessful = useSelector(getIsAssessmentSuccessful);
  const {
    status: { code }
  } = orderDetails;
  const scheduled = code === OrderStatuses.SCHEDULED;

  return (
    <button
      className={cn(
        "project-details__nav-item",
        tabIndex === "assessment" && "active"
      )}
      type="button"
      disabled={!(isAssessmentInProgress || scheduled)}
      onClick={() => handleTabNavClick("assessment")}>
      {code === OrderStatuses.ASSESSMENT_IN_PROGRESS || scheduled ? (
        <CircleProgressIcon className="project-details__nav-icon" />
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {isAssessmentSuccessful ? (
            <CheckCircleSuccessIcon className="project-details__nav-icon" />
          ) : (
            <CheckCircleDisabledIcon className="project-details__nav-icon" />
          )}
        </>
      )}
      Assessment
    </button>
  );
};

const Assessment = ({ orderDetails, tabIndex, handleTabNavClick }) => {
  const {
    status: { code },
    scheduled_date
  } = orderDetails;

  return (
    <>
      <Mobile>
        <AssessmentNavItem
          tabIndex={tabIndex}
          handleTabNavClick={handleTabNavClick}
          orderDetails={orderDetails}
        />
      </Mobile>
      {tabIndex === "assessment" && (
        <div className="project-details__tab">
          {
            // eslint-disable-next-line no-nested-ternary
            code === OrderStatuses.ASSESSMENT_IN_PROGRESS ? (
              <>
                <PanelHeader
                  title="Assessment in progress"
                  description="The assessment is in motion! Our team is at work, and you'll hear from us with a report upon completion."
                  icon={<CircleProgressIcon />}
                  hasButton={false}
                />
                <div className="project-details__section">
                  <div className="project-details__info">
                    <div className="project-details__info-image">
                      <AnimationImage title="progress" />
                    </div>
                    <div className="project-details__info-content">
                      <div className="project-details__info-title">
                        What will the assessment include?
                      </div>
                      <div className="project-details__info-text">
                        The MASA assessment aims to validate a mobile
                        application`s security posture in alignment with the
                        OWASP MASVS Level 1 standards, focusing on crucial
                        security aspects.Through MASA, developers can bolster
                        their application`s defenses, enhance consumer
                        confidence, and demonstrate a commitment to security and
                        data privacy.
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : code === OrderStatuses.SCHEDULED ? (
              <>
                <PanelHeader
                  title="Assessment is scheduled"
                  description={formatString(
                    "The assessment date is locked in! The MASA validation for your application is scheduled for {scheduled_date} and will commence as outlined in the plan you selected.",
                    {
                      scheduled_date:
                        moment(scheduled_date).format("MMMM D, YYYY")
                    }
                  )}
                  icon={<CircleProgressIcon />}
                  hasButton={false}
                />
                <div className="project-details__section">
                  <div className="project-details__info">
                    <div className="project-details__info-image">
                      <AnimationImage title="scheduled" />
                    </div>
                    <div className="project-details__info-content">
                      <div className="project-details__info-title">
                        What will the assessment include?
                      </div>
                      <div className="project-details__info-text">
                        The MASA assessment aims to validate a mobile
                        application`s security posture in alignment with the
                        OWASP MASVS Level 1 standards, focusing on crucial
                        security aspects.Through MASA, developers can bolster
                        their application`s defenses, enhance consumer
                        confidence, and demonstrate a commitment to security and
                        data privacy.
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <PanelHeader
                title="Assessment completed"
                description="Your application’s assessment is complete. Please review the findings report and initiate necessary remediation measures."
                icon={<CheckCircleSuccessIcon />}
                hasButton={false}
              />
            )
          }
        </div>
      )}
    </>
  );
};

Assessment.propTypes = {
  orderDetails: PropTypes.object.isRequired,
  tabIndex: PropTypes.string,
  handleTabNavClick: PropTypes.func
};

AssessmentNavItem.propTypes = {
  tabIndex: PropTypes.string,
  handleTabNavClick: PropTypes.func,
  orderDetails: PropTypes.object.isRequired
};

export default Assessment;
