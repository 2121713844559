import React from "react";
import purify from "dompurify";
import PropTypes from "prop-types";
import InfoImage from "@/assets/images/info.gif";
import AnimationImage from "@/components/AnimationImage";
import cn from "classnames";
import "./OrderInfo.scss";

const OrderInfo = ({ title, text, animationImage, button, inverted }) => {
  return (
    <div className={cn("order-info", inverted && "order-info_inverted")}>
      <div className="order-info__image">
        {animationImage ? (
          <AnimationImage title={animationImage} />
        ) : (
          <img
            className="order-info__image-i"
            src={InfoImage}
            alt=""
            width={122}
            height={122}
          />
        )}
      </div>
      <div className="order-info__content">
        {title && <div className="order-info__title">{title}</div>}
        {text && (
          <div
            className="order-info__text"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: purify.sanitize(text) }}
          />
        )}
        {button && (
          <div className="order-info__footer">
            {button.external ? (
              <a
                className="order-info__button btn btn-default"
                href={button.url}
                target="_blank"
                rel="noopener noreferrer">
                {button.label}
              </a>
            ) : (
              <a
                className="order-info__button btn btn-default"
                href={button.url}>
                {button.label}
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

OrderInfo.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  animationImage: PropTypes.string,
  button: PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
    external: PropTypes.bool
  }),
  inverted: PropTypes.bool
};

export default OrderInfo;
