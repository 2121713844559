import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { TrashInvertedIcon } from "@/icons";
import { routes } from "@/constants";
import {
  OrderActions,
  postRemoveProjectCasa
} from "@/store/order/order.actions";
import {
  getIsMitigationsNeeded,
  getIsOrderFinalizedSuccessfully,
  getIsOrderFinalizedUnsuccessfully
} from "@/store/order/order.reducer";
import { OrderStatuses } from "@/constants/orderStatuses";
import ServicePopup from "@/components/ServicePopup";
import ThirdPartyService from "@/services/ThirdPartyService";
import { TabletMd, TabletDesktop, Mobile } from "@/components/Responsive";
import { useMediaQuery } from "react-responsive";
import CompanyDetails, { CompanyDetailsNavItem } from "./CompanyDetails";
import TestingDetails, { TestingDetailsNavItem } from "./TestingDetails";
import PricingOptions, { PricingOptionsNavItem } from "./PricingOptions";
import Assessment, { AssessmentNavItem } from "./Assessment";
import Remediation, { RemediationNavItem } from "./Remediation";
import Results, { ResultsNavItem } from "./Results";

const ProjectDetailsCasa = ({ orderDetails, infoWidget }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingReportAdd, setIsLoadingReportAdd] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const {
    id,
    application_name,
    status: { code },
    payed_at
  } = orderDetails;
  const assessmentState =
    code === OrderStatuses.SCHEDULED ||
    code === OrderStatuses.ASSESSMENT_IN_PROGRESS;
  const isMitigationsNeeded = useSelector(getIsMitigationsNeeded);
  const isAssessmentSuccessfulFinalized = useSelector(
    getIsOrderFinalizedSuccessfully
  );
  const isAssessmentUnsuccessful = useSelector(
    getIsOrderFinalizedUnsuccessfully
  );
  const isPricingOptions =
    code === OrderStatuses.AWAITING_PAYMENT ||
    code === OrderStatuses.CONFIRMING_PURCHASE_ORDER ||
    code === OrderStatuses.QUOTE_READY ||
    payed_at;

  useEffect(() => {
    if (!isMobile && !tabIndex) {
      setTabIndex("company-details");

      if (application_name) {
        setTabIndex("testing-details");
      }
      if (isPricingOptions) {
        setTabIndex("pricing-options");
      }
      if (assessmentState) {
        setTabIndex("assessment");
      }
      if (isMitigationsNeeded) {
        setTabIndex("remediation");
      }
      if (isAssessmentSuccessfulFinalized || isAssessmentUnsuccessful) {
        setTabIndex("results");
      }
    }
  }, [
    isMobile,
    tabIndex,
    application_name,
    isPricingOptions,
    assessmentState,
    isMitigationsNeeded,
    isAssessmentSuccessfulFinalized,
    isAssessmentUnsuccessful
  ]);

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteProject = async () => {
    await dispatch(postRemoveProjectCasa(id));

    closeDeleteModal();
    navigate(routes.ROOT);
    window.location.reload();
  };

  const downloadReport = (_report, additional) => async (event) => {
    event.stopPropagation();
    if (additional) {
      setIsLoadingReportAdd(true);
    } else {
      setIsLoading(true);
    }
    try {
      await ThirdPartyService.downloadFile({
        url: _report.api_url,
        filename: _report.file_name,
        contentType: _report.content_type
      });

      if (additional) {
        setIsLoadingReportAdd(false);
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      if (additional) {
        setIsLoadingReportAdd(false);
      } else {
        setIsLoading(false);
      }
    }
  };

  const handleTabNavClick = (index) => {
    if (tabIndex === index && isMobile) {
      setTabIndex("");
    } else {
      setTabIndex(index);
    }
  };

  const renderDeleteProject = () => {
    return (
      (code === OrderStatuses.CREATED || code === OrderStatuses.SCOPING) && (
        <button
          className="project-details__delete"
          type="button"
          onClick={openDeleteModal}>
          <TrashInvertedIcon className="project-details__delete-icon" />
          <span className="project-details__delete-label">Delete project</span>
        </button>
      )
    );
  };

  return (
    <div className="project-details__content">
      <TabletDesktop>
        <div className="project-details__content-aside">
          <div className="project-details__nav">
            <CompanyDetailsNavItem
              tabIndex={tabIndex}
              handleTabNavClick={handleTabNavClick}
            />
            <TestingDetailsNavItem
              tabIndex={tabIndex}
              handleTabNavClick={handleTabNavClick}
              orderDetails={orderDetails}
            />
            <PricingOptionsNavItem
              tabIndex={tabIndex}
              handleTabNavClick={handleTabNavClick}
              orderDetails={orderDetails}
            />
            <AssessmentNavItem
              tabIndex={tabIndex}
              handleTabNavClick={handleTabNavClick}
              orderDetails={orderDetails}
            />
            <RemediationNavItem
              tabIndex={tabIndex}
              handleTabNavClick={handleTabNavClick}
            />
            <ResultsNavItem
              tabIndex={tabIndex}
              handleTabNavClick={handleTabNavClick}
            />
          </div>
          {renderDeleteProject()}
        </div>
      </TabletDesktop>
      <div className="project-details__content-body">
        <CompanyDetails
          orderDetails={orderDetails}
          tabIndex={tabIndex}
          handleTabNavClick={handleTabNavClick}
        />

        <TestingDetails
          orderDetails={orderDetails}
          tabIndex={tabIndex}
          handleTabNavClick={handleTabNavClick}
        />

        <PricingOptions
          orderDetails={orderDetails}
          tabIndex={tabIndex}
          handleTabNavClick={handleTabNavClick}
        />

        <Assessment
          orderDetails={orderDetails}
          tabIndex={tabIndex}
          handleTabNavClick={handleTabNavClick}
        />

        <Remediation
          orderDetails={orderDetails}
          tabIndex={tabIndex}
          handleTabNavClick={handleTabNavClick}
          downloadReport={downloadReport}
          isLoading={isLoading}
        />

        <Results
          tabIndex={tabIndex}
          handleTabNavClick={handleTabNavClick}
          downloadReport={downloadReport}
          isLoading={isLoading}
          isLoadingReportAdd={isLoadingReportAdd}
        />

        <Mobile>{renderDeleteProject()}</Mobile>

        {infoWidget && (
          <TabletMd>
            <div className="project-details__aside">{infoWidget}</div>
          </TabletMd>
        )}
      </div>
      {showDeleteModal && (
        <ServicePopup
          onClose={closeDeleteModal}
          onSubmit={handleDeleteProject}
          onSubmitAction={OrderActions.POST_REMOVE_PROJECT}
          title="Confirm Project Deletion"
          text="Are you sure you want to permanently delete this project?"
          submitButtonLabel="Delete project"
          submitDelete
        />
      )}
    </div>
  );
};

ProjectDetailsCasa.propTypes = {
  orderDetails: PropTypes.object.isRequired,
  infoWidget: PropTypes.node
};

export default ProjectDetailsCasa;
