import React from "react";
import { useParams, generatePath, useNavigate } from "react-router-dom";
import Button, { ButtonLink } from "@/components/Button";
import { PropTypes } from "prop-types";
import "./OrderVerification.scss";
import { routes } from "@/constants";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetails, getIsOrderPaid } from "@/store/order/order.reducer";
import {
  OrderActions,
  resendOtpCode,
  sendVerificationInfoAuto
} from "@/store/order/order.actions";
import { isInProcess } from "@/store/pending/pending.reducer";

const OrderVerification = ({ skipLink }) => {
  const navigate = useNavigate();
  const isLoading = useSelector(
    (state) =>
      isInProcess(state, OrderActions.RESEND_OTP_CODE) ||
      isInProcess(state, OrderActions.SEND_VERIFICATION_INFO_AUTO)
  );
  const isPaidOrder = useSelector(getIsOrderPaid);

  const dispatch = useDispatch();
  const { id } = useParams();
  const order = useSelector(getOrderDetails);

  const handleNext = async () => {
    await dispatch(
      sendVerificationInfoAuto({ id, is_manual_verification: false })
    );
    await dispatch(resendOtpCode(id));

    navigate(
      generatePath(routes.OTP_CONFIRMATION, {
        id
      })
    );
  };

  return (
    <div className="order-verify">
      <div className="fields-group">
        <div className="fields-group-title">Automatic verification</div>
      </div>
      <div className="fields-group-subtitle">
        We will send a verifcation email to the developer contact email that you
        indicated on the application’s Google Play page to verify your
        authority.
      </div>
      <div className="fields-group">
        <div className="order-verify__email">{order?.developer_email}</div>
      </div>
      <div className="main-layout__drawer-footer">
        <div className="main-layout__drawer-buttons">
          {skipLink && !isPaidOrder && (
            <div className="main-layout__drawer-buttons-item">
              <ButtonLink
                text="Do it later"
                className="btn-light btn-fullwidth"
                to={skipLink}
              />
            </div>
          )}
          <div className="main-layout__drawer-buttons-item">
            <Button
              isLoading={isLoading}
              onClick={handleNext}
              text="Continue with e-mail"
              className="btn-default btn-fullwidth"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

OrderVerification.propTypes = {
  skipLink: PropTypes.string
};

export default OrderVerification;
