import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Link } from "react-router-dom";
import { IconForward, IconBack } from "@/icons";

const BUTTON_DIRECTIONS = {
  BACK: "back",
  FORWARD: "forward"
};

// eslint-disable-next-line react/display-name
const ButtonLink = React.forwardRef(
  (
    { icon, blank, disabled, size, replace, text, className, to, onClick },
    ref
  ) => {
    return (
      <Link
        disabled={disabled}
        className={cn(
          "btn",
          size && `btn-${size}`,
          blank && "btn-link",
          disabled ? "disabled" : "",
          className
        )}
        onClick={onClick}
        to={to}
        replace={replace}
        ref={ref}>
        {icon === BUTTON_DIRECTIONS.BACK && (
          <IconBack
            className={
              icon === BUTTON_DIRECTIONS.BACK ? "icon-back" : "icon-forward"
            }
          />
        )}
        {text}
        {icon === BUTTON_DIRECTIONS.FORWARD && (
          <IconForward
            className={
              icon === BUTTON_DIRECTIONS.BACK ? "icon-back" : "icon-forward"
            }
          />
        )}
      </Link>
    );
  }
);

ButtonLink.defaultProps = {
  replace: false,
  icon: null,
  blank: false
};

ButtonLink.propTypes = {
  blank: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.string, // back | forward | null
  onClick: PropTypes.func,
  text: PropTypes.string,
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
  replace: PropTypes.bool,
  size: PropTypes.string // sm, md, lg
};

export default ButtonLink;
