/* eslint-disable no-console */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createSearchParams,
  generatePath,
  useNavigate,
  useParams
} from "react-router-dom";
import OrderTabs from "@/components/OrderTabs";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "@/utils/yup";
import RadioButton from "@/components/RadioButton";
import PurchasePlanCard from "@/components/PurchasePlanCard";
import Button, { ButtonLink } from "@/components/Button";
import CircleSpinner from "@/components/CircleSpinner";
import { routes } from "@/constants";

import { getOrderProducts } from "@/store/order/order.reducer";
import {
  createPayment,
  fetchOrderProducts,
  OrderActions
} from "@/store/order/order.actions";
import { isInProcess } from "@/store/pending/pending.reducer";

const schema = yup.object({
  product_id: yup.string().nullable().required("Required")
});

const OrderCart = () => {
  const dispatch = useDispatch();
  const orderProducts = useSelector(getOrderProducts);
  const isLoading = useSelector((state) =>
    isInProcess(state, OrderActions.CREATE_PAYMENT)
  );
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    dispatch(fetchOrderProducts());
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (data) => {
    try {
      const productIdInt = parseInt(data.product_id, 10);
      const productById = orderProducts.find((p) => p.id === productIdInt);
      const res = await dispatch(
        createPayment({ id, product_id: productById.id })
      );

      const params = `?${createSearchParams({
        ...data,
        client_secret: res.client_secret
      })}`;

      const path = generatePath(routes.ORDER_PAYMENT, {
        id
      });
      navigate({
        pathname: path,
        search: params
      });
    } catch (error) {
      console.log("Error / Create payment failed");
    }
  };

  return (
    <OrderTabs>
      <form onSubmit={handleSubmit(onSubmit)} className="cart">
        <div className="fields-group-md">
          {orderProducts.length ? (
            orderProducts?.map((product) => (
              <RadioButton
                key={product.id}
                errors={errors}
                text={product.name}
                value={product.id}
                name="product_id"
                {...register("product_id")}
                render={({ text, hasError }) => (
                  <PurchasePlanCard
                    price={product.amount / 100}
                    text={text}
                    hasError={hasError}
                    options={product.description.map((k) => k.text)}
                  />
                )}
              />
            ))
          ) : (
            <CircleSpinner />
          )}
        </div>
        <div className="main-layout__drawer-footer">
          <div className="main-layout__drawer-buttons">
            <div className="main-layout__drawer-buttons-item">
              <ButtonLink
                to={generatePath(routes.AGREEMENT, { id })}
                text="Back"
                className="btn-light btn-fullwidth"
              />
            </div>
            <div className="main-layout__drawer-buttons-item">
              <Button
                isLoading={isLoading}
                type="submit"
                text="Continue"
                className="btn-default btn-fullwidth"
              />
            </div>
          </div>
        </div>
      </form>
    </OrderTabs>
  );
};

export default OrderCart;
