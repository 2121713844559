import { createAsyncAction } from "@/utils/createAsyncAction";
import ThirdPartyService from "@/services/ThirdPartyService";

export const CountriesActions = {
  GET_COUNTRIES_AND_STATES: "GET_COUNTRIES_AND_STATES"
};

export const getCountriesAndStates = createAsyncAction(
  CountriesActions.GET_COUNTRIES_AND_STATES,
  async () => ThirdPartyService.getCountriesAndStates()
);
