/* eslint-disable react/no-array-index-key */
/* eslint-disable import/extensions */
import React, { useState } from "react";
import purify from "dompurify";
import Collapse, { Panel } from "rc-collapse";
import AnimationImage from "@/components/AnimationImage";
import GcpProjectNumberImage from "@/assets/images/faq/GCP_Project_Number.png";
import GcpOAuthRestrictedScopesImage from "@/assets/images/faq/GCP-OAuth-restricted-scopes.png";
import FixAllCWEImage from "@/assets/images/faq/fix_all_CWE.png";
import { routes, routeTitles } from "@/constants";
import motion from "@/utils/motionUtil.js";
import "rc-collapse/assets/index.css";
import "./Faq.scss";

const Faq = () => {
  const [comingSoon] = useState(true);
  const list = [
    {
      title: "Cloud Application Security Assessment",
      list: [
        {
          title: "Where can I find the GCP Project number?",
          text: `<p>To determine your project's number, do the following:
            <br/>1. Open the Cloud project in Google Cloud.
            <br/>2. At the top-right, click More > <strong>Project settings</strong>.
            <br/>3. View <strong>Project number</strong> in the resulting <strong>Settings</strong> panel. The <strong>Project number</strong> consists only of digits.</p>
            <img src="${GcpProjectNumberImage}" alt="" />`
        },
        {
          title: "Where can I find the GCP OAuth restricted scopes?",
          text: `<p>Go to your Google Cloud Platform and follow directions below:</p>
            <img src="${GcpOAuthRestrictedScopesImage}" alt="" />`
        },
        {
          title:
            "What is the purpose of whitelisting Leviathan’s IPs on my Web Application Firewall (WAF)?",
          text: `<p>Web Application Firewalls (WAFs) are crucial components in securing online applications. They act as diligent gatekeepers, filtering incoming traffic to ward off malicious attacks. However, while WAFs are proficient in delaying potential breaches, they aren’t invincible fortresses.</p>
            <p>It’s essential to understand that even the most robust WAFs can be circumvented with sufficient time and expertise - and in our extensive experience, we have successfully navigated through various WAFs. But this isn't a testament to their inefficiency; rather, it highlights that security is a multi-layered endeavor, where WAFs are one of many lines of defence.</p>
            <p>When conducting security assessments, the focus is not solely on testing the fortitude of your firewall but examining the integrity and resilience of the application it protects. This is in line with the requirements laid down by industry giants like Google and auditing standards like SOC, which emphasize the imperative need for a comprehensive security review of the <strong>application residing behind the WAF</strong>.</p>
            <p>To facilitate a smooth, efficient, and thorough assessment process, whitelisting our IPs is necessary. Without whitelisting, our team would indeed engage substantial time and resources to bypass the WAF, inevitably leading to extended timelines and additional costs. Whitelisting allows us to directly evaluate your application’s security, providing you with insightful, valuable feedback without unnecessary delays and expenses.</p>
            <p>In a nutshell, whitelisting streamlines the assessment process, ensuring you receive timely, accurate insights into your application’s security posture, while your WAF continues its vital role in your broader security strategy.</p>`
        },
        {
          title: "Should CAPTCHA be disabled during the assessment?",
          text: `<p>Disabling CAPTCHA is necessary for the duration of the assessment. CAPTCHA functions as a security measure designed to determine whether the user is human. While it is effective in blocking automated bots, it also prevents our assessment tools from efficiently analyzing and testing your application.</p>
            <p>Preferably, the assessment should be conducted on a staging environment that mirrors the production environment. This approach allows for a comprehensive and realistic evaluation without directly impacting the live application.</p>
            <p>By temporarily turning off CAPTCHA, you ensure a smoother, uninterrupted testing process, allowing our system to thoroughly evaluate your application's security measures. Please remember to reactivate CAPTCHA as soon as the assessment period concludes to maintain your site's protection.</p>`
        },
        {
          title: "How should I securely store Google data and tokens?",
          text: `<p>OAuth tokens and user data from restricted scopes must be encrypted at rest and the keys and key material have to be managed appropriately (e.g., stored in a hardware security module or equivalent-strength key management system).</p>
            <p>
              <strong>1. Encryption at rest:</strong><br />
              Google access, refresh tokens and user data obtained via Restricted scopes APIs must be encrypted before being stored in the database. Database encryption does not meet this requirement.
            </p>
            <p>
              <strong>2. Up-to-date ciphers:</strong><br />
              Tokens must be encrypted with up-to-date encryption algorithm and encryption mode of operation, such as AES-256-GCM, before being written to a database or other storage.
            </p>
            <p>
              <strong>3. Key management:</strong><br />
              The encryption key must be stored in the HSM. Cloud providers offer easy-to-use HSM-enabled services: AWS KMS, AWS Secrets Manager, AWS Parameter Store, AWS CloudHSM, GCP Cloud HSM, Azure HSM. On-premise solutions should go with YubiHSM 2 or other dedicated hardware.
            </p>`
        },
        {
          title: "How to comply with CASA requirements?",
          text: `<p>To obtain a Letter of Validation, it's imperative to address and resolve all findings identified during the assessment, irrespective of their Common Weakness Enumeration (CWE) ratings and severity levels — this includes high, medium, low, and informational findings, as per App Defense Alliance's most recent stipulations.</p>
            <p>For more details, refer to this <a href="https://appdefensealliance.dev/casa/updates" target="_blank" rel="noopener noreferrer">link</a>.</p>
            <img src="${FixAllCWEImage}" alt="" />`
        },
        {
          title: "How much time do I have for remediation?",
          text: "<p>In addition to the deadline for certification from Google, we need to be sure that no significant changes have been made to the application. Therefore, we generally allow up to 2 months after initial testing to provide fixes for our verification.</p>"
        },
        {
          title:
            "How long does the Letter of Validation (LOV) take to generate?",
          text: "<p>It takes around 2 days to prepare a Letter of Validation and submit it to Google.</p>"
        },
        {
          title:
            "How long does it take for Google to process the submission of Letter of Validation (LOV)?",
          text: `It usually takes up to 3 days for Google to process the request. Developers should expect a <strong>"Request Granted"</strong> confirmation from Google in the GCP Console and via CASA-related email.`
        }
      ]
    },
    {
      title: "Cloud Application Security Assessment Tier 2",
      list: [
        {
          title: "Which source code directories need to be scanned?",
          text: `<p>All repositories associated with the target application must be scanned for compliance with CASA requirements; even those that do not contain code that handles Google API restricted scopes.</p>
            <p>Please follow <a href="https://appdefensealliance.dev/casa/tier-2/ast-guide" target="_blank" rel="noopener noreferrer">this link</a> to review the application scanning guide.</p>`
        },
        {
          title:
            "Is it possible to conduct the assessment without granting access to source code?",
          text: `<p>Yes. In this case, you will need to perform a SAST scan yourself (FluidAttacks / semgrep) and provide reports for our review.</p>
            <h3><strong>FluidAttacks</strong></h3>
            <p>Instructions on how to run FluidAttacks against your application code can be found on the official CASA page: <a href="https://appdefensealliance.dev/casa/tier-2/ast-guide/static-scan" target="_blank" rel="noopener noreferrer">https://appdefensealliance.dev/casa/tier-2/ast-guide/static-scan</a> (skip step 5 if you are running a report on a non Android code)</p>
            <h3><strong>semgrep</strong></h3>
            <p>We also approve <strong>semgrep</strong> scans, which covers all CASA Tier 2 source code checks and may be easier/faster to adjust and run. If you choose to run a <strong>semgrep</strong> scan, follow the instructions below:</p>
            <ul>
              <li>To use semgrep, you can either use docker or install semgrep directly.</li>
              <li>While running semgrep, ensure that the current directory is set to the root directory of the specific project sources.</li>
              <li>The output of the command will be saved to -o argument, in this case “semgrep-results.txt”.</li>
            </ul>
            <p>
              The docker command looks like the following:
              <br />
              <code>
                docker run --rm -v "&#36;{PWD}:/src" returntocorp/semgrep semgrep scan --config auto path/to/src/folder --
              </code>
            </p>
            <p>
              Installation:
              <br />
              <code>
                # For macOS
                brew install semgrep
                
                # For Ubuntu/WSL/Linux/macOS
                python3 -m pip install semgrep
              </code>
            </p>
            <p>
              After installation run semgrep executing below command:
              <br />
              <code>
                semgrep --config auto path/to/src/folder --json -o semgrep-report.json --no-git-ignore
              </code>
            </p>
            <p>
              And then upload results to our shared folder.
              <br />
              (Taken from <a href="https://github.com/returntocorp/semgrep" target="_blank" rel="noopener noreferrer">https://github.com/returntocorp/semgrep</a>)
            </p>`
        },
        {
          title: "How to complete a Self-Assessment Questionnaire?",
          text: `<p>Based on the application type and valid certifications provided (by you), LSG will create a self-assessment questionnaire with the remaining CASA checks. You need to complete it by checking the requirements in column <strong>‘Verification’&nbsp;<i>(F)</i></strong>.</p>
            <p>If some of the  CASA requirements are missing and are marked as ‘Not implemented’ (No) in column <strong>‘Verification’&nbsp;<i>(F)</i></strong>, we will add them to our final list of the identified vulnerabilities that you will need to address. Once they have been addressed, you will need to mark them as <strong>'Remedied'</strong> in column <strong><i>(I)</i></strong>.</p>
            <p>Please note that the questionnaire is part of the self-attestation and we do not check your answers using automated scanning or manual testing. Those requirements that are marked as <strong>‘Remedied’</strong> in column <strong><i>(I)</i></strong> will be indicated as resolved based on your statements. However, Google may request a completed questionnaire confirming certain requirements before/during the following year's audit.</p>`
        }
      ]
    },
    {
      title: "Cloud Application Security Assessment Tier 3",
      list: [
        {
          title:
            "Is it possible to conduct the assessment without granting access to source code?",
          text: "<p>Access to all repositories related to the application in scope are mandatory for CASA Tier 3.</p>"
        },
        {
          title:
            "Our product is a part of a large application with multiple workspaces / extensions / add-ons. What parts of the application are in scope?",
          text: `<p>The application, all related code repositories, and all extensions, add-ons and downstream applications - wherever data flows – fall under the scope of CASA Tier 3.</p>
            <p><sup>*</sup>The exception is a marketplace application, which is a container only and runs on top of the workspace product.</p>`
        }
      ]
    }
  ];

  return (
    <div className="faq">
      <div className="faq__inner">
        {comingSoon && (
          <div className="faq__content">
            <div className="faq__top">
              <div className="faq__top-title">Frequently Asked Question</div>
            </div>
            {list && (
              <div className="faq__list">
                {list.map((item) => (
                  <div className="faq__list-item" key={item.title}>
                    <div className="faq__list-title">{item.title}</div>
                    {item.list && (
                      <Collapse className="faq__list" openMotion={motion}>
                        {item.list.map((listItem, index) => (
                          <Panel
                            header={listItem.title}
                            key={`${listItem.title}-${index}`}>
                            <div
                              className="faq__list-text"
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{
                                __html: purify.sanitize(listItem.text)
                              }}
                            />
                          </Panel>
                        ))}
                      </Collapse>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        <div className="faq__aside">
          <div className="faq__wrap">
            <div className="faq__image">
              <AnimationImage title={routeTitles[routes.FAQ]} />
            </div>
            <div className="faq__title">Still have a question?</div>
            <div className="faq__label">
              Please, contact us via email&nbsp;
              <a
                className="faq__label_email"
                href="mailto:hub@leviathansecurity.com">
                hub@leviathansecurity.com
              </a>
            </div>
            <a
              className="faq__button btn btn-default"
              href="mailto:hub@leviathansecurity.com">
              Contact us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
