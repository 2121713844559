import { createReducer } from "@reduxjs/toolkit";
import { reduceSuccessResponse } from "@/utils/getSuccessResponse";
import { OrderActions } from "@/store/order/order.actions";
import { OrderFilters, OrderStatuses } from "@/constants/orderStatuses";
import { pick } from "@/utils/pick";

const initialState = {
  orderDetails: null,
  orderList: null,
  orderListMore: null,
  orderListTable: null,
  orderProducts: [],
  orderCount: null,
  servicesList: null
};

export const order = createReducer(initialState, {
  [OrderActions.CREATE_ORDER]: (state, action) =>
    reduceSuccessResponse(state, action, (res) => ({
      orderDetails: res
    })),
  [OrderActions.UPDATE_DEVELOPER_INFO]: (state, action) =>
    reduceSuccessResponse(state, action, (res) => ({
      orderDetails: res
    })),
  [OrderActions.UPDATE_QUESTIONNAIRE]: (state, action) =>
    reduceSuccessResponse(state, action, (res) => ({
      orderDetails: res
    })),
  [OrderActions.START_QUESTIONNAIRE]: (state, action) =>
    reduceSuccessResponse(state, action, (res) => ({
      orderDetails: res
    })),
  [OrderActions.GET_ORDER_DETAILS_BY_ID]: (state, action) =>
    reduceSuccessResponse(state, action, (res) => ({
      orderDetails: res
    })),
  [OrderActions.FETCH_ORDER_TABLE_COUNT]: (state, action) =>
    reduceSuccessResponse(state, action, (res) => ({
      orderCount: res
    })),
  [OrderActions.CLEAR_ORDERS_REDUCER]: () => {
    return initialState;
  },
  [OrderActions.POST_APPLICATION_INFO]: (state, action) =>
    reduceSuccessResponse(state, action, (res) => ({
      orderDetails: res
    })),
  [OrderActions.APP_INFO_FILE_UPLOAD_SUCCESS]: (state, action) => {
    const data = JSON.parse(action.payload);
    if (data.id) {
      state.orderDetails = data;
    }
  },
  [OrderActions.POST_AGREEMENT]: (state, action) =>
    reduceSuccessResponse(state, action, (res) => ({
      orderDetails: res
    })),
  [OrderActions.FETCH_MITIGATIONS_RECHECK]: (state, action) =>
    reduceSuccessResponse(state, action, (res) => ({
      orderDetails: res
    })),
  [OrderActions.VERIFY_OTP_CODE]: (state, action) =>
    reduceSuccessResponse(state, action, (res) => ({
      orderDetails: res
    })),
  [OrderActions.GET_ALL_ORDERS]: (state, action) =>
    reduceSuccessResponse(state, action, (res) => ({
      orderList: res
    })),
  [OrderActions.GET_ALL_ORDERS_MORE]: (state, action) =>
    reduceSuccessResponse(state, action, (res) => ({
      orderListMore: res.data
    })),
  [OrderActions.FETCH_ORDER_PRODUCTS]: (state, action) =>
    reduceSuccessResponse(state, action, (res) => ({
      orderProducts: res
    })),
  [OrderActions.CLEAR_ORDERS_TABLE]: (state) => {
    if (state.orderListTable?.data) {
      state.orderListTable.data = undefined;
    }
  },
  [OrderActions.GET_ORDERS_TABLE]: (state, action) =>
    reduceSuccessResponse(state, action, (res) => {
      return {
        orderListTable: res
      };
    }),
  [OrderActions.CLEAR_ORDER_DETAILS]: (state) => {
    state.orderDetails = null;
  },
  [OrderActions.GET_ALL_SERVICES]: (state, action) =>
    reduceSuccessResponse(state, action, (res) => ({
      servicesList: res
    }))
});

export const getServicesList = (state) => state.order.servicesList;

export const getOrderDetails = (state) => state.order.orderDetails;

export const getOrderTableCount = (state) => state.order.orderCount;

export const getIsOrderPaid = (state) => !!state.order.orderDetails?.payed_at;

export const getIsDeveloperInfoFilled = (state) =>
  !!state.order.orderDetails?.developer_name;
export const getIsAppInfoFilled = (state) =>
  !!state.order.orderDetails?.package_name;

export const getIsDevAndAppInfoFilled = (state) => {
  return getIsDeveloperInfoFilled(state) && getIsAppInfoFilled(state);
};

export const getIsOrderPaidOrVerified = (state) =>
  !!state.order.orderDetails?.payed_at ||
  !!state.order.orderDetails?.developer_email_verified_at;

export const getIsOrderVerified = (state) =>
  !!state.order.orderDetails?.developer_email_verified_at;

export const getIsAutomaticVerification = (state) =>
  !!state.order?.orderDetails?.developer_email;

export const getMoreOrderInfoText = (state) => {
  const orderStatuses = state.order.orderDetails?.statuses;

  if (orderStatuses) {
    const item = orderStatuses.find(
      (e) => e.code === OrderStatuses.NEED_MORE_INFO
    );

    return item;
  }

  return null;
};

export const getMitigationsStatusText = (state) => {
  const orderStatuses = state.order.orderDetails?.statuses;

  if (orderStatuses) {
    const item = orderStatuses.find(
      (e) => e.code === OrderStatuses.MITIGATIONS_NEEDED
    );

    return item;
  }

  return null;
};

export const getIsMoreInfoAccepted = (state) => {
  const orderStatuses = state.order.orderDetails?.statuses.map((el) => el.code);

  if (orderStatuses) {
    return (
      orderStatuses.includes(OrderStatuses.NEED_MORE_INFO) &&
      orderStatuses.includes(OrderStatuses.ACCEPTED)
    );
  }

  return false;
};

export const getIsAssessmentInProgress = (state) => {
  const orderStatuses = state.order.orderDetails?.statuses;

  if (orderStatuses) {
    const item = orderStatuses.find(
      (e) => e.code === OrderStatuses.ASSESSMENT_IN_PROGRESS
    );

    return item;
  }

  return null;
};

export const getIsAssessmentSuccessful = (state) => {
  const orderStatuses = state.order.orderDetails?.statuses.map((el) => el.code);

  if (orderStatuses) {
    return (
      (orderStatuses.includes(OrderStatuses.ASSESSMENT_IN_PROGRESS) &&
        orderStatuses.includes(OrderStatuses.MITIGATIONS_NEEDED)) ||
      orderStatuses.includes(OrderStatuses.VERIFIED) ||
      orderStatuses.NOT_VERIFIED
    );
  }

  return null;
};

export const getIsMitigationsNeeded = (state) => {
  const orderStatuses = state.order.orderDetails?.statuses;

  if (orderStatuses) {
    const item = orderStatuses.find(
      (e) => e.code === OrderStatuses.MITIGATIONS_NEEDED
    );
    return item;
  }

  return null;
};

export const getIsVerifyingMitigations = (state) => {
  const orderStatuses = state.order.orderDetails?.statuses.map((el) => el.code);

  if (orderStatuses) {
    return orderStatuses.includes(OrderStatuses.VERIFYING_MITIGATIONS);
  }

  return null;
};

export const getIsMitigationsNeededSuccessful = (state) => {
  const orderStatuses = state.order.orderDetails?.statuses.map((el) => el.code);

  if (orderStatuses) {
    return (
      orderStatuses.includes(OrderStatuses.MITIGATIONS_NEEDED) ||
      orderStatuses.includes(OrderStatuses.VERIFIED) ||
      orderStatuses.includes(OrderStatuses.NOT_VERIFIED)
    );
  }

  return null;
};

export const getCurrentStatusCode = (state) => {
  return state.order.orderDetails?.status?.code;
};

export const getIsCurrentStatusMitigationsNeeded = (state) => {
  const status = getCurrentStatusCode(state);

  return status === OrderStatuses.MITIGATIONS_NEEDED;
};

export const getMitigationsNeededItems = (state) => {
  const orderStatuses = state.order.orderDetails?.statuses;
  if (orderStatuses) {
    const items = orderStatuses.filter(
      (e) => e.code === OrderStatuses.MITIGATIONS_NEEDED
    );

    return items;
  }

  return null;
};

export const getLastMitigationsNeededItem = (state) => {
  const items = getMitigationsNeededItems(state);
  if (items) {
    return items[0];
  }

  return null;
};

export const getIsOrderFinalizedSuccessfully = (state) => {
  const orderStatuses = state.order.orderDetails?.statuses;

  if (orderStatuses) {
    const item = orderStatuses.find((e) => e.code === OrderStatuses.VERIFIED);
    return item;
  }

  return null;
};

export const getIsOrderFinalizedUnsuccessfully = (state) => {
  const orderStatuses = state.order.orderDetails?.statuses;

  if (orderStatuses) {
    const item = orderStatuses.find(
      (e) => e.code === OrderStatuses.NOT_VERIFIED
    );
    return item;
  }

  return null;
};

export const getAssessmentCompletedDetails = (state) => {
  const finalizedDetails = getIsOrderFinalizedSuccessfully(state);
  const unfinalizedDetails = getIsOrderFinalizedUnsuccessfully(state);

  return finalizedDetails || unfinalizedDetails;
};

export const getOrderList = (state) => state.order.orderList;
export const getOrdersMore = (state) => state.order.orderListMore;
export const getHasCreatedOrders = (state) => state.order.orderList?.total > 0;

export const getOrderProducts = (state) => state.order.orderProducts;

export const getProductById = (state, productId) =>
  state.order.orderProducts?.find((product) => product.id === productId);

export const getOrderPaginationDetails = (state) => {
  if (state.order.orderListTable) {
    return pick(state.order.orderListTable, [
      "current_page",
      "from",
      "last_page",
      "per_page",
      "to",
      "total"
    ]);
  }

  return null;
};

export const getOrdersForTable = (state) => {
  return state.order.orderListTable?.data;
};

export const getInProgressOrders = (state) => {
  const orders = getOrdersForTable(state);
  if (orders) {
    return orders.filter((o) => o.status === OrderFilters.NEW);
  }

  return [];
};

export default order;
