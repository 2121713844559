/* eslint-disable consistent-return */
import React, { useRef, useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { generatePath, useParams, useNavigate } from "react-router-dom";
import Button, { ButtonLink } from "@/components/Button";
import { MAX_TXT_FILE_SIZE_IN_MEGABYTES, routes } from "@/constants";
import { useForm } from "react-hook-form";
import yup from "@/utils/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  OrderActions,
  uploadFileSuccessful,
  postSourceCodeInfo,
  postRemoveSourceCodeFileCasa
} from "@/store/order/order.actions";
import { getOrderDetails } from "@/store/order/order.reducer";
import { isInProcess } from "@/store/pending/pending.reducer";
import ResumableFileUploader from "@/components/ResumableFileUploader";
import InputField from "@/components/InputField";
import "./SourceCodeInfo.scss";
// eslint-disable-next-line import/extensions
import motion from "@/utils/motionUtil.js";
import "rc-collapse/assets/index.css";
import Collapse, { Panel } from "rc-collapse";
import OrderInfo from "@/components/OrderInfo";
import { editableModeByStatus } from "@/utils/editableModeByStatus";

const schema = yup.object({
  // complexity measurements
  number_endpoints: yup
    .string()
    .nullable()
    .max(255)
    .when("src_filename", (src_filename) => {
      if (!src_filename) {
        return yup.string().nullable().max(255).required();
      }
    }),
  number_repositories: yup
    .string()
    .nullable()
    .max(255)
    .when("src_filename", (src_filename) => {
      if (!src_filename) {
        return yup.string().nullable().max(255).required();
      }
    }),
  loc_sloc: yup.string().nullable(),
  cyclomatic_complexity: yup.string().nullable().max(255),
  halstead_difficulty: yup.string().nullable().max(255),
  halstead_length: yup.string().nullable().max(255),
  halstead_calculated_length: yup.string().nullable().max(255),
  halstead_volume: yup.string().nullable().max(255),
  halstead_effort: yup.string().nullable().max(255),
  halstead_vocabulary: yup.string().nullable().max(255),
  custom_metrics: yup.string().nullable(),
  // quality measurements
  code_churn: yup.string().nullable(),
  code_coverage: yup.string().nullable(),
  code_dependencies: yup.string().nullable().max(255),
  code_reuse: yup.string().nullable().max(255),
  maintainability_index: yup.string().nullable().max(255),
  your_metrics_code_quality: yup.string().nullable(),
  src_filename: yup
    .mixed()
    .nullable()
    // .test("ifString", "Required", (value) => {
    //   if (typeof value === "string") {
    //     return !!value;
    //   }

    //   return true;
    // })
    .test(
      "size",
      `Size of file can't be larger than ${MAX_TXT_FILE_SIZE_IN_MEGABYTES} Mb`,
      (value) => {
        if (typeof value === "string") {
          return true;
        }
        // eslint-disable-next-line no-unsafe-optional-chaining
        const sizeMB = value?.size / 1024 / 1024;
        return sizeMB <= MAX_TXT_FILE_SIZE_IN_MEGABYTES;
      }
    )
  // .test("type", "Only .txt files are allowed", (value) => {
  //   if (typeof value === "string") {
  //     return !!value;
  //   }
  //   return value?.type === "text/plain" || value?.name.endsWith(".txt");
  // })
});

const SourceCodeInfo = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const orderDetailsNew = useSelector(getOrderDetails);
  const preloadedValues = id ? orderDetailsNew : {};
  const isLoading = useSelector((state) =>
    isInProcess(state, OrderActions.POST_SOURCE_CODE_INFO)
  );
  const {
    register,
    control,
    setValue,
    resetField,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      loc_sloc: preloadedValues.loc_sloc ?? "",
      cyclomatic_complexity: preloadedValues.cyclomatic_complexity ?? "",
      mc_cabes_complexity: preloadedValues.mc_cabes_complexity ?? "",
      halstead_complexity: preloadedValues.halstead_complexity ?? "",
      number_endpoints: preloadedValues.number_endpoints ?? "",
      number_repositories: preloadedValues.number_repositories ?? "",
      your_metrics_code_size: preloadedValues.your_metrics ?? "",
      code_churn: preloadedValues.code_churn ?? "",
      code_coverage: preloadedValues.code_coverage ?? "",
      code_dependencies: preloadedValues.code_dependencies ?? "",
      code_reuse: preloadedValues.code_reuse ?? "",
      maintainability_index: preloadedValues.maintainability_index ?? "",
      your_metrics_code_quality:
        preloadedValues.your_metrics_code_quality ?? "",
      src_filename: preloadedValues.src_filename ?? ""
    }
  });

  useEffect(() => {
    if (
      orderDetailsNew &&
      orderDetailsNew.status &&
      !editableModeByStatus(orderDetailsNew.status.code)
    ) {
      navigate(routes.ROOT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // file upload
  const fileUploader = useRef();
  const [isFileLoading, setIsFileLoading] = useState(false);
  const dispatch = useDispatch();

  const removeFile = async () => {
    await dispatch(postRemoveSourceCodeFileCasa(id));
  };

  const uploadFile = () => {
    fileUploader.current?.startUploading();
  };

  const fileUploadURL = useMemo(() => {
    return `${process.env.REACT_APP_API_URL}/api/v2/order-casa/${id}/source-code-info/file`;
  }, [id]);

  const onFileUploadSuccess = (_file, orderDetails) => {
    setIsFileLoading(false);

    if (orderDetails) {
      dispatch(uploadFileSuccessful(orderDetails));
    }

    const path = generatePath(routes.ORDER_CASA_SUCCESS, { id });
    navigate(path);
  };

  const onSubmit = async (data) => {
    const payload = Object.keys(data).reduce((acc, key) => {
      if (key !== "src_filename") {
        acc[key] = data[key];
      }
      return acc;
    }, {});

    if (fileUploader.current?.resumable.files.length) {
      uploadFile();

      await dispatch(
        postSourceCodeInfo({
          id,
          data: payload
        })
      );

      return;
    }

    await dispatch(
      postSourceCodeInfo({
        id,
        data: payload
      })
    );

    const path = generatePath(routes.ORDER_CASA_SUCCESS, { id });
    navigate(path);
  };

  const [activeKeysColapse, setActiveKeysColapse] = useState([]);

  const handleValidation = () => {
    if (!getValues("src_filename")) {
      setActiveKeysColapse(0);

      setTimeout(() => {
        document
          .querySelector(".main-layout__drawer-content")
          .scrollTo({ top: window.innerHeight / 2, behavior: "smooth" });
      }, 200);
    }
  };

  return (
    <form className="source-code-info" onSubmit={handleSubmit(onSubmit)}>
      <div className="source-code-info__widget">
        <OrderInfo
          inverted
          animationImage="help"
          title="Help with metrics?"
          text="Automatically obtain code metrics with accuracy and ease using our tool."
          button={{
            label: "Get the tool",
            url: "https://github.com/Octal-Security/scoping-git-metrics",
            external: true
          }}
        />
      </div>
      <div className="fields-group">
        <ResumableFileUploader
          name="src_filename"
          fileAcceptLabel="Only .txt"
          fileAccept="text/plain"
          setValue={setValue}
          resetField={resetField}
          target={fileUploadURL}
          ref={fileUploader}
          errors={errors}
          defaultFilename={preloadedValues.src_filename}
          onFileProgress={() => setIsFileLoading(true)}
          onFileUploadPause={() => setIsFileLoading(false)}
          onAfterFileSuccess={onFileUploadSuccess}
          onAfterUploadCanceled={() => setIsFileLoading(false)}
          onAfterFileError={() => {
            setIsFileLoading(false);
          }}
          control={control}
          onRemoveFile={removeFile}
        />
        <div className="fields-group-separator">
          <div className="fields-group-separator-inner">
            or fill the measurements
          </div>
        </div>
        <Collapse
          openMotion={motion}
          activeKey={activeKeysColapse}
          onChange={(key) => {
            setActiveKeysColapse(key);
          }}>
          <Panel
            header={
              <div className="fields-group-title">
                Code complexity measurements
              </div>
            }>
            <InputField
              name="number_endpoints"
              type="text"
              label="Number of API endpoints <sup>*</sup>"
              errors={errors}
              placeholder="Number of API endpoints"
              {...register("number_endpoints")}
            />
            <InputField
              name="number_repositories"
              type="text"
              label="Number of repositories <sup>*</sup>"
              errors={errors}
              placeholder="Number of repositories"
              {...register("number_repositories")}
            />
            <InputField
              multiline="2"
              name="loc_sloc"
              type="text"
              label="Lines of code (LOC)/ Source lines of code (SLOC)"
              errors={errors}
              placeholder="Value"
              {...register("loc_sloc")}
            />
            <InputField
              name="cyclomatic_complexity"
              type="text"
              label="Cyclomatic complexity (CC)"
              errors={errors}
              placeholder="Cyclomatic complexity"
              {...register("cyclomatic_complexity")}
            />
            <InputField
              name="halstead_difficulty"
              type="text"
              label="Halstead Difficulty (D)"
              errors={errors}
              placeholder="Halstead Difficulty"
              {...register("halstead_difficulty")}
            />
            <InputField
              name="halstead_length"
              type="text"
              label="Halstead Length (N)"
              errors={errors}
              placeholder="Halstead Length"
              {...register("halstead_length")}
            />
            <InputField
              name="halstead_calculated_length"
              type="text"
              label="Halstead Calculated Length (Nx)"
              errors={errors}
              placeholder="Halstead Calculated Length"
              {...register("halstead_calculated_length")}
            />
            <InputField
              name="halstead_volume"
              type="text"
              label="Halstead Volume (V)"
              errors={errors}
              placeholder="Halstead Volume"
              {...register("halstead_volume")}
            />
            <InputField
              name="halstead_effort"
              type="text"
              label="Halstead Effort (E)"
              errors={errors}
              placeholder="Halstead Effort"
              {...register("halstead_effort")}
            />
            <InputField
              name="halstead_vocabulary"
              type="text"
              label="Halstead Vocabulary (n)"
              errors={errors}
              placeholder="Halstead Vocabulary"
              {...register("halstead_vocabulary")}
            />
            <InputField
              multiline="2"
              name="custom_metrics"
              type="text"
              label="Custom metrics"
              errors={errors}
              placeholder="You can add custom metrics here"
              {...register("custom_metrics")}
            />
          </Panel>
          <Panel
            header={
              <div className="fields-group-title">
                Code quality measurements
              </div>
            }>
            <InputField
              multiline="2"
              name="code_churn"
              type="text"
              label="Code churn"
              errors={errors}
              placeholder="Complexity"
              {...register("code_churn")}
            />
            <InputField
              multiline="2"
              name="code_coverage"
              type="text"
              label="Code coverage"
              errors={errors}
              placeholder="Line coverage, Function coverage, Branch coverage, Statement coverage, etc."
              {...register("code_coverage")}
            />
            <InputField
              name="code_dependencies"
              type="text"
              label="Code dependencies"
              errors={errors}
              placeholder="Coupling, Instability, etc."
              {...register("code_dependencies")}
            />
            <InputField
              name="code_reuse"
              type="text"
              label="Code reuse"
              errors={errors}
              placeholder="Reusability Index, Duplication Ratio, etc."
              {...register("code_reuse")}
            />
            <InputField
              name="maintainability_index"
              type="text"
              label="Maintainability index"
              errors={errors}
              placeholder="Value"
              {...register("maintainability_index")}
            />
            <InputField
              multiline="2"
              name="your_metrics_code_quality"
              type="text"
              label="Custom metrics"
              errors={errors}
              placeholder="You can add custom metrics here"
              {...register("your_metrics_code_quality")}
            />
          </Panel>
        </Collapse>
      </div>
      <div className="main-layout__drawer-footer">
        <div className="main-layout__drawer-buttons">
          <div className="main-layout__drawer-buttons-item">
            <ButtonLink
              text="Back"
              className="btn-light btn-fullwidth"
              to={generatePath(routes.ORDER_CASA_ASSESSMENT_INFO, { id })}
            />
          </div>
          <div className="main-layout__drawer-buttons-item">
            <Button
              isLoading={isLoading || isFileLoading}
              type="submit"
              className="btn-default btn-fullwidth"
              text="Continue"
              onClick={handleValidation}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default SourceCodeInfo;
