import React from "react";
import {
  Routes,
  Route,
  createSearchParams,
  useNavigate
} from "react-router-dom";
import { routes } from "@/constants";
import EmailForm from "./EmailForm";
import ResetPasswordForm from "./ResetPasswordForm";
import ResetPasswordSuccess from "./ResetPasswordSuccess";

const ForgotPasswordFlow = () => {
  const navigate = useNavigate();

  const onEmailFormSubmit = (email) => {
    const params = `?${createSearchParams({ email })}`;
    navigate({
      pathname: routes.RESET_PASSWORD,
      search: params
    });
  };

  const onResetPasswordSubmit = () => {
    navigate(routes.RESET_PASSWORD_SUCCESS);
  };

  return (
    <Routes>
      <Route index element={<EmailForm onAfterSubmit={onEmailFormSubmit} />} />
      <Route
        path="reset"
        element={<ResetPasswordForm onAfterSubmit={onResetPasswordSubmit} />}
      />
      <Route path="success" element={<ResetPasswordSuccess />} />
    </Routes>
  );
};

export default ForgotPasswordFlow;
