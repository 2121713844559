import { createAsyncAction } from "@/utils/createAsyncAction";
import AuthService from "@/services/AuthService";
import { createAction } from "@reduxjs/toolkit";

export const AuthActions = {
  REGISTER: "REGISTER",
  CONFIRM_EMAIL_CODE: "CONFIRM_EMAIL_CODE",
  LOGOUT: "LOGOUT",
  LOGIN: "LOGIN",
  RESPOND_TO_AUTH_CHALLENGE: "RESPOND_TO_AUTH_CHALLENGE",
  ASSOCIATE_MFA_SOFTWARE_TOKEN: "ASSOCIATE_MFA_SOFTWARE_TOKEN",
  VERIFY_MFA_SOFTWARE_TOKEN: "VERIFY_MFA_SOFTWARE_TOKEN",
  REMOVE_MFA_SETUP_DETAILS: "REMOVE_MFA_SETUP_DETAILS",
  VALIDATE_TOKEN: "VALIDATE_TOKEN",
  LOGIN_USING_SOCIAL: "LOGIN_USING_SOCIAL",
  SEND_RESET_CODE: "SEND_RESET_CODE",
  RESET_PASSWORD: "RESET_PASSWORD"
};

export const registerUser = createAsyncAction(
  AuthActions.REGISTER,
  async (data) => AuthService.register(data)
);

export const confirmEmailCode = createAsyncAction(
  AuthActions.CONFIRM_EMAIL_CODE,
  async (data) => AuthService.confirmEmailCode(data)
);

export const login =
  ({ login: _login, password, code }) =>
  async (dispatch) => {
    const loginAction = createAsyncAction(
      AuthActions.LOGIN,
      async (credentials) => {
        return AuthService.login(credentials);
      }
    );

    const res = await dispatch(loginAction({ login: _login, password, code }));

    return res;
  };

export const associateMFASoftwareToken = createAsyncAction(
  AuthActions.ASSOCIATE_MFA_SOFTWARE_TOKEN,
  async (data) => {
    return AuthService.associateMFASoftwareToken(data);
  }
);

export const removeMFASetupDetails = createAction(
  AuthActions.REMOVE_MFA_SETUP_DETAILS
);

export const respondToAuthChallenge = createAsyncAction(
  AuthActions.RESPOND_TO_AUTH_CHALLENGE,
  async (data) => AuthService.respondToAuthChallenge(data)
);

export const verifyMFASoftwareToken = createAsyncAction(
  AuthActions.VERIFY_MFA_SOFTWARE_TOKEN,
  async (data) => {
    return AuthService.verifyMFASoftwareToken(data);
  }
);

export const logout = createAction(AuthActions.LOGOUT);

export const validateToken = createAsyncAction(
  AuthActions.VALIDATE_TOKEN,
  (cancelToken) => AuthService.validateToken(cancelToken)
);

export const loginUsingSocial = createAction(AuthActions.LOGIN_USING_SOCIAL);

export const setSessionThroughSocial = createAction(
  AuthActions.LOGIN_USING_SOCIAL
);

export const sendResetCode = createAsyncAction(
  AuthActions.SEND_RESET_CODE,
  async (email) => AuthService.sendResetCode(email)
);

export const resetPassword = createAsyncAction(
  AuthActions.RESET_PASSWORD,
  async (params) => AuthService.resetPassword(params)
);
