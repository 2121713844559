import React from "react";
// import OrderTabs from "@/components/OrderTabs";
import { ButtonLink } from "@/components/Button";
import { generatePath, useParams } from "react-router-dom";
import { routes } from "@/constants";

import "./OrderPaymentStatus.scss";

const OrderPaymentStatus = () => {
  const { id } = useParams();

  return (
    <div className="order-payment-status">
      <div className="fields-group">
        <h2 className="order-payment-status__title">
          Your payment was successful
        </h2>
      </div>
      <div className="fields-group">
        <p className="order-payment-status__text">
          Now, you can check your status at the order history list
        </p>
      </div>
      <div className="order-payment-status__buttons-wr">
        <ButtonLink
          to={generatePath(routes.PROJECT_PROGRESS, { id })}
          text="Open details"
          blank={false}
          className="btn-light btn-fullwidth"
        />
      </div>
    </div>
  );
};

export default OrderPaymentStatus;
