import React from "react";
import { ButtonLink } from "@/components/Button";
import AnimationImage from "@/components/AnimationImage";
import { routes, routeTitles } from "@/constants";
import "./MFASetup.scss";

const DisableMFASuccess = () => {
  return (
    <div className="disable-mfa-success">
      <div className="disable-mfa-success__image">
        <AnimationImage title={routeTitles[routes.MFA]} />
      </div>
      <div className="disable-mfa-success__title">
        You’ve successfully disabled multi-factor authentication for your
        account.
      </div>
      <div className="main-layout__drawer-footer">
        <div className="main-layout__drawer-buttons">
          <div className="main-layout__drawer-buttons-item">
            <ButtonLink
              blank={false}
              className="btn-default btn-fullwidth"
              to={routes.SETTINGS}
              text="Go to settings"
              replace
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisableMFASuccess;
