import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createPayment } from "@/store/order/order.actions";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CircleSpinner from "@/components/CircleSpinner";
import CheckoutForm from "@/components/OrderSteps/CheckoutForm";
import PaymentTotalInfo from "@/components/OrderStepsCasa/PaymentTotalInfo";
import numeral from "numeral";
import "./OnlinePayment.scss";

const stripe = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const OnlinePayment = ({ selectedPlan }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const productId = searchParams.get("product_id");
  const [clientSecret, setClientSecret] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [processingFee, setProcessingFee] = useState();

  useEffect(() => {
    const processing = (selectedPlan.amount_total - selectedPlan.amount) / 100;
    const totalAmountFormatted = numeral(
      selectedPlan.amount_total / 100
    ).format("0,0.00");

    setProcessingFee(numeral(processing).format("0,0.00"));
    setTotalAmount(totalAmountFormatted);

    (async () => {
      try {
        const res = await dispatch(
          createPayment({ id, product_id: selectedPlan.id })
        );

        setClientSecret(res.client_secret);
      } catch (err) {
        console.log("Error / Create payment failed");
      }
    })();
    // eslint-disable-next-line
  }, [id, selectedPlan]);

  return (
    <div className="online-payment">
      <div className="online-payment__inner">
        <div className="online-payment__wrap">
          {clientSecret && stripe && totalAmount ? (
            <Elements stripe={stripe} options={{ clientSecret }}>
              <CheckoutForm
                clientSecret={clientSecret}
                productId={productId}
                amount={totalAmount}
              />
            </Elements>
          ) : (
            <CircleSpinner />
          )}
        </div>
        {clientSecret && selectedPlan && totalAmount && (
          <PaymentTotalInfo
            title={`MASA - ${selectedPlan.name} Assessment`}
            amount={numeral(selectedPlan.amount / 100).format("0,0")}
            totalAmount={totalAmount}
            processing={processingFee}
            subtotal
          />
        )}
      </div>
    </div>
  );
};

OnlinePayment.propTypes = {
  selectedPlan: PropTypes.object
};

export default OnlinePayment;
