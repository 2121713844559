export const PlanTypes = {
  REGULAR: "Regular",
  EXPRESS: "Express"
};

export const PlanTypeProperties = {
  [PlanTypes.REGULAR]: {
    amountInUsd: 3000
  },
  [PlanTypes.EXPRESS]: {
    amountInUsd: 1000
  }
};
