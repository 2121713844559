import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { getOrderProducts } from "@/store/order/order.reducer";
import { fetchOrderProducts } from "@/store/order/order.actions";
import PanelHeader from "@/components/ProjectDetails/components/PanelHeader";
import {
  CheckCircleSuccessIcon,
  CircleProgressIcon,
  CheckCircleDisabledIcon
} from "@/icons";
import cn from "classnames";
import { OrderStatuses } from "@/constants/orderStatuses";
import PaymentChooser from "@/components/ProjectDetails/components/PaymentChooser";
import { Mobile } from "@/components/Responsive";
import CircleSpinner from "@/components/CircleSpinner";

export const PricingOptionsNavItem = ({
  tabIndex,
  handleTabNavClick,
  orderDetails
}) => {
  const {
    payed_at,
    status: { code },
    order_email
  } = orderDetails;

  return (
    <button
      className={cn(
        "project-details__nav-item",
        tabIndex === "pricing-options" && "active"
      )}
      disabled={
        !(
          code === OrderStatuses.AWAITING_PAYMENT ||
          payed_at ||
          code === OrderStatuses.INVOICE_PROCESSING ||
          code !== OrderStatuses.CREATED
        )
      }
      type="button"
      onClick={() => handleTabNavClick("pricing-options")}>
      {
        // eslint-disable-next-line no-nested-ternary
        code === OrderStatuses.INVOICE_PROCESSING && !payed_at ? (
          <CircleProgressIcon className="project-details__nav-icon" />
        ) : payed_at || order_email ? (
          <CheckCircleSuccessIcon className="project-details__nav-icon" />
        ) : (
          <CheckCircleDisabledIcon className="project-details__nav-icon" />
        )
      }
      Pricing options
    </button>
  );
};

const PricingOptions = ({ orderDetails, tabIndex, handleTabNavClick }) => {
  const {
    status: { code },
    payed_at
  } = orderDetails;

  const [selectedPlan, setSelectedPlan] = useState();

  const orderProducts = useSelector(getOrderProducts);
  const orderProductsL1 = orderProducts.filter((o) => o.type === 0);
  const orderProductsL2 = orderProducts.filter((o) => o.type === 1);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOrderProducts());
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Mobile>
        <PricingOptionsNavItem
          tabIndex={tabIndex}
          handleTabNavClick={handleTabNavClick}
          orderDetails={orderDetails}
        />
      </Mobile>
      {tabIndex === "pricing-options" && (
        <div className="project-details__tab">
          {code === OrderStatuses.INVOICE_PROCESSING ||
          code === OrderStatuses.INVOICE_DETAILS_CONFIRMED ? (
            <PanelHeader
              title="Pricing options"
              description={
                code === OrderStatuses.INVOICE_DETAILS_CONFIRMED
                  ? "Invoicing details have been verified and approved; invoice is being prepared. Assessment will start according to the option you chose."
                  : "Hang tight! Our accounting team will shortly reach out to the Accounts Payable/Accounting contact you provided to finalize invoice details."
              }
              icon={
                code === OrderStatuses.INVOICE_DETAILS_CONFIRMED ? (
                  <CheckCircleSuccessIcon />
                ) : (
                  <CircleProgressIcon />
                )
              }
              hasButton={false}
            />
          ) : (
            <>
              <PanelHeader
                title="Pricing options"
                description={
                  payed_at
                    ? "We successfully confirmed your payment. Assessment will start according to the option you chose."
                    : "Choose a pricing plan that suits your needs."
                }
                icon={
                  payed_at ? (
                    <CheckCircleSuccessIcon />
                  ) : (
                    <CheckCircleDisabledIcon />
                  )
                }
                hasButton={false}
              />
              {code !== OrderStatuses.INVOICE_PROCESSING && !payed_at && (
                <div>
                  <div className="project-details__section">
                    {orderProductsL1.length ? (
                      <PaymentChooser
                        title="MASA Level 1 Assessment"
                        setSelectedPlan={setSelectedPlan}
                        selectedPlan={selectedPlan}
                        description="Choose a pricing plan that suits your needs"
                        products={orderProductsL1}
                        recommended={-1}
                      />
                    ) : (
                      <CircleSpinner />
                    )}
                  </div>
                  <div className="project-details__section_upselling">
                    {orderProductsL2.length ? (
                      <PaymentChooser
                        title="MASA Level 2 Assessment"
                        description="Upgrade your MASA assessment"
                        products={orderProductsL2}
                        setSelectedPlan={setSelectedPlan}
                        selectedPlan={selectedPlan}
                        recommended={-1}
                        useIcons
                      />
                    ) : (
                      <CircleSpinner />
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

PricingOptions.propTypes = {
  orderDetails: PropTypes.object.isRequired,
  tabIndex: PropTypes.string,
  handleTabNavClick: PropTypes.func
};

PricingOptionsNavItem.propTypes = {
  tabIndex: PropTypes.string,
  handleTabNavClick: PropTypes.func,
  orderDetails: PropTypes.object.isRequired
};

export default PricingOptions;
