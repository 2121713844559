import { routesToPropsMap } from "@/constants";
import { matchPath, useLocation } from "react-router-dom";
import { useMemo } from "react";

export const matchLastRouteDefinition = (definitions, location) => {
  const ignoredPaths = ["*"];
  let result;

  definitions.forEach((path) => {
    const match = matchPath({ path }, location.pathname);
    if (match && !ignoredPaths.includes(path)) {
      result = match;
    }
  });

  return result;
};

export const useAsideLayoutProps = (routesArray) => {
  const location = useLocation();

  return useMemo(() => {
    const match = matchLastRouteDefinition(routesArray, location);
    return routesToPropsMap[match?.pattern?.path] || {};
  }, [location, routesArray]);
};
