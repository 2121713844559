import React, { useState, useEffect } from "react";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import { Outlet } from "react-router-dom";
import { useAsideLayoutProps } from "@/hooks";
import { Desktop } from "@/components/Responsive";
import AnimationImage from "@/components/AnimationImage";
import { routes } from "@/constants";
import cn from "classnames";
import "./AsideLayout.scss";

const AsideLayout = () => {
  const layoutProps = useAsideLayoutProps(Object.values(routes));
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleSticky = () => {
      setIsSticky(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleSticky, { passive: true });
    return () => window.removeEventListener("scroll", handleSticky);
  }, []);

  return (
    <div className="aside-layout">
      <div
        className={cn(
          "aside-layout__header",
          isSticky && "aside-layout__header_sticky"
        )}>
        <Header mod="simple" />
      </div>
      <div className="aside-layout__body">
        <div className="aside-layout__content">
          <Outlet />
        </div>
        <Desktop>
          <div className="aside-layout__aside">
            <div className="aside-layout__image">
              {layoutProps.title && (
                <AnimationImage title={layoutProps.title} />
              )}
            </div>
          </div>
        </Desktop>
      </div>
      <div className="aside-layout__footer">
        <Footer mod="simple" />
      </div>
    </div>
  );
};

export default AsideLayout;
