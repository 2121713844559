import React from "react";
import PropTypes from "prop-types";

const DEFAULT_TEXT = "-";

const OptionalTextField = ({ tag, text, ...props }) => {
  return React.createElement(tag || "span", props, text || DEFAULT_TEXT);
};

OptionalTextField.defaultProps = {
  text: DEFAULT_TEXT
};

OptionalTextField.propTypes = {
  text: PropTypes.string,
  tag: PropTypes.string,
  className: PropTypes.string
};

export default OptionalTextField;
