/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-duplicates, no-console */
import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { Auth } from "aws-amplify";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getAuthType } from "@/store/auth/auth.reducer";
import {
  getAccountEmail,
  getAccountChangePasswordDate
} from "@/store/account/account.reducer";
import { AuthTypes, lsKeys } from "@/constants";
import cn from "classnames";
import {
  TrashIcon,
  NotificationInvertedIcon,
  LockIcon,
  GoogleIcon,
  MailIcon,
  AppleLogo
} from "@/icons";
import ServicePopup from "@/components/ServicePopup";
import { TabletDesktop, Mobile } from "@/components/Responsive";
import { routes } from "@/constants/routes";
import "./Settings.scss";

const Settings = () => {
  const navigate = useNavigate();
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const authType = useSelector(getAuthType);
  const accountEmail = useSelector(getAccountEmail);
  const [isEmailAuth, setEmailAuth] = useState(false);
  const [isGoogleAuth, setGoogleAuth] = useState(false);
  const [isAppleAuth, setAppleAuth] = useState(false);
  const [pushNotifications, setPushNotifications] = useState(false);
  const [mfaAuth, setMfaAuth] = useState();
  const accountChangePasswordDate = useSelector(getAccountChangePasswordDate);
  const [comingSoon] = useState(false);
  const isMfaAuth = localStorage.getItem(lsKeys.MFA_SESSION);

  const openDeleteAccountModal = () => {
    setShowDeleteAccountModal(true);
  };

  const closeDeleteAccountModal = () => {
    setShowDeleteAccountModal(false);
  };

  const handlePushNotifications = () => {
    setPushNotifications(!pushNotifications);
  };

  const handleMfaAuth = () => {
    if (mfaAuth) {
      navigate(routes.MFA_DISABLE);
    } else {
      navigate(routes.MFA_ENABLE);
    }
  };

  useEffect(() => {
    setMfaAuth(isMfaAuth);

    const provider =
      Auth.user &&
      JSON.parse(Auth.user.attributes.identities)[0].providerName.toLowerCase();

    if (provider && provider.indexOf("google") !== -1) {
      setGoogleAuth(true);
    }
    if (provider && provider.indexOf("apple") !== -1) {
      setAppleAuth(true);
    }

    if (authType === AuthTypes.EMAIL_PASSWORD) {
      setEmailAuth(true);
    }
  }, [authType, isMfaAuth]);

  return (
    <div className="settings">
      <div className="settings__content">
        <div className="settings__section">
          <div className="settings__panel">
            <div className="settings__info">
              {accountEmail && (
                <div className="settings__auth">
                  <div className="settings__auth-icon">{accountEmail[0]}</div>
                  <div className="settings__auth-wrap">
                    <div className="settings__auth-title">{accountEmail}</div>
                    <Mobile>
                      <div className="settings__auth-label">
                        {isGoogleAuth && "Sign in with Google"}
                        {isEmailAuth && "Sign in with email"}
                        {isAppleAuth && "Sign in with Apple"}
                      </div>
                    </Mobile>
                  </div>
                </div>
              )}
              <TabletDesktop>
                <div className="settings__auth-type">
                  {isGoogleAuth && (
                    <>
                      <GoogleIcon className="settings__auth-type-icon" />
                      <span className="settings__auth-type-label">
                        Sign in with Google
                      </span>
                    </>
                  )}
                  {isEmailAuth && (
                    <>
                      <MailIcon className="settings__auth-type-icon" />
                      <span className="settings__auth-type-label">
                        Sign in with email
                      </span>
                    </>
                  )}
                  {isAppleAuth && (
                    <>
                      <AppleLogo className="settings__auth-type-icon" />
                      <span className="settings__auth-type-label">
                        Sign in with Apple
                      </span>
                    </>
                  )}
                </div>
              </TabletDesktop>
            </div>
          </div>
        </div>
        {isEmailAuth && (
          <div className="settings__section">
            <div className="settings__panel settings__panel_head">
              <div className="settings__panel-icon">
                <LockIcon className="settings__panel-icon-i" />
              </div>
              <div className="settings__panel-title">Privacy and Security</div>
            </div>
            <div className="settings__panel">
              <div className="settings__list">
                <div className="settings__list-item">
                  <div className="settings__list-title">Change password</div>
                  {accountChangePasswordDate && (
                    <div className="settings__list-text">
                      Changed:{" "}
                      {format(
                        new Date(accountChangePasswordDate),
                        "MMMM d, yyyy"
                      )}
                    </div>
                  )}
                  <div className="settings__list-control">
                    <Link
                      className="settings__list-control-link"
                      to={routes.CHANGE_PASSWORD}>
                      Change
                    </Link>
                  </div>
                </div>
                <div className="settings__list-item">
                  <div className="settings__list-title">
                    Multi-factor authentification
                  </div>
                  <div className="settings__list-text">Set up MFA</div>
                  <div className="settings__list-control">
                    <button
                      className={cn("settings__toggle", mfaAuth && "active")}
                      type="button"
                      aria-label="Multi-factor authentification"
                      onClick={handleMfaAuth}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {comingSoon && (
          <div className="settings__section">
            <div className="settings__panel settings__panel_head">
              <div className="settings__panel-icon">
                <NotificationInvertedIcon className="settings__panel-icon-i" />
              </div>
              <div className="settings__panel-title">Notification settings</div>
            </div>
            <div className="settings__panel">
              <div className="settings__list">
                <div className="settings__list-item">
                  <div className="settings__list-title">Push-notifications</div>
                  <div className="settings__list-text">Allow notifications</div>
                  <div className="settings__list-control">
                    <button
                      className={cn(
                        "settings__toggle",
                        pushNotifications && "active"
                      )}
                      type="button"
                      aria-label="Push-notifications"
                      onClick={handlePushNotifications}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {comingSoon && (
          <div className="settings__panel">
            <button
              className="settings__delete-account"
              type="button"
              onClick={openDeleteAccountModal}>
              <div className="settings__delete-account-icon">
                <TrashIcon className="settings__delete-account-icon-i" />
              </div>
              <div className="settings__delete-account-label">
                Delete account
              </div>
            </button>
          </div>
        )}
      </div>
      {showDeleteAccountModal && (
        <ServicePopup
          onClose={closeDeleteAccountModal}
          // onSubmit={handleDeleteAccount}
          // onSubmitAction={AccountActions.DELETE_ACCOUNT}
          title="Delete account"
          text="Are you sure you want to delete your account?"
          submitButtonLabel="Delete account"
          submitDelete
        />
      )}
    </div>
  );
};

export default Settings;
