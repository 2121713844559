import React, { useEffect, useRef } from "react";
import purify from "dompurify";
import PropTypes from "prop-types";
import cn from "classnames";
import ErrorMessage from "@/components/ErrorMessage";
import { useCombinedRefs } from "@/hooks";
import { Controller } from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./PhoneField.scss";

// eslint-disable-next-line react/display-name
const PhoneField = React.forwardRef(
  (
    {
      control,
      wrapperClassName,
      fieldName,
      label,
      errors,
      autoFocus,
      placeholder,
      readonlyValue
    },
    ref
  ) => {
    const inputRef = useRef(null);
    const combinedRef = useCombinedRefs(ref, inputRef);

    useEffect(() => {
      if (autoFocus) {
        combinedRef.current.focus();
      }
    }, [autoFocus, combinedRef]);

    return (
      <div
        className={cn(
          "form-group",
          wrapperClassName,
          errors[fieldName] ? "has-error" : ""
        )}>
        {label && (
          <div className="control-top-labels">
            {label && (
              <div
                className="form-label"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: purify.sanitize(label) }}
              />
            )}
          </div>
        )}
        <div className="form-field">
          <Controller
            control={control}
            name={fieldName}
            render={({ field }) => (
              <PhoneInput
                {...field}
                readOnly={readonlyValue && true}
                ref={combinedRef}
                name={fieldName}
                placeholder={placeholder}
                value={readonlyValue || field.value}
              />
            )}
          />
        </div>
        {!readonlyValue && <ErrorMessage errors={errors} name={fieldName} />}
      </div>
    );
  }
);

PhoneField.defaultProps = {
  errors: {}
};

PhoneField.propTypes = {
  control: PropTypes.object.isRequired,
  autoFocus: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  errors: PropTypes.object,
  placeholder: PropTypes.string,
  readonlyValue: PropTypes.string
};

export default PhoneField;
