import React, { useState } from "react";
import InputField from "@/components/InputField";
import Button from "@/components/Button";
import FederalProvidersRow from "@/components/FederalProvidersRow";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "@/utils/yup";
import { useDispatch, useSelector } from "react-redux";
import { AuthActions, login } from "@/store/auth/auth.actions";
import {
  createSearchParams,
  Link,
  useLocation,
  useNavigate
} from "react-router-dom";
import { routes } from "@/constants";
import { isInProcess } from "@/store/pending/pending.reducer";
import AlertMessage from "@/components/AlertMessage";
import InputPasswordField from "@/components/InputPasswordField";
import "./LoginForm.scss";

const loginSchema = yup.object({
  login: yup.string().nullable().max(255).email().required(),
  password: yup
    .string()
    .nullable()
    .min(8)
    .max(99)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-"!@#%&/,><':;_~`])\S{8,99}$/,
      "Not appropriate password format"
    )
    .required()
});

const LoginForm = () => {
  const [error, setError] = useState(false);
  const isLoading = useSelector((state) =>
    isInProcess(state, AuthActions.LOGIN)
  );
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(loginSchema)
  });
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || routes.ROOT;

  const onSubmit = async (data) => {
    setError(null);
    try {
      const res = await dispatch(login(data));

      if (res.challenge) {
        const searchParams = `?${createSearchParams(res)}`;
        navigate({
          pathname: routes.MFA,
          search: searchParams,
          state: { from }
        });
      } else if (res?.token) {
        navigate(from, { replace: true });
      }
      // eslint-disable-next-line no-empty
    } catch (e) {
      setError("Login failed. Something went wrong.");
    }
  };

  return (
    <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="login-form__title">Welcome back!</div>
      <div className="login-form__subtitle">
        It’s great to see you 👋 Log in to your account below
      </div>
      <FederalProvidersRow />
      <div className="login-form__label">
        or continue with your email and password
      </div>
      <div className="fields-group">
        <InputField
          label="Email"
          name="login"
          type="email"
          errors={errors}
          placeholder="example@mail.com"
          {...register("login")}
        />
        <Link
          className="login-form__forgot-password"
          to={routes.FORGOT_PASSWORD}>
          Forgot password?
        </Link>
        <InputPasswordField
          label="Password"
          name="password"
          errors={errors}
          {...register("password")}
        />
      </div>
      <div className="fields-group">
        <Button
          isLoading={isLoading}
          text="Login"
          className="login-form__button btn-default btn-fullwidth"
          type="submit"
        />
      </div>
      {error && (
        <div className="login-form__error">
          <AlertMessage text={error} type="error" />
        </div>
      )}
      <div className="login-form__info">
        First time here?&nbsp;
        <Link to={routes.REGISTER}>Get started</Link>
      </div>
      {/* <div className="login-form__privacy">
        Signing up for a CASA account means your business does not connect with
        Russia and you agree to the&nbsp;
        <Link to={routes.PRIVACY_POLICY}>Privacy Policy</Link>,&nbsp;
        <Link to={routes.TERMS}>Terms of Service</Link>.
      </div> */}
    </form>
  );
};

export default LoginForm;
