import { isSuccess } from "./createAsyncAction";

const defaultResponseParser = (response) => response;

function reduceSuccessResponse(
  state,
  action,
  responseParser = defaultResponseParser
) {
  if (isSuccess(action)) {
    return {
      ...state,
      ...responseParser(action.payload.response, state)
    };
  }
  return state;
}

export { reduceSuccessResponse };
