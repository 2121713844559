import React, { useState } from "react";
import PropTypes from "prop-types";
import { ButtonLink } from "@/components/Button";
import cn from "classnames";
import { routes } from "@/constants";
import { useSelector } from "react-redux";
import { OrderActions } from "@/store/order/order.actions";
import { isInProcess } from "@/store/pending/pending.reducer";
import CircleSpinner from "@/components/CircleSpinner";
import OrderInfo from "@/components/OrderInfo";
import "./OrderStart.scss";

const OrderStart = ({ services }) => {
  const [selectedProduct, setSelectedProduct] = useState();
  const [productOrderUrl, setProductOrderUrl] = useState();

  const isLoading = useSelector((state) =>
    isInProcess(state, OrderActions.GET_ALL_SERVICES)
  );

  if (isLoading) {
    return (
      <div className="order-start">
        <CircleSpinner />
      </div>
    );
  }

  const handleChooseProduct = (productId) => {
    setSelectedProduct(productId);

    switch (productId.toLowerCase()) {
      case "casa":
        setProductOrderUrl(routes.NEW_ORDER_CASA);
        break;
      case "masa":
        setProductOrderUrl(routes.NEW_ORDER);
        break;
      default:
        break;
    }
  };

  return (
    <div className="order-start">
      <div className="order-start__list">
        {services &&
          services.map((item) => (
            <button
              key={item.name}
              className={cn(
                "order-start__control",
                selectedProduct === item.name && "active"
              )}
              type="button"
              onClick={() => handleChooseProduct(item.name)}>
              {item.title && (
                <div className="order-start__control-title">{item.title}</div>
              )}
              {item.description && (
                <div className="order-start__control-label">
                  {item.description}
                </div>
              )}
            </button>
          ))}
      </div>
      <div className="order-start__info">
        <OrderInfo
          animationImage="project-details"
          title="See the full picture"
          text="Gain holistic insights with our comprehensive assessment. Get a discount for a pentest with your CASA validation. <a href='mailto:hub@leviathansecurity.com'>Contact us</a>"
        />
      </div>
      <div className="main-layout__drawer-footer">
        <div className="main-layout__drawer-buttons">
          <div className="main-layout__drawer-buttons-item">
            <ButtonLink
              disabled={!selectedProduct}
              to={productOrderUrl || ""}
              text="Continue"
              className="btn-default btn-fullwidth"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

OrderStart.propTypes = {
  services: PropTypes.array
};

export default OrderStart;
