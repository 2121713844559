import PanelHeader from "@/components/ProjectProgress/components/PanelHeader";
import { CheckCircleSuccessIcon, CircleWarningIcon } from "@/icons";
import React from "react";
import PropTypes from "prop-types";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { routes } from "@/constants";
import { useSelector } from "react-redux";
import {
  getIsAppInfoFilled,
  getIsOrderPaidOrVerified
} from "@/store/order/order.reducer";
import OptionalTextField from "@/components/ProjectProgress/components/OptionalTextField";

export const ApplicationInfoPanelHeader = ({ active }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isAppInfoFilled = useSelector(getIsAppInfoFilled);
  const isPaidOrVerified = useSelector(getIsOrderPaidOrVerified);
  const onAppInfoClick = () => {
    navigate(
      generatePath(routes.ORDER_APPLICATION_INFO, {
        id
      })
    );
  };

  return (
    <PanelHeader
      title="Application details"
      description="Application and package details, app review information"
      icon={
        isAppInfoFilled ? <CheckCircleSuccessIcon /> : <CircleWarningIcon />
      }
      active={active}
      hasButton={!isPaidOrVerified}
      buttonText="Update"
      onClick={onAppInfoClick}
    />
  );
};

ApplicationInfoPanelHeader.propTypes = {
  active: PropTypes.bool.isRequired
};

export const ApplicationInfoPanelBody = ({ orderDetails }) => {
  return (
    <div className="progress-accordion__body inner">
      <div className="progress-accordion__body-item body-item">
        <h6 className="body-item__title">Application information</h6>
        <div className="body-item__info-block">
          <span className="body-item__label">Package name</span>
          <OptionalTextField
            text={orderDetails.package_name}
            className="body-item__text"
          />
        </div>
        <div className="body-item__info-block">
          <span className="body-item__label">App name</span>
          <OptionalTextField
            text={orderDetails.application_name}
            className="body-item__text"
          />
        </div>
      </div>
      <div className="progress-accordion__body-item body-item">
        <h6 className="body-item__title">Sign-in Information</h6>
        <div className="body-item__info-block">
          <span className="body-item__label">Email</span>
          <OptionalTextField
            text={orderDetails.app_login}
            className="body-item__text"
          />
        </div>
        <div className="body-item__info-block">
          <span className="body-item__label">Password</span>
          <OptionalTextField
            text={orderDetails.app_password}
            className="body-item__text"
          />
        </div>
      </div>
      <div className="progress-accordion__body-item body-item">
        <h6 className="body-item__title">APK file</h6>
        <div className="body-item__info-block">
          <span className="body-item__label">Filename</span>
          <OptionalTextField
            text={orderDetails.app_filename}
            tag="a"
            className="body-item__text"
          />
        </div>
      </div>
    </div>
  );
};

ApplicationInfoPanelBody.propTypes = {
  orderDetails: PropTypes.object.isRequired
};
