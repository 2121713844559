import React, { useRef, useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { generatePath, useParams, useNavigate } from "react-router-dom";
import { getOrderDetails } from "@/store/order/order.reducer";
import { useForm } from "react-hook-form";
import yup from "@/utils/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { isInProcess } from "@/store/pending/pending.reducer";
import {
  OrderActions,
  uploadFileSuccessful,
  postAssesmentInfo,
  postRemoveAssesmentFileCasa
} from "@/store/order/order.actions";
import InputField from "@/components/InputField";
import CustomSelect from "@/components/CustomSelect";
import Button, { ButtonLink } from "@/components/Button";
import ResumableFileUploader from "@/components/ResumableFileUploader";
import { MAX_SCREENSHOT_FILE_SIZE_IN_MEGABYTES, routes } from "@/constants";
import AssessmentDueDateScreenshot from "@/assets/images/assessment-due-date-screenshot.jpg";
import { editableModeByStatus } from "@/utils/editableModeByStatus";

const schema = yup.object({
  tier: yup.string().nullable().max(255).required(),
  due_date: yup.string().nullable().max(255),
  app_filename: yup
    .mixed()
    .nullable()
    .test(
      "size",
      `Size of file can't be larger than ${MAX_SCREENSHOT_FILE_SIZE_IN_MEGABYTES} Mb`,
      (value) => {
        if (typeof value === "string") {
          return true;
        }
        // eslint-disable-next-line no-unsafe-optional-chaining
        const sizeMB = value?.size / 1024 / 1024;
        return sizeMB <= MAX_SCREENSHOT_FILE_SIZE_IN_MEGABYTES;
      }
    )
  // .test("type", "Only PNG, JPG or JPEG files are allowed", (value) => {
  //   if (typeof value === "string") {
  //     return !!value;
  //   }

  //   return (
  //     value?.type === "image/*" ||
  //     value?.name.endsWith(".jpg") ||
  //     value?.name.endsWith(".jpeg") ||
  //     value?.name.endsWith(".png")
  //   );
  // })
});

const AssessmentInfo = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const orderDetailsNew = useSelector(getOrderDetails);
  const preloadedValues = id ? orderDetailsNew : {};
  const {
    register,
    control,
    setValue,
    resetField,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      tier: preloadedValues.tier ?? "",
      due_date: preloadedValues.due_date ?? "",
      app_filename: preloadedValues.app_filename ?? ""
    }
  });
  const isLoading = useSelector((state) =>
    isInProcess(state, OrderActions.POST_ASSESMENT_INFO)
  );

  useEffect(() => {
    if (
      orderDetailsNew &&
      orderDetailsNew.status &&
      !editableModeByStatus(orderDetailsNew.status.code)
    ) {
      navigate(routes.ROOT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // screenshot upload
  const fileUploader = useRef();
  const [isFileLoading, setIsFileLoading] = useState(false);
  const dispatch = useDispatch();

  const removeFile = async () => {
    await dispatch(postRemoveAssesmentFileCasa(id));
  };

  const uploadFile = () => {
    fileUploader.current?.startUploading();
  };

  const fileUploadURL = useMemo(() => {
    return `${process.env.REACT_APP_API_URL}/api/v2/order-casa/${id}/assessment-info/file`;
  }, [id]);

  const onFileUploadSuccess = (_file, orderDetails) => {
    setIsFileLoading(false);

    if (orderDetails) {
      dispatch(uploadFileSuccessful(orderDetails));
    }

    const path = generatePath(routes.ORDER_CASA_SOURCE_CODE_INFO, { id });
    navigate(path);
  };

  const onSubmit = async (data) => {
    const payload = Object.keys(data).reduce((acc, key) => {
      if (key !== "app_filename") {
        acc[key] = data[key];
      }
      return acc;
    }, {});

    if (fileUploader.current?.resumable.files.length) {
      await dispatch(
        postAssesmentInfo({
          id,
          data: payload
        })
      );

      uploadFile();

      return;
    }

    await dispatch(
      postAssesmentInfo({
        id,
        data: payload
      })
    );

    const path = generatePath(routes.ORDER_CASA_SOURCE_CODE_INFO, { id });
    navigate(path);
  };

  return (
    <form className="assessment-info" onSubmit={handleSubmit(onSubmit)}>
      <div className="fields-group">
        <CustomSelect
          name="tier"
          control={control}
          isSearchable={false}
          label="Tier <sup>*</sup>"
          placeholder="Tier"
          errors={errors}
          options={[
            {
              value: "Tier 2",
              label: "CASA Tier 2 (Lab Tested - Lab Verified)"
            },
            {
              value: "Tier 3",
              label: "CASA Tier 3 (Lab Tested - Lab Verified)"
            }
          ]}
        />
        <div className="fields-group-subtitle">Assessment due date</div>
        <InputField
          name="due_date"
          preloadValue={preloadedValues.due_date}
          control={control}
          datePicker
          datePickerFormat="MMMM d, yyyy"
          label="What is the due date of your assessment, as outlined in email from Google?"
          errors={errors}
          placeholder="Due date"
          {...register("due_date")}
        />
        <div className="fields-group-subtitle">
          Assessment due date screenshot
        </div>
        <InputField
          withoutInput
          name="screenshot_label"
          tooltipTitle="Where to find it?"
          tooltipContent={
            <div className="where-to-find-tooltip">
              <div className="where-to-find-tooltip-image">
                <img
                  className="where-to-find-tooltip-image-i"
                  src={AssessmentDueDateScreenshot}
                  alt=""
                  width={424}
                  height={410}
                />
              </div>
            </div>
          }
          label="Please provide a screenshot of the email you received from Google regarding the assessment where the due date is clearly visible"
        />
        <ResumableFileUploader
          name="app_filename"
          fileAcceptLabel="Use PNG, JPG or JPEG"
          fileAccept="image/png, image/jpg, image/jpeg"
          setValue={setValue}
          resetField={resetField}
          target={fileUploadURL}
          ref={fileUploader}
          errors={errors}
          defaultFilename={preloadedValues.app_filename}
          onFileProgress={() => setIsFileLoading(true)}
          onFileUploadPause={() => setIsFileLoading(false)}
          onAfterFileSuccess={onFileUploadSuccess}
          onAfterUploadCanceled={() => setIsFileLoading(false)}
          onAfterFileError={() => {
            setIsFileLoading(false);
          }}
          control={control}
          onRemoveFile={removeFile}
        />
      </div>
      <div className="main-layout__drawer-footer">
        <div className="main-layout__drawer-buttons">
          <div className="main-layout__drawer-buttons-item">
            <ButtonLink
              text="Back"
              className="btn-light btn-fullwidth"
              to={generatePath(routes.ORDER_CASA_APPLICATION_INFO, { id })}
            />
          </div>
          <div className="main-layout__drawer-buttons-item">
            <Button
              isLoading={isLoading || isFileLoading}
              type="submit"
              className="btn-default btn-fullwidth"
              text="Continue"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default AssessmentInfo;
