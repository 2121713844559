import { ApiClient } from "@/services/api";

class AccountService extends ApiClient {
  fetchUserDetails() {
    return this.apiClient.get("/api/v2/user/details");
  }

  changePassword(params) {
    return this.apiClient.post("/api/v1/user/change-password", params);
  }

  postPreEnableMFA() {
    return this.apiClient.post("/api/v2/user/mfa/pre-enable");
  }

  postEnableMFA(params) {
    return this.apiClient.post("/api/v2/user/mfa/enable", params);
  }

  postDisableMFA(params) {
    return this.apiClient.post("api/v2/user/mfa/disable", params);
  }
}

export default new AccountService();
