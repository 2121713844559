import React from "react";
import AnimationImage from "@/components/AnimationImage";
import { routes, routeTitles } from "@/constants";
import { ButtonLink } from "@/components/Button";
import "./ChangePasswordSuccess.scss";

const ChangePasswordSuccess = () => {
  return (
    <div className="change-password-success">
      <div className="change-password-success__image">
        <AnimationImage title={routeTitles[routes.CHANGE_PASSWORD]} />
      </div>
      <div className="change-password-success__title">Password changed</div>
      <div className="change-password-success__text">
        Your password changed successfully.
      </div>
      <div className="main-layout__drawer-footer">
        <div className="main-layout__drawer-buttons">
          <div className="main-layout__drawer-buttons-item">
            <ButtonLink
              text="Back to Settings"
              className="btn-default btn-fullwidth"
              to={routes.SETTINGS}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordSuccess;
