import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage as RHFErrorMessage } from "@hookform/error-message";

const ErrorMessage = ({ errors, name }) => {
  return (
    <RHFErrorMessage
      name={name}
      errors={errors}
      render={({ messages, message }) => {
        if (messages) {
          return Object.entries(messages).map((error) => (
            <div key={error.type} className="error-message">
              <sup>*</sup>
              {error.message}
            </div>
          ));
        }

        if (message) {
          return (
            <div className="error-message">
              <sup>*</sup>
              {message}
            </div>
          );
        }

        return null;
      }}
    />
  );
};

export const BaseErrorMessage = ({ text }) => {
  if (!text) {
    return null;
  }

  return (
    <div key={text} className="error-message">
      <sup>*</sup>
      {text}
    </div>
  );
};

BaseErrorMessage.propTypes = {
  text: PropTypes.string
};

ErrorMessage.propTypes = {
  errors: PropTypes.object,
  name: PropTypes.string.isRequired
};

export default ErrorMessage;
