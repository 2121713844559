import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import { logout } from "@/store/auth/auth.actions";
import { clearOrdersReducer } from "@/store/order/order.actions";
import { getAccountEmail } from "@/store/account/account.reducer";
import { routes } from "@/constants";
import { Mobile, MobileTablet, Desktop } from "@/components/Responsive";
import {
  LogoutIcon,
  InfoIcon,
  DashboardIcon,
  SettingsIcon,
  NotificationIcon
} from "@/icons";
import "./PageSidebar.scss";

const PageSidebar = ({ handleClick, handleClickNotifications }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accountEmail = useSelector(getAccountEmail);
  const [comingSoon] = useState(false);

  const handleLogout = async () => {
    dispatch(logout());
    await Auth.signOut();
    navigate(routes.LOGIN);
    dispatch(clearOrdersReducer());
  };

  const handleNoticeClick = () => {
    handleClickNotifications();
    handleClick();
  };

  return (
    <div className="page-sidebar">
      <MobileTablet>
        <div className="page-sidebar__head">
          {accountEmail && (
            <div className="page-sidebar__auth">
              <div className="page-sidebar__auth-icon">{accountEmail[0]}</div>
              <div className="page-sidebar__auth-label">{accountEmail}</div>
            </div>
          )}
          {comingSoon && (
            <button
              className="page-sidebar__notice"
              type="button"
              onClick={handleNoticeClick}>
              <div className="page-sidebar__notice-icon active">
                <NotificationIcon className="page-sidebar__notice-icon-i" />
              </div>
            </button>
          )}
        </div>
      </MobileTablet>
      <div className="page-sidebar__body">
        {accountEmail && (
          <Desktop>
            <div className="page-sidebar__auth">
              <div className="page-sidebar__auth-icon">{accountEmail[0]}</div>
              <div className="page-sidebar__auth-label">{accountEmail}</div>
            </div>
          </Desktop>
        )}
        <ul className="page-sidebar__menu">
          <li className="page-sidebar__menu-item">
            <NavLink
              className="page-sidebar__menu-link"
              to={routes.ROOT}
              onClick={handleClick}>
              <DashboardIcon className="page-sidebar__menu-icon" />
              Dashboard
            </NavLink>
          </li>
          <li className="page-sidebar__menu-item">
            <NavLink
              className="page-sidebar__menu-link"
              to={routes.SETTINGS}
              onClick={handleClick}>
              <SettingsIcon className="page-sidebar__menu-icon" />
              Settings
            </NavLink>
          </li>
          <li className="page-sidebar__menu-item">
            <NavLink
              className="page-sidebar__menu-link"
              to={routes.FAQ}
              onClick={handleClick}>
              <InfoIcon className="page-sidebar__menu-icon" />
              Help
            </NavLink>
          </li>
        </ul>
        <Mobile>
          <div className="page-sidebar__wrap">
            <Link
              to={routes.START_ORDER}
              className="page-sidebar__start-button btn btn-default btn-fullwidth"
              onClick={handleClick}>
              New assesment
            </Link>
          </div>
        </Mobile>
      </div>
      <div className="page-sidebar__footer">
        <button
          onClick={handleLogout}
          className="page-sidebar__logout"
          type="button">
          <LogoutIcon className="page-sidebar__logout-icon" />
          <span className="page-sidebar__logout-label">Log Out</span>
        </button>
      </div>
    </div>
  );
};

PageSidebar.propTypes = {
  handleClick: PropTypes.func,
  handleClickNotifications: PropTypes.func
};

export default PageSidebar;
