import React, { useEffect } from "react";
import { generatePath, useParams } from "react-router-dom";
import "./OrderVerification.scss";
import { useDispatch, useSelector } from "react-redux";
import { getIsAutomaticVerification } from "@/store/order/order.reducer";
import { getOrderDetailsById } from "@/store/order/order.actions";
import AutomaticVerification from "@/components/OrderSteps/OrderVerification/AutomaticVerification";
import ManualVerification from "@/components/OrderSteps/OrderVerification/ManualVerification";
import OrderTabs from "@/components/OrderTabs";
import { routes } from "@/constants";

const OrderVerification = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const isAutomatic = useSelector(getIsAutomaticVerification);

  useEffect(() => {
    if (id) {
      dispatch(getOrderDetailsById(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <OrderTabs>
      {isAutomatic && (
        <AutomaticVerification
          skipLink={generatePath(routes.AGREEMENT, { id })}
        />
      )}
      <ManualVerification
        backLink={generatePath(routes.ORDER_APPLICATION_INFO, { id })}
      />
    </OrderTabs>
  );
};

export default OrderVerification;
