import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import yup from "@/utils/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { OrderActions, updateQuestionnaire } from "@/store/order/order.actions";
import { getOrderDetails } from "@/store/order/order.reducer";
import { isInProcess } from "@/store/pending/pending.reducer";
import { routes, routeActions } from "@/constants";
import RadioGroup from "@/components/RadioGroup";
import Button from "@/components/Button";
import PageNotice from "@/components/PageNotice";
import { OrderStatuses } from "@/constants/orderStatuses";
import cn from "classnames";

const schema = yup.object({
  mobile_frameworks_used: yup.number().nullable().required(),
  credentials_stored_keystore: yup.number().nullable().required(),
  keys_stored_keystore: yup.number().nullable().required(),
  pii_encrypted: yup.number().nullable().required(),
  sensitive_data_securely_stored: yup.number().nullable().required(),
  keyboard_caching_disabled: yup
    .number()
    .nullable()
    .when("mobile_frameworks_used", {
      is: 1,
      then: yup.number().nullable().required(),
      otherwise: yup.number().nullable()
    }),
  no_sensitive_data_display: yup
    .number()
    .nullable()
    .when("mobile_frameworks_used", {
      is: 1,
      then: yup.number().nullable().required(),
      otherwise: yup.number().nullable()
    }),
  no_sensitive_data_logs: yup
    .number()
    .nullable()
    .when("mobile_frameworks_used", {
      is: 1,
      then: yup.number().nullable().required(),
      otherwise: yup.number().nullable()
    }),
  no_sensitive_data_debug: yup
    .number()
    .nullable()
    .when("mobile_frameworks_used", {
      is: 1,
      then: yup.number().nullable().required(),
      otherwise: yup.number().nullable()
    }),
  no_sensitive_data_errors: yup
    .number()
    .nullable()
    .when("mobile_frameworks_used", {
      is: 1,
      then: yup.number().nullable().required(),
      otherwise: yup.number().nullable()
    }),
  user_visible_data_hidden: yup
    .number()
    .nullable()
    .when("mobile_frameworks_used", {
      is: 1,
      then: yup.number().nullable().required(),
      otherwise: yup.number().nullable()
    }),
  users_pii_educated: yup.number().nullable().required(),
  privacy_policy_accessible: yup.number().nullable().required(),
  remote_auth: yup.number().nullable().required(),
  unique_session_ids: yup.number().nullable().required(),
  secure_algo_token_protection: yup.number().nullable().required(),
  remote_session_terminated_on_logout: yup.number().nullable().required(),
  password_policy_enforced: yup.number().nullable().required(),
  used_passwords_forbidden: yup.number().nullable().required(),
  excessive_number_logins_blocked: yup.number().nullable().required(),
  inactive_sessions_invalidated: yup.number().nullable().required(),
  tokens_expire: yup.number().nullable().required(),
  user_inputs_validated: yup
    .number()
    .nullable()
    .when("mobile_frameworks_used", {
      is: 1,
      then: yup.number().nullable().required(),
      otherwise: yup.number().nullable()
    }),
  ipc_data_validated: yup.number().nullable().when("mobile_frameworks_used", {
    is: 1,
    then: yup.number().nullable().required(),
    otherwise: yup.number().nullable()
  }),
  network_data_validated: yup
    .number()
    .nullable()
    .when("mobile_frameworks_used", {
      is: 1,
      then: yup.number().nullable().required(),
      otherwise: yup.number().nullable()
    }),
  sensitive_data_ipc_exposed: yup.number().nullable().required(),
  sensitive_data_ipc_secured: yup.number().nullable().required()
});

const Questionnaire = () => {
  const dispatch = useDispatch();
  const { id, action } = useParams();
  const isLoading = useSelector((state) =>
    isInProcess(state, OrderActions.UPDATE_QUESTIONNAIRE)
  );
  const navigate = useNavigate();
  const orderDetails = useSelector(getOrderDetails);
  const preloadedValues = orderDetails;
  const formRef = useRef(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      mobile_frameworks_used: preloadedValues.mobile_frameworks_used ?? null,
      credentials_stored_keystore:
        preloadedValues.credentials_stored_keystore ?? null,
      keys_stored_keystore: preloadedValues.keys_stored_keystore ?? null,
      pii_encrypted: preloadedValues.pii_encrypted ?? null,
      sensitive_data_securely_stored:
        preloadedValues.sensitive_data_securely_stored ?? null,
      keyboard_caching_disabled:
        preloadedValues.keyboard_caching_disabled ?? null,
      no_sensitive_data_display:
        preloadedValues.no_sensitive_data_display ?? null,
      no_sensitive_data_logs: preloadedValues.no_sensitive_data_logs ?? null,
      no_sensitive_data_debug: preloadedValues.no_sensitive_data_debug ?? null,
      no_sensitive_data_errors:
        preloadedValues.no_sensitive_data_errors ?? null,
      user_visible_data_hidden:
        preloadedValues.user_visible_data_hidden ?? null,
      users_pii_educated: preloadedValues.users_pii_educated ?? null,
      privacy_policy_accessible:
        preloadedValues.privacy_policy_accessible ?? null,
      remote_auth: preloadedValues.remote_auth ?? null,
      unique_session_ids: preloadedValues.unique_session_ids ?? null,
      secure_algo_token_protection:
        preloadedValues.secure_algo_token_protection ?? null,
      remote_session_terminated_on_logout:
        preloadedValues.remote_session_terminated_on_logout ?? null,
      password_policy_enforced:
        preloadedValues.password_policy_enforced ?? null,
      used_passwords_forbidden:
        preloadedValues.used_passwords_forbidden ?? null,
      excessive_number_logins_blocked:
        preloadedValues.excessive_number_logins_blocked ?? null,
      inactive_sessions_invalidated:
        preloadedValues.inactive_sessions_invalidated ?? null,
      tokens_expire: preloadedValues.tokens_expire ?? null,
      user_inputs_validated: preloadedValues.user_inputs_validated ?? null,
      ipc_data_validated: preloadedValues.ipc_data_validated ?? null,
      network_data_validated: preloadedValues.network_data_validated ?? null,
      sensitive_data_ipc_exposed:
        preloadedValues.sensitive_data_ipc_exposed ?? null,
      sensitive_data_ipc_secured:
        preloadedValues.sensitive_data_ipc_secured ?? null
    }
  });

  const disableFlagRef = useRef(false);
  // New useEffect to update disableFlagRef right after useForm is initialized
  useEffect(() => {
    disableFlagRef.current = true;
  }, []); // Empty dependency array ensures this runs once after mount
  useEffect(() => {
    if (action === routeActions.view) {
      if (formRef.current) {
        formRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [action]);

  useEffect(() => {
    // Fill
    if (action === routeActions.fill) {
      // If not new - redirect to View
      if (
        orderDetails.status.code !==
        OrderStatuses.QUESTIONNAIRE_SUBMISSION_PENDING
      ) {
        const path = generatePath(routes.ORDER_QUESTIONNAIRE_BY_ID, {
          id,
          action: routeActions.view
        });
        navigate(path);
      }
      // View
    } else if (action === routeActions.view) {
      // If new - redirect to Fill
      if (
        orderDetails.status.code ===
        OrderStatuses.QUESTIONNAIRE_SUBMISSION_PENDING
      ) {
        const path = generatePath(routes.ORDER_QUESTIONNAIRE_BY_ID, {
          id,
          action: routeActions.fill
        });
        navigate(path);
      }
      // Verify
    } else if (action === routeActions.verify) {
      // if new - redirect to Fill
      if (
        orderDetails.status.code ===
        OrderStatuses.QUESTIONNAIRE_SUBMISSION_PENDING
      ) {
        const path = generatePath(routes.ORDER_QUESTIONNAIRE_BY_ID, {
          id,
          action: routeActions.fill
        });
        navigate(path);
      }

      // if no errors - redirect to View
      if (
        orderDetails &&
        orderDetails.errors &&
        orderDetails.errors.length === 0
      ) {
        const path = generatePath(routes.ORDER_QUESTIONNAIRE_BY_ID, {
          id,
          action: routeActions.view
        });
        navigate(path);
      }
    }
  }, [orderDetails, action, id, navigate]);

  const onSubmit = async (data) => {
    const res = await dispatch(updateQuestionnaire({ id, ...data }));

    const path = generatePath(routes.ORDER_QUESTIONNAIRE_BY_ID, {
      id: res.id,
      action: routeActions.view
    });
    navigate(path);
  };

  const isError = (name, isSection) => {
    const arr = isSection ? orderDetails?.errorGroups : orderDetails?.errors;
    return arr ? arr.includes(name) : false;
  };

  const getMod = (name, isSection = false) => {
    return action !== routeActions.fill && isError(name, isSection)
      ? "error"
      : null;
  };

  const getSectionClass = (name) => {
    const err = isError(name, true);

    return cn("fields-group-title", {
      error: action !== routeActions.fill && err
    });
  };

  const getDisabled = (name) => {
    const err = isError(name);
    return (
      disableFlagRef.current &&
      action !== routeActions.fill &&
      (!err || action === routeActions.view)
    );
  };

  let notificationMod = "error";
  let notificationTitle = "Remediation Required";
  let notificationText =
    "Some security requirements are not currently met. Please address the identified issues and resubmit the questionnaire once the corrections are made.";

  if (orderDetails && orderDetails.errors && orderDetails.errors.length === 0) {
    notificationMod = "success";
    notificationTitle = "Self-Declaration Successful";
    notificationText =
      "Your app has successfully met all self-declared security requirements. Our team will now proceed with validating the remaining criteria to complete the assessment.";
  }

  const credentialsStoredKeystoreValue = watch("credentials_stored_keystore");
  const keysStoredKeystoreValue = watch("keys_stored_keystore");
  const piiEncryptedValue = watch("pii_encrypted");
  const sensitiveDataSecurelyStoredValue = watch(
    "sensitive_data_securely_stored"
  );
  const keyboardCachingDisabledValue = watch("keyboard_caching_disabled");
  const noSensitiveDataDisplayValue = watch("no_sensitive_data_display");
  const noSensitiveDataLogsValue = watch("no_sensitive_data_logs");
  const noSensitiveDataDebugValue = watch("no_sensitive_data_debug");
  const noSensitiveDataErrorsValue = watch("no_sensitive_data_errors");
  const userVisibleDataHiddenValue = watch("user_visible_data_hidden");
  const usersPiiEducatedValue = watch("users_pii_educated");
  const privacyPolicyAccessibleValue = watch("privacy_policy_accessible");
  const remoteAuthValue = watch("remote_auth");
  const uniqueSessionIdsValue = watch("unique_session_ids");
  const secureAlgoTokenProtectionValue = watch("secure_algo_token_protection");
  const remoteSessionTerminatedOnLogoutValue = watch(
    "remote_session_terminated_on_logout"
  );
  const passwordPolicyEnforcedValue = watch("password_policy_enforced");
  const usedPasswordsForbiddenValue = watch("used_passwords_forbidden");
  const excessiveNumberLoginsBlockedValue = watch(
    "excessive_number_logins_blocked"
  );
  const inactiveSessionsInvalidatedValue = watch(
    "inactive_sessions_invalidated"
  );
  const tokensExpireValue = watch("tokens_expire");
  const userInputsValidatedValue = watch("user_inputs_validated");
  const ipcDataValidatedValue = watch("ipc_data_validated");
  const networkDataValidatedValue = watch("network_data_validated");
  const sensitiveDataIpcExposedValue = watch("sensitive_data_ipc_exposed");
  const sensitiveDataIpcSecuredValue = watch("sensitive_data_ipc_secured");

  const isSubmitDisabled = () => {
    return (
      !credentialsStoredKeystoreValue ||
      !keysStoredKeystoreValue ||
      !piiEncryptedValue ||
      !sensitiveDataSecurelyStoredValue ||
      (orderDetails.mobile_frameworks_used && !keyboardCachingDisabledValue) ||
      (orderDetails.mobile_frameworks_used && !noSensitiveDataDisplayValue) ||
      (orderDetails.mobile_frameworks_used && !noSensitiveDataLogsValue) ||
      (orderDetails.mobile_frameworks_used && !noSensitiveDataDebugValue) ||
      (orderDetails.mobile_frameworks_used && !noSensitiveDataErrorsValue) ||
      (orderDetails.mobile_frameworks_used && !userVisibleDataHiddenValue) ||
      !usersPiiEducatedValue ||
      !privacyPolicyAccessibleValue ||
      !remoteAuthValue ||
      !uniqueSessionIdsValue ||
      !secureAlgoTokenProtectionValue ||
      !remoteSessionTerminatedOnLogoutValue ||
      !passwordPolicyEnforcedValue ||
      !usedPasswordsForbiddenValue ||
      !excessiveNumberLoginsBlockedValue ||
      !inactiveSessionsInvalidatedValue ||
      !tokensExpireValue ||
      (orderDetails.mobile_frameworks_used && !userInputsValidatedValue) ||
      (orderDetails.mobile_frameworks_used && !ipcDataValidatedValue) ||
      (orderDetails.mobile_frameworks_used && !networkDataValidatedValue) ||
      !sensitiveDataIpcExposedValue ||
      !sensitiveDataIpcSecuredValue
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="order-creation"
      ref={formRef}>
      {action !== routeActions.fill && (
        <PageNotice
          mod={notificationMod}
          title={notificationTitle}
          text={notificationText}
        />
      )}
      <div className="fields-group">
        <div className={getSectionClass("MSTG-STORAGE-1")}>MSTG-STORAGE-1</div>
        <RadioGroup
          label="Does your app (or library/SDK) use Android Keystore API to store user credentials?"
          isRequired
          preloadedValue={
            // eslint-disable-next-line no-nested-ternary
            orderDetails.credentials_stored_keystore
          }
          values={[
            {
              text: "Yes",
              value: 1
            },
            {
              text: "No",
              value: 0
            }
          ]}
          name="credentials_stored_keystore"
          errors={errors}
          mod={getMod("credentials_stored_keystore")}
          disabled={getDisabled("credentials_stored_keystore")}
          {...register("credentials_stored_keystore")}
        />
        <RadioGroup
          label="Does your app (or library/SDK) use Android Keystore API to store cryptographic keys?"
          isRequired
          preloadedValue={
            // eslint-disable-next-line no-nested-ternary
            orderDetails.keys_stored_keystore
          }
          values={[
            {
              text: "Yes",
              value: 1
            },
            {
              text: "No",
              value: 0
            }
          ]}
          name="keys_stored_keystore"
          errors={errors}
          mod={getMod("keys_stored_keystore")}
          disabled={getDisabled("keys_stored_keystore")}
          {...register("keys_stored_keystore")}
        />
        <RadioGroup
          label="Does your app (or library/SDK) use cryptographic keys to encrypt all data that may be considered sensitive, such as PII?"
          isRequired
          preloadedValue={
            // eslint-disable-next-line no-nested-ternary
            orderDetails.pii_encrypted
          }
          values={[
            {
              text: "Yes",
              value: 1
            },
            {
              text: "No",
              value: 0
            }
          ]}
          name="pii_encrypted"
          errors={errors}
          mod={getMod("pii_encrypted")}
          disabled={getDisabled("pii_encrypted")}
          {...register("pii_encrypted")}
        />
      </div>
      <div className="fields-group">
        <div className={getSectionClass("MSTG-STORAGE-2")}>MSTG-STORAGE-2</div>
        <RadioGroup
          label="Does your application or library/SDK exclusively store sensitive data within the app container or use system credential storage facilities?"
          isRequired
          preloadedValue={
            // eslint-disable-next-line no-nested-ternary
            orderDetails.sensitive_data_securely_stored
          }
          values={[
            {
              text: "Yes",
              value: 1
            },
            {
              text: "No",
              value: 0
            }
          ]}
          name="sensitive_data_securely_stored"
          errors={errors}
          mod={getMod("sensitive_data_securely_stored")}
          disabled={getDisabled("sensitive_data_securely_stored")}
          {...register("sensitive_data_securely_stored")}
        />
      </div>
      {orderDetails.mobile_frameworks_used === 1 && (
        <div className="fields-group">
          <div className={getSectionClass("MSTG-STORAGE-5")}>
            MSTG-STORAGE-5
          </div>
          <RadioGroup
            label="Does your app (or library/SDK) has keyboard caching disabled on text input fields where users enter sensitive data (e.g., passwords, credit card numbers, PINs)?"
            isRequired
            preloadedValue={
              // eslint-disable-next-line no-nested-ternary
              orderDetails.keyboard_caching_disabled
            }
            values={[
              {
                text: "Yes",
                value: 1
              },
              {
                text: "No",
                value: 0
              }
            ]}
            name="keyboard_caching_disabled"
            errors={errors}
            mod={getMod("keyboard_caching_disabled")}
            disabled={getDisabled("keyboard_caching_disabled")}
            {...register("keyboard_caching_disabled")}
          />
        </div>
      )}
      {orderDetails.mobile_frameworks_used === 1 && (
        <div className="fields-group">
          <div className={getSectionClass("MSTG-STORAGE-7")}>
            MSTG-STORAGE-7
          </div>
          <RadioGroup
            label="Does your app (or library/SDK) avoids displaying sensitive data (e.g., passwords, PINs) in plain text within input fields?"
            isRequired
            preloadedValue={
              // eslint-disable-next-line no-nested-ternary
              orderDetails.no_sensitive_data_display
            }
            values={[
              {
                text: "Yes",
                value: 1
              },
              {
                text: "No",
                value: 0
              }
            ]}
            name="no_sensitive_data_display"
            errors={errors}
            mod={getMod("no_sensitive_data_display")}
            disabled={getDisabled("no_sensitive_data_display")}
            {...register("no_sensitive_data_display")}
          />
          <RadioGroup
            label="Does your app (or library/SDK)'s logging mechanism avoid capturing and storing sensitive information (e.g., passwords, PINs) in logs?"
            isRequired
            preloadedValue={
              // eslint-disable-next-line no-nested-ternary
              orderDetails.no_sensitive_data_logs
            }
            values={[
              {
                text: "Yes",
                value: 1
              },
              {
                text: "No",
                value: 0
              }
            ]}
            name="no_sensitive_data_logs"
            errors={errors}
            mod={getMod("no_sensitive_data_logs")}
            disabled={getDisabled("no_sensitive_data_logs")}
            {...register("no_sensitive_data_logs")}
          />
          <RadioGroup
            label="Does your app (or library/SDK)'s debug messages avoid containing sensitive information (e.g., passwords, PINs)?"
            isRequired
            preloadedValue={
              // eslint-disable-next-line no-nested-ternary
              orderDetails.no_sensitive_data_debug
            }
            values={[
              {
                text: "Yes",
                value: 1
              },
              {
                text: "No",
                value: 0
              }
            ]}
            name="no_sensitive_data_debug"
            errors={errors}
            mod={getMod("no_sensitive_data_debug")}
            disabled={getDisabled("no_sensitive_data_debug")}
            {...register("no_sensitive_data_debug")}
          />
          <RadioGroup
            label="Does your app (or library/SDK)'s error messages avoid revealing sensitive information (e.g., passwords, PINs)?"
            isRequired
            preloadedValue={
              // eslint-disable-next-line no-nested-ternary
              orderDetails.no_sensitive_data_errors
            }
            values={[
              {
                text: "Yes",
                value: 1
              },
              {
                text: "No",
                value: 0
              }
            ]}
            name="no_sensitive_data_errors"
            errors={errors}
            mod={getMod("no_sensitive_data_errors")}
            disabled={getDisabled("no_sensitive_data_errors")}
            {...register("no_sensitive_data_errors")}
          />
          <RadioGroup
            label="Does your app (or library/SDK) hides all user-visible data to ensure that no sensitive information (e.g., passwords, PINs) is inadvertently displayed?"
            isRequired
            preloadedValue={
              // eslint-disable-next-line no-nested-ternary
              orderDetails.user_visible_data_hidden
            }
            values={[
              {
                text: "Yes",
                value: 1
              },
              {
                text: "No",
                value: 0
              }
            ]}
            name="user_visible_data_hidden"
            errors={errors}
            mod={getMod("user_visible_data_hidden")}
            disabled={getDisabled("user_visible_data_hidden")}
            {...register("user_visible_data_hidden")}
          />
        </div>
      )}
      <div className="fields-group">
        <div className={getSectionClass("MSTG-STORAGE-12")}>
          MSTG-STORAGE-12
        </div>
        <RadioGroup
          label="Do you educate users about the types of personally identifiable information (PII) it processes, as well as security best practices they should follow when using the app?"
          isRequired
          preloadedValue={
            // eslint-disable-next-line no-nested-ternary
            orderDetails.users_pii_educated
          }
          values={[
            {
              text: "Yes",
              value: 1
            },
            {
              text: "No",
              value: 0
            }
          ]}
          name="users_pii_educated"
          errors={errors}
          mod={getMod("users_pii_educated")}
          disabled={getDisabled("users_pii_educated")}
          {...register("users_pii_educated")}
        />
        <RadioGroup
          label="Does your app (or library/SDK) provide a clear and easily accessible link to your privacy policy within the app?"
          isRequired
          preloadedValue={
            // eslint-disable-next-line no-nested-ternary
            orderDetails.privacy_policy_accessible
          }
          values={[
            {
              text: "Yes",
              value: 1
            },
            {
              text: "No",
              value: 0
            }
          ]}
          name="privacy_policy_accessible"
          errors={errors}
          mod={getMod("privacy_policy_accessible")}
          disabled={getDisabled("privacy_policy_accessible")}
          {...register("privacy_policy_accessible")}
        />
      </div>
      <div className="fields-group">
        <div className={getSectionClass("MSTG-AUTH-1")}>MSTG-AUTH-1</div>
        <RadioGroup
          label="Does your app (or library/SDK) implement authentication at the remote endpoint?"
          isRequired
          preloadedValue={
            // eslint-disable-next-line no-nested-ternary
            orderDetails.remote_auth
          }
          values={[
            {
              text: "Yes",
              value: 1
            },
            {
              text: "No",
              value: 0
            },
            {
              text: "N/A",
              value: 2
            }
          ]}
          name="remote_auth"
          errors={errors}
          mod={getMod("remote_auth")}
          disabled={getDisabled("remote_auth")}
          {...register("remote_auth")}
        />
      </div>
      <div className="fields-group">
        <div className={getSectionClass("MSTG-AUTH-2")}>MSTG-AUTH-2</div>
        <RadioGroup
          label="Does your app’s (or library/SDK) remote endpoint use unique and unpredictable session identifiers to authenticate client requests without transmitting the user's credentials?"
          isRequired
          preloadedValue={
            // eslint-disable-next-line no-nested-ternary
            orderDetails.unique_session_ids
          }
          values={[
            {
              text: "Yes",
              value: 1
            },
            {
              text: "No",
              value: 0
            },
            {
              text: "N/A",
              value: 2
            }
          ]}
          name="unique_session_ids"
          errors={errors}
          mod={getMod("unique_session_ids")}
          disabled={getDisabled("unique_session_ids")}
          {...register("unique_session_ids")}
        />
      </div>
      <div className="fields-group">
        <div className={getSectionClass("MSTG-AUTH-3")}>MSTG-AUTH-3</div>
        <RadioGroup
          label="If stateless token-based authentication is utilized by your application, does your app server (or library/SDK) provide a token protected using a secure algorithm such as HMAC-SHA256?"
          isRequired
          preloadedValue={
            // eslint-disable-next-line no-nested-ternary
            orderDetails.secure_algo_token_protection
          }
          values={[
            {
              text: "Yes",
              value: 1
            },
            {
              text: "No",
              value: 0
            },
            {
              text: "N/A*",
              value: 2
            }
          ]}
          name="secure_algo_token_protection"
          description="* app does not have authentication or app does not use stateless token-based authentication"
          errors={errors}
          mod={getMod("secure_algo_token_protection")}
          disabled={getDisabled("secure_algo_token_protection")}
          {...register("secure_algo_token_protection")}
        />
      </div>
      <div className="fields-group">
        <div className={getSectionClass("MSTG-AUTH-4")}>MSTG-AUTH-4</div>
        <RadioGroup
          label="Does your app (or library/SDK) terminate the existing session at the remote endpoint when the user logs out?"
          isRequired
          preloadedValue={
            // eslint-disable-next-line no-nested-ternary
            orderDetails.remote_session_terminated_on_logout
          }
          values={[
            {
              text: "Yes",
              value: 1
            },
            {
              text: "No",
              value: 0
            },
            {
              text: "N/A*",
              value: 2
            }
          ]}
          name="remote_session_terminated_on_logout"
          description="* app does not have authentication"
          errors={errors}
          mod={getMod("remote_session_terminated_on_logout")}
          disabled={getDisabled("remote_session_terminated_on_logout")}
          {...register("remote_session_terminated_on_logout")}
        />
      </div>
      <div className="fields-group">
        <div className={getSectionClass("MSTG-AUTH-5")}>MSTG-AUTH-5</div>
        <RadioGroup
          label="Does your app (or library/SDK) enforce a password policy (e.g., minimum length, complexity) on the server/backend side?"
          isRequired
          preloadedValue={
            // eslint-disable-next-line no-nested-ternary
            orderDetails.password_policy_enforced
          }
          values={[
            {
              text: "Yes",
              value: 1
            },
            {
              text: "No",
              value: 0
            },
            {
              text: "N/A*",
              value: 2
            }
          ]}
          name="password_policy_enforced"
          description="* app does not have authentication or password is never sent to server (e.g. federated auth or zero-knowledge password proof)"
          errors={errors}
          mod={getMod("password_policy_enforced")}
          disabled={getDisabled("password_policy_enforced")}
          {...register("password_policy_enforced")}
        />
        <RadioGroup
          label="Does your app (or library/SDK) prevent users from setting passwords they have already used before at the remote endpoint?"
          isRequired
          preloadedValue={
            // eslint-disable-next-line no-nested-ternary
            orderDetails.used_passwords_forbidden
          }
          values={[
            {
              text: "Yes",
              value: 1
            },
            {
              text: "No",
              value: 0
            },
            {
              text: "N/A*",
              value: 2
            }
          ]}
          name="used_passwords_forbidden"
          description="* app does not have authentication"
          errors={errors}
          mod={getMod("used_passwords_forbidden")}
          disabled={getDisabled("used_passwords_forbidden")}
          {...register("used_passwords_forbidden")}
        />
      </div>
      <div className="fields-group">
        <div className={getSectionClass("MSTG-AUTH-6")}>MSTG-AUTH-6</div>
        <RadioGroup
          label="Does your app (or library/SDK) implement a mechanism at the remote endpoint to protect against the submission of credentials an excessive number of times?"
          isRequired
          preloadedValue={
            // eslint-disable-next-line no-nested-ternary
            orderDetails.excessive_number_logins_blocked
          }
          values={[
            {
              text: "Yes",
              value: 1
            },
            {
              text: "No",
              value: 0
            },
            {
              text: "N/A*",
              value: 2
            }
          ]}
          name="excessive_number_logins_blocked"
          description="* app does not have authentication or password is never sent to server (e.g. federated auth or zero-knowledge password proof)"
          errors={errors}
          mod={getMod("excessive_number_logins_blocked")}
          disabled={getDisabled("excessive_number_logins_blocked")}
          {...register("excessive_number_logins_blocked")}
        />
      </div>
      <div className="fields-group">
        <div className={getSectionClass("MSTG-AUTH-7")}>MSTG-AUTH-7</div>
        <RadioGroup
          label="Does your app (or library/SDK) implement a mechanism at the remote endpoint (server-side) to automatically invalidate user sessions after a predefined period of inactivity?"
          isRequired
          preloadedValue={
            // eslint-disable-next-line no-nested-ternary
            orderDetails.inactive_sessions_invalidated
          }
          values={[
            {
              text: "Yes",
              value: 1
            },
            {
              text: "No",
              value: 0
            },
            {
              text: "N/A*",
              value: 2
            }
          ]}
          name="inactive_sessions_invalidated"
          description="* app does not have authentication"
          errors={errors}
          mod={getMod("inactive_sessions_invalidated")}
          disabled={getDisabled("inactive_sessions_invalidated")}
          {...register("inactive_sessions_invalidated")}
        />
        <RadioGroup
          label="Do access tokens used for authentication and authorization within your app (or library/SDK) have a set expiration time, after which the server will reject them as invalid?"
          isRequired
          preloadedValue={
            // eslint-disable-next-line no-nested-ternary
            orderDetails.tokens_expire
          }
          values={[
            {
              text: "Yes",
              value: 1
            },
            {
              text: "No",
              value: 0
            },
            {
              text: "N/A*",
              value: 2
            }
          ]}
          name="tokens_expire"
          description="* app does not have authentication"
          errors={errors}
          mod={getMod("tokens_expire")}
          disabled={getDisabled("tokens_expire")}
          {...register("tokens_expire")}
        />
      </div>
      {orderDetails.mobile_frameworks_used === 1 && (
        <div className="fields-group">
          <div className={getSectionClass("MSTG-PLATFORM-2")}>
            MSTG-PLATFORM-2
          </div>
          <RadioGroup
            label="Does your app (or library/SDK) validate and sanitize all user input before using it?"
            isRequired
            preloadedValue={
              // eslint-disable-next-line no-nested-ternary
              orderDetails.user_inputs_validated
            }
            values={[
              {
                text: "Yes",
                value: 1
              },
              {
                text: "No",
                value: 0
              }
            ]}
            name="user_inputs_validated"
            errors={errors}
            mod={getMod("user_inputs_validated")}
            disabled={getDisabled("user_inputs_validated")}
            {...register("user_inputs_validated")}
          />
          <RadioGroup
            label="Does your app (or library/SDK) validate and sanitize all data received through IPC mechanisms like intents and/or custom URL schemes before using it?"
            isRequired
            preloadedValue={
              // eslint-disable-next-line no-nested-ternary
              orderDetails.ipc_data_validated
            }
            values={[
              {
                text: "Yes",
                value: 1
              },
              {
                text: "No",
                value: 0
              }
            ]}
            name="ipc_data_validated"
            errors={errors}
            mod={getMod("ipc_data_validated")}
            disabled={getDisabled("ipc_data_validated")}
            {...register("ipc_data_validated")}
          />
          <RadioGroup
            label="Does your app (or library/SDK) validate and sanitize all data received from network sources (e.g., web services, APIs) before using it?"
            isRequired
            preloadedValue={
              // eslint-disable-next-line no-nested-ternary
              orderDetails.network_data_validated
            }
            values={[
              {
                text: "Yes",
                value: 1
              },
              {
                text: "No",
                value: 0
              }
            ]}
            name="network_data_validated"
            errors={errors}
            mod={getMod("network_data_validated")}
            disabled={getDisabled("network_data_validated")}
            {...register("network_data_validated")}
          />
        </div>
      )}
      <div className="fields-group">
        <div className={getSectionClass("MSTG-PLATFORM-4")}>
          MSTG-PLATFORM-4
        </div>
        <RadioGroup
          label="Does your app (or library/SDK) expose sensitive functionality through inter-process communication (IPC) mechanisms?"
          isRequired
          preloadedValue={
            // eslint-disable-next-line no-nested-ternary
            orderDetails.sensitive_data_ipc_exposed
          }
          values={[
            {
              text: "Yes",
              value: 1
            },
            {
              text: "No",
              value: 0
            }
          ]}
          name="sensitive_data_ipc_exposed"
          errors={errors}
          mod={getMod("sensitive_data_ipc_exposed")}
          disabled={getDisabled("sensitive_data_ipc_exposed")}
          {...register("sensitive_data_ipc_exposed")}
        />
        <RadioGroup
          label="Do you have security measures like access controls, data validation, and encryption in place to protect sensitive functionality exposed through IPC mechanisms in your app or library/SDK?"
          isRequired
          preloadedValue={
            // eslint-disable-next-line no-nested-ternary
            orderDetails.sensitive_data_ipc_secured
          }
          values={[
            {
              text: "Yes",
              value: 1
            },
            {
              text: "No",
              value: 0
            },
            {
              text: "N/A*",
              value: 2
            }
          ]}
          name="sensitive_data_ipc_secured"
          description="* app does not have IPC mechanisms"
          errors={errors}
          mod={getMod("sensitive_data_ipc_secured")}
          disabled={getDisabled("sensitive_data_ipc_secured")}
          {...register("sensitive_data_ipc_secured")}
        />
      </div>
      <div className="main-layout__drawer-footer">
        {action === routeActions.fill && (
          <>
            <Button
              className="btn-light btn-halfwidth"
              text="Back"
              onClick={() =>
                navigate(
                  generatePath(routes.ORDER_QUESTIONNAIRE_START, {
                    id
                  })
                )
              }
            />
            <Button
              isLoading={isLoading}
              type="submit"
              className="btn-default btn-halfwidth"
              disabled={isSubmitDisabled()}
              text="Submit"
            />
          </>
        )}
        {action === routeActions.verify && (
          <Button
            isLoading={isLoading}
            type="submit"
            className="btn-default btn-fullwidth"
            disabled={isSubmitDisabled()}
            text="Submit"
          />
        )}
        {action === routeActions.view && (
          <Button
            className="btn-default btn-fullwidth"
            text="Close"
            onClick={() =>
              navigate(
                generatePath(routes.PROJECT_DETAILS, {
                  id
                })
              )
            }
          />
        )}
      </div>
    </form>
  );
};

export default Questionnaire;
