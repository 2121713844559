import React, { useState, useEffect } from "react";
import purify from "dompurify";
import PropTypes from "prop-types";
import { generatePath, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearOrderDetails } from "@/store/order/order.actions";
import { routes, routeActions } from "@/constants";
import cn from "classnames";
import PageNotice from "@/components/PageNotice";
import moment from "moment";
import { formatString } from "@/utils/formatString";
import {
  ClassNameByStatus,
  OrderMasaDescrByStatus,
  OrderCasaDescrByStatus,
  OrderStatuses
} from "@/constants/orderStatuses";
import { ClockIcon, TagIcon } from "@/icons";
import { usePopperTooltip } from "react-popper-tooltip";
import "./ProjectCard.scss";

const ProjectCard = ({ project }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [title, setTitle] = useState("-");
  const [serviceName, setServiceName] = useState(false);
  const [description, setDescription] = useState(false);
  const {
    organization_name,
    application_name,
    package_name,
    service_name,
    status: { code, name },
    id,
    gcp_project,
    due_date,
    scheduled_date,
    errors
  } = project;
  const [controlledVisible, setControlledVisible] = useState(false);
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible
  } = usePopperTooltip({
    offset: 6,
    trigger: "hover",
    closeOnOutsideClick: true,
    interactive: true,
    placement: "top",
    visible: controlledVisible,
    onVisibleChange: setControlledVisible
  });

  useEffect(() => {
    switch (service_name) {
      case "casa":
        setTitle(application_name || organization_name);
        setServiceName("Cloud Application Security Assessment");
        setDescription(
          formatString(OrderCasaDescrByStatus[code] || "", {
            scheduled_date: moment(scheduled_date).format("MMMM D, YYYY")
          })
        );
        break;
      case "masa":
        setTitle(package_name || organization_name);
        setServiceName("Mobile Application Security Assessment");
        setDescription(
          formatString(OrderMasaDescrByStatus[code] || "", {
            scheduled_date: moment(scheduled_date).format("MMMM D, YYYY")
          })
        );
        break;
      default:
        break;
    }
  }, [
    organization_name,
    application_name,
    package_name,
    service_name,
    code,
    scheduled_date
  ]);

  const handleOpenOrderView = async () => {
    await dispatch(clearOrderDetails());

    navigate(generatePath(routes.PROJECT_DETAILS, { id }));
  };

  let noticeTitle = "Questionnaire submission pending";
  let noticeText =
    "Please complete and submit the self-declaration questionnaire to begin the assessment process. Your app’s evaluation cannot proceed until this step is completed.";
  let noticeTo = generatePath(routes.ORDER_QUESTIONNAIRE_START, {
    id
  });
  let noticeToText = "Let's get started!";

  const showNotice =
    code === OrderStatuses.QUESTIONNAIRE_SUBMISSION_PENDING ||
    (errors &&
      errors.length > 0 &&
      (code === OrderStatuses.ACCEPTED ||
        code === OrderStatuses.SCHEDULED ||
        code === OrderStatuses.SCOPING ||
        code === OrderStatuses.ASSESSMENT_IN_PROGRESS ||
        code === OrderStatuses.MITIGATIONS_NEEDED ||
        code === OrderStatuses.VERIFYING_MITIGATIONS));

  if (showNotice && code !== OrderStatuses.QUESTIONNAIRE_SUBMISSION_PENDING) {
    noticeTitle = "Awaiting remediation of questionnaire requirements";
    noticeText =
      "The assessment has started, but some questionnaire items did not meet the security requirements. Please resolve these issues and resubmit the questionnaire.";
    noticeTo = generatePath(routes.ORDER_QUESTIONNAIRE_BY_ID, {
      id,
      action: routeActions.verify
    });
    noticeToText = "Resubmit";
  }

  return (
    <button
      className="project-card"
      onClick={handleOpenOrderView}
      type="button">
      <div className="project-card__panel">
        {name && (
          <div
            className={cn(
              "project-card__badge",
              code && ClassNameByStatus[code]
            )}>
            {name}
          </div>
        )}
      </div>
      <div className="project-card__head">
        <div
          className="project-card__title"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: purify.sanitize(title) }}
        />
        <div className="project-card__subtitle">{serviceName}</div>
      </div>
      {description && <div className="project-card__text">{description}</div>}
      {showNotice && (
        <div className="project-card__tags">
          <PageNotice
            mod="highlight"
            title={noticeTitle}
            text={noticeText}
            to={noticeTo}
            toText={noticeToText}
          />
        </div>
      )}
      {service_name === "casa" && (
        <div className="project-card__tags">
          {gcp_project && (
            <div className="project-card__tag">
              <TagIcon className="project-card__tag-icon" />
              <span className="project-card__tag-label">
                GCP Project number: {gcp_project}
              </span>
            </div>
          )}
          {due_date && (
            <div className="project-card__tag" ref={setTriggerRef}>
              <ClockIcon className="project-card__tag-icon" />
              <span className="project-card__tag-label">{due_date}</span>
            </div>
          )}
          {visible && (
            <div
              ref={setTooltipRef}
              {...getTooltipProps({ className: "tooltip-container" })}>
              Assessment due date
              <div {...getArrowProps({ className: "tooltip-arrow" })} />
            </div>
          )}
        </div>
      )}
    </button>
  );
};

ProjectCard.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.object,
    is_paid: PropTypes.bool,
    payed_at: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    package_name: PropTypes.string,
    organization_name: PropTypes.string,
    application_name: PropTypes.string,
    due_date: PropTypes.string,
    scheduled_date: PropTypes.string,
    service_name: PropTypes.string,
    errors: PropTypes.array,
    order_number: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    gcp_project: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
  }).isRequired
};

export default ProjectCard;
