import React, { useState } from "react";
import {
  useParams,
  generatePath,
  useSearchParams,
  useNavigate
} from "react-router-dom";
import Button, { ButtonLink } from "@/components/Button";
import "./OrderVerification.scss";
import { routes } from "@/constants";
import { useDispatch, useSelector } from "react-redux";
import { getIsOrderPaid, getOrderDetails } from "@/store/order/order.reducer";
import {
  OrderActions,
  resendOtpCode,
  verifyOtpCode
} from "@/store/order/order.actions";
import { isInProcess } from "@/store/pending/pending.reducer";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "@/utils/yup";
import OrderTabs from "@/components/OrderTabs";
import InputField from "@/components/InputField";
import AlertMessage from "@/components/AlertMessage";

const OTPConfirmationSchema = yup.object({
  otp: yup.string().nullable().min(6).max(6).required()
});

const OTPConfirmation = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [resendEmailState, setResendEmailState] = useState(null);
  const isLoading = useSelector(
    (state) =>
      isInProcess(state, OrderActions.RESEND_OTP_CODE) ||
      isInProcess(state, OrderActions.VERIFY_OTP_CODE)
  );
  const dispatch = useDispatch();
  const { id } = useParams();
  const order = useSelector(getOrderDetails);
  const isPaidOrder = useSelector(getIsOrderPaid);

  const handleResendCode = async () => {
    setResendEmailState(null);

    try {
      await dispatch(resendOtpCode(id));
      setResendEmailState({
        type: "success",
        text: "We’ve resent an email with password"
      });
    } catch (e) {
      setResendEmailState({
        type: "error",
        text: "Can't resend email with password"
      });
    }
  };

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(OTPConfirmationSchema)
  });

  const onSubmit = async ({ otp }) => {
    const otpUppercase = otp.toUpperCase();

    try {
      await dispatch(
        verifyOtpCode({
          id,
          otp: otpUppercase
        })
      );
      setSearchParams({
        status: "success"
      });
    } catch (e) {
      setError("otp", {
        type: "custom",
        message: e.data?.otp?.join("\n") || "Invalid code"
      });
      setSearchParams({
        status: "error"
      });
    }
  };

  return (
    <OrderTabs>
      <form onSubmit={handleSubmit(onSubmit)} className="order-verify">
        {/* <div className="fields-group"> */}
        {/*  <h6 className="order-verify__title">Automatic verification</h6> */}
        {/* </div> */}
        <div className="fields-group">
          <p className="order-verify__text">
            We’ve sent a verifcation email to the contact email that you
            indicated on the package’s Google Play page to verify your
            authority.
          </p>
        </div>
        <div className="fields-group">
          <h6 className="order-verify__email">{order?.developer_email}</h6>
        </div>
        {searchParams.get("status") === "success" ? (
          <>
            <div className="fields-group">
              <AlertMessage
                type="success"
                text="Your e-mail has been verified"
              />
            </div>

            <div className="fields-group order-verify__navigation">
              {!isPaidOrder && (
                <Button
                  onClick={() => navigate(-1)}
                  type="button"
                  text="Back"
                  className="btn-link thin"
                  icon="back"
                />
              )}
              <Button
                onClick={() =>
                  navigate(
                    generatePath(
                      isPaidOrder ? routes.PROJECT_PROGRESS : routes.AGREEMENT,
                      {
                        id
                      }
                    )
                  )
                }
                className="btn-default"
                type="button"
                text="Continue"
                icon="forward"
              />
            </div>
          </>
        ) : (
          <>
            <div className="fields-group">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <a onClick={handleResendCode} className="order-verify__btn-link">
                Re-send letter?
              </a>
            </div>
            <div className="fields-group">
              {resendEmailState && (
                <AlertMessage
                  className="order-verify__text"
                  type={resendEmailState?.type}
                  text={resendEmailState?.text}
                />
              )}
            </div>
            <div className="fields-group">
              <InputField
                inputClassName="order-verify__field-uppercase"
                errors={errors}
                placeholder="Password from email"
                label="Please paste your one-time password from email you received bellow"
                name="otp"
                {...register("otp")}
              />
            </div>
            <div className="fields-group">
              <Button
                isLoading={isLoading}
                text="Verify my e-mail"
                icon="forward"
                className="btn-default"
                size="lg"
                type="submit"
              />
            </div>
            {!isPaidOrder && (
              <div className="fields-group order-verify__navigation">
                <ButtonLink
                  to={generatePath(routes.ORDER_VERIFY, { id })}
                  text="Back"
                  className="thin"
                  type="button"
                  icon="back"
                />
                <ButtonLink
                  to={generatePath(routes.AGREEMENT, { id })}
                  type="button"
                  className="btn-link thin"
                  text="Do it later"
                  icon="forward"
                />
              </div>
            )}
          </>
        )}
      </form>
    </OrderTabs>
  );
};

export default OTPConfirmation;
