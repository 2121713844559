import React from "react";
import purify from "dompurify";
import Select, { components } from "react-select";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import ErrorMessage from "@/components/ErrorMessage";
import cn from "classnames";
import "./CustomSelect.scss";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span
        className={cn(
          "react-select-custom-caret",
          // eslint-disable-next-line react/prop-types, react/destructuring-assignment
          props.selectProps.menuIsOpen && "active"
        )}
      />
    </components.DropdownIndicator>
  );
};

// eslint-disable-next-line react/display-name
const CustomSelect = (props) => {
  const {
    isSearchable,
    errors,
    name,
    defaultValue,
    options,
    id,
    control,
    label,
    placeholder
  } = props;

  const customSelectStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#ffffff" : "#272C3F",
      fontWeight: "500",
      transition: "0.2s background-color ease-in-out",
      cursor: "pointer"
    }),
    menu: () => ({
      position: "absolute",
      zIndex: "10",
      top: "100%",
      left: "0",
      right: "0",
      marginTop: "5px",
      backgroundColor: "#ffffff",
      borderRadius: "2px",
      border: "1px solid #d8dbe1",
      boxShadow: "0 4px 11px hsl(0deg 0% 0% / 10%)"
    }),
    control: (_, { selectProps: { hasError } }) => ({
      display: "flex",
      alignItems: "center",
      height: "44px",
      borderRadius: "4px",
      fontSize: "16px",
      fontWeight: "600",
      border: `1px solid ${(hasError && "#e85b5b") || "#d1d1d9"}`,
      backgroundColor: "#ffffff",
      cursor: "pointer"
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: "2px 15px",
      fontSize: "16px",
      fontWeight: "600"
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "16px",
      fontWeight: "normal",
      lineHeight: "1.1",
      color: "#717786"
    }),
    indicatorSeparator: (provided) => {
      const display = "none";
      return { ...provided, display };
    }
  };

  return (
    <div className="form-group">
      {label && (
        <div
          className="form-label"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: purify.sanitize(label) }}
        />
      )}
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value, ref } }) => (
          <Select
            ref={ref}
            hasError={!!errors[name]}
            styles={customSelectStyles}
            isSearchable={isSearchable}
            onChange={(option) => onChange(option.value)}
            options={options}
            className={cn({ "has-error": !!errors[name] })}
            value={options.find((o) => o.value === value)}
            id={id}
            instanceId={id}
            placeholder={placeholder}
            components={{ DropdownIndicator }}
          />
        )}
      />

      <ErrorMessage name={name} errors={errors} />
    </div>
  );
};

CustomSelect.defaultProps = {
  errors: {}
};

CustomSelect.propTypes = {
  control: PropTypes.object.isRequired,
  isSearchable: PropTypes.bool,
  errors: PropTypes.object,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  label: PropTypes.string
};

export default CustomSelect;
