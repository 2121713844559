import { routes } from "@/constants/routes";

export const routeActions = {
  fill: "fill",
  verify: "verify",
  view: "view"
};

export const routeTitles = {
  [routes.ROOT]: "Main",
  [routes.LOGIN]: "Sign in",
  [routes.REGISTER]: "Sign up",
  [routes.REGISTER_CONFIRMATION]: "Register confirmation",
  [routes.FORGOT_PASSWORD]: "Forgot password",
  [routes.RESET_PASSWORD]: "Reset password",
  [routes.MFA]: "Multi-Factor Authentication",
  [routes.MFA_SETUP_SUCCESS]: "Multi-Factor Authentication",
  [routes.MFA_ENABLE]: "Multi-Factor Authentication",
  [routes.MFA_DISABLE]: "Disable Multi-Factor Authentication",
  [routes.MFA_ENABLE_SUCCESS]: "Multi-Factor Authentication",
  [routes.MFA_DISABLE_SUCCESS]: "Disable Multi-Factor Authentication",
  [routes.NEW_ORDER]: "New Order",
  [routes.ORDER_BY_ID]: "Order",
  [routes.ORDER_QUESTIONNAIRE_BY_ID]: "Questionnaire",
  [routes.ORDER_QUESTIONNAIRE_START]: "Questionnaire",
  [routes.ORDER_APPLICATION_INFO]: "Testing details",
  [routes.ORDER_CASA_BY_ID]: "Order",
  [routes.ORDER_CASA_ASSESSMENT_INFO]: "Assessment info",
  [routes.ORDER_CASA_APPLICATION_INFO]: "Application info",
  [routes.ORDER_CASA_SOURCE_CODE_INFO]: "Application info",
  [routes.ORDER_CASA_SUCCESS]: "Successfully submitted",
  [routes.FAQ]: "Frequently Asked Question",
  [routes.PROJECTS]: "Projects",
  [routes.ORDER_VERIFY]: "Verification",
  [routes.OTP_CONFIRMATION]: "OTP",
  [routes.AGREEMENT]: "Agreement",
  [routes.ORDER_PAYMENT_SUCCESS]: "Payment status",
  [routes.CHANGE_PASSWORD]: "Change password",
  [routes.ORDER_CASA_PAYMENT]: "Plans and pricing",
  [routes.ORDER_CASA_PAYMENT_TYPE]: "Select your payment method",
  [routes.ORDER_CASA_SUCCESS_PAYMENT]: "Successfully paid",
  [routes.ORDER_CASA_SUCCESS_INVOICE]: "Invoicing information received",
  [routes.ORDER_CASA_AGREEMENT]: "Agreement"
};

export const routesToPropsMap = {
  [routes.LOGIN]: {
    title: "Sign in"
  },
  [routes.REGISTER]: {
    title: "Sign up"
  },
  [routes.MFA]: {
    title: "Multi-Factor Authentication"
  },
  [routes.MFA_SETUP_SUCCESS]: {
    title: "Multi-Factor Authentication"
  },
  [routes.MFA_ENABLE]: {
    title: "Multi-Factor Authentication",
    label:
      "Secure your account by adding multi-factor authentication. Follow the instructions below to complete the setup. You will be asked to provide a one-time code next time you login."
  },
  [routes.MFA_DISABLE]: {
    title: "Disable Multi-Factor Authentication"
  },
  [routes.MFA_ENABLE_SUCCESS]: {
    title: "Multi-Factor Authentication"
  },
  [routes.MFA_DISABLE_SUCCESS]: {
    title: "Multi-Factor Authentication"
  },
  [routes.REGISTER_CONFIRMATION]: {
    title: "Register confirmation"
  },
  [routes.FORGOT_PASSWORD]: {
    title: "Forgot password"
  },
  [routes.RESET_PASSWORD]: {
    title: "Reset password"
  },
  [routes.START_ORDER]: {
    title: "Start a new assesment",
    label:
      "Select validation scheme against which your application will be assessed."
  },
  [routes.NEW_ORDER_CASA]: {
    title: "Start a new assesment",
    label: "Please provide the information below to start CASA"
  },
  [routes.ORDER_CASA_APPLICATION_INFO]: {
    title: "Application details"
  },
  [routes.ORDER_CASA_BY_ID]: {
    title: "Application details"
  },
  [routes.ORDER_CASA_ASSESSMENT_INFO]: {
    title: "Assessment details"
  },
  [routes.ORDER_CASA_SOURCE_CODE_INFO]: {
    title: "Source code details",
    label:
      "Please provide at least two metrics under each category to help us understand the complexity and health of your code. Select metrics that are commonly used in your Software Development Life Cycle (SDLC) and are readily available or calculable."
  },
  [routes.NEW_ORDER]: {
    title: "Start a new assesment",
    label: "Please provide the necessary information to proceed with MASA"
  },
  [routes.ORDER_BY_ID]: {
    title: "Application details"
  },
  [routes.ORDER_QUESTIONNAIRE_START]: {
    title: "Self-declare questionnaire",
    label:
      "Before we begin the assessment, please answer the following questions about your application."
  },
  [routes.ORDER_QUESTIONNAIRE_BY_ID]: {
    title: "Self-declare questionnaire",
    label:
      "Before we begin the assessment, please answer the following questions about your application."
  },
  [routes.ORDER_APPLICATION_INFO]: {
    title: "Testing details",
    label: "Please provide the information about the application"
  },
  [routes.AGREEMENT]: {
    title: "Agreement",
    label:
      "Please review and accept our terms and conditions to proceed with the  assessment"
  },
  [routes.CART]: {
    title: "Cart"
  },
  [routes.FAQ]: {
    title: "Frequently Asked Question"
  },
  [routes.PROJECTS]: {
    title: "Sign in"
  },
  [routes.ORDER_PAYMENT]: {
    title: "Select your payment method",
    label:
      "Use our secure online payment gateway for instant transactions, or choose invoice for traditional payment processing."
  },
  [routes.ORDER_VERIFY]: {
    title: "Verification"
  },
  [routes.OTP_CONFIRMATION]: {
    title: "Verification"
  },
  [routes.CHANGE_PASSWORD]: {
    title: "Change password",
    label: "Change your password here"
  },
  [routes.ORDER_CASA_PAYMENT]: {
    title: "Plans and pricing",
    label: "Choose your plan and get started with CASA."
  },
  [routes.ORDER_CASA_PAYMENT_TYPE]: {
    title: "Select your payment method",
    label:
      "Use our secure online payment gateway for instant transactions, or choose invoice for traditional payment processing."
  },
  [routes.ORDER_CASA_AGREEMENT]: {
    title: "Agreement",
    label:
      "Please review and accept our terms and conditions to proceed with the assessment"
  }
};
