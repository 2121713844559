import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import "./Footer.scss";

const Footer = ({ mod }) => {
  const date = new Date();

  return (
    <footer className={cn("footer", mod && `footer_${mod}`)}>
      <div className="footer__info">
        Need assistance or have questions? Reach out to&nbsp;
        <a href="mailto:hub@leviathansecurity.com">hub@leviathansecurity.com</a>
      </div>
      <div className="footer__copyright">
        &copy; {date.getFullYear()} Leviathan Security Group
      </div>
    </footer>
  );
};

Footer.propTypes = {
  mod: PropTypes.string
};

export default Footer;
