import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  generatePath,
  Navigate,
  useParams,
  useSearchParams
} from "react-router-dom";
import OrderTabs from "@/components/OrderTabs";
import PurchasePlanCard from "@/components/PurchasePlanCard";
import CircleSpinner from "@/components/CircleSpinner";
import { routes } from "@/constants";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getOrderProducts, getProductById } from "@/store/order/order.reducer";
import { fetchOrderProducts } from "@/store/order/order.actions";
import CheckoutForm from "./CheckoutForm";
import "./OrderPayment.scss";

const stripe = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const OrderPayment = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const orderProducts = useSelector(getOrderProducts);

  const clientSecret = searchParams.get("client_secret");
  const selectedProduct = useSelector((state) =>
    getProductById(state, parseInt(searchParams.get("product_id"), 10))
  );

  useEffect(() => {
    if (!orderProducts?.length) {
      dispatch(fetchOrderProducts());
    }
    // eslint-disable-next-line
  }, [orderProducts]);

  if (!orderProducts?.length) {
    return null;
  }

  if (!selectedProduct || !clientSecret) {
    return <Navigate to={generatePath(routes.CART, { id })} />;
  }

  return (
    <OrderTabs>
      <div className="cart">
        <div className="fields-group">
          <PurchasePlanCard
            price={selectedProduct.amount / 100}
            text={selectedProduct.name}
            className="selected"
            hasError={false}
            options={selectedProduct.description.map((k) => k.text)}
          />
        </div>
        <div className="fields-group">
          {clientSecret && stripe ? (
            <Elements stripe={stripe} options={{ clientSecret }}>
              <CheckoutForm clientSecret={clientSecret} />
            </Elements>
          ) : (
            <CircleSpinner />
          )}
        </div>
      </div>
    </OrderTabs>
  );
};

export default OrderPayment;
