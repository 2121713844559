import React, { useEffect } from "react";
import { ButtonLink } from "@/components/Button";
import { routes } from "@/constants";

import "./mfaSucess.scss";
import { useDispatch } from "react-redux";
import { removeMFASetupDetails } from "@/store/auth/auth.actions";

const SetupMFASuccess = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(removeMFASetupDetails());
    // eslint-disable-next-line
  }, []);

  return (
    <div className="mfa-success">
      <h2 className="mfa-success__title">Multi-Factor Authentication</h2>
      <p className="mfa-success__description">
        You’ve successfully enabled multi-factor authentication for your
        account. You will be asked to provide an OTP code next time you login
        into the app.
      </p>
      <div className="buttons-wrapper">
        <ButtonLink
          blank={false}
          className="btn-default btn-fullwidth"
          to={routes.ROOT}
          text="Continue"
          replace
        />
      </div>
    </div>
  );
};

export default SetupMFASuccess;
