import { createReducer } from "@reduxjs/toolkit";
import { reduceSuccessResponse } from "@/utils/getSuccessResponse";
import { AccountActions } from "@/store/account/account.actions";

const initialState = {
  accountDetails: null
};

export const account = createReducer(initialState, {
  [AccountActions.GET_USER_DETAILS]: (state, action) =>
    reduceSuccessResponse(state, action, (res) => {
      return {
        ...state,
        accountDetails: res
      };
    })
});

export const getAccountEmail = (state) => state.account.accountDetails?.email;

export const getAccountChangePasswordDate = (state) =>
  state.account.accountDetails?.changepassword_at;

export default account;
