import React from "react";
import { ButtonLink } from "@/components/Button";
import { routes } from "@/constants";

const ResetPasswordSuccess = () => {
  return (
    <div className="forgot-password-form">
      <div className="forgot-password-form__head">
        <div className="forgot-password-form__title">Password changed</div>
        <div className="forgot-password-form__text">
          Your password changed successfully.
        </div>
      </div>
      <div className="fields-group">
        <ButtonLink
          to={routes.LOGIN}
          text="Back to Login"
          className="btn-default btn-fullwidth"
        />
      </div>
    </div>
  );
};

export default ResetPasswordSuccess;
