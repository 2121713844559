import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  getIsOrderFinalizedSuccessfully,
  getIsOrderFinalizedUnsuccessfully
} from "@/store/order/order.reducer";
import PanelHeader from "@/components/ProjectDetails/components/PanelHeader";
import Button from "@/components/Button";
import {
  CheckCircleSuccessIcon,
  CrossRedIcon,
  CheckCircleDisabledIcon
} from "@/icons";
import cn from "classnames";
import { Mobile } from "@/components/Responsive";

export const ResultsNavItem = ({ tabIndex, handleTabNavClick }) => {
  const isAssessmentSuccessfulFinalized = useSelector(
    getIsOrderFinalizedSuccessfully
  );
  const isAssessmentUnsuccessful = useSelector(
    getIsOrderFinalizedUnsuccessfully
  );

  return (
    <button
      className={cn(
        "project-details__nav-item",
        tabIndex === "results" && "active"
      )}
      type="button"
      disabled={!(isAssessmentSuccessfulFinalized || isAssessmentUnsuccessful)}
      onClick={() => handleTabNavClick("results")}>
      {
        // eslint-disable-next-line no-nested-ternary
        isAssessmentSuccessfulFinalized ? (
          <CheckCircleSuccessIcon className="project-details__nav-icon" />
        ) : isAssessmentUnsuccessful ? (
          <CrossRedIcon className="project-details__nav-icon" />
        ) : (
          <CheckCircleDisabledIcon className="project-details__nav-icon" />
        )
      }
      Results
    </button>
  );
};

const Results = ({
  downloadReport,
  tabIndex,
  handleTabNavClick,
  isLoading,
  isLoadingReportAdd
}) => {
  const isAssessmentSuccessfulFinalized = useSelector(
    getIsOrderFinalizedSuccessfully
  );
  const isAssessmentUnsuccessful = useSelector(
    getIsOrderFinalizedUnsuccessfully
  );

  return (
    <>
      <Mobile>
        <ResultsNavItem
          tabIndex={tabIndex}
          handleTabNavClick={handleTabNavClick}
        />
      </Mobile>
      {tabIndex === "results" && (
        <div className="project-details__tab">
          <PanelHeader
            title={
              isAssessmentSuccessfulFinalized
                ? "CASA Validation Completed"
                : "Not Validated"
            }
            description={
              isAssessmentSuccessfulFinalized
                ? "Congratulations! Your application has successfully met the CASA security requirements. The implemented remediations have been validated. Please consult the Findings Report for details on the findings and the Validation Report for confirmation of meeting the CASA requirements."
                : "The application has not been validated."
            }
            icon={
              isAssessmentSuccessfulFinalized ? (
                <CheckCircleSuccessIcon />
              ) : (
                <CrossRedIcon />
              )
            }
            hasButton={false}>
            <>
              {isAssessmentSuccessfulFinalized?.report && (
                <Button
                  isLoading={isLoading}
                  onClick={downloadReport(
                    isAssessmentSuccessfulFinalized?.report
                  )}
                  className="project-details__panel-button btn btn-light btn-fullwidth"
                  type="button"
                  icon="download"
                  text="Findings report"
                />
              )}
              {isAssessmentSuccessfulFinalized?.report_additional && (
                <Button
                  isLoading={isLoadingReportAdd}
                  onClick={downloadReport(
                    isAssessmentSuccessfulFinalized?.report_additional,
                    true
                  )}
                  className="project-details__panel-button btn btn-light btn-fullwidth"
                  type="button"
                  icon="download"
                  text="Validation report"
                />
              )}
              {isAssessmentUnsuccessful && (
                <Button
                  isLoading={isLoading}
                  onClick={downloadReport(isAssessmentUnsuccessful?.report)}
                  type="button"
                  icon="download"
                  text="Validation report"
                  className="project-details__panel-button btn-light btn-fullwidth"
                />
              )}
            </>
          </PanelHeader>
        </div>
      )}
    </>
  );
};

Results.propTypes = {
  downloadReport: PropTypes.func,
  tabIndex: PropTypes.string,
  handleTabNavClick: PropTypes.func,
  isLoading: PropTypes.bool,
  isLoadingReportAdd: PropTypes.bool
};

ResultsNavItem.propTypes = {
  tabIndex: PropTypes.string,
  handleTabNavClick: PropTypes.func
};

export default Results;
