import React from "react";
import InputField from "@/components/InputField";
import InputPasswordField from "@/components/InputPasswordField";
import yup from "@/utils/yup";
import { PropTypes } from "prop-types";
import { useForm } from "react-hook-form";
import Checkbox from "@/components/Checkbox";
import Button, { ButtonLink } from "@/components/Button";
import { routes } from "@/constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { isInProcess } from "@/store/pending/pending.reducer";
import {
  OrderActions,
  postApplicationInfoCasa
} from "@/store/order/order.actions";
import { generatePath, useNavigate, useParams } from "react-router-dom";

const schema = yup.object({
  application_name: yup.string().nullable().max(255).required(),
  application_url: yup.string().nullable().max(255).required(),
  gcp_project: yup.string().nullable().max(255),
  has_login: yup.boolean().nullable(),
  app_login: yup.string().nullable().when("has_login", {
    is: true,
    then: yup.string().nullable().required()
  }),
  app_password: yup.string().nullable().when("has_login", {
    is: true,
    then: yup.string().nullable().required()
  })
});

const ApplicationInfoForm = ({ predefinedValues }) => {
  const navigate = useNavigate();
  const isLoading = useSelector((state) =>
    isInProcess(state, OrderActions.POST_APPLICATION_INFO)
  );
  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: predefinedValues
  });
  const watchHasLogin = watch("has_login");

  const onSubmit = async (data) => {
    data.has_login = +data.has_login;
    if (!watchHasLogin) {
      data.app_login = "";
      data.app_password = "";
    }
    const payload = Object.keys(data).reduce((acc, key) => {
      acc[key] = data[key];
      return acc;
    }, {});

    await dispatch(
      postApplicationInfoCasa({
        id,
        data: payload
      })
    );

    const path = generatePath(routes.ORDER_CASA_ASSESSMENT_INFO, { id });
    navigate(path);
  };

  return (
    <form className="application-info" onSubmit={handleSubmit(onSubmit)}>
      <div className="fields-group">
        <InputField
          name="application_name"
          type="text"
          label="Application name <sup>*</sup>"
          errors={errors}
          placeholder="Application name"
          {...register("application_name")}
        />
        <InputField
          name="application_url"
          type="text"
          label="Application URL <sup>*</sup>"
          errors={errors}
          placeholder="website.com"
          {...register("application_url")}
        />
        <InputField
          onInput={(e) => {
            e.target.value = e.target.value.replace(/[^0-9]/g, "");
          }}
          name="gcp_project"
          type="text"
          tooltipTitle="Where to find it?"
          tooltipContent={
            <div className="where-to-find-tooltip">
              <ol className="where-to-find-tooltip-list">
                <li className="where-to-find-tooltip-list__item">
                  Go to the Google Developer Console:{" "}
                  <a
                    href="https://console.developers.google.com"
                    target="_blank"
                    rel="noreferrer">
                    https://console.developers.google.com
                  </a>
                </li>
                <li className="where-to-find-tooltip-list__item">
                  Select your project
                </li>
                <li className="where-to-find-tooltip-list__item">
                  Go to Cloud Overview page
                </li>
                <li className="where-to-find-tooltip-list__item">
                  Find your project number under project info card
                </li>
              </ol>
            </div>
          }
          errors={errors}
          label="GCP Project number"
          placeholder="GCP Project #"
          {...register("gcp_project")}
        />
      </div>
      <div className="fields-group">
        <Checkbox
          text="Application requires user login"
          name="has_login"
          errors={errors}
          {...register("has_login")}
        />
      </div>
      {watchHasLogin && (
        <div className="fields-group">
          <div className="fields-group-subtitle">
            Provide a set of valid credentials that we can use for testing
            purposes. The credentials shared will be securely handled and
            exclusively used for assessment purposes.
          </div>
          <InputField
            name="app_login"
            type="text"
            label="Username"
            errors={errors}
            placeholder="Email (e.g. user@yourapp.com)"
            {...register("app_login")}
          />
          <InputPasswordField
            name="app_password"
            errors={errors}
            label="Password"
            placeholder="Password"
            {...register("app_password")}
          />
        </div>
      )}
      <div className="main-layout__drawer-footer">
        <div className="main-layout__drawer-buttons">
          <div className="main-layout__drawer-buttons-item">
            <ButtonLink
              text="Back"
              className="btn-light btn-fullwidth"
              to={generatePath(routes.ORDER_CASA_BY_ID, { id })}
            />
          </div>
          <div className="main-layout__drawer-buttons-item">
            <Button
              isLoading={isLoading}
              type="submit"
              className="btn-default btn-fullwidth"
              text="Continue"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

ApplicationInfoForm.propTypes = {
  predefinedValues: PropTypes.object
};

export default ApplicationInfoForm;
