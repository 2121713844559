import { ApiClient } from "@/services/api";
import { RequestConstants } from "@/constants";

class ThirdPartyService extends ApiClient {
  getCountriesAndStates() {
    return this.apiClient.get("https://restcountries.com/v2/all", {
      params: {
        fields: "name,alpha2Code"
      }
    });
  }

  async downloadFile({ url, filename, contentType }) {
    try {
      const data = await this.apiClient.get(url, {
        url,
        timeout: RequestConstants.FILE_REQUESTS_TIMEOUT_IN_SECONDS,
        responseType: "arraybuffer",
        headers: {
          "Content-Type": contentType
        }
      });

      const blob = new Blob([data], { type: contentType });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        const windowUrl = window.URL || window.webkitURL;
        const href = windowUrl.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("download", filename);
        a.setAttribute("href", href);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }

      return data;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("Failed to download file");

      return e;
    }
  }
}

export default new ThirdPartyService();
