import axios from "axios";
import { lsKeys, routes, StatusCodes } from "@/constants";
import { Auth } from "aws-amplify";

export class ApiClient {
  constructor() {
    this.apiClient = axios.create({
      baseURL: process.env.REACT_APP_API_URL || "https://localhost:8088",
      timeout: 10 * 1000
    });

    this.initRequestInterceptors();
    this.initResponseInterceptors();
  }

  initRequestInterceptors(resToken) {
    this.apiClient.interceptors.request.use((config) => {
      const token =
        resToken ?? localStorage.getItem(lsKeys.AUTHORIZATION_TOKEN);

      if (token) {
        return {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${token}`
          }
        };
      }

      return config;
    });
  }

  initResponseInterceptors() {
    this.apiClient.interceptors.response.use(
      (config) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data

        return config.data;
      },
      async (error) => {
        const { data = null, status = null } = error.response ?? {};
        const { code } = error;

        if (status === StatusCodes._403) {
          localStorage.clear();
          await Auth.signOut();
          document.location = routes.LOGIN;
          // window.location.reload();
        }

        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
          data,
          code,
          status
        });
      }
    );
  }
}
