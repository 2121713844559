import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Header from "@/components/Header";
import PageSidebar from "@/components/PageSidebar";
import Footer from "@/components/Footer";
import ProjectTiles from "@/components/ProjectTiles";
import { Desktop, TabletDesktop } from "@/components/Responsive";
import Settings from "@/components/Settings";
import { Outlet, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrders } from "@/store/order/order.actions";
import { getOrderList } from "@/store/order/order.reducer";
import { useAsideLayoutProps } from "@/hooks";
import { routes } from "@/constants";
import "../MainLayout/MainLayout.scss";

const DrawerLayout = ({ pageState }) => {
  const dispatch = useDispatch();
  const orderList = useSelector(getOrderList);
  const layoutProps = useAsideLayoutProps(Object.values(routes));
  let closePath = routes.ROOT;

  if (pageState === "settings") {
    closePath = routes.SETTINGS;
  }

  useEffect(() => {
    dispatch(getAllOrders());
  }, [dispatch]);

  return (
    <div className="main-layout main-layout_with-drawer">
      <TabletDesktop>
        <div className="main-layout__header">
          <Header />
        </div>
      </TabletDesktop>
      <Desktop>
        <div className="main-layout__aside">
          <PageSidebar />
        </div>
      </Desktop>
      <TabletDesktop>
        <div className="main-layout__body" id="main-layout-body">
          {orderList && !pageState && <ProjectTiles orders={orderList} />}
          {pageState === "settings" && <Settings />}
        </div>
      </TabletDesktop>
      <div className="main-layout__drawer">
        <div className="main-layout__drawer-content">
          {layoutProps.title && (
            <div className="main-layout__drawer-head">
              <div className="main-layout__drawer-head-wrap">
                <div className="main-layout__drawer-title">
                  {layoutProps.title}
                </div>
                {layoutProps.label && (
                  <div className="main-layout__drawer-label">
                    {layoutProps.label}
                  </div>
                )}
              </div>
              <Link
                className="main-layout__drawer-close"
                to={closePath}
                aria-label="Close">
                <svg
                  className="main-layout__drawer-close-icon"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    className="main-layout__drawer-close-path"
                    d="M7.00023 5.58599L11.9502 0.635986L13.3642 2.04999L8.41423 6.99999L13.3642 11.95L11.9502 13.364L7.00023 8.41399L2.05023 13.364L0.63623 11.95L5.58623 6.99999L0.63623 2.04999L2.05023 0.635986L7.00023 5.58599Z"
                    fill="#717786"
                  />
                </svg>
              </Link>
            </div>
          )}
          <Outlet />
        </div>
      </div>
      <TabletDesktop>
        <div className="main-layout__footer">
          <Footer />
        </div>
      </TabletDesktop>
    </div>
  );
};

DrawerLayout.propTypes = {
  pageState: PropTypes.string
};

export default DrawerLayout;
