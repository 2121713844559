import { OrderStatuses } from "@/constants/orderStatuses";

export const editableModeByStatus = (code) => {
  const statuses = [OrderStatuses.CREATED, OrderStatuses.SCOPING].filter(
    (status) => status === code
  );

  if (statuses.length === 0) {
    return false;
  }

  return true;
};
