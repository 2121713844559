import React from "react";
import Button from "@/components/Button";
import { Auth } from "aws-amplify";
import { FederatedLoginProviders } from "@/constants";

import "./FederalProvidersRow.scss";
import { AppleLogo, GoogleIcon } from "@/icons";

const FederalProvidersRow = () => {
  // const onFacebookSignIn = async () => {
  //   await Auth.federatedSignIn({
  //     provider: FederatedLoginProviders.FACEBOOK
  //   });
  // };

  const onGoogleSignIn = async () => {
    await Auth.federatedSignIn({
      provider: FederatedLoginProviders.GOOGLE
    });
  };

  const onAppleSignIn = async () => {
    await Auth.federatedSignIn({
      provider: FederatedLoginProviders.APPLE
    });
  };

  return (
    <div className="federal-providers fields-group">
      <div className="federal-providers__row">
        {/* <Button
          className="federal-providers__button btn-bordered"
          type="button"
          text="Facebook"
          onClick={onFacebookSignIn}
        /> */}
        <Button
          type="button"
          className="federal-providers__button federal-providers__button_google btn-lightgrey"
          text="Sign in with Google"
          btnIcon={<GoogleIcon className="btn__icon" />}
          onClick={onGoogleSignIn}
        />
        <Button
          type="button"
          className="federal-providers__button federal-providers__button_apple btn-lightgrey"
          text="Sign in with Apple"
          btnIcon={<AppleLogo className="btn__icon" />}
          onClick={onAppleSignIn}
        />
        {/* <Button
          type="button"
          className="federal-providers__button federal-providers__button_amazon btn-bordered"
          text="Amazon"
          icon="amazon"
        /> */}
      </div>
    </div>
  );
};

export default FederalProvidersRow;
