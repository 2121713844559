import PanelHeader from "@/components/ProjectProgress/components/PanelHeader";
import {
  AssessmentProgressIcon,
  AssessmentSuccessIcon,
  CheckCircleDisabledIcon,
  CheckCircleSuccessIcon,
  CircleWarningIcon
} from "@/icons";
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  getIsAssessmentInProgress,
  getIsAssessmentSuccessful
} from "@/store/order/order.reducer";

export const AssessmentPanelHeader = ({ active }) => {
  const isAssessmentInProgress = useSelector(getIsAssessmentInProgress);
  const isAssessmentSuccessful = useSelector(getIsAssessmentSuccessful);

  return (
    <PanelHeader
      title="Assessment in progress"
      description="Sit back and relax, your application’s assessment is underway!"
      icon={
        // eslint-disable-next-line no-nested-ternary
        isAssessmentSuccessful ? (
          <CheckCircleSuccessIcon />
        ) : isAssessmentInProgress ? (
          <CircleWarningIcon />
        ) : (
          <CheckCircleDisabledIcon />
        )
      }
      active={active}
      hasButton={false}
    />
  );
};

AssessmentPanelHeader.propTypes = {
  active: PropTypes.bool.isRequired
};

// eslint-disable-next-line
export const AssessmentPanelBody = ({ orderDetails }) => {
  const isAssessmentSuccessful = useSelector(getIsAssessmentSuccessful);

  return (
    <div className="progress-accordion__body inner row-info-wrapper">
      <div className="row-info-wrapper__icon">
        {isAssessmentSuccessful ? (
          <AssessmentSuccessIcon />
        ) : (
          <AssessmentProgressIcon />
        )}
      </div>
      <div className="row-info-wrapper__info">
        <div className="row-info-wrapper__title">
          What will the assessment include?
        </div>
        <div className="row-info-wrapper__text">
          The scope of the assessment consists of client-side security,
          authentication to the backend/cloud service, and connectivity to the
          backend/cloud service looking at general security and some privacy
          best practices. You can read more about the checks at the Mobile
          AppSec Verification Standard’s page.
        </div>
      </div>
    </div>
  );
};

AssessmentPanelBody.propTypes = {
  orderDetails: PropTypes.object.isRequired
};
