import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  generatePath,
  useParams,
  createSearchParams,
  useNavigate
} from "react-router-dom";
import { getOrderDetails } from "@/store/order/order.reducer";
import { isInProcess } from "@/store/pending/pending.reducer";
import {
  OrderActions,
  getOrderCasaDetailsById
} from "@/store/order/order.actions";
import CircleSpinner from "@/components/CircleSpinner";
import Button, { ButtonLink } from "@/components/Button";
import OrderInfo from "@/components/OrderInfo";
import PurchasePlanCard from "@/components/PurchasePlanCard";
import { routes } from "@/constants";
import cn from "classnames";
import "./Payment.scss";

const Payment = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orderDetails = useSelector(getOrderDetails);
  const isLoading = useSelector((state) =>
    isInProcess(state, OrderActions.GET_ORDER_DETAILS_BY_ID)
  );
  const [selectedPlan, setSelectedPlan] = useState();
  const recommendedPlan = 1;

  useEffect(() => {
    if (id) {
      dispatch(getOrderCasaDetailsById(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (orderDetails && orderDetails.plane[recommendedPlan]) {
      setSelectedPlan(orderDetails.plane[recommendedPlan].product_id);
    }
    if (orderDetails && orderDetails.plane.length === 1) {
      setSelectedPlan(orderDetails.plane[0].product_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <div className="payment">
        <CircleSpinner />
      </div>
    );
  }

  const handleContinue = () => {
    const path = generatePath(routes.ORDER_CASA_PAYMENT_TYPE, { id });
    const params = `?${createSearchParams({
      product_id: selectedPlan,
      path_ref: "payment"
    })}`;

    navigate({
      pathname: path,
      search: params
    });
  };

  const handleChoosePlan = (planId) => {
    setSelectedPlan(planId);
  };

  return (
    <div className="payment">
      <div className="payment__inner">
        {orderDetails.plane && (
          <div className="payment__wrap">
            {orderDetails.plane.map((item, index) => (
              <button
                key={item.product_id}
                className="payment__item"
                type="button"
                onClick={() => handleChoosePlan(item.product_id)}>
                <PurchasePlanCard
                  className={cn(
                    "bordered",
                    selectedPlan === item.product_id && "active"
                  )}
                  price={item.price / 100}
                  text={item.product.name}
                  hasError={false}
                  options={[item.product.description]}
                  selectedBadge={
                    recommendedPlan === index && "Recommended for you"
                  }
                />
              </button>
            ))}
          </div>
        )}
        <OrderInfo
          title="Want a comprehensive assessment of your application?"
          text="<a href='mailto:hub@leviathansecurity.com'>Contact us</a> today and get a discount for a pentest with your CASA verification"
        />
      </div>
      <div className="main-layout__drawer-footer">
        <div className="main-layout__drawer-buttons">
          <div className="main-layout__drawer-buttons-item">
            <ButtonLink
              to={routes.ROOT}
              text="Back"
              className="btn-light btn-fullwidth"
            />
          </div>
          <div className="main-layout__drawer-buttons-item">
            <Button
              disabled={!selectedPlan}
              text="Continue"
              className="btn-default btn-fullwidth"
              onClick={handleContinue}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
