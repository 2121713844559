import { createReducer } from "@reduxjs/toolkit";
import { CounterActions } from "@/store/counter/counter.actions";
import { reduceSuccessResponse } from "@/utils/getSuccessResponse";

const initialState = {
  value: 0
};

export const counter = createReducer(initialState, {
  [CounterActions.INCREMENT]: (state) => {
    state.value += 1;
  },
  [CounterActions.DECREMENT]: (state) => {
    state.value -= 1;
  },
  [CounterActions.INCREMENT_BY_AMOUNT]: (state, action) => {
    state.value += action.payload;
  },
  [CounterActions.INCREMENT_ASYNC]: (state, action) =>
    reduceSuccessResponse(state, action, (res) => {
      return {
        ...state,
        value: state.value + res
      };
    })
});

export default counter;
