import React from "react";
import PropTypes from "prop-types";
import PanelHeader from "@/components/ProjectProgress/components/PanelHeader";
import { CheckCircleSuccessIcon, CircleWarningIcon } from "@/icons";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { ContactTypes, routes } from "@/constants";
import { useSelector } from "react-redux";
import {
  getIsDeveloperInfoFilled,
  getIsOrderPaidOrVerified
} from "@/store/order/order.reducer";
import OptionalTextField from "@/components/ProjectProgress/components/OptionalTextField";

export const DeveloperInfoPanelHeader = ({ active }) => {
  const { id } = useParams();
  const isPaidOrVerified = useSelector(getIsOrderPaidOrVerified);
  const isDevInfoFilled = useSelector(getIsDeveloperInfoFilled);
  const navigate = useNavigate();
  const onInfoDeveloperClick = () => {
    navigate(
      generatePath(routes.ORDER_BY_ID, {
        id
      })
    );
  };

  return (
    <PanelHeader
      title="Developer details"
      description="Information about you, contact details"
      icon={
        isDevInfoFilled ? <CheckCircleSuccessIcon /> : <CircleWarningIcon />
      }
      active={active}
      hasButton={!isPaidOrVerified}
      buttonText="Update"
      onClick={onInfoDeveloperClick}
    />
  );
};

DeveloperInfoPanelHeader.propTypes = {
  active: PropTypes.bool.isRequired
};

export const DeveloperInfoPanelBody = ({ orderDetails }) => (
  <div className="progress-accordion__body inner">
    <div className="progress-accordion__body-item body-item">
      <h6 className="body-item__title">General information</h6>
      <div className="body-item__info-block">
        <span className="body-item__label">Developer name</span>
        <OptionalTextField
          text={orderDetails.developer_name}
          className="body-item__text"
        />
      </div>
      {orderDetails.organization_name && (
        <div className="body-item__info-block">
          <span className="body-item__label">Company name</span>
          <OptionalTextField
            text={orderDetails.organization_name}
            className="body-item__text"
          />
        </div>
      )}
    </div>
    <div className="progress-accordion__body-item body-item">
      <h6 className="body-item__title">
        {orderDetails.contact_type === ContactTypes.ORGANIZATION
          ? "Organization address"
          : "Address"}
      </h6>
      <div className="body-item__info-block right-padding">
        {/* <span className="body-item__label">s</span> */}
        <OptionalTextField
          text={orderDetails.address}
          className="body-item__text"
        />
      </div>
    </div>
    <div className="progress-accordion__body-item body-item">
      <h6 className="body-item__title">Contacts</h6>
      <div className="body-item__info-block">
        <span className="body-item__label">Contact name</span>
        <OptionalTextField
          text={orderDetails.contact_name}
          className="body-item__text"
        />
      </div>
      <div className="body-item__info-block">
        <span className="body-item__label">Contact title</span>
        <OptionalTextField
          text={orderDetails.contact_title}
          className="body-item__text"
        />
      </div>
      <div className="body-item__info-block">
        <span className="body-item__label">Contact e-mail address</span>
        <OptionalTextField
          text={orderDetails.contact_email}
          className="body-item__text"
        />
      </div>
      <div className="body-item__info-block">
        <span className="body-item__label">Contact phone number</span>
        <OptionalTextField
          text={orderDetails.contact_phone}
          className="body-item__text"
        />
      </div>
    </div>
  </div>
);

DeveloperInfoPanelBody.propTypes = {
  orderDetails: PropTypes.object.isRequired
};
