import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAuthType, getIsAuthenticated } from "@/store/auth/auth.reducer";
import { AuthTypes, routes } from "@/constants";
import { logout, validateToken } from "@/store/auth/auth.actions";
import { Auth } from "aws-amplify";
import { getUserDetails } from "@/store/account/account.actions";
import axios from "axios";

const RequireAuth = ({ redirectTo }) => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const authType = useSelector(getAuthType);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getUserDetails());
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    if (authType === AuthTypes.EMAIL_PASSWORD) {
      dispatch(validateToken(cancelTokenSource.token));
    } else {
      (async () => {
        try {
          await Auth.currentAuthenticatedUser({
            bypassCache: false
          });
        } catch (error) {
          dispatch(logout());
        }
      })();
    }

    return () => {
      cancelTokenSource?.cancel();
    };
  }, [authType, dispatch]);

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={redirectTo} state={{ from: location }} replace />
  );
};

RequireAuth.defaultProps = {
  redirectTo: routes.LOGIN
};

RequireAuth.propTypes = {
  redirectTo: PropTypes.string
};

export default RequireAuth;
