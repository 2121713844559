import PanelHeader from "@/components/ProjectProgress/components/PanelHeader";
import purify from "dompurify";
import {
  CheckCircleSuccessIcon,
  CircleWarningIcon,
  MoreInfoIcon,
  MoreInfoSuccessIcon
} from "@/icons";
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  getIsMoreInfoAccepted,
  getMoreOrderInfoText
} from "@/store/order/order.reducer";

export const AdditionalInfoPanelHeader = ({ active }) => {
  const isMoreInfoAccepted = useSelector(getIsMoreInfoAccepted);
  return (
    <PanelHeader
      title="Additional information"
      description="We might request additional information before performing the assessment"
      icon={
        isMoreInfoAccepted ? <CheckCircleSuccessIcon /> : <CircleWarningIcon />
      }
      active={active}
      hasButton={false}
    />
  );
};

AdditionalInfoPanelHeader.propTypes = {
  active: PropTypes.bool.isRequired
};

// eslint-disable-next-line
export const AdditionalInfoPanelBody = ({ orderDetails }) => {
  const moreInfoItem = useSelector(getMoreOrderInfoText);
  const isMoreInfoAccepted = useSelector(getIsMoreInfoAccepted);

  return (
    <div className="progress-accordion__body inner row-info-wrapper">
      <div className="row-info-wrapper__icon">
        {isMoreInfoAccepted ? <MoreInfoSuccessIcon /> : <MoreInfoIcon />}
      </div>
      <div className="row-info-wrapper__info">
        <div className="row-info-wrapper__title">
          {isMoreInfoAccepted
            ? "We have all info we need"
            : "Provide more information"}
        </div>
        {isMoreInfoAccepted ? (
          <div className="row-info-wrapper__text">
            We will start the assessment soon
          </div>
        ) : (
          <div
            className="row-info-wrapper__html-text"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: purify.sanitize(moreInfoItem.text)
            }}
          />
        )}
      </div>
    </div>
  );
};

AdditionalInfoPanelBody.propTypes = {
  orderDetails: PropTypes.object.isRequired
};
