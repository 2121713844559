import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { lsKeys, routes, routeTitles } from "@/constants";
import InputField from "@/components/InputField";
import Button from "@/components/Button";
import AnimationImage from "@/components/AnimationImage";
import { isInProcess } from "@/store/pending/pending.reducer";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "@/utils/yup";
import { AccountActions, disableMFA } from "@/store/account/account.actions";

const schema = yup.object({
  code: yup.string().nullable().min(6).max(6).required()
});

const DisableMFA = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state) =>
    isInProcess(state, AccountActions.POST_DISABLE_MFA)
  );

  const {
    register,
    setValue,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data) => {
    try {
      const res = await dispatch(
        disableMFA({
          code: data.code
        })
      );

      if (res && res.message) {
        localStorage.removeItem(lsKeys.MFA_SESSION);
        navigate(routes.MFA_DISABLE_SUCCESS, { replace: true });
      }
    } catch (error) {
      setError("code", {
        type: "custom",
        message:
          error.data?.code?.join("\n") || error.data?.message || "Invalid code"
      });
      // setTimeout(() => {
      //   navigate(routes.SETTINGS);
      // }, 2000);
    }
  };

  return (
    <div className="mfa-disable">
      <form onSubmit={handleSubmit(onSubmit)} className="mfa-disable__form">
        <div className="fields-group">
          <div className="mfa-disable__image">
            <AnimationImage title={routeTitles[routes.MFA]} />
          </div>
          <p className="mfa-disable__text">
            Type the 6-digit code from the authenticator application to verify
            your setup:
          </p>
          <InputField
            setValue={setValue}
            otpInput
            name="code"
            errors={errors}
            type="text"
            placeholder="Code"
            {...register("code")}
          />
        </div>
        <div className="main-layout__drawer-footer">
          <div className="main-layout__drawer-buttons">
            <div className="main-layout__drawer-buttons-item">
              <Button
                isLoading={isLoading}
                type="submit"
                className="btn-default btn-fullwidth"
                text="Disable multi-factor authentication"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DisableMFA;
