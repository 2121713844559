import React from "react";
import PropTypes from "prop-types";
import RadioButton from "@/components/RadioButton";
import "./RadioGroup.scss";

// eslint-disable-next-line react/display-name
const RadioGroup = React.forwardRef(
  (
    {
      label,
      isRequired,
      onChange,
      onBlur,
      values,
      description,
      name,
      errors,
      disabled,
      mod,
      preloadedValue
    },
    ref
  ) => {
    return (
      <div className="form-radio-group form-group">
        {label && (
          <div className="control-top-labels">
            <div className="form-label">
              {label}
              {isRequired && (
                <>
                  &nbsp;<sup>*</sup>
                </>
              )}
            </div>
          </div>
        )}
        {values && (
          <div className="form-radio-group__wrap">
            {values.map((item) => (
              <div
                className="form-radio-group__item"
                key={`${name}_${item.value}`}>
                <RadioButton
                  ref={ref}
                  isCustomButton
                  value={item.value}
                  text={item.text}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  disabled={disabled}
                  mod={mod}
                  isChecked={preloadedValue === item.value}
                />
              </div>
            ))}
          </div>
        )}
        {description && <div className="description-label">{description}</div>}
        {errors[name] && (
          <div className="error-message">
            <sup>*</sup> Field must be checked
          </div>
        )}
      </div>
    );
  }
);

RadioGroup.defaultProps = {
  errors: {}
};

RadioGroup.propTypes = {
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired
    })
  ).isRequired,
  description: PropTypes.string,
  name: PropTypes.string,
  errors: PropTypes.object,
  mod: PropTypes.string,
  disabled: PropTypes.bool,
  preloadedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ])
};

export default RadioGroup;
