import { createAsyncAction } from "@/utils/createAsyncAction";
import AccountService from "@/services/AccountService";

export const AccountActions = {
  GET_USER_DETAILS: "GET_USER_DETAILS",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  POST_PRE_ENABLE_MFA: "POST_PRE_ENABLE_MFA",
  POST_ENABLE_MFA: "POST_ENABLE_MFA",
  POST_DISABLE_MFA: "POST_DISABLE_MFA"
};

export const getUserDetails = createAsyncAction(
  AccountActions.GET_USER_DETAILS,
  () => AccountService.fetchUserDetails()
);

export const changePassword = createAsyncAction(
  AccountActions.CHANGE_PASSWORD,
  async (params) => AccountService.changePassword(params)
);

export const preEnableMFA = createAsyncAction(
  AccountActions.POST_PRE_ENABLE_MFA,
  () => AccountService.postPreEnableMFA()
);

export const enableMFA = createAsyncAction(
  AccountActions.POST_ENABLE_MFA,
  async (params) => AccountService.postEnableMFA(params)
);

export const disableMFA = createAsyncAction(
  AccountActions.POST_DISABLE_MFA,
  async (params) => AccountService.postDisableMFA(params)
);
