import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getIsMitigationsNeeded,
  getIsOrderFinalizedSuccessfully,
  getIsOrderFinalizedUnsuccessfully,
  getIsVerifyingMitigations
} from "@/store/order/order.reducer";
import {
  OrderActions,
  postReadyForRevalidationCasa
} from "@/store/order/order.actions";
import PanelHeader from "@/components/ProjectDetails/components/PanelHeader";
import Button from "@/components/Button";
import ServicePopup from "@/components/ServicePopup";
import {
  CheckCircleSuccessIcon,
  CircleProgressIcon,
  CheckCircleDisabledIcon
} from "@/icons";
import cn from "classnames";
import { Mobile } from "@/components/Responsive";

export const RemediationNavItem = ({ tabIndex, handleTabNavClick }) => {
  const isMitigationsNeeded = useSelector(getIsMitigationsNeeded);
  const isAssessmentSuccessfulFinalized = useSelector(
    getIsOrderFinalizedSuccessfully
  );
  const isAssessmentUnsuccessful = useSelector(
    getIsOrderFinalizedUnsuccessfully
  );
  const isVerifyingMitigations = useSelector(getIsVerifyingMitigations);

  return (
    <button
      className={cn(
        "project-details__nav-item",
        tabIndex === "remediation" && "active"
      )}
      type="button"
      disabled={!isMitigationsNeeded}
      onClick={() => handleTabNavClick("remediation")}>
      {
        // eslint-disable-next-line no-nested-ternary
        isAssessmentSuccessfulFinalized || isAssessmentUnsuccessful ? (
          <CheckCircleSuccessIcon className="project-details__nav-icon" />
        ) : // eslint-disable-next-line no-nested-ternary
        isMitigationsNeeded ? (
          <CircleProgressIcon className="project-details__nav-icon" />
        ) : isVerifyingMitigations ? (
          <CircleProgressIcon className="project-details__nav-icon" />
        ) : (
          <CheckCircleDisabledIcon className="project-details__nav-icon" />
        )
      }
      Remediation
    </button>
  );
};

const Remediation = ({
  orderDetails,
  downloadReport,
  tabIndex,
  handleTabNavClick,
  isLoading
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = orderDetails;
  const [showRevalidationModal, setShowRevalidationModal] = useState(false);
  const [isLoadingRevalidation, setIsLoadingRevalidation] = useState(false);
  const isMitigationsNeeded = useSelector(getIsMitigationsNeeded);
  const isAssessmentSuccessfulFinalized = useSelector(
    getIsOrderFinalizedSuccessfully
  );
  const isAssessmentUnsuccessful = useSelector(
    getIsOrderFinalizedUnsuccessfully
  );
  const isVerifyingMitigations = useSelector(getIsVerifyingMitigations);

  const openRevalidationModal = () => {
    setShowRevalidationModal(true);
  };

  const closeRevalidationModal = () => {
    setShowRevalidationModal(false);
  };

  const handleReadyForRevalidation = async () => {
    setIsLoadingRevalidation(true);
    await dispatch(postReadyForRevalidationCasa(id));

    setIsLoadingRevalidation(false);
    navigate(0);
  };

  return (
    <>
      <Mobile>
        <RemediationNavItem
          tabIndex={tabIndex}
          handleTabNavClick={handleTabNavClick}
        />
      </Mobile>
      {tabIndex === "remediation" && (
        <div className="project-details__tab">
          <PanelHeader
            title={
              isVerifyingMitigations
                ? "Remediation verification in progress"
                : "Remediation needed"
            }
            description={
              isVerifyingMitigations
                ? "We are in the process of verifying the effectiveness of the remediation steps taken. This phase ensures that all identified weaknesses are properly mitigated."
                : "Your CASA findings report is now available for download. The report outlines findings that have to be fixed to complete CASA validation. We kindly urge you to address each identified issue. Upon completion of the necessary fixes, and once you are prepared for a review of the remediated areas, please select the “Ready for Revalidation” option. This will signal our team to re-engage and verify the remediations implemented."
            }
            icon={
              isAssessmentSuccessfulFinalized || isAssessmentUnsuccessful ? (
                <CheckCircleSuccessIcon />
              ) : (
                <CircleProgressIcon />
              )
            }
            hasButton={false}>
            <>
              <Button
                isLoading={isLoading}
                onClick={downloadReport(isMitigationsNeeded?.report)}
                className="project-details__panel-button btn btn-light btn-fullwidth"
                type="button"
                icon="download"
                text="Findings report"
              />
              <Button
                disabled={isVerifyingMitigations}
                isLoading={isLoadingRevalidation}
                text="Ready for revalidation"
                className="project-details__panel-button btn-light btn-fullwidth"
                onClick={openRevalidationModal}
                type="button"
              />
            </>
          </PanelHeader>
          {showRevalidationModal && (
            <ServicePopup
              onClose={closeRevalidationModal}
              onSubmit={handleReadyForRevalidation}
              onSubmitAction={OrderActions.READY_FOR_REVALIDATION}
              title="Ready for revalidation"
              text="Please ensure all identified issues have been addressed and remediated before proceeding. Click Ready to initiate the revalidation of your application."
              submitButtonLabel="Ready"
              closeButtonLabel="Not yet"
            />
          )}
        </div>
      )}
    </>
  );
};

Remediation.propTypes = {
  orderDetails: PropTypes.object.isRequired,
  downloadReport: PropTypes.func,
  tabIndex: PropTypes.string,
  handleTabNavClick: PropTypes.func,
  isLoading: PropTypes.bool
};

RemediationNavItem.propTypes = {
  tabIndex: PropTypes.string,
  handleTabNavClick: PropTypes.func
};

export default Remediation;
