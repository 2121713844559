import { configureStore } from "@reduxjs/toolkit";
import counter from "./counter/counter.reducer";
import pending from "./pending/pending.reducer";
import auth from "./auth/auth.reducer";
import order from "./order/order.reducer";
import account from "./account/account.reducer";
import countries from "./countries/countries.reducer";

export default configureStore({
  reducer: {
    counter,
    pending,
    auth,
    order,
    account,
    countries
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
  devTools: process.env.NODE_ENV === "development"
});
