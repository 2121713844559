// eslint-disable-next-line
import cn from "classnames";
import { MinusIcon, PlusIcon } from "@/icons";
import Button from "@/components/Button";
import { TabletDesktop, Mobile } from "@/components/Responsive";
import React, { useState } from "react";
import PropTypes from "prop-types";
import ThirdPartyService from "@/services/ThirdPartyService";

// eslint-disable-next-line react/prop-types
const PanelHeader = ({
  active,
  title,
  description,
  icon,
  hasButton = true,
  report,
  onClick,
  buttonText
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const downloadReport = (_report) => async (event) => {
    event.stopPropagation();
    setIsLoading(true);
    try {
      await ThirdPartyService.downloadFile({
        url: _report.api_url,
        filename: _report.file_name,
        contentType: _report.content_type
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  return (
    <div className={cn("progress-accordion__button", active)}>
      <div className="progress-accordion__heading-left">
        <div className="progress-accordion__heading-left-icon">{icon}</div>
        <div className="progress-accordion__heading-left-text">
          <h5 className="progress-accordion__heading-title">{title}</h5>
          <div className="progress-accordion__heading-text">{description}</div>
        </div>
        <Mobile>
          {report && (
            <div className="progress-accordion__heading-left-footer">
              <Button
                isLoading={isLoading}
                onClick={downloadReport(report)}
                className="btn-default"
                type="button"
                size="sm"
                text="Download report"
                rel="noreferrer"
              />
            </div>
          )}
          {hasButton && (
            <div className="progress-accordion__heading-left-footer">
              <Button
                onClick={onClick}
                text={buttonText}
                className="btn-default"
                size="sm"
              />
            </div>
          )}
        </Mobile>
      </div>
      <div className="progress-accordion__heading-right">
        <TabletDesktop>
          {report && (
            <Button
              isLoading={isLoading}
              onClick={downloadReport(report)}
              className="btn-default"
              type="button"
              text="Download report"
              rel="noreferrer"
            />
          )}
          {hasButton && (
            <Button
              onClick={onClick}
              text={buttonText}
              className="btn-default"
            />
          )}
        </TabletDesktop>
        {!active ? (
          <Button
            className="progress-accordion__expand-btn expand-plus-icon"
            onClick={() => {}}
            text={<PlusIcon />}
          />
        ) : (
          <Button
            className="progress-accordion__expand-btn expand-minus-icon"
            onClick={() => {}}
            text={<MinusIcon />}
          />
        )}
      </div>
    </div>
  );
};

PanelHeader.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
    .isRequired,
  report: PropTypes.object,
  active: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
  hasButton: PropTypes.bool
};

export default PanelHeader;
