import React, { useEffect, useRef, useState } from "react";
import purify from "dompurify";
import PropTypes from "prop-types";
import { format } from "date-fns";
import cn from "classnames";
import ErrorMessage from "@/components/ErrorMessage";
import "./InputField.scss";
import { useCombinedRefs } from "@/hooks";
import { usePopperTooltip } from "react-popper-tooltip";
import "react-popper-tooltip/dist/styles.css";
import { InfoIcon } from "@/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import OtpInput from "react-otp-input";
import { generatePasswordPlaceholder } from "@/utils/generatePasswordPlaceholder";

// eslint-disable-next-line react/display-name
const InputField = React.forwardRef(
  (
    {
      wrapperClassName,
      inputClassName,
      placeholder,
      name,
      label,
      tooltipContent,
      tooltipTitle,
      errors,
      autoFocus,
      onChange,
      onBlur,
      datePicker,
      datePickerFormat,
      withoutInput,
      multiline,
      readonlyValue,
      control,
      preloadValue,
      otpInput,
      setValue,
      hasPasswordShow,
      ...props
    },
    ref
  ) => {
    const inputRef = useRef(null);
    const combinedRef = useCombinedRefs(ref, inputRef);
    const [controlledVisible, setControlledVisible] = useState(false);
    const [startDate, setStartDate] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [otp, setOtp] = useState("");
    const {
      getArrowProps,
      getTooltipProps,
      setTooltipRef,
      setTriggerRef,
      visible
    } = usePopperTooltip({
      offset: 6,
      trigger: "click",
      closeOnOutsideClick: true,
      interactive: true,
      placement: "bottom-start",
      visible: controlledVisible,
      onVisibleChange: setControlledVisible
    });

    const handleShowPassword = () => {
      setShowPassword(!showPassword);
    };

    useEffect(() => {
      if (autoFocus) {
        combinedRef.current?.focus();
      }
      if (datePicker && preloadValue) {
        setStartDate(new Date(preloadValue));
      }
    }, [autoFocus, combinedRef, datePicker, preloadValue]);

    return (
      <div
        className={cn(
          "form-group",
          wrapperClassName,
          errors[name] ? "has-error" : ""
        )}>
        {label && (
          <div className="control-top-labels">
            <div
              className="form-label"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: purify.sanitize(label) }}
            />
          </div>
        )}
        {!withoutInput && !readonlyValue && (
          <div className="form-field">
            {datePicker ? (
              <Controller
                control={control}
                name={name}
                render={({ field }) => (
                  <DatePicker
                    selected={startDate}
                    ref={combinedRef}
                    className={cn("form-control", inputClassName)}
                    name={name}
                    onChange={(date) => {
                      field.onChange(format(new Date(date), datePickerFormat));
                      setStartDate(date);
                    }}
                    onBlur={onBlur}
                    dateFormat={datePickerFormat}
                    placeholderText={placeholder}
                    autoComplete="off"
                  />
                )}
              />
            ) : (
              <>
                {multiline && (
                  <textarea
                    {...props}
                    rows={multiline}
                    className={cn("form-control", inputClassName)}
                    ref={combinedRef}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    placeholder={placeholder}
                  />
                )}
                {otpInput && (
                  <div className="otp-input-wrap">
                    <OtpInput
                      value={otp}
                      onChange={(value) => {
                        setOtp(value);
                        setValue(name, value);
                      }}
                      numInputs={6}
                      inputStyle="form-control form-control-otp"
                      renderSeparator={<span className="otp-input-separator" />}
                      // eslint-disable-next-line no-shadow
                      renderInput={(props) => <input {...props} />}
                    />
                  </div>
                )}
                {!multiline && !otpInput && (
                  <input
                    {...props}
                    className={cn("form-control", inputClassName)}
                    ref={combinedRef}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    placeholder={placeholder}
                  />
                )}
              </>
            )}
          </div>
        )}
        {readonlyValue && hasPasswordShow ? (
          <div className="form-static-wrap">
            <div className="form-static-input">
              {showPassword
                ? readonlyValue
                : generatePasswordPlaceholder(readonlyValue)}
            </div>
            {hasPasswordShow && (
              <button
                className="form-static-toggle"
                onClick={() => handleShowPassword()}
                type="button">
                {showPassword ? "Hide" : "Show"}
              </button>
            )}
          </div>
        ) : (
          <div
            className="form-static-input"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: purify.sanitize(readonlyValue) }}
          />
        )}
        {tooltipContent && (
          <div ref={setTriggerRef} className="tooltip-title">
            <div className="icon-wrapper">
              <InfoIcon />
            </div>
            <span>{tooltipTitle}</span>
          </div>
        )}
        {visible && (
          <div
            ref={setTooltipRef}
            {...getTooltipProps({ className: "tooltip-container" })}>
            {tooltipContent}
            <div {...getArrowProps({ className: "tooltip-arrow" })} />
          </div>
        )}
        <ErrorMessage errors={errors} name={name} />
      </div>
    );
  }
);

InputField.defaultProps = {
  errors: {}
};

InputField.propTypes = {
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  tooltipContent: PropTypes.node,
  tooltipTitle: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  errors: PropTypes.object,
  datePicker: PropTypes.bool,
  datePickerFormat: PropTypes.string,
  withoutInput: PropTypes.bool,
  multiline: PropTypes.string,
  readonlyValue: PropTypes.string,
  control: PropTypes.object,
  preloadValue: PropTypes.string,
  otpInput: PropTypes.bool,
  setValue: PropTypes.func,
  hasPasswordShow: PropTypes.bool
};

export default InputField;
