import React from "react";
import PropTypes from "prop-types";
import PanelHeader from "@/components/ProjectDetails/components/PanelHeader";
import {
  CheckCircleSuccessIcon,
  CircleProgressIcon,
  CheckCircleDisabledIcon
} from "@/icons";
import cn from "classnames";
import { OrderStatuses } from "@/constants/orderStatuses";
import PaymentChooser from "@/components/ProjectDetails/components/PaymentChooser";
import { Mobile } from "@/components/Responsive";

export const PricingOptionsNavItem = ({
  tabIndex,
  handleTabNavClick,
  orderDetails
}) => {
  const {
    payed_at,
    plane,
    status: { code }
  } = orderDetails;

  return (
    <button
      className={cn(
        "project-details__nav-item",
        tabIndex === "pricing-options" && "active"
      )}
      disabled={
        code === OrderStatuses.CREATED ||
        code === OrderStatuses.SCOPING ||
        !plane.length
      }
      type="button"
      onClick={() => handleTabNavClick("pricing-options")}>
      {
        // eslint-disable-next-line no-nested-ternary
        code === OrderStatuses.CONFIRMING_PURCHASE_ORDER && !payed_at ? (
          <CircleProgressIcon className="project-details__nav-icon" />
        ) : payed_at ? (
          <CheckCircleSuccessIcon className="project-details__nav-icon" />
        ) : (
          <CheckCircleDisabledIcon className="project-details__nav-icon" />
        )
      }
      Pricing options
    </button>
  );
};

const PricingOptions = ({ orderDetails, tabIndex, handleTabNavClick }) => {
  const {
    status: { code },
    payed_at,
    plane
  } = orderDetails;

  return (
    <>
      <Mobile>
        <PricingOptionsNavItem
          tabIndex={tabIndex}
          handleTabNavClick={handleTabNavClick}
          orderDetails={orderDetails}
        />
      </Mobile>
      {tabIndex === "pricing-options" && (
        <div className="project-details__tab">
          {code === OrderStatuses.CONFIRMING_PURCHASE_ORDER ||
          code === OrderStatuses.PURCHASE_ORDER_CONFIRMED ? (
            <PanelHeader
              title="Pricing options"
              description={
                code === OrderStatuses.PURCHASE_ORDER_CONFIRMED
                  ? "Invoicing details have been verified and approved; invoice is being prepared. Assessment will start according to the option you chose."
                  : "Hang tight! Our accounting team will shortly reach out to the Accounts Payable/Accounting contact you provided to finalize invoice details."
              }
              icon={
                // eslint-disable-next-line no-nested-ternary
                code === OrderStatuses.PURCHASE_ORDER_CONFIRMED ? (
                  <CheckCircleSuccessIcon />
                ) : code === OrderStatuses.CONFIRMING_PURCHASE_ORDER ? (
                  <CircleProgressIcon />
                ) : (
                  <CheckCircleDisabledIcon />
                )
              }
              hasButton={false}
            />
          ) : (
            <>
              <PanelHeader
                title="Pricing options"
                description={
                  payed_at
                    ? "We successfully confirmed your payment. Assessment will start according to the option you chose."
                    : "Choose a pricing plan that suits your needs."
                }
                icon={
                  // eslint-disable-next-line no-nested-ternary
                  payed_at ? (
                    <CheckCircleSuccessIcon />
                  ) : code === OrderStatuses.INVOICE_PROCESSING ? (
                    <CircleProgressIcon />
                  ) : (
                    <CheckCircleDisabledIcon />
                  )
                }
                hasButton={false}
              />
              {plane && plane.length !== 0 && !payed_at && (
                <div className="project-details__section">
                  <PaymentChooser orderDetails={orderDetails} />
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

PricingOptions.propTypes = {
  orderDetails: PropTypes.object.isRequired,
  tabIndex: PropTypes.string,
  handleTabNavClick: PropTypes.func
};

PricingOptionsNavItem.propTypes = {
  tabIndex: PropTypes.string,
  handleTabNavClick: PropTypes.func,
  orderDetails: PropTypes.object.isRequired
};

export default PricingOptions;
