/* eslint-disable react/no-danger */
import React from "react";
import purify from "dompurify";
import PropTypes from "prop-types";
import cn from "classnames";
import { NotificationIcon } from "@/icons";
import "./Notification.scss";

const Notification = ({ item }) => {
  return (
    <div className={cn("notification", item.new && "new")}>
      <div className="notification__wrap">
        <div className="notification__icon">
          <NotificationIcon className="notification__icon-i" />
        </div>
        <div className="notification__aside">
          {item.title && (
            <div
              className="notification__title"
              dangerouslySetInnerHTML={{ __html: purify.sanitize(item.title) }}
            />
          )}
          {item.date && <div className="notification__date">{item.date}</div>}
        </div>
      </div>
    </div>
  );
};

Notification.propTypes = {
  item: PropTypes.object
};

export default Notification;
