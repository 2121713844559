import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { routes } from "@/constants";
import ProjectTiles from "@/components/ProjectTiles";
import ProjectProgress from "@/components/ProjectProgress";
import ProjectDetails from "@/components/ProjectDetails";
import Faq from "@/components/Faq";
import Settings from "@/components/Settings";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrders } from "@/store/order/order.actions";
import { getOrderList } from "@/store/order/order.reducer";

const RootPage = () => {
  const dispatch = useDispatch();
  const orderList = useSelector(getOrderList);

  useEffect(() => {
    dispatch(getAllOrders());
  }, [dispatch]);

  if (!orderList) {
    return null;
  }

  return (
    <Routes>
      <Route index element={<ProjectTiles orders={orderList} />} />
      <Route path="faq" element={<Faq />} />
      <Route path="settings" element={<Settings />} />
      <Route path="project-progress" element={<Navigate to={routes.ROOT} />} />
      <Route path="project-progress/:id" element={<ProjectProgress />} />

      <Route path="project-details/:id" element={<ProjectDetails />} />
    </Routes>
  );
};

export default RootPage;
