import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { IconBack, IconForward, DownloadIcon } from "@/icons";
import "./Button.scss";

const BUTTON_DIRECTIONS = {
  BACK: "back",
  FORWARD: "forward",
  DOWNLOAD: "download"
};

// eslint-disable-next-line react/display-name
const Button = React.forwardRef(
  (
    {
      type,
      icon,
      btnIcon,
      disabled,
      isLoading,
      size,
      text,
      className,
      onClick,
      ...props
    },
    ref
  ) => {
    return (
      <button
        {...props}
        className={cn(
          "btn",
          size && `btn-${size}`,
          disabled ? "disabled" : "",
          className,
          { loading: isLoading }
        )}
        type={type}
        disabled={disabled}
        onClick={onClick}
        ref={ref}>
        {isLoading && <div className="spinner" />}
        <div className="btn__inner-content">
          {icon === BUTTON_DIRECTIONS.BACK && (
            <IconBack
              className={
                icon === BUTTON_DIRECTIONS.BACK ? "icon-back" : "icon-forward"
              }
            />
          )}
          {icon === BUTTON_DIRECTIONS.DOWNLOAD && (
            <DownloadIcon className="btn__icon icon-download" />
          )}
          {btnIcon}
          {text}
          {icon === BUTTON_DIRECTIONS.FORWARD && (
            <IconForward
              className={
                icon === BUTTON_DIRECTIONS.BACK ? "icon-back" : "icon-forward"
              }
            />
          )}
        </div>
      </button>
    );
  }
);

Button.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.string, // back | forward | null | <Icon />
  btnIcon: PropTypes.node,
  onClick: PropTypes.func,
  text: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string, // sm, md, lg
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default Button;
