import React from "react";
import PropTypes from "prop-types";
import { generatePath, useNavigate } from "react-router-dom";
import InputField from "@/components/InputField";
import PanelHeader from "@/components/ProjectDetails/components/PanelHeader";
import {
  CheckCircleSuccessIcon,
  CheckCircleDisabledIcon,
  FileIcon
} from "@/icons";
import { Mobile } from "@/components/Responsive";
import cn from "classnames";
import { OrderStatuses } from "@/constants/orderStatuses";
import { routes } from "@/constants";

export const TestingDetailsNavItem = ({
  tabIndex,
  handleTabNavClick,
  orderDetails
}) => {
  const { application_name } = orderDetails;

  return (
    <button
      className={cn(
        "project-details__nav-item",
        tabIndex === "testing-details" && "active"
      )}
      type="button"
      disabled={!application_name}
      onClick={() => handleTabNavClick("testing-details")}>
      {application_name ? (
        <CheckCircleSuccessIcon className="project-details__nav-icon" />
      ) : (
        <CheckCircleDisabledIcon className="project-details__nav-icon" />
      )}
      Testing details
    </button>
  );
};

const TestingDetails = ({ orderDetails, tabIndex, handleTabNavClick }) => {
  const navigate = useNavigate();
  const {
    id,
    status: { code },
    tier,
    application_name,
    application_url,
    gcp_project,
    has_login,
    app_login,
    app_password,
    due_date,
    app_filename,
    app_size,
    src_filename,
    src_size,
    number_endpoints,
    number_repositories,
    loc_sloc,
    cyclomatic_complexity,
    halstead_difficulty,
    halstead_length,
    halstead_calculated_length,
    halstead_volume,
    halstead_effort,
    halstead_vocabulary,
    custom_metrics,
    code_churn,
    code_coverage,
    code_dependencies,
    code_reuse,
    maintainability_index,
    your_metrics_code_quality
  } = orderDetails;

  const getTierTitle = (tierVal) => {
    switch (tierVal) {
      case "Tier 2":
        return "CASA Tier 2 (Lab Tested - Lab Verified)";
      case "Tier 3":
        return "CASA Tier 3 (Lab Tested - Lab Verified)";
      default:
        return " ";
    }
  };

  const renderFileView = (fileName, fileSize) => {
    return (
      <div className="project-details__file">
        <div className="project-details__file-icon">
          <FileIcon className="project-details__file-icon-i" />
        </div>
        <div className="project-details__file-wrap">
          {fileName && (
            <div className="project-details__file-name">{fileName}</div>
          )}
          {fileSize && (
            <div className="project-details__file-size">{fileSize}</div>
          )}
        </div>
      </div>
    );
  };

  const onTestingDetailsClick = () => {
    navigate(generatePath(routes.ORDER_CASA_APPLICATION_INFO, { id }));
  };

  return (
    <>
      <Mobile>
        <TestingDetailsNavItem
          tabIndex={tabIndex}
          handleTabNavClick={handleTabNavClick}
          orderDetails={orderDetails}
        />
      </Mobile>
      {tabIndex === "testing-details" && (
        <div className="project-details__tab">
          <PanelHeader
            title="Testing details"
            description="We appreciate the details you've shared. Our team is reviewing the assessment's scope, and we'll return with a personalized quote shortly."
            icon={
              application_name && tier ? (
                <CheckCircleSuccessIcon />
              ) : (
                <CheckCircleDisabledIcon />
              )
            }
            hasButton={code === OrderStatuses.CREATED}
            buttonText="Continue"
            onClick={onTestingDetailsClick}
          />
          <div className="project-details__section">
            <div className="fields-group">
              <div className="fields-group-title">Application details</div>
              {application_name && (
                <InputField
                  label="Application name <sup>*</sup>"
                  name="application_name"
                  readonlyValue={application_name}
                />
              )}
              {application_url && (
                <InputField
                  label="Application URL <sup>*</sup>"
                  name="application_url"
                  readonlyValue={application_url}
                />
              )}
              {gcp_project && (
                <InputField
                  label="GCP Project #"
                  name="gcp_project"
                  readonlyValue={gcp_project}
                />
              )}
              {has_login && (
                <>
                  <div className="fields-group-subtitle">
                    Application has login functionality
                  </div>
                  {app_login && (
                    <InputField
                      label="Application login"
                      name="app_login"
                      readonlyValue={app_login}
                    />
                  )}
                  {app_password && (
                    <InputField
                      label="Application password"
                      name="app_password"
                      readonlyValue={app_password}
                      hasPasswordShow
                    />
                  )}
                </>
              )}
            </div>
            {(tier || due_date || app_filename) && (
              <div className="fields-group">
                <div className="fields-group-title">Assessment details</div>
                {tier && (
                  <InputField
                    label="Tier <sup>*</sup>"
                    readonlyValue={getTierTitle(tier)}
                    name={tier}
                  />
                )}
                {due_date && (
                  <InputField
                    label="Due date for assessment completion"
                    readonlyValue={due_date}
                    name={due_date}
                  />
                )}
                {app_filename && (
                  <div className="form-group">
                    <div className="form-label form-label_bottom-offset">
                      Screenshot of email received from Google regarding
                      assessment (due date and assigned tier must be clearly
                      visible)
                    </div>
                    {renderFileView(app_filename, app_size)}
                  </div>
                )}
              </div>
            )}
            {src_filename && (
              <div className="fields-group">
                <div className="fields-group-title">Source code details</div>
                {renderFileView(src_filename, src_size)}
              </div>
            )}
            {(number_endpoints ||
              number_repositories ||
              loc_sloc ||
              cyclomatic_complexity ||
              halstead_difficulty ||
              halstead_length ||
              halstead_calculated_length ||
              halstead_volume ||
              halstead_effort ||
              halstead_vocabulary ||
              custom_metrics) && (
              <div className="fields-group">
                <div className="fields-group-title">
                  Code complexity measurements
                </div>
                {number_endpoints && (
                  <InputField
                    label="Number of API endpoints"
                    name="number_endpoints"
                    readonlyValue={number_endpoints}
                  />
                )}
                {number_repositories && (
                  <InputField
                    label="Number of repositories"
                    name="number_repositories"
                    readonlyValue={number_repositories}
                  />
                )}
                {loc_sloc && (
                  <InputField
                    label="Lines of code (LOC)/ Source lines of code (SLOC)"
                    multiline="2"
                    name="loc_sloc"
                    readonlyValue={loc_sloc}
                  />
                )}
                {cyclomatic_complexity && (
                  <InputField
                    label="Cyclomatic complexity (CC)"
                    name="cyclomatic_complexity"
                    readonlyValue={cyclomatic_complexity}
                  />
                )}
                {halstead_difficulty && (
                  <InputField
                    label="Halstead Difficulty (D)"
                    name="halstead_difficulty"
                    readonlyValue={halstead_difficulty}
                  />
                )}
                {halstead_length && (
                  <InputField
                    label="Halstead Length (N)"
                    name="halstead_length"
                    readonlyValue={halstead_length}
                  />
                )}
                {halstead_calculated_length && (
                  <InputField
                    label="Halstead Calculated Length (Nx)"
                    name="halstead_calculated_length"
                    readonlyValue={halstead_calculated_length}
                  />
                )}
                {halstead_volume && (
                  <InputField
                    label="Halstead Volume (V)"
                    name="halstead_volume"
                    readonlyValue={halstead_volume}
                  />
                )}
                {halstead_effort && (
                  <InputField
                    label="Halstead Effort (E)"
                    name="halstead_effort"
                    readonlyValue={halstead_effort}
                  />
                )}
                {halstead_vocabulary && (
                  <InputField
                    label="Halstead Vocabulary (n)"
                    name="halstead_vocabulary"
                    readonlyValue={halstead_vocabulary}
                  />
                )}
                {custom_metrics && (
                  <InputField
                    label="Custom metrics"
                    multiline="2"
                    name="custom_metrics"
                    readonlyValue={custom_metrics}
                  />
                )}
              </div>
            )}
            {(code_churn ||
              code_coverage ||
              code_dependencies ||
              code_reuse ||
              maintainability_index ||
              your_metrics_code_quality) && (
              <div className="fields-group">
                <div className="fields-group-title">
                  Code quality measurements
                </div>
                {code_churn && (
                  <InputField
                    label="Code churn"
                    multiline="2"
                    name="code_churn"
                    readonlyValue={code_churn}
                  />
                )}
                {code_coverage && (
                  <InputField
                    label="Code coverage"
                    multiline="2"
                    name="code_coverage"
                    readonlyValue={code_coverage}
                  />
                )}
                {code_dependencies && (
                  <InputField
                    label="Code dependencies"
                    name="code_dependencies"
                    readonlyValue={code_dependencies}
                  />
                )}
                {code_reuse && (
                  <InputField
                    label="Code reuse"
                    name="code_reuse"
                    readonlyValue={code_reuse}
                  />
                )}
                {maintainability_index && (
                  <InputField
                    label="Maintainability index"
                    name="maintainability_index"
                    readonlyValue={maintainability_index}
                  />
                )}
                {your_metrics_code_quality && (
                  <InputField
                    label="Custom metrics"
                    multiline="2"
                    name="your_metrics_code_quality"
                    readonlyValue={your_metrics_code_quality}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

TestingDetails.propTypes = {
  orderDetails: PropTypes.object.isRequired,
  tabIndex: PropTypes.string,
  handleTabNavClick: PropTypes.func
};

TestingDetailsNavItem.propTypes = {
  tabIndex: PropTypes.string,
  handleTabNavClick: PropTypes.func,
  orderDetails: PropTypes.object.isRequired
};

export default TestingDetails;
