/* eslint-disable import/extensions */
import React, { useEffect, useState } from "react";
import "./ProjectProgress.scss";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetailsById } from "@/store/order/order.actions";
import {
  getAssessmentCompletedDetails,
  getMoreOrderInfoText,
  getOrderDetails
} from "@/store/order/order.reducer";
import Collapse, { Panel } from "rc-collapse";
import motion from "@/utils/motionUtil.js";
import "rc-collapse/assets/index.css";
import {
  AdditionalInfoPanelHeader,
  AdditionalInfoPanelBody
} from "@/components/ProjectProgress/components/AdditionalInfoPanel";
import {
  ApplicationInfoPanelHeader,
  ApplicationInfoPanelBody
} from "@/components/ProjectProgress/components/ApplicationInfoPanel";
import {
  VerificationPanelHeader,
  VerificationPanelBody
} from "@/components/ProjectProgress/components/VerificationPanel";
import {
  PaymentPanelBody,
  PaymentPanelHeader
} from "@/components/ProjectProgress/components/PaymentPanel";
import {
  AssessmentPanelBody,
  AssessmentPanelHeader
} from "@/components/ProjectProgress/components/AssessmentPanel";
import {
  MitigationsBody,
  MitigationsHeader
} from "@/components/ProjectProgress/components/MitigationsPanel";
import {
  AssessmentCompletedPanelBody,
  AssessmentCompletedPanelHeader
} from "@/components/ProjectProgress/components/AssessmentCompletedPanel";
import {
  DeveloperInfoPanelBody,
  DeveloperInfoPanelHeader
} from "@/components/ProjectProgress/components/DeveloperInfoPanel";

function random() {
  return parseInt((Math.random() * 10).toString(), 10) + 1;
}

const ProjectProgress = () => {
  const orderDetails = useSelector(getOrderDetails);
  const moreInfoItem = useSelector(getMoreOrderInfoText);
  const assessmentCompleted = useSelector(getAssessmentCompletedDetails);
  const { id } = useParams();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    time: random(),
    accordion: false,
    activeKey: [],
    collapsible: undefined
  });

  const onChange = (activeKey) => {
    setState((prev) => ({
      ...prev,
      activeKey
    }));
  };

  useEffect(() => {
    if (id) {
      dispatch(getOrderDetailsById(id));
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (assessmentCompleted) {
      setState((prev) => ({
        ...prev,
        activeKey: ["7"]
      }));
    }
  }, [assessmentCompleted]);

  if (!orderDetails) {
    return null;
  }

  return (
    <div className="project-progress">
      {orderDetails.package_name && (
        <h2 className="project-progress__title">{orderDetails.package_name}</h2>
      )}
      <div className="project-progress__items-wrapper">
        <Collapse
          className="progress-accordion"
          collapsible={state.collapsible}
          accordion={state.accordion}
          key={state.activeKey.join(",")}
          onChange={onChange}
          activeKey={state.activeKey}
          openMotion={motion}>
          <Panel
            key="0"
            header={
              <DeveloperInfoPanelHeader
                active={state.activeKey.includes("0")}
              />
            }
            headerClass="panel-header">
            <DeveloperInfoPanelBody orderDetails={orderDetails} />
          </Panel>
          <Panel
            key="1"
            header={
              <ApplicationInfoPanelHeader
                active={state.activeKey.includes("1")}
              />
            }
            headerClass="panel-header">
            <ApplicationInfoPanelBody orderDetails={orderDetails} />
          </Panel>
          <Panel
            key="2"
            header={
              <VerificationPanelHeader active={state.activeKey.includes("2")} />
            }
            headerClass="panel-header">
            <VerificationPanelBody orderDetails={orderDetails} />
          </Panel>
          <Panel
            key="3"
            header={
              <PaymentPanelHeader active={state.activeKey.includes("3")} />
            }
            headerClass="panel-header">
            <PaymentPanelBody orderDetails={orderDetails} />
          </Panel>
          {moreInfoItem && (
            <Panel
              key="4"
              header={
                <AdditionalInfoPanelHeader
                  active={state.activeKey.includes("4")}
                />
              }
              headerClass="panel-header">
              <AdditionalInfoPanelBody orderDetails={orderDetails} />
            </Panel>
          )}
          <Panel
            key="5"
            header={
              <AssessmentPanelHeader active={state.activeKey.includes("5")} />
            }
            headerClass="panel-header">
            <AssessmentPanelBody orderDetails={orderDetails} />
          </Panel>
          <Panel
            key="6"
            header={
              <MitigationsHeader active={state.activeKey.includes("6")} />
            }
            headerClass="panel-header">
            <MitigationsBody orderDetails={orderDetails} />
          </Panel>
          <Panel
            key="7"
            header={
              <AssessmentCompletedPanelHeader
                active={state.activeKey.includes("7")}
              />
            }
            headerClass="panel-header">
            <AssessmentCompletedPanelBody orderDetails={orderDetails} />
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default ProjectProgress;
