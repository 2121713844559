import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import yup from "@/utils/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { OrderActions, startQuestionnaire } from "@/store/order/order.actions";
import { getOrderDetails } from "@/store/order/order.reducer";
import { isInProcess } from "@/store/pending/pending.reducer";
import { routes, routeActions } from "@/constants";
import RadioGroup from "@/components/RadioGroup";
import Button from "@/components/Button";

const schema = yup.object({
  mobile_frameworks_used: yup.number().required()
});

const QuestionnaireStart = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const isLoading = useSelector((state) =>
    isInProcess(state, OrderActions.UPDATE_QUESTIONNAIRE)
  );
  const navigate = useNavigate();
  const orderDetails = useSelector(getOrderDetails);
  const preloadedValues = orderDetails;

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      mobile_frameworks_used: preloadedValues.mobile_frameworks_used ?? null
    }
  });

  const onSubmit = async (data) => {
    const res = await dispatch(startQuestionnaire({ id, ...data }));

    const path = generatePath(routes.ORDER_QUESTIONNAIRE_BY_ID, {
      id: res.id,
      action: routeActions.fill
    });
    navigate(path);
  };

  const mobileFrameworksUsedValue = watch("mobile_frameworks_used");

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="order-creation">
      <div className="fields-group">
        <RadioGroup
          label="Does your application utilize mobile development frameworks such as React Native, Flutter, or Xamarin?"
          isRequired
          preloadedValue={
            // eslint-disable-next-line no-nested-ternary
            orderDetails.mobile_frameworks_used
          }
          values={[
            {
              text: "Yes",
              value: 1
            },
            {
              text: "No",
              value: 0
            }
          ]}
          name="mobile_frameworks_used"
          errors={errors}
          {...register("mobile_frameworks_used")}
        />
      </div>
      <div className="main-layout__drawer-footer">
        <Button
          isLoading={isLoading}
          type="submit"
          className="btn-default btn-fullwidth"
          disabled={!mobileFrameworksUsedValue}
          text="Continue"
        />
      </div>
    </form>
  );
};

export default QuestionnaireStart;
