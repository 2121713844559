import React, { useEffect } from "react";
import {
  ApplicationInfo,
  AssessmentInfo,
  Payment,
  PaymentType,
  OrderCreation,
  SourceCodeInfo,
  Agreement
} from "@/components/OrderStepsCasa";
import SuccessBox from "@/components/SuccessBox";
import { Routes, Route, useParams, Outlet, Navigate } from "react-router-dom";
import { DrawerLayout } from "@/layouts";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetails } from "@/store/order/order.reducer";
import { getOrderCasaDetailsById } from "@/store/order/order.actions";
import { routes } from "@/constants";

const DetailsSupplier = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const orderDetails = useSelector(getOrderDetails);

  useEffect(() => {
    if (id) {
      dispatch(getOrderCasaDetailsById(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (id && !orderDetails) {
    return null;
  }

  return <Outlet />;
};

const OrderPage = () => {
  return (
    <Routes>
      <Route element={<DetailsSupplier />}>
        <Route element={<DrawerLayout />}>
          <Route index element={<Navigate to={routes.NEW_ORDER_CASA} />} />
          <Route path="/:id" element={<OrderCreation />} />
          <Route path="/:id/application-info" element={<ApplicationInfo />} />
          <Route path="/:id/assessment-info" element={<AssessmentInfo />} />
          <Route path="/:id/source-code-info" element={<SourceCodeInfo />} />
          <Route
            path="/:id/success"
            element={
              <SuccessBox
                title="Successfully submitted"
                subtitle="Thank you for your submission. <br/>We will return with a personalized quote shortly."
                button={{
                  label: "Go to dashboard",
                  url: routes.ROOT
                }}
              />
            }
          />

          <Route path="/:id/agreement" element={<Agreement />} />
          {/* payment */}
          <Route path="/:id/payment" element={<Payment />} />
          <Route path="/:id/payment-type" element={<PaymentType />} />
          <Route
            path="/:id/payment-success"
            element={
              <SuccessBox
                title="Payment received"
                subtitle="Your payment has been successfully processed. The assessment process will unfold based on the plan you have opted for!"
                button={{
                  label: "Go to dashboard",
                  url: routes.ROOT
                }}
              />
            }
          />
          <Route
            path="/:id/invoice-success"
            element={
              <SuccessBox
                title="Invoicing information received"
                subtitle="Thank you for providing the invoicing details. Our team is processing the information, and we will be in touch shortly."
                button={{
                  label: "Go to dashboard",
                  url: routes.ROOT
                }}
              />
            }
          />
        </Route>
      </Route>
    </Routes>
  );
};

export default OrderPage;
