import React from "react";
import { useParams, generatePath, useNavigate } from "react-router-dom";
import Button, { ButtonLink } from "@/components/Button";
import { PropTypes } from "prop-types";
import "./OrderVerification.scss";
import { routes } from "@/constants";
import {
  OrderActions,
  sendVerificationInfoManual
} from "@/store/order/order.actions";
import { useDispatch, useSelector } from "react-redux";
import { isInProcess } from "@/store/pending/pending.reducer";
import { getIsOrderPaid } from "@/store/order/order.reducer";

const ManualVerification = ({ backLink }) => {
  const isLoading = useSelector((state) =>
    isInProcess(state, OrderActions.SEND_VERIFICATION_INFO_MANUAL)
  );
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const isPaidOrder = useSelector(getIsOrderPaid);

  const handleContinue = async () => {
    await dispatch(
      sendVerificationInfoManual({ id, is_manual_verification: true })
    );

    navigate(
      generatePath(isPaidOrder ? routes.PROJECT_PROGRESS : routes.AGREEMENT, {
        id
      })
    );
  };

  return (
    <div className="order-verify">
      <div className="fields-group">
        <div className="fields-group-title">Manual verification</div>
      </div>
      <div className="fields-group-subtitle">
        We will contact you to the email address you provided and will manually
        work with you to verify your authority over the application. Note that
        it will take more time.
      </div>
      <div className="main-layout__drawer-footer">
        <div className="main-layout__drawer-buttons">
          {backLink && !isPaidOrder && (
            <div className="main-layout__drawer-buttons-item">
              <ButtonLink
                text="Back"
                className="btn-light btn-fullwidth"
                to={backLink}
              />
            </div>
          )}
          <div className="main-layout__drawer-buttons-item">
            <Button
              isLoading={isLoading}
              onClick={handleContinue}
              text="Continue"
              className="btn-default btn-fullwidth"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ManualVerification.propTypes = {
  backLink: PropTypes.string
};

export default ManualVerification;
