import React, { useState } from "react";
import InputField from "@/components/InputField";
import Button from "@/components/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "@/utils/yup";
import PropTypes from "prop-types";
import { routes } from "@/constants";
import AlertMessage from "@/components/AlertMessage";
import { Navigate, useSearchParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AuthActions, resetPassword } from "@/store/auth/auth.actions";
import { isInProcess } from "@/store/pending/pending.reducer";
import PasswordValidityRules from "@/components/PasswordValidityRules";

const resetPasswordFormSchema = yup.object({
  code: yup.string().nullable().max(255).required(),
  password: yup
    .string()
    .min(8)
    .max(99)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-"!@#%&/,><':;_~`])\S{8,99}$/,
      "Not appropriate password format"
    )
    .required(),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
});

const ResetPasswordForm = ({ onAfterSubmit }) => {
  const dispatch = useDispatch();
  const [isFocused, setIsFocused] = useState(false);
  const isLoading = useSelector((state) =>
    isInProcess(state, AuthActions.RESET_PASSWORD)
  );
  const [feedback, setFeedback] = useState(null);
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(resetPasswordFormSchema)
  });
  const passwordWatch = watch("password", "");

  const [searchParams] = useSearchParams();

  const onSubmit = async (data) => {
    setFeedback(null);
    try {
      const email = searchParams.get("email") ?? "";

      await dispatch(resetPassword({ ...data, email }));

      if (onAfterSubmit) {
        onAfterSubmit({ ...data, email });
      }
      // eslint-disable-next-line no-empty
    } catch (e) {
      setFeedback({
        type: "error",
        text: e?.data?.message || "Reset password failed. Something went wrong."
      });
    }
  };

  if (!searchParams.get("email")) {
    return <Navigate to={routes.FORGOT_PASSWORD} replace />;
  }

  return (
    <form className="forgot-password-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="forgot-password-form__head">
        <div className="forgot-password-form__title">Reset your password</div>
        <div className="forgot-password-form__text">
          Please fill in confirmation code sent to your email and type a new
          password
        </div>
      </div>
      {feedback && (
        <div className="fields-group">
          <AlertMessage type={feedback?.type} text={feedback?.text} />
        </div>
      )}
      <div className="fields-group">
        <InputField
          label="Confirmation code"
          name="code"
          type="text"
          errors={errors}
          {...register("code")}
        />
      </div>
      <div className="fields-group">
        <InputField
          name="password"
          type="password"
          onFocus={() => setIsFocused(true)}
          label="New password"
          errors={errors}
          {...register("password")}
        />
        {isFocused && <PasswordValidityRules password={passwordWatch} />}
      </div>
      <div className="fields-group">
        <InputField
          name="password_confirmation"
          label="Confirm new password"
          type="password"
          errors={errors}
          {...register("password_confirmation")}
        />
      </div>
      <div className="fields-group">
        <Button
          isLoading={isLoading}
          text="Reset your password"
          className="btn-default btn-fullwidth"
          type="submit"
        />
      </div>
      <div className="forgot-password-form__info">
        Take me back to&nbsp;
        <Link to={routes.FORGOT_PASSWORD}>Forgot password</Link>
      </div>
    </form>
  );
};

ResetPasswordForm.propTypes = {
  onAfterSubmit: PropTypes.func
};

export default ResetPasswordForm;
