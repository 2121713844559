import { createAction } from "@reduxjs/toolkit";
import { createAsyncAction } from "@/utils/createAsyncAction";

export const CounterActions = {
  INCREMENT: "INCREMENT",
  INCREMENT_ASYNC: "INCREMENT_ASYNC",
  DECREMENT: "DECREMENT",
  INCREMENT_BY_AMOUNT: "INCREMENT_BY_AMOUNT"
};

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const increment = createAction(CounterActions.INCREMENT);
export const decrement = createAction(CounterActions.DECREMENT);
export const incrementByAmount = createAction(
  CounterActions.INCREMENT_BY_AMOUNT
);

export const incrementAsync = createAsyncAction(
  CounterActions.INCREMENT_ASYNC,
  async (count) => {
    await delay(4000);

    return count;
  }
);
