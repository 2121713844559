export const MAX_APK_FILE_SIZE_IN_BYTES =
  1024 *
  1024 *
  (parseInt(process.env.REACT_APP_MAX_APK_SIZE_IN_MEGABYTES, 10) || 150);

export const MAX_APK_FILE_SIZE_IN_MEGABYTES =
  parseInt(process.env.REACT_APP_MAX_APK_SIZE_IN_MEGABYTES, 10) || 150;

export const APK_CHUNK_SIZE_IN_BYTES =
  1024 *
  1024 *
  (parseInt(process.env.REACT_APP_APK_CHUNK_SIZE_IN_MEGABYTES, 10) ?? 1);

export const APK_UPLOAD_TIMEOUT_IN_MS =
  (parseInt(process.env.REACT_APP_APK_UPLOAD_TIMEOUT_IN_SECONDS, 10) || 10) *
  1000;

export const UPLOAD_MAX_CHUNK_RETRIES_COUNT =
  parseInt(process.env.REACT_APP_UPLOAD_MAX_CHUNK_RETRIES, 10) || 5;

export const MAX_TXT_FILE_SIZE_IN_MEGABYTES =
  parseInt(process.env.REACT_APP_MAX_TXT_FILE_SIZE_IN_MEGABYTES, 10) || 5;

export const MAX_SCREENSHOT_FILE_SIZE_IN_MEGABYTES =
  parseInt(process.env.REACT_APP_MAX_SCREENSHOT_FILE_SIZE_IN_MEGABYTES, 10) ||
  10;

export const MAX_DOC_FILE_SIZE_IN_MEGABYTES =
  parseInt(process.env.REACT_APP_MAX_DOC_FILE_SIZE_IN_MEGABYTES, 10) || 10;
