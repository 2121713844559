import React, { useState } from "react";
import InputField from "@/components/InputField";
import Button from "@/components/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "@/utils/yup";
import PropTypes from "prop-types";
import { routes } from "@/constants";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AuthActions, sendResetCode } from "@/store/auth/auth.actions";
import AlertMessage from "@/components/AlertMessage";
import { isInProcess } from "@/store/pending/pending.reducer";
import "./ForgotPasswordForm.scss";

const emailAddressSchema = yup.object({
  email: yup.string().nullable().max(255).email().required()
});

const EmailForm = ({ onAfterSubmit }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) =>
    isInProcess(state, AuthActions.SEND_RESET_CODE)
  );
  const [feedback, setFeedback] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(emailAddressSchema)
  });

  const onSubmit = async (data) => {
    setFeedback(null);
    try {
      await dispatch(sendResetCode(data.email));
      setFeedback({
        type: "success",
        text: "We’ve send an email with the confirmation code"
      });

      if (onAfterSubmit) {
        setTimeout(() => {
          onAfterSubmit(data.email);
        }, 2 * 1000);
      }
      // eslint-disable-next-line no-empty
    } catch (e) {
      setFeedback({
        type: "error",
        text: e?.data?.message || "Something went wrong."
      });
    }
  };

  return (
    <form className="forgot-password-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="forgot-password-form__head">
        <div className="forgot-password-form__title">Forgot password?</div>
        <div className="forgot-password-form__text">
          Fill in your email and we’ll send you a link to reset your password
        </div>
      </div>
      {feedback && (
        <div className="fields-group">
          <AlertMessage type={feedback?.type} text={feedback?.text} />
        </div>
      )}
      <div className="fields-group">
        <InputField
          name="email"
          type="email"
          label="Email"
          errors={errors}
          placeholder="example@mail.com"
          {...register("email")}
        />
      </div>
      <div className="fields-group">
        <Button
          isLoading={isLoading}
          text="Send reset email"
          className="btn-default btn-fullwidth"
          type="submit"
        />
      </div>
      <div className="forgot-password-form__info">
        Take me back to&nbsp;
        <Link to={routes.LOGIN}>Login page</Link>
      </div>
    </form>
  );
};

EmailForm.propTypes = {
  onAfterSubmit: PropTypes.func
};

export default EmailForm;
