import React, { useState, useEffect, useRef, useCallback } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { useSelector } from "react-redux";
import TagManager from "react-gtm-module";
import { useCookies } from "react-cookie";
import { getAccountEmail } from "@/store/account/account.reducer";
import "./CookiesPopup.scss";

const CookiesPopup = () => {
  const [isOpenedSettings, setIsOpenedSettings] = useState(false);
  const [isShowBlock, setIsShowBlock] = useState(true);
  const [cookieAnalytics, setCookieAnalytics] = useState(true);
  const analyticsCheckbox = useRef(null);
  const [cookies, setCookie] = useCookies(["gdprMasa"]);
  const accountEmail = useSelector(getAccountEmail);

  const initAllAnalytics = useCallback((userEmail) => {
    if (process.env.REACT_APP_NODE_ENV !== "stage") {
      datadogRum.init({
        applicationId: process.env.REACT_APP_DATADOG_APP_ID,
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
        site: "datadoghq.com",
        service: "assessment-hub",
        env: "prod",
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "allow"
      });
      datadogRum.startSessionReplayRecording();
      datadogRum.setUser({ email: userEmail });

      const tagManagerArgs = { gtmId: process.env.REACT_APP_GTM };
      TagManager.initialize(tagManagerArgs);
    }
  }, []);

  const handleToggleSettings = () => {
    setIsOpenedSettings(!isOpenedSettings);
  };

  const setCookies = useCallback(() => {
    const settings = {
      analytics: `${cookieAnalytics}`
    };
    setCookie("gdprMasa", JSON.stringify(settings), {
      path: "/",
      maxAge: "2592000" // 30 days
    });
    setIsShowBlock(false);

    if (cookieAnalytics) {
      initAllAnalytics();
    }
  }, [initAllAnalytics, cookieAnalytics, setCookie]);

  const handleCheckboxClick = () => {
    setCookieAnalytics(analyticsCheckbox.current.checked);
  };

  const handleSaveSettings = () => {
    setCookies();
  };

  const setAllCookies = () => {
    setCookieAnalytics(true);
    setCookies();
  };

  useEffect(() => {
    const storage = cookies.gdprMasa;

    if (storage) {
      setIsShowBlock(false);
      if (storage.analytics === "true" && accountEmail) {
        initAllAnalytics(accountEmail);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountEmail]);

  return (
    isShowBlock && (
      <div className="cookies-popup">
        {!isOpenedSettings && (
          <div className="cookies-popup__inner">
            <div className="cookies-popup__content">
              <div className="cookies-popup__title">
                Our website uses cookies
              </div>
              <div className="cookies-popup__text">
                Thank you for using Assessment Hub. The information that`s
                shared with us helps to provide a great experience for you.
              </div>
            </div>
            <div className="cookies-popup__buttons">
              <button
                className="cookies-popup__button btn btn-default"
                type="button"
                onClick={setAllCookies}>
                Accept All
              </button>
              <button
                className="cookies-popup__button btn btn-link-default"
                type="button"
                onClick={handleToggleSettings}>
                Manage Preferences
              </button>
            </div>
          </div>
        )}
        {isOpenedSettings && (
          <div className="cookies-popup__settings">
            <div className="cookies-popup__settings-body">
              <div className="cookies-popup__settings-title">
                Cookies Preferences
              </div>
              <div className="cookies-popup__settings-text">
                When you visit websites, they may store or retrieve data in your
                browser. This storage is often necessary for the basic
                functionality of the website. The storage may be used for
                marketing, analytics, and personalisation of the site, such as
                storing your preferences. Privacy is important to us, so you
                have the option of disabling certain types of storage that may
                not be necessary for the basic functioning of the website.
                Blocking categories may impact your experience on the website.
              </div>
              <div className="cookies-popup__settings-list">
                <div className="cookies-popup__settings-item">
                  <div className="cookies-popup__settings-icon active" />
                  <div className="cookies-popup__settings-item-title">
                    Essential
                  </div>
                  <div className="cookies-popup__settings-item-text">
                    These items are required to enable basic website
                    functionality.
                  </div>
                </div>
                <label
                  className="cookies-popup__settings-item"
                  htmlFor="checkbox-analytics">
                  <input
                    id="checkbox-analytics"
                    type="checkbox"
                    className="cookies-popup__settings-checkbox"
                    checked={cookieAnalytics}
                    onChange={handleCheckboxClick}
                    ref={analyticsCheckbox}
                  />
                  <div className="cookies-popup__settings-icon" />
                  <div className="cookies-popup__settings-item-title">
                    Analytics
                  </div>
                  <div className="cookies-popup__settings-item-text">
                    These items help the website operator understand how its
                    website performs, how visitors interact with the site, and
                    whether there may be technical issues. This storage type
                    usually doesn’t collect information that identifies a
                    visitor.
                  </div>
                </label>
              </div>
            </div>
            <div className="cookies-popup__buttons">
              <button
                className="cookies-popup__button btn btn-default"
                type="button"
                onClick={handleSaveSettings}>
                Save Preferences
              </button>
              <button
                className="cookies-popup__button btn btn-link-default"
                type="button"
                onClick={handleToggleSettings}>
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default CookiesPopup;
