import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AnimationImage from "@/components/AnimationImage";
import { routes } from "@/constants";
import ProjectCard from "@/components/ProjectCard";
import CircleSpinner from "@/components/CircleSpinner";
// import PageNotice from "@/components/PageNotice";
import { useMediaQuery } from "react-responsive";
import InfiniteScroll from "react-infinite-scroll-component";
import { getAllOrdersMore } from "@/store/order/order.actions";
import { getOrdersMore } from "@/store/order/order.reducer";
import "./ProjectTiles.scss";

const ProjectTiles = ({ orders }) => {
  const dispatch = useDispatch();
  const moreOrders = useSelector(getOrdersMore);
  const [ordersArr, setOrdersArr] = useState(orders.data);
  const [page, setPage] = useState(1);
  const isDesktop = useMediaQuery({ query: "(min-width: 1024px)" });

  useEffect(() => {
    if (moreOrders) {
      setOrdersArr(ordersArr.concat(moreOrders));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moreOrders]);

  const fetchMoreData = async () => {
    await dispatch(getAllOrdersMore(page + 1));
    setPage(page + 1);
  };

  return (
    <div className="project-tiles">
      {/* <PageNotice
        mod="warning"
        title="Holiday Notice"
        text="Our team will be on break from December 25th to January 5th. During this period, assessments will neither be scoped nor conducted. We wish you a wonderful holiday season!"
      /> */}
      {ordersArr && ordersArr.length ? (
        <>
          <h1 className="project-tiles__title">All Projects</h1>
          <InfiniteScroll
            className="project-tiles__list"
            dataLength={ordersArr.length}
            loader={
              <div className="project-tiles__loading">
                <CircleSpinner />
              </div>
            }
            next={fetchMoreData}
            scrollableTarget={isDesktop && "main-layout-body"}
            hasMore={orders.total !== ordersArr.length}>
            {ordersArr.map((project) => (
              <div className="project-tiles__list-item" key={project.id}>
                <ProjectCard project={project} />
              </div>
            ))}
          </InfiniteScroll>
        </>
      ) : (
        <div className="project-tiles__intro">
          <div className="project-tiles__intro-image">
            <AnimationImage title="projects-empty" />
          </div>
          <h1 className="project-tiles__intro-title">
            Welcome to the Assessment Hub
          </h1>
          <div className="project-tiles__intro-text">
            To begin a new security assessment for your application, click the
            button below
          </div>
          <div className="project-tiles__intro-footer">
            <Link
              to={routes.START_ORDER}
              className="project-tiles__intro-button btn btn-default">
              Start new assesment
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

ProjectTiles.propTypes = {
  orders: PropTypes.object.isRequired
};

export default ProjectTiles;
