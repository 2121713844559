import React from "react";
import { ContactTypes } from "@/constants";
import { useSelector } from "react-redux";
import { getOrderDetails } from "@/store/order/order.reducer";
import { Tab, TabList, Tabs } from "react-tabs";
import PropTypes from "prop-types";
import cn from "classnames";

const OrderTabs = ({ children, asTabs }) => {
  const order = useSelector(getOrderDetails);

  if (asTabs) {
    return (
      <Tabs
        className="tabs"
        defaultIndex={order?.contact_type === ContactTypes.INDIVIDUAL ? 1 : 0}>
        <TabList className="tabs__nav">
          <Tab className="tabs__nav-control">Organization</Tab>
          <Tab className="tabs__nav-control">Individual</Tab>
        </TabList>
        {children}
      </Tabs>
    );
  }

  return (
    <div className="tabs">
      <div className="tabs__nav">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          disabled
          className={cn("tabs__nav-control", {
            active: order?.contact_type === ContactTypes.ORGANIZATION
          })}>
          Organization
        </a>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          disabled
          className={cn("tabs__nav-control", {
            active: order?.contact_type === ContactTypes.INDIVIDUAL
          })}>
          Individual
        </a>
      </div>
      <div>{children}</div>
    </div>
  );
};

OrderTabs.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  asTabs: PropTypes.bool
};

export default OrderTabs;
