/* eslint-disable no-console */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import {
  lsKeys,
  APK_UPLOAD_TIMEOUT_IN_MS,
  APK_CHUNK_SIZE_IN_BYTES
} from "@/constants";
import Resumable from "resumablejs";
import PropTypes from "prop-types";
import ErrorMessage, { BaseErrorMessage } from "@/components/ErrorMessage";
import "./ResumableFileUploader.scss";
import { useController } from "react-hook-form";
import AlertMessage from "@/components/AlertMessage";
import { convertBytes } from "@/utils/convertBytes";
import { DeleteIcon, FileIcon } from "@/icons";
import cn from "classnames";

const getAuthorizationToken = () =>
  localStorage.getItem(lsKeys.AUTHORIZATION_TOKEN);

const APK_FILE_RESOLUTION =
  "application/vnd.android.package-archive,application/octet-stream, .apk";

const getResumableConfig = () => ({
  target: `${process.env.REACT_APP_API_URL}/api/v1/order/{testID}/application-info/file`,
  timeout: APK_UPLOAD_TIMEOUT_IN_MS,
  headers: {
    Authorization: `Bearer ${getAuthorizationToken()}`
  },
  testChunks: false,
  withCredentials: false,
  uploadMethod: "POST",
  maxFiles: 1,
  chunkSize: APK_CHUNK_SIZE_IN_BYTES, // APK_CHUNK_SIZE_IN_BYTES, // 1024 * 512,
  method: "multipart",
  // simultaneousUploads: 1,
  // fileType: [APK_MIME_TYPE],
  // maxFileSize: MAX_APK_FILE_SIZE_IN_BYTES,
  maxChunkRetries: 2
});

const UPLOADER_ID = "uploader-item";
// eslint-disable-next-line react/display-name
const ResumableFileUploader = forwardRef(
  (
    {
      control,
      errors,
      name,
      resetField,
      setValue,
      target,
      fileAccept = APK_FILE_RESOLUTION,
      fileAcceptLabel,
      defaultFilename,
      onAfterFileAdded,
      onFileUploadCompleted,
      onAfterUploadCanceled,
      onFileUploadPause,
      onFileUploadStart,
      onAfterFileSuccess,
      onFileProgress,
      onAfterFileError,
      onRemoveFile,
      ...props
    },
    ref
  ) => {
    const dragDropArea = useRef();

    const maxFileSizeErrorCallback = (file, errorCount) => {
      console.warn(file, errorCount);
    };

    const resumableRef = useRef(
      new Resumable({
        ...getResumableConfig(),
        maxFileSizeErrorCallback,
        target
      })
    );

    const {
      field: { ref: fieldRef, name: fieldName }
    } = useController({
      name,
      control,
      defaultValue: ""
    });

    const [uploaderState, setUploaderState] = useState({
      progressBar: 0,
      messageStatus: "",
      fileList: { files: [] },
      filename: "",
      isPaused: false,
      isSuccess: false,
      isError: false,
      isUploading: false,
      retryCount: null,
      resumable: undefined,
      fileSize: ""
    });
    const resetFieldFile = () => {
      setUploaderState((prev) => ({
        ...prev,
        progressBar: 0,
        messageStatus: "",
        fileList: { files: [] },
        filename: "",
        isPaused: false,
        isSuccess: false,
        isError: false,
        isUploading: false,
        retryCount: null,
        resumable: undefined,
        fileSize: ""
      }));
      if (resetField) {
        resetField(name, { defaultValue: "" });
      }
    };

    const handleRemoveFile = () => {
      resetFieldFile();

      if (onRemoveFile) {
        onRemoveFile();
      }
      if (onFileProgress) {
        resumableRef.current.cancel();
      }
    };

    const onUploadStart = useCallback(() => {
      if (onFileUploadStart) {
        onFileUploadStart();
      }
    }, [onFileUploadStart]);

    const onFileChange = (event) => {
      // resetFieldFile();
      const { files } = event.target;
      const [selectedFile] = files;

      if (files.length) {
        setValue(name, selectedFile);
        resumableRef.current.addFile(selectedFile);
      }
    };

    const onFileAdded = useCallback(
      (file, event) => {
        setUploaderState((prev) => ({
          ...prev,
          filename: file.fileName,
          messageStatus: "File added! ",
          isError: false,
          progressBar: 0,
          isSuccess: false,
          retryCount: null,
          fileSize: convertBytes(file.file.size)
        }));
        // file.bootstrap();

        if (onAfterFileAdded) {
          onAfterFileAdded(file, event);
        }

        console.warn("file added", file);
      },
      [onAfterFileAdded]
    );

    const onFileSuccess = useCallback(
      (file, message) => {
        setUploaderState((prev) => ({
          ...prev,
          progressBar: 100,
          isSuccess: true,
          isError: false,
          retryCount: null
        }));

        setTimeout(() => {
          if (onAfterFileSuccess) {
            onAfterFileSuccess(file, message);
          }
        }, 1000);
        console.warn("file upload success", file, message);
      },
      [onAfterFileSuccess]
    );

    const onFileError = useCallback(
      (file, message) => {
        setUploaderState((prev) => ({
          ...prev,
          progressBar: 0,
          isError: true,
          isSuccess: false
        }));

        if (onAfterFileError) {
          onAfterFileError(file, message);
        }

        console.warn("file upload error", file, message);
      },
      [onAfterFileError]
    );

    const onFileRetry = useCallback(() => {
      console.warn("file upload retry");

      setUploaderState((prev) => ({
        ...prev,
        retryCount: !prev.retryCount ? 1 : prev.retryCount + 1
      }));
    }, []);

    const onUploadPause = useCallback(() => {
      console.warn("file upload pause");
      setUploaderState((prev) => ({
        ...prev,
        isPaused: true
      }));

      if (onFileUploadPause) {
        onFileUploadPause();
      }
    }, [onFileUploadPause]);

    const onUploadCompleted = useCallback(() => {
      setUploaderState((prev) => ({
        ...prev,
        retryCount: null
      }));

      setTimeout(() => {
        if (onFileUploadCompleted) {
          onFileUploadCompleted();
        }
      }, 1000);
    }, [onFileUploadCompleted]);

    const onProgress = useCallback(() => {
      setUploaderState((prev) => ({
        ...prev,
        isUploading: resumableRef.current.isUploading()
      }));

      const progress = resumableRef.current.progress() * 100;

      if (progress < 100) {
        setUploaderState((prev) => ({
          ...prev,
          messageStatus: `${progress}%`,
          progressBar: progress,
          isPaused: false
        }));
      } else {
        setUploaderState((prev) => ({
          ...prev,
          progressBar: 100,
          isPaused: false
        }));
      }

      if (onFileProgress) {
        onFileProgress(resumableRef.current.progress());
      }
    }, [onFileProgress]);

    const onUploadCanceled = useCallback(() => {
      console.warn("UPLOAD CANCELLED");
      if (onAfterUploadCanceled) {
        onAfterUploadCanceled();
      }
    }, [onAfterUploadCanceled]);

    useEffect(() => {
      const r = resumableRef.current;
      r.assignDrop(dragDropArea.current);

      r.on("uploadStart", onUploadStart);
      r.on("pause", onUploadPause);
      r.on("fileAdded", onFileAdded);
      r.on("fileSuccess", onFileSuccess);
      r.on("fileError", onFileError);
      r.on("fileRetry", onFileRetry);
      r.on("progress", onProgress);
      r.on("cancel", onUploadCanceled);
      r.on("complete", onUploadCompleted);
    }, [
      onFileAdded,
      onFileError,
      onFileRetry,
      onFileSuccess,
      onUploadPause,
      onProgress,
      onUploadStart,
      onUploadCompleted,
      onUploadCanceled
    ]);

    useImperativeHandle(ref, () => ({
      async startUploading() {
        if (resumableRef.current.files[0].isComplete()) {
          resumableRef.current.files[0].bootstrap();
          await new Promise((res) => setTimeout(res, 1000));
        }
        if (resumableRef.current.files.length > 0) {
          resumableRef.current.upload();
        }
      },
      get state() {
        return uploaderState;
      },
      get resumable() {
        return resumableRef.current;
      }
    }));

    useEffect(() => {
      setUploaderState((prev) => ({
        ...prev,
        filename: defaultFilename
      }));
    }, [defaultFilename]);

    useEffect(() => {
      const r = resumableRef.current;
      return () => {
        if (r) {
          r?.cancel();
        }
      };
    }, []);

    return (
      <div className="file-uploader-widget">
        {uploaderState.filename && (
          <div className="file-uploader-info">
            <div className="file-uploader-info-icon">
              <FileIcon className="file-uploader-info-icon-i" />
            </div>
            <div className="file-uploader-info-content">
              <div className="file-uploader-info-name">
                {uploaderState.filename}
              </div>
              <div className="file-uploader-info-size">
                {uploaderState.fileSize}
              </div>
            </div>
            <button
              className="file-uploader-info-delete"
              type="button"
              onClick={handleRemoveFile}>
              <DeleteIcon className="file-uploader-info-delete-icon" />
            </button>
          </div>
        )}
        <div
          className={cn(
            "file-uploader-wrapper",
            uploaderState.filename && "file-uploader-wrapper_hide"
          )}>
          <div ref={dragDropArea} className="file-drop-area">
            <div className="file-drop-area-wrap">
              <span className="file-message">Drag and drop file here, or</span>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
              <label htmlFor={UPLOADER_ID}>
                <span className="choose-file-btn">Browse</span>
              </label>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <input
                name={fieldName}
                ref={fieldRef}
                onChange={onFileChange}
                disabled={uploaderState.isUploading}
                type="file"
                className="file-input"
                accept={fileAccept}
                id={UPLOADER_ID}
                {...props}
              />
            </div>
            {fileAcceptLabel && (
              <div className="file-accept-label">{fileAcceptLabel}</div>
            )}
          </div>
        </div>
        {uploaderState.filename &&
        uploaderState.progressBar &&
        !uploaderState.isError ? (
          <div className="file-progress">
            <div className="file-progress-bar">
              <div
                className="file-progress-overlay"
                style={{
                  width: `${Math.ceil(uploaderState.progressBar)}%`
                }}
              />
            </div>
            <div className="file-progress-value">
              {Math.ceil(uploaderState.progressBar)}%
            </div>
          </div>
        ) : null}
        <BaseErrorMessage
          text={uploaderState.isError ? "File failed to upload" : null}
        />
        <BaseErrorMessage
          text={
            uploaderState.retryCount
              ? `File chunk failed to upload. Retrying...`
              : null
          }
        />
        {uploaderState.isSuccess && (
          <AlertMessage type="success" text="File was successfully uploaded" />
        )}
        <ErrorMessage name={name} errors={errors} />
      </div>
    );
  }
);

ResumableFileUploader.defaultProps = {
  errors: {}
};

ResumableFileUploader.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object,
  name: PropTypes.string.isRequired,
  target: PropTypes.string,
  fileAccept: PropTypes.string,
  fileAcceptLabel: PropTypes.string,
  defaultFilename: PropTypes.string,
  onFileUploadCompleted: PropTypes.func,
  setValue: PropTypes.func,
  resetField: PropTypes.func,
  onFileUploadStart: PropTypes.func,
  onFileUploadPause: PropTypes.func,
  onAfterFileAdded: PropTypes.func,
  onAfterFileSuccess: PropTypes.func,
  onFileProgress: PropTypes.func,
  onAfterUploadCanceled: PropTypes.func,
  onAfterFileError: PropTypes.func,
  onRemoveFile: PropTypes.func
};

export default ResumableFileUploader;
