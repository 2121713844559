import React, { useMemo, useState } from "react";
import { Link, matchPath, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import cn from "classnames";
import { routes } from "@/constants";
import { getIsAuthenticated } from "@/store/auth/auth.reducer";
import Logo from "@/assets/images/logo.svg";
import { NotificationIcon } from "@/icons";
import { Desktop, TabletDesktop, MobileTablet } from "@/components/Responsive";
import PageSidebar from "@/components/PageSidebar";
import "./Header.scss";

const Header = ({ mod, handleClickNotifications }) => {
  const location = useLocation();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const [isOpened, setIsOpened] = useState(false);
  const [comingSoon] = useState(false);

  const rootRoute = useMemo(() => {
    const isMainRoute = matchPath(routes.ROOT, location.pathname);
    return routes.ROOT + (isMainRoute ? location.search : "");
  }, [location]);

  const handleToggleMenu = () => {
    setIsOpened(!isOpened);
  };
  const handleHideMenu = () => {
    if (isOpened) {
      setIsOpened(false);
    }
  };

  return (
    <header className={cn("header", mod && `header_${mod}`)}>
      <Link className="header__logo" to={rootRoute} onClick={handleHideMenu}>
        <img
          className="header__logo-image"
          src={Logo}
          alt="Assessment Hub - by Leviathan Security Group"
          width="249"
          height="26"
        />
      </Link>
      {isAuthenticated && (
        <div className="header__aside">
          <Desktop>
            {comingSoon && (
              <button
                className="header__notice"
                type="button"
                onClick={handleClickNotifications}>
                <div className="header__notice-icon active">
                  <NotificationIcon className="header__notice-icon-i" />
                </div>
                <div className="header__notice-label">Notifications</div>
              </button>
            )}
          </Desktop>
          <TabletDesktop>
            <Link
              to={routes.START_ORDER}
              className="header__start-button btn btn-light">
              Start new assesment
            </Link>
          </TabletDesktop>
          <MobileTablet>
            <button
              className={cn(
                "header__toggle",
                isOpened && "header__toggle_active"
              )}
              type="button"
              onClick={handleToggleMenu}
              aria-label="Menu">
              <div className="header__toggle-item" />
            </button>
            {isOpened && (
              <div className="header__menu">
                <div className="header__menu-content">
                  <PageSidebar
                    handleClick={handleHideMenu}
                    handleClickNotifications={handleClickNotifications}
                  />
                </div>
              </div>
            )}
          </MobileTablet>
        </div>
      )}
    </header>
  );
};

Header.propTypes = {
  mod: PropTypes.string,
  handleClickNotifications: PropTypes.func
};

export default Header;
