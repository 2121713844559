import React from "react";
import RequiredMFA from "@/components/MFA/RequiredMFA";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import { MFAChallengeTypes, routes } from "@/constants";
import SetupMFA from "./SetupMFA";
import "./mfa.scss";

const MFA = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const from = location.state?.from?.pathname || routes.ROOT;

  if (!searchParams.has("challenge")) {
    return <Navigate to={from} replace />;
  }
  const challenge = searchParams.get("challenge");

  if (challenge === MFAChallengeTypes.MFA_SETUP) {
    return <SetupMFA />;
  }

  if (challenge === MFAChallengeTypes.MFA_REQUIRED) {
    return <RequiredMFA />;
  }

  return null;
};

export default MFA;
