import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, generatePath } from "react-router-dom";
import { getOrderDetails } from "@/store/order/order.reducer";
import { isInProcess } from "@/store/pending/pending.reducer";
import { useForm } from "react-hook-form";
import yup from "@/utils/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  OrderActions,
  createOrderCasa,
  updateDeveloperInfoCasa
} from "@/store/order/order.actions";
import { getAccountEmail } from "@/store/account/account.reducer";
import { isValidPhoneNumber } from "react-phone-number-input";
import InputField from "@/components/InputField";
import PhoneField from "@/components/PhoneField";
import Button from "@/components/Button";
import PlacesAutocompleteControl from "@/components/PlacesAutocompleteControl";
import { routes } from "@/constants";
import { editableModeByStatus } from "@/utils/editableModeByStatus";
import RadioGroup from "@/components/RadioGroup";
import { decode } from "html-entities";

const schema = yup.object({
  organization_name: yup.string().nullable().max(255).required(),
  organization_website: yup
    .string()
    .nullable()
    .max(255)
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
      "Should be a valid URL"
    )
    .required(),
  address: yup.object().nullable().required(),
  contact_name: yup.string().nullable().max(255).required(),
  contact_email: yup
    .string()
    .nullable()
    .email()
    .max(255)
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Should be a valid email"
    )
    .required(),
  contact_phone: yup
    .string()
    .required()
    // eslint-disable-next-line func-names
    .test("isPhoneValid", function () {
      return isValidPhoneNumber(this.parent.contact_phone);
    }),
  previously_worked: yup.bool().required(),
  nylas_google_api: yup.bool().required()
});

const OrderCreation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const accountEmail = useSelector(getAccountEmail);
  const orderDetailsNew = useSelector(getOrderDetails);
  const preloadedValues = id ? orderDetailsNew : {};
  const {
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      organization_name: decode(preloadedValues.organization_name) ?? "",
      organization_website: preloadedValues.organization_website ?? "",
      address: preloadedValues.address ?? "",
      contact_name: preloadedValues.contact_name ?? "",
      contact_email: preloadedValues.contact_email ?? accountEmail,
      contact_phone: preloadedValues.contact_phone ?? "",
      previously_worked: preloadedValues.previously_worked ?? "",
      nylas_google_api: preloadedValues.nylas_google_api ?? ""
    }
  });
  const isLoading = useSelector((state) =>
    isInProcess(
      state,
      id ? OrderActions.UPDATE_DEVELOPER_INFO : OrderActions.CREATE_ORDER
    )
  );

  useEffect(() => {
    if (
      id &&
      orderDetailsNew &&
      orderDetailsNew.status &&
      !editableModeByStatus(orderDetailsNew.status.code)
    ) {
      navigate(routes.ROOT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data) => {
    data.google_place_id = data.address.value.place_id;
    data.address = data.address.label;

    if (id) {
      // edit mode
      const res = await dispatch(updateDeveloperInfoCasa({ id, ...data }));

      const path = generatePath(routes.ORDER_CASA_APPLICATION_INFO, {
        id: res.id
      });
      navigate(path);

      return;
    }

    const res = await dispatch(createOrderCasa(data));
    const path = generatePath(routes.ORDER_CASA_APPLICATION_INFO, {
      id: res.id
    });
    navigate(path);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="fields-group">
        <div className="fields-group-title">Company details</div>
        <InputField
          name="organization_name"
          type="text"
          label="Company name <sup>*</sup>"
          errors={errors}
          placeholder="Company name"
          {...register("organization_name")}
        />
        <InputField
          name="organization_website"
          type="text"
          label="Company website URL <sup>*</sup>"
          placeholder="website.com"
          errors={errors}
          {...register("organization_website")}
        />
        <PlacesAutocompleteControl
          name="address"
          defaultPlaceId={preloadedValues.google_place_id}
          defaultValue={preloadedValues.address}
          control={control}
          label="Company address <sup>*</sup>"
          placeholder="Start typing the address"
          errors={errors}
        />
      </div>
      <div className="fields-group">
        <div className="fields-group-title">Point of contact</div>
        <InputField
          name="contact_name"
          type="text"
          label="Contact name <sup>*</sup>"
          errors={errors}
          placeholder="Contact name"
          {...register("contact_name")}
        />
        <InputField
          name="contact_email"
          type="text"
          errors={errors}
          label="Contact e-mail <sup>*</sup>"
          placeholder="Contact e-mail"
          {...register("contact_email")}
        />
        <PhoneField
          control={control}
          fieldName="contact_phone"
          label="Contact phone number <sup>*</sup>"
          placeholder="Contact phone number"
          errors={errors}
          {...register("contact_phone")}
        />
        <RadioGroup
          label="Have you or your company previously worked with Leviathan Security Group?"
          isRequired
          preloadedValue={
            // eslint-disable-next-line no-nested-ternary
            id ? (orderDetailsNew.previously_worked ? "1" : "0") : ""
          }
          values={[
            {
              text: "Yes",
              value: "1"
            },
            {
              text: "No",
              value: "0"
            }
          ]}
          name="previously_worked"
          errors={errors}
          {...register("previously_worked")}
        />
        <RadioGroup
          label="Are you currently using Nylas for integration with Google API?"
          isRequired
          preloadedValue={
            // eslint-disable-next-line no-nested-ternary
            id ? (orderDetailsNew.nylas_google_api ? "1" : "0") : ""
          }
          values={[
            {
              text: "Yes",
              value: "1"
            },
            {
              text: "No",
              value: "0"
            }
          ]}
          name="nylas_google_api"
          errors={errors}
          {...register("nylas_google_api")}
        />
      </div>
      <div className="main-layout__drawer-footer">
        <Button
          isLoading={isLoading}
          type="submit"
          className="btn-default btn-fullwidth"
          text="Continue"
        />
      </div>
    </form>
  );
};

export default OrderCreation;
