import React from "react";
import { routes, lsKeys } from "@/constants";
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import InputField from "@/components/InputField";
import Button from "@/components/Button";
import { useDispatch, useSelector } from "react-redux";
import { isInProcess } from "@/store/pending/pending.reducer";
import { AuthActions, respondToAuthChallenge } from "@/store/auth/auth.actions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "@/utils/yup";

const schema = yup.object({
  code: yup.string().nullable().min(6).max(6).required()
});

const RequiredMFA = () => {
  const [searhParams] = useSearchParams();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) =>
    isInProcess(state, AuthActions.RESPOND_TO_AUTH_CHALLENGE)
  );
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || routes.ROOT;

  const {
    register,
    setValue,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data) => {
    try {
      const { code } = data;
      const session = searhParams.get("session");

      await dispatch(
        respondToAuthChallenge({
          code,
          session
        })
      );

      localStorage.setItem(lsKeys.MFA_SESSION, true);
      navigate(from, { replace: true });
    } catch (error) {
      setError("code", {
        type: "custom",
        message:
          error.data?.code?.join("\n") || error.data?.message || "Invalid code"
      });
      // if (error.data?.message) {
      // dispatch(validateToken());
      // }
    }
  };

  if (!searhParams.has("session")) {
    return <Navigate to={routes.LOGIN} state={from} replace />;
  }

  return (
    <div className="mfa">
      <form onSubmit={handleSubmit(onSubmit)} className="mfa__steps">
        <div className="fields-group">
          <h1 className="mfa__title mfa__title_bottom-offset">
            Multi-Factor Authentication
          </h1>
          <p className="mfa__step-text mfa__step-bottom-gutter">
            Type the 6-digit code from your authenticator application:
          </p>
          <InputField
            setValue={setValue}
            otpInput
            autoFocus
            name="code"
            errors={errors}
            type="text"
            placeholder="Code"
            {...register("code")}
          />
        </div>
        <div className="fields-group">
          <Button
            isLoading={isLoading}
            type="submit"
            className="btn-default btn-fullwidth"
            text="Submit"
          />
        </div>
      </form>
    </div>
  );
};

export default RequiredMFA;
