import React from "react";
import {
  CheckCircleDisabledIcon,
  CheckCircleSuccessIcon,
  CircleWarningIcon
} from "@/icons";

export const OrderInfoType = {
  INFO_DEVELOPER: "INFO_DEVELOPER",
  INFO_APP: "INFO_APP",
  VERIFICATION: "VERIFICATION",
  PAYMENT: "PAYMENT",
  ADDITIONAL_INFO: "ADDITIONAL_INFO",
  ASSESSMENT_IN_PROGRESS: "ASSESSMENT_IN_PROGRESS",
  MITIGATIONS_NEEDED: "MITIGATIONS_NEEDED",
  ASSESSMENT_COMPLETED: "ASSESSMENT_COMPLETED"
};

export const OrderInfoTypeProperties = {
  [OrderInfoType.INFO_DEVELOPER]: {
    title: "Developer details",
    description: "Information about you, contact details",
    icon: <CheckCircleSuccessIcon />
  },
  [OrderInfoType.INFO_APP]: {
    title: "Application details",
    description: "Application and package details, app review information",
    icon: <CheckCircleSuccessIcon />
  },
  [OrderInfoType.VERIFICATION]: {
    title: "Verification",
    description:
      "We need to assess your authority over the application to start the assessment",
    icon: <CircleWarningIcon />
  },
  [OrderInfoType.PAYMENT]: {
    title: "Payment",
    description: "Choose your plan for the assessment",
    icon: <CircleWarningIcon />
  },
  [OrderInfoType.ADDITIONAL_INFO]: {
    title: "Additional information",
    description:
      "We might request additional information before performing the assessment",
    icon: <CircleWarningIcon />
  },
  [OrderInfoType.ASSESSMENT_IN_PROGRESS]: {
    title: "Assessment in progress",
    description:
      "Sit back and relax, your application’s assessment is underway!",
    icon: <CheckCircleDisabledIcon />
  },
  [OrderInfoType.MITIGATIONS_NEEDED]: {
    title: "Mitigations needed",
    description:
      "We found vulnerabilities or misconfigurations that need to be fixed to complete the assessment",
    icon: <CheckCircleDisabledIcon />
  },
  [OrderInfoType.ASSESSMENT_COMPLETED]: {
    title: "Assessment completed",
    description: "You can download the report here",
    icon: <CheckCircleDisabledIcon />
  }
};
