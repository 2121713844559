/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react";
import PropTypes from "prop-types";
import AnimationImage from "@/components/AnimationImage";
import { routes, routeTitles } from "@/constants";
import Notification from "@/components/Notification";
import "./Notifications.scss";

const Notifications = ({ handleClick }) => {
  const list = [
    {
      new: true,
      title:
        "Your report for <a href='https://www.adobe.com' target='_blank'>com.adobe.reader</a> is ready now.",
      date: "2 hours ago"
    },
    {
      new: true,
      title: "Price",
      date: "1 June 2023, 12:23:08"
    },
    {
      title: "Welcome to CASA Portal",
      date: "2 June 2023, 12:23:08"
    }
  ];

  return (
    <div className="notifications">
      <div className="notifications__inner">
        <div className="notifications__header">
          <div className="notifications__title">Notifications</div>
          <div className="notifications__subtitle">Recent updates</div>
          <button className="notifications__close" onClick={handleClick} />
        </div>
        {list.length ? (
          <div className="notifications__list">
            {list?.map((item) => (
              <div className="notifications__item" key={item.title}>
                <Notification item={item} />
              </div>
            ))}
          </div>
        ) : (
          <div className="notifications__wrap">
            <div className="notifications__wrap-image">
              <AnimationImage title={routeTitles[routes.FAQ]} />
            </div>
            <div className="notifications__wrap-title">
              Your don’t have any notifications yet. We’ll let you know when we
              get news
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Notifications.propTypes = {
  handleClick: PropTypes.func
};

export default Notifications;
