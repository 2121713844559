/* eslint-disable react/no-unused-prop-types */
import React from "react";
import PropTypes from "prop-types";
import Button from "@/components/Button";
import { TrashInvertedIcon } from "@/icons";
import { useSelector } from "react-redux";
import { isInProcess } from "@/store/pending/pending.reducer";
import "./ServicePopup.scss";

const ServicePopup = ({
  onClose,
  onSubmit,
  onSubmitAction,
  title,
  text,
  submitButtonLabel,
  submitDelete,
  closeButtonLabel
}) => {
  const isLoading = useSelector((state) => isInProcess(state, onSubmitAction));

  return (
    <div className="service-popup">
      <div className="service-popup__content">
        {title && <div className="service-popup__title">{title}</div>}
        {text && <div className="service-popup__subtitle">{text}</div>}
        <div className="service-popup__buttons">
          <Button
            type="button"
            className="service-popup__button btn btn-light btn-fullwidth"
            text={closeButtonLabel || "Cancel"}
            onClick={onClose}
          />
          {submitDelete ? (
            <Button
              isLoading={isLoading}
              type="button"
              className="service-popup__button btn btn-lightred btn-fullwidth"
              text={submitButtonLabel}
              btnIcon={<TrashInvertedIcon className="btn__icon" />}
              onClick={onSubmit}
            />
          ) : (
            <Button
              isLoading={isLoading}
              type="button"
              className="service-popup__button btn btn-default btn-fullwidth"
              text={submitButtonLabel}
              onClick={onSubmit}
            />
          )}
        </div>
      </div>
    </div>
  );
};

ServicePopup.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onSubmitAction: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  submitDelete: PropTypes.bool,
  closeButtonLabel: PropTypes.string
};

export default ServicePopup;
