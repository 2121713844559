import PanelHeader from "@/components/ProjectProgress/components/PanelHeader";
import {
  CheckCircleSuccessIcon,
  CircleWarningIcon,
  VerificationAbsentIcon,
  VerificationSuccessIcon
} from "@/icons";
import React from "react";
import PropTypes from "prop-types";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { routes } from "@/constants";
import { useSelector } from "react-redux";
import {
  getIsDevAndAppInfoFilled,
  getIsOrderVerified
} from "@/store/order/order.reducer";

export const VerificationPanelHeader = ({ active }) => {
  const isVerifiedOrder = useSelector(getIsOrderVerified);
  const isDevAndAppInfoFilled = useSelector(getIsDevAndAppInfoFilled);
  const { id } = useParams();
  const navigate = useNavigate();
  const onVerifyClick = () => {
    navigate(
      generatePath(routes.ORDER_VERIFY, {
        id
      })
    );
  };

  return (
    <PanelHeader
      title="Ownership Verification"
      description="We need to assess your authority over the application to start the assessment"
      icon={
        isVerifiedOrder ? <CheckCircleSuccessIcon /> : <CircleWarningIcon />
      }
      hasButton={
        // eslint-disable-next-line no-nested-ternary
        isDevAndAppInfoFilled ? !isVerifiedOrder : false
      }
      active={active}
      buttonText="Verify"
      onClick={onVerifyClick}
    />
  );
};

VerificationPanelHeader.propTypes = {
  active: PropTypes.bool.isRequired
};

// eslint-disable-next-line
export const VerificationPanelBody = ({ orderDetails }) => {
  const isVerifiedOrder = useSelector(getIsOrderVerified);

  return (
    <div className="progress-accordion__body inner row-info-wrapper">
      <div className="row-info-wrapper__icon">
        {isVerifiedOrder ? (
          <VerificationSuccessIcon />
        ) : (
          <VerificationAbsentIcon />
        )}
      </div>
      <div className="row-info-wrapper__info">
        <div className="row-info-wrapper__title">
          {isVerifiedOrder
            ? "Successfully verified"
            : "Verify ownership of the application"}
        </div>
        <div className="row-info-wrapper__text">
          {isVerifiedOrder
            ? "You have successfully verified ownership of the application"
            : "We need to assess your authority over the application to start the assessment"}
        </div>
      </div>
    </div>
  );
};

VerificationPanelBody.propTypes = {
  orderDetails: PropTypes.object.isRequired
};
