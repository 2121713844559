import React from "react";
import { CheckIcon } from "@/icons";
import PropTypes from "prop-types";
import cn from "classnames";
import "./AlertMessage.scss";

const ALERT_TYPES = {
  SUCCESS: "success",
  ERROR: "error",
  NEUTRAL: "neutral"
};

const AlertMessage = ({ type, className, text }) => {
  return (
    <div className={cn("alert-message", type, className)}>
      <span>
        {type === ALERT_TYPES.SUCCESS && <CheckIcon />}
        {text}
      </span>
    </div>
  );
};

AlertMessage.defaultProps = {
  type: ALERT_TYPES.SUCCESS
};

AlertMessage.propTypes = {
  type: PropTypes.string, // success | error | neutral,
  className: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired
};

export default AlertMessage;
