export const formatString = (inputString, replacementObject) => {
  const regex = /{([^{}]+)}/g;

  // Replace placeholders with values from the replacementObject
  const formattedString = inputString.replace(regex, (match, key) => {
    const replacementValue = replacementObject[key];
    return replacementValue !== undefined ? replacementValue : match;
  });

  return formattedString;
};
