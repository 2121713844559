import React from "react";
import { OrderCreation } from "@/components/OrderStepsCasa";
import { Routes, Route } from "react-router-dom";
import { DrawerLayout } from "@/layouts";

const NewOrderCasaPage = () => {
  return (
    <Routes>
      <Route element={<DrawerLayout />}>
        <Route index element={<OrderCreation />} />
      </Route>
    </Routes>
  );
};

export default NewOrderCasaPage;
