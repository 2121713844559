import React from "react";
import PropTypes from "prop-types";
import { generatePath, useNavigate } from "react-router-dom";
import PanelHeader from "@/components/ProjectDetails/components/PanelHeader";
import {
  CheckCircleSuccessIcon,
  CircleProgressIcon,
  CheckCircleDisabledIcon,
  TriangleWarningIcon
} from "@/icons";
import { routes, routeActions } from "@/constants";
import { OrderStatuses } from "@/constants/orderStatuses";
import { Mobile } from "@/components/Responsive";
import cn from "classnames";
import AnimationImage from "@/components/AnimationImage";
import Button from "@/components/Button";

const checkDisabled = (orderDetails) => {
  const disabledStatuses = [
    OrderStatuses.CREATED,
    OrderStatuses.PENDING_EMAIL_VERIFICATION,
    OrderStatuses.QUOTE_READY,
    OrderStatuses.EMAIL_VERIFIED,
    OrderStatuses.NEED_MORE_INFO,
    OrderStatuses.AWAITING_PAYMENT,
    OrderStatuses.INVOICE_PROCESSING,
    OrderStatuses.INVOICE_DETAILS_CONFIRMED,
    OrderStatuses.CONFIRMING_PURCHASE_ORDER,
    OrderStatuses.PURCHASE_ORDER_CONFIRMED,
    OrderStatuses.PAID
  ];

  const disabled =
    disabledStatuses.includes(orderDetails.status.code) ||
    orderDetails.product === null ||
    orderDetails.product.type !== 0;

  return disabled;
};

const checkDone = (orderDetails) => {
  return !orderDetails.errors || orderDetails.errors.length === 0;
};

const checkInProgress = (orderDetails) => {
  return (
    orderDetails.status.code === OrderStatuses.QUESTIONNAIRE_SUBMISSION_PENDING
  );
};

export const QuestionnaireDetailsNavItem = ({
  tabIndex,
  handleTabNavClick,
  orderDetails
}) => {
  const done = checkDone(orderDetails);
  const disabled = checkDisabled(orderDetails);
  const progress = checkInProgress(orderDetails);
  const error = !disabled && !progress && !done;

  let icon;

  if (disabled) {
    icon = <CheckCircleDisabledIcon className="project-details__nav-icon" />;
  } else if (progress) {
    icon = <CircleProgressIcon className="project-details__nav-icon" />;
  } else if (done) {
    icon = <CheckCircleSuccessIcon className="project-details__nav-icon" />;
  } else if (error) {
    icon = <TriangleWarningIcon className="project-details__nav-icon" />;
  }

  return (
    <button
      className={cn(
        "project-details__nav-item",
        tabIndex === "questionnaire-details" && "active"
      )}
      disabled={disabled}
      onClick={() => handleTabNavClick("questionnaire-details")}
      type="button">
      {icon}
      <span className={cn({ "text-red": error })}>Questionnaire</span>
    </button>
  );
};

const QuestionnaireDetails = ({
  orderDetails,
  tabIndex,
  handleTabNavClick
}) => {
  const done = checkDone(orderDetails);
  const disabled = checkDisabled(orderDetails);
  const progress = checkInProgress(orderDetails);
  const error = !disabled && !progress && !done;

  let icon;

  if (disabled) {
    icon = <CheckCircleDisabledIcon className="project-details__nav-icon" />;
  } else if (progress) {
    icon = <CircleProgressIcon className="project-details__nav-icon" />;
  } else if (done) {
    icon = <CheckCircleSuccessIcon className="project-details__nav-icon" />;
  } else if (error) {
    icon = <TriangleWarningIcon className="project-details__nav-icon" />;
  }

  let sectionHeader = "";
  let sectionDetails = "";
  let sectionButtonText = "";
  let path = "";

  if (progress) {
    sectionHeader = "Continue the assessment by answering a few questions";
    sectionDetails =
      "Your responses will help us tailor the experience to your needs";
    sectionButtonText = "Get started!";
    path = generatePath(routes.ORDER_QUESTIONNAIRE_START, {
      id: orderDetails.id
    });
  } else if (done) {
    sectionHeader = "Self-Declaration Successful";
    sectionDetails =
      "Your app has successfully met all self-declared security requirements. Our team will now proceed with validating the remaining criteria to complete the assessment.";
    sectionButtonText = "Check your responses";
    path = generatePath(routes.ORDER_QUESTIONNAIRE_BY_ID, {
      id: orderDetails.id,
      action: routeActions.view
    });
  } else if (error) {
    sectionHeader = "Awaiting remediation of questionnaire requirements";
    sectionDetails =
      "The assessment has started, but some questionnaire items did not meet the security requirements. Please resolve these issues and resubmit the questionnaire.";
    sectionButtonText = "Resubmit questionnaire ";
    path = generatePath(routes.ORDER_QUESTIONNAIRE_BY_ID, {
      id: orderDetails.id,
      action: routeActions.verify
    });
  }

  const navigate = useNavigate();

  const onQuestionnaireClicked = async () => {
    navigate(path);
  };

  return (
    <>
      <Mobile>
        <QuestionnaireDetailsNavItem
          tabIndex={tabIndex}
          handleTabNavClick={handleTabNavClick}
          orderDetails={orderDetails}
        />
      </Mobile>
      {
        tabIndex === "questionnaire-details" && (
        /* eslint-disable prettier/prettier */
        <div className="project-details__tab">
          <PanelHeader
            title="Questionnaire"
            description="Your responses will help us tailor the experience to your needs. Let's get started!"
            hasButton={false}
            icon={icon}
          />
          <div className="project-details__section">
            <div className="project-details__info">
              {progress && (
                <div className="project-details__info-image">
                  <AnimationImage title="scheduled" />
                </div>
              )}
              <div className="project-details__info-content">
                <div className="project-details__info-title">
                  {sectionHeader}
                </div>
                <div className="project-details__info-text">
                  {sectionDetails}
                </div>
                <p>
                  <Button
                    text={sectionButtonText}
                    className="project-details__panel-button btn-light"
                    onClick={onQuestionnaireClicked}
                    type="button"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      )
      /* eslint-enable prettier/prettier */
      }
    </>
  );
};

QuestionnaireDetails.propTypes = {
  orderDetails: PropTypes.object.isRequired,
  tabIndex: PropTypes.string,
  handleTabNavClick: PropTypes.func
};

QuestionnaireDetailsNavItem.propTypes = {
  tabIndex: PropTypes.string,
  handleTabNavClick: PropTypes.func,
  orderDetails: PropTypes.object.isRequired
};

export default QuestionnaireDetails;
