import { isPending, isTracked } from "@/utils/createAsyncAction";
import { createReducer } from "@reduxjs/toolkit";

const pending = createReducer([], (builder) => {
  builder.addMatcher(
    (action) => {
      return isTracked(action) && isPending(action);
    },
    // eslint-disable-next-line consistent-return
    (state, action) => {
      if (!state.includes(action.type)) {
        state.push(action.type);
      }
    }
  );

  builder.addMatcher(
    (action) => {
      return isTracked(action) && !isPending(action);
    },
    (state, action) => {
      if (state.includes(action.type)) {
        return state.filter((actionType) => {
          return actionType !== action.type;
        });
      }
      return state;
    }
  );
});

export const isInProcess = (state, action) => {
  return state.pending.includes(action);
};

export default pending;
