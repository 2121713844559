import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import warningIcon from "@/assets/images/warning-icon.svg";
import "./PageNotice.scss";

const PageNotice = ({ mod, title, text, to, toText }) => {
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    switch (mod) {
      case "warning":
        setImageUrl(warningIcon);
        break;
      case "highlight":
      case "error":
      case "success":
      default:
        setImageUrl();
        break;
    }
  }, [mod]);

  return (
    <div className={cn("page-notice", mod && `page-notice_${mod}`)}>
      <div className="page-notice__icon">
        {imageUrl && (
          <img
            className="page-notice__icon-i"
            src={imageUrl}
            alt=""
            width="36"
            height="36"
          />
        )}
      </div>
      <div className="page-notice__content">
        {title && <div className="page-notice__title">{title}</div>}
        {text && <div className="page-notice__text">{text}</div>}
        {toText && to && (
          <div className="page-notice__link">
            <a href={to}>{toText}</a>
          </div>
        )}
      </div>
    </div>
  );
};

PageNotice.propTypes = {
  mod: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  to: PropTypes.string,
  toText: PropTypes.string
};

export default PageNotice;
