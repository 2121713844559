import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import yup from "@/utils/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createOrder,
  OrderActions,
  updateDeveloperInfo
} from "@/store/order/order.actions";
import { getOrderDetails } from "@/store/order/order.reducer";
import { isInProcess } from "@/store/pending/pending.reducer";
import InputField from "@/components/InputField";
import PhoneField from "@/components/PhoneField";
import Button from "@/components/Button";
import PlacesAutocompleteControl from "@/components/PlacesAutocompleteControl";
import { isValidPhoneNumber } from "react-phone-number-input";
import { ContactTypes, routes } from "@/constants";
import { decode } from "html-entities";

const schema = yup.object({
  developer_name: yup.string().nullable().max(255).required(),
  organization_name: yup.string().nullable().max(255).required(),
  address: yup.object().nullable().required(),
  contact_name: yup.string().nullable().max(255).required(),
  contact_email: yup.string().nullable().email().max(255).required(),
  contact_phone: yup
    .string()
    .required()
    .test("isPhoneValid", function () {
      return isValidPhoneNumber(this.parent.contact_phone);
    })
});

const OrderCreation = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const isLoading = useSelector((state) =>
    isInProcess(
      state,
      id ? OrderActions.UPDATE_DEVELOPER_INFO : OrderActions.CREATE_ORDER
    )
  );
  const navigate = useNavigate();
  const orderDetails = useSelector(getOrderDetails);
  const preloadedValues = id ? orderDetails : {};

  const {
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      developer_name: decode(preloadedValues.developer_name) ?? "",
      organization_name: decode(preloadedValues.organization_name) ?? "",
      address: preloadedValues.address ?? "",
      contact_name: preloadedValues.contact_name ?? "",
      contact_email: preloadedValues.contact_email ?? "",
      contact_phone: preloadedValues.contact_phone ?? ""
    }
  });

  const onSubmit = async (data) => {
    data.contact_type = ContactTypes.ORGANIZATION;
    data.google_place_id = data.address.value.place_id;
    data.address = data.address.label;

    if (id) {
      // edit mode
      const res = await dispatch(updateDeveloperInfo({ id, ...data }));

      const path = generatePath(routes.ORDER_APPLICATION_INFO, { id: res.id });
      navigate(path);

      return;
    }

    const res = await dispatch(createOrder(data));
    const path = generatePath(routes.ORDER_APPLICATION_INFO, { id: res.id });
    navigate(path);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="order-creation">
      <div className="fields-group">
        <div className="fields-group-title">Company details</div>
        <InputField
          name="developer_name"
          type="text"
          label="Developer name <sup>*</sup>"
          errors={errors}
          placeholder="Developer name"
          {...register("developer_name")}
        />
        <InputField
          name="organization_name"
          type="text"
          label="Company name <sup>*</sup>"
          errors={errors}
          placeholder="Company name"
          {...register("organization_name")}
        />
        <PlacesAutocompleteControl
          name="address"
          defaultPlaceId={preloadedValues.google_place_id}
          defaultValue={preloadedValues.address}
          control={control}
          label="Company address <sup>*</sup>"
          placeholder="Start typing the address"
          errors={errors}
        />
      </div>
      <div className="fields-group">
        <div className="fields-group-title">Point of contact</div>
        <InputField
          name="contact_name"
          type="text"
          label="Contact name <sup>*</sup>"
          errors={errors}
          placeholder="Contact name"
          {...register("contact_name")}
        />
        <InputField
          name="contact_email"
          type="text"
          label="Contact e-mail <sup>*</sup>"
          errors={errors}
          placeholder="Contact e-mail"
          {...register("contact_email")}
        />
        <PhoneField
          control={control}
          fieldName="contact_phone"
          label="Contact phone number <sup>*</sup>"
          placeholder="Contact phone number"
          errors={errors}
          {...register("contact_phone")}
        />
      </div>
      <div className="main-layout__drawer-footer">
        <Button
          isLoading={isLoading}
          type="submit"
          className="btn-default btn-fullwidth"
          text="Continue"
        />
      </div>
    </form>
  );
};

export default OrderCreation;
