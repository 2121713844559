import React, { useState } from "react";
import InputField from "@/components/InputField";
import Button from "@/components/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "@/utils/yup";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { routes } from "@/constants";
import { AuthActions, registerUser } from "@/store/auth/auth.actions";
import { getRegisterErrorData } from "@/store/auth/auth.reducer";
import PropTypes from "prop-types";
import FederalProvidersRow from "@/components/FederalProvidersRow";
import AlertMessage from "@/components/AlertMessage";
import { isInProcess } from "@/store/pending/pending.reducer";
import InputPasswordField from "@/components/InputPasswordField";
import PasswordValidityRules from "@/components/PasswordValidityRules";
import "./RegisterForm.scss";

const registerSchema = yup.object({
  email: yup.string().nullable().max(255).email().required(),
  password: yup
    .string()
    .nullable()
    .min(8)
    .max(99)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-"!@#%&/,><':;_~`])\S{8,99}$/,
      "Not appropriate password format"
    )
    .required(),
  password_confirmation: yup
    .string()
    .nullable()
    .oneOf([yup.ref("password"), null], "Passwords must match")
});

const RegisterForm = ({ onAfterSubmit }) => {
  const [isFocused, setIsFocused] = useState(false);
  const dispatch = useDispatch();
  const error = useSelector(getRegisterErrorData);
  const isLoading = useSelector((state) =>
    isInProcess(state, AuthActions.REGISTER)
  );

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(registerSchema)
  });

  const passwordWatch = watch("password", "");

  const onSubmit = async (data) => {
    try {
      await dispatch(registerUser(data));

      if (onAfterSubmit) {
        onAfterSubmit(data.email);
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  return (
    <form className="register-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="register-form__title">Hi there!</div>
      <div className="register-form__subtitle">Sign up with your socials</div>
      <FederalProvidersRow />
      <div className="register-form__label">
        or register with email and password
      </div>
      <div className="fields-group">
        <InputField
          name="email"
          type="email"
          label="Email"
          errors={errors}
          placeholder="example@mail.com"
          {...register("email")}
        />
        <InputPasswordField
          label="Password"
          name="password"
          errors={errors}
          onFocus={() => setIsFocused(true)}
          {...register("password")}
        />
        <div className="fields-group">
          {isFocused && <PasswordValidityRules password={passwordWatch} />}
        </div>
        <InputPasswordField
          label="Repeat password"
          name="passwordConfirm"
          errors={errors}
          {...register("password_confirmation")}
        />
      </div>
      <div className="fields-group">
        <Button
          isLoading={isLoading}
          text="Register"
          className="register-form__button btn-default btn-fullwidth"
          type="submit"
        />
      </div>
      {error?.data?.message && (
        <div className="register-form__error-message">
          <AlertMessage text={error?.data?.message} type="error" />
        </div>
      )}
      <div className="register-form__info">
        Already have an account?&nbsp;
        <Link to={routes.LOGIN}>Sign in here</Link>
      </div>
      <div className="register-form__privacy">
        When you sign up for an account on the website, you are confirming that
        your business has no connections with Russia, and you are also agreeing
        to our&nbsp;
        <Link to={routes.PRIVACY_POLICY}>Privacy Policy</Link> and&nbsp;
        <Link to={routes.TERMS}>Terms of Service</Link>
      </div>
    </form>
  );
};

RegisterForm.propTypes = {
  onAfterSubmit: PropTypes.func
};

export default RegisterForm;
