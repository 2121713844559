/* eslint-disable no-console */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetails } from "@/store/order/order.reducer";
// import PurchaseOrder from "@/components/OrderStepsCasa/PurchaseOrder";
import Invoice from "@/components/OrderStepsCasa/Invoice";
import OnlinePayment from "@/components/OrderStepsCasa/OnlinePayment";
import { getOrderCasaDetailsById } from "@/store/order/order.actions";
import cn from "classnames";
import "./PaymentType.scss";

const PaymentType = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [selectedPayment, setSelectedPayment] = useState();
  const [isInvoice, setIsInvoice] = useState(false);
  const list = useMemo(
    () => [
      {
        id: "online",
        title: "Online Payment",
        isActive: true
      },
      {
        id: "invoice",
        title: "Invoice"
      }
      // {
      //   id: "purchase-order",
      //   title: "Purchase order"
      // }
    ],
    []
  );
  const [selectedPlan, setSelectedPlan] = useState();
  const [searchParams] = useSearchParams();
  const productId = searchParams.get("product_id");
  const orderInfo = useSelector(getOrderDetails);

  const handleChoosePayment = useCallback((pId) => {
    setSelectedPayment(pId);

    switch (pId) {
      case "online":
        setIsInvoice(false);
        break;
      case "invoice":
        setIsInvoice(true);
        break;
      // case "purchase-order":
      //   setIsPurchaseOrder(true);
      //   break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (orderInfo && orderInfo.plane) {
      setSelectedPlan(
        orderInfo.plane.find((p) => p.product_id === parseInt(productId, 10))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderInfo, productId]);

  useEffect(() => {
    if (id) {
      dispatch(getOrderCasaDetailsById(id));
    }

    setSelectedPayment(list[0].id);
    handleChoosePayment(list[0].id);
    // eslint-disable-next-line
  }, [list, handleChoosePayment, id]);

  return (
    <div className="payment-type">
      <div className="payment-type__inner">
        <div className="payment-type__nav">
          {list.length &&
            list?.map((item) => (
              <button
                className={cn(
                  "payment-type__item",
                  selectedPayment === item.id && "active"
                )}
                key={item.id}
                type="button"
                onClick={() => handleChoosePayment(item.id)}>
                <div className="payment-type__item-wrap">
                  {item.title && (
                    <div className="payment-type__item-title">{item.title}</div>
                  )}
                </div>
              </button>
            ))}
        </div>
        {orderInfo && selectedPlan && (
          <div className="payment-type__body">
            {isInvoice ? (
              <Invoice selectedPlan={selectedPlan} orderInfo={orderInfo} />
            ) : (
              <OnlinePayment
                selectedPlan={selectedPlan}
                orderInfo={orderInfo}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentType;
