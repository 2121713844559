import PanelHeader from "@/components/ProjectProgress/components/PanelHeader";
import purify from "dompurify";
import {
  AssessmentSuccessIcon,
  CheckCircleDisabledIcon,
  CheckCircleSuccessIcon,
  CrossRedIcon
} from "@/icons";
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  getAssessmentCompletedDetails,
  getIsOrderFinalizedSuccessfully,
  getIsOrderFinalizedUnsuccessfully
} from "@/store/order/order.reducer";

export const AssessmentCompletedPanelHeader = ({ active }) => {
  const isAssessmentUnsuccessful = useSelector(
    getIsOrderFinalizedUnsuccessfully
  );
  const isAssessmentSuccessful = useSelector(getIsOrderFinalizedSuccessfully);

  const orderIcon = useMemo(() => {
    if (isAssessmentUnsuccessful) {
      return <CrossRedIcon />;
    }

    if (isAssessmentSuccessful) {
      return <CheckCircleSuccessIcon />;
    }

    return <CheckCircleDisabledIcon />;
  }, [isAssessmentSuccessful, isAssessmentUnsuccessful]);

  return (
    <PanelHeader
      title="Assessment completed"
      description="You can review the report"
      icon={orderIcon}
      report={
        isAssessmentUnsuccessful?.report || isAssessmentSuccessful?.report
      }
      active={active}
      hasButton={false}
    />
  );
};

AssessmentCompletedPanelHeader.propTypes = {
  active: PropTypes.bool.isRequired
};

export const AssessmentCompletedPanelBody = () => {
  const assessmentCompletedDetails = useSelector(getAssessmentCompletedDetails);

  return (
    <div className="progress-accordion__body">
      <div className="inner">
        <div className="row-info-wrapper__icon">
          <AssessmentSuccessIcon />
        </div>
        <div className="row-info-wrapper__info">
          <div className="row-info-wrapper__title">Completion details</div>
          {assessmentCompletedDetails && (
            <div className="row-info-wrapper">
              <div
                key={assessmentCompletedDetails.created_at}
                className="row-info-wrapper__html-text"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: purify.sanitize(assessmentCompletedDetails.text)
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
