/* eslint-disable no-unused-vars */
import React, { useRef, useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  generatePath,
  useParams,
  useNavigate,
  useSearchParams,
  createSearchParams
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "@/components/Button";
import InputField from "@/components/InputField";
import ResumableFileUploader from "@/components/ResumableFileUploader";
// eslint-disable-next-line import/extensions
import motion from "@/utils/motionUtil.js";
import { MAX_DOC_FILE_SIZE_IN_MEGABYTES, routes } from "@/constants";
import Collapse, { Panel } from "rc-collapse";
import { useForm } from "react-hook-form";
import yup from "@/utils/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  uploadFileSuccessful,
  postPurchaseOrder,
  OrderActions
} from "@/store/order/order.actions";
import { getOrderDetails } from "@/store/order/order.reducer";
import { isInProcess } from "@/store/pending/pending.reducer";
import "./PurchaseOrder.scss";

const schema = yup.object({
  order_number: yup.string().nullable().max(255).required(),
  order_date: yup.string().nullable().max(255).required(),
  memo: yup.string().nullable().max(255),
  doc_filename: yup
    .mixed()
    .nullable()
    // eslint-disable-next-line camelcase,consistent-return
    .when("loc_sloc", (loc_sloc) => {
      // eslint-disable-next-line camelcase
      if (!loc_sloc) {
        return yup
          .mixed()
          .test("ifString", "Required", (value) => {
            if (typeof value === "string") {
              return !!value;
            }

            return true;
          })
          .test(
            "size",
            `Size of file can't be larger than ${MAX_DOC_FILE_SIZE_IN_MEGABYTES} Mb`,
            (value) => {
              if (typeof value === "string") {
                return true;
              }
              // eslint-disable-next-line no-unsafe-optional-chaining
              const sizeMB = value?.size / 1024 / 1024;
              return sizeMB <= MAX_DOC_FILE_SIZE_IN_MEGABYTES;
            }
          )
          .test("type", "PDF, doc, docx files are allowed", (value) => {
            if (typeof value === "string") {
              return !!value;
            }
            return (
              value?.type === "application/pdf" ||
              value?.type === "application/msword" ||
              value?.type === "application/vnd.ms-word" ||
              value?.name.endsWith(".pdf") ||
              value?.name.endsWith(".doc") ||
              value?.name.endsWith(".docx")
            );
          });
      }
    })
});

const PurchaseOrder = ({ hideBackButton }) => {
  const { id } = useParams();
  const {
    register,
    control,
    setValue,
    resetField,
    formState: { errors },
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema)
  });

  // file upload
  const fileUploader = useRef();
  const [isFileLoading, setIsFileLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const productId = searchParams.get("product_id");
  const orderInfo = useSelector(getOrderDetails);
  const [selectedPlan, setSelectedPlan] = useState();

  const uploadFile = () => {
    fileUploader.current?.startUploading();
  };

  const fileUploadURL = useMemo(() => {
    return `${process.env.REACT_APP_API_URL}/api/v2/order-casa/${id}/purchase-order/file`;
  }, [id]);

  const isLoading = useSelector((state) =>
    isInProcess(state, OrderActions.POST_PURCHASE_ORDER)
  );

  const onFileUploadSuccess = (_file, orderDetails) => {
    setIsFileLoading(false);

    if (orderDetails) {
      dispatch(uploadFileSuccessful(orderDetails));
    }

    const path = generatePath(routes.ORDER_CASA_SUCCESS, { id });
    navigate(path);
  };

  const handleBack = () => {
    const params = `?${createSearchParams({
      product_id: productId
    })}`;

    navigate({
      pathname: generatePath(routes.ORDER_CASA_PAYMENT, { id }),
      search: params
    });
  };

  useEffect(() => {
    if (orderInfo && orderInfo.plane) {
      setSelectedPlan(
        orderInfo.plane.find((p) => p.product_id === parseInt(productId, 10))
      );
    }
  }, [orderInfo, productId]);

  const onSubmit = async (data) => {
    const payload = Object.keys(data).reduce((acc, key) => {
      if (key !== "doc_filename") {
        acc[key] = data[key];
      }
      return acc;
    }, {});

    if (fileUploader.current?.resumable.files.length) {
      await dispatch(
        postPurchaseOrder({
          id,
          data: payload
        })
      );

      uploadFile();

      return;
    }

    await dispatch(
      postPurchaseOrder({
        id,
        data: payload
      })
    );

    const path = generatePath(routes.ORDER_CASA_SUCCESS, { id });
    navigate(path);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="purchase-order">
      <Collapse openMotion={motion} defaultActiveKey={[0, 1]}>
        <Panel
          header={
            <div className="fields-group-title">Purchase order details</div>
          }>
          <InputField
            name="order_number"
            type="tel"
            label="Purchase order number <sup>*</sup>"
            errors={errors}
            placeholder="Purchase order #"
            {...register("order_number")}
          />
          <InputField
            name="order_date"
            control={control}
            datePicker
            datePickerFormat="MMMM d, yyyy"
            label="Purchase order date <sup>*</sup>"
            errors={errors}
            placeholder="Purchase order date"
            {...register("order_date")}
          />
          <InputField
            multiline="2"
            name="memo"
            type="text"
            label="Notes / Memo"
            errors={errors}
            {...register("memo")}
          />
          <div className="fields-group-subtitle">
            Upload the purchase order form
          </div>
          <InputField
            withoutInput
            name="doc_filename_label"
            label="Please provide a documents <sup>*</sup>"
          />
          <ResumableFileUploader
            name="doc_filename"
            fileAcceptLabel="PDF, docx etc"
            fileAccept=".pdf, .doc, .docx"
            setValue={setValue}
            resetField={resetField}
            target={fileUploadURL}
            ref={fileUploader}
            errors={errors}
            onFileProgress={() => setIsFileLoading(true)}
            onFileUploadPause={() => setIsFileLoading(false)}
            onAfterFileSuccess={onFileUploadSuccess}
            onAfterUploadCanceled={() => setIsFileLoading(false)}
            onAfterFileError={() => {
              setIsFileLoading(false);
            }}
            control={control}
          />
        </Panel>
        <Panel
          header={<div className="fields-group-title">Vendor details</div>}>
          <div className="purchase-order__wrap">
            <InputField
              withoutInput
              name="vendor_details"
              label="Vendor details"
            />
            <div className="purchase-order__text">
              Leviathan Security Group, Inc.2925 1ST AVE SSeattle, WA 98134,
              866-452-6997. Leviathan Security Group, Inc.2925 1ST AVE S,
              Seattle, WA 98134 , 866-452-6997
            </div>
          </div>
        </Panel>
      </Collapse>
      {selectedPlan && (
        <div className="purchase-order__info">
          <div className="purchase-order__info-title">Order details</div>
          <div className="purchase-order__info-row">
            <div className="purchase-order__info-label">
              CASA - {selectedPlan.product.name}
            </div>
            <div className="purchase-order__info-value">
              $ {selectedPlan.price / 100}
            </div>
          </div>
        </div>
      )}
      <div className="main-layout__drawer-footer">
        <div className="main-layout__drawer-buttons">
          {!hideBackButton && (
            <div className="main-layout__drawer-buttons-item">
              <Button
                text="Back"
                className="btn-light btn-fullwidth"
                type="button"
                onClick={handleBack}
              />
            </div>
          )}
          <div className="main-layout__drawer-buttons-item">
            <Button
              isLoading={isLoading || isFileLoading}
              text="Submit"
              className="btn-default btn-fullwidth"
              type="submit"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

PurchaseOrder.propTypes = {
  hideBackButton: PropTypes.bool
};

export default PurchaseOrder;
