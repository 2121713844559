import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  associateMFASoftwareToken,
  AuthActions,
  removeMFASetupDetails,
  verifyMFASoftwareToken
} from "@/store/auth/auth.actions";
import { ExternalLinks, routes, StatusCodes } from "@/constants";
import { getMFASetupDetails } from "@/store/auth/auth.reducer";
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import InputField from "@/components/InputField";
import Button from "@/components/Button";
import { isInProcess } from "@/store/pending/pending.reducer";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "@/utils/yup";

const schema = yup.object({
  code: yup.string().nullable().min(6).max(6).required()
});

const SetupMFA = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const setupDetails = useSelector(getMFASetupDetails);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || routes.ROOT;

  const session = searchParams.get("session");

  const isLoading = useSelector((state) =>
    isInProcess(state, AuthActions.VERIFY_MFA_SOFTWARE_TOKEN)
  );

  const {
    register,
    setValue,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data) => {
    try {
      const { code } = data;
      const res = await dispatch(
        verifyMFASoftwareToken({
          code,
          session: setupDetails.session
        })
      );

      if (res?.token) {
        navigate(from, { replace: true });
      } else {
        navigate(routes.MFA_SETUP_SUCCESS, { replace: true });
      }
    } catch (error) {
      setError("code", {
        type: "custom",
        message:
          error.data?.code?.join("\n") || error.data?.message || "Invalid code"
      });
      setTimeout(() => {
        navigate(routes.LOGIN);
      }, 2000);

      // if (error.data?.message) {
      // dispatch(validateToken());
      // }
    }
  };

  useEffect(() => {
    if (session) {
      (async () => {
        try {
          await dispatch(
            associateMFASoftwareToken({
              session
            })
          );
        } catch (e) {
          if (e.status === StatusCodes._400) {
            navigate(routes.LOGIN, { replace: true });
          }
        }
      })();
    }

    return () => {
      dispatch(removeMFASetupDetails());
    };
    // eslint-disable-next-line
  }, [session]);

  if (!session) {
    return <Navigate to={routes.LOGIN} replace />;
  }

  if (!setupDetails) {
    return null;
  }

  return (
    <div className="mfa">
      <h1 className="mfa__title">Multi-Factor Authentication</h1>
      <p className="mfa__description">
        Secure your account by adding multi-factor authentication. Follow the
        instructions below to complete the setup. You will be asked to provide a
        one-time code next time you login.
      </p>
      <div className="mfa__steps">
        <div className="fields-group">
          <p className="mfa__step-text">
            {/* eslint-disable-next-line */}
            1. Please download{" "}
            <a
              target="_blank"
              rel="noreferrer noopener"
              href={ExternalLinks.GOOGLE_AUTHENTICATOR}>
              Google Authenticator
            </a>{" "}
            ,{" "}
            <a
              target="_blank"
              rel="noreferrer noopener"
              href={ExternalLinks.MICROSOFT_AUTHENTICATOR}>
              Microsoft Authenticator
            </a>{" "}
            or use your preferred MFA application from your phone&apos;s store.
          </p>
        </div>
        <div className="fields-group">
          <p className="mfa__step-text">
            2. Scan the QR code below or enter key manually using the
            application:
          </p>

          <div className="mfa__qr-code-image-wrapper">
            <img
              className="mfa__qr-code-image"
              src={setupDetails.qr}
              alt="QR code"
            />
          </div>
          <div className="mfa__secret">
            <div className="mfa__secret-label">Secret:</div>
            <div className="mfa__secret-value">{setupDetails.secret_code}</div>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="mfa__steps">
          <div className="fields-group">
            <p className="mfa__step-text">
              3. Type the 6-digit code from the authenticator application to
              verify your setup:
            </p>
            <InputField
              setValue={setValue}
              otpInput
              name="code"
              errors={errors}
              type="text"
              placeholder="Code"
              {...register("code")}
            />
          </div>
          <Button
            isLoading={isLoading}
            type="submit"
            className="btn-default btn-fullwidth"
            text="Set up multi-factor authentication"
          />
        </form>
      </div>
    </div>
  );
};

export default SetupMFA;
