import React, { useMemo, useEffect } from "react";
import { decode } from "html-entities";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getOrderDetails } from "@/store/order/order.reducer";
import { editableModeByStatus } from "@/utils/editableModeByStatus";
import { routes } from "@/constants";
import ApplicationInfoForm from "./ApplicationInfoForm";

const ApplicationInfo = () => {
  const order = useSelector(getOrderDetails);
  const navigate = useNavigate();

  useEffect(() => {
    if (order && order.status && !editableModeByStatus(order.status.code)) {
      navigate(routes.ROOT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const predefinedValues = useMemo(
    () => ({
      application_name: decode(order?.application_name),
      application_url: order?.application_url,
      gcp_project: order?.gcp_project,
      has_login: order?.has_login,
      app_login: order?.app_login,
      app_password: order?.app_password
    }),
    [order]
  );

  if (!order) {
    return null;
  }

  return <ApplicationInfoForm predefinedValues={predefinedValues} />;
};

export default ApplicationInfo;
