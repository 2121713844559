import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { routes, routeTitles } from "@/constants";
import Lottie from "lottie-react";
import signInAnimationImage from "@/assets/images/juicy-girl-is-working-on-laptop-at-a-remote-job.json";
import introAnimationImage from "@/assets/images/juicy-girl-working-at-home.json";
import successAnimationImage from "@/assets/images/juicy-girl-sending-messages-from-her-phone.json";
import scheduledAnimationImage from "@/assets/images/juicy-woman-is-reading-a-book-at-home.json";
import progressAnimationImage from "@/assets/images/juicy_man_programmer_writing_code_and_make_web_design_on_a_pc.json";
import detailsAnimationImage from "@/assets/images/juicy-teamwork.json";
import helpAnimationImage from "@/assets/images/juicy-woman-searching-with-a-magnifying-glass.json";
import forgotPasswordImage from "@/assets/images/forgot-pass-img.svg";
import mfaImage from "@/assets/images/mfa-img.svg";
import faqImage from "@/assets/images/juicy-man-and-woman-talking.svg";
import "./AnimationImage.scss";

const AnimationImage = ({ title }) => {
  const [isAnimation, setIsAnimation] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [animationObject, setAnimationObject] = useState(null);

  useEffect(() => {
    switch (title) {
      case routeTitles[routes.LOGIN]:
        setImageUrl(signInAnimationImage);
        break;
      case routeTitles[routes.REGISTER]:
        setImageUrl(signInAnimationImage);
        break;
      case routeTitles[routes.FORGOT_PASSWORD]:
        setImageUrl(forgotPasswordImage);
        break;
      case routeTitles[routes.RESET_PASSWORD]:
        setImageUrl(forgotPasswordImage);
        break;
      case routeTitles[routes.CHANGE_PASSWORD]:
        setImageUrl(forgotPasswordImage);
        break;
      case routeTitles[routes.MFA]:
        setImageUrl(mfaImage);
        break;
      case routeTitles[routes.MFA_SETUP_SUCCESS]:
        setImageUrl(mfaImage);
        break;
      case routeTitles[routes.REGISTER_CONFIRMATION]:
        setImageUrl(signInAnimationImage);
        break;
      case routeTitles[routes.FAQ]:
        setImageUrl(faqImage);
        break;
      case "projects-empty":
        setImageUrl(introAnimationImage);
        break;
      case "success":
        setImageUrl(successAnimationImage);
        break;
      case "project-details":
        setImageUrl(detailsAnimationImage);
        break;
      case "progress":
        setImageUrl(progressAnimationImage);
        break;
      case "scheduled":
        setImageUrl(scheduledAnimationImage);
        break;
      case "help":
        setImageUrl(helpAnimationImage);
        break;
      default:
        setImageUrl();
        break;
    }

    if (imageUrl && typeof imageUrl === "string") {
      setIsAnimation(false);
      setAnimationObject(null);
    }
    if (imageUrl && typeof imageUrl === "object") {
      setAnimationObject(imageUrl);
      setIsAnimation(true);
    }
  }, [title, imageUrl]);

  return (
    <div className="animation-image">
      {isAnimation ? (
        <Lottie
          animationData={animationObject}
          className="animation-image-el"
        />
      ) : (
        <img
          className="animation-image__i"
          src={imageUrl}
          alt=""
          width="100%"
          height="auto"
        />
      )}
    </div>
  );
};

AnimationImage.propTypes = {
  title: PropTypes.string
};

export default AnimationImage;
