import React from "react";
import PropTypes from "prop-types";
import "./PaymentTotalInfo.scss";

function PaymentTotalInfo({
  title,
  amount,
  totalAmount,
  processing,
  subtotal
}) {
  return (
    <div className="payment-total-info">
      {title && (
        <div className="payment-total-info__item">
          <div className="payment-total-info__row">
            <div className="payment-total-info__label">{title}</div>
            <div className="payment-total-info__value">${amount}</div>
          </div>
        </div>
      )}
      {subtotal && (
        <div className="payment-total-info__item">
          <div className="payment-total-info__row">
            <div className="payment-total-info__label">Subtotal</div>
            <div className="payment-total-info__value">${amount}</div>
          </div>
        </div>
      )}
      <div className="payment-total-info__item">
        {processing && (
          <div className="payment-total-info__add">
            <div className="payment-total-info__add-label">Processing Fee</div>
            <div className="payment-total-info__add-value">${processing}</div>
          </div>
        )}
        {totalAmount && (
          <div className="payment-total-info__row">
            <div className="payment-total-info__label">Total</div>
            <div className="payment-total-info__value">${totalAmount}</div>
          </div>
        )}
      </div>
    </div>
  );
}

PaymentTotalInfo.propTypes = {
  title: PropTypes.string,
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  totalAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  processing: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  subtotal: PropTypes.bool
};

export default PaymentTotalInfo;
