import React from "react";
import PropTypes from "prop-types";
import { generatePath, useNavigate } from "react-router-dom";
import InputField from "@/components/InputField";
import PanelHeader from "@/components/ProjectDetails/components/PanelHeader";
import {
  CheckCircleSuccessIcon,
  CheckCircleDisabledIcon,
  CircleWarningIcon
} from "@/icons";
import apkFileIcon from "@/assets/images/apk-file-icon.png";
import { routes } from "@/constants";
import { OrderStatuses } from "@/constants/orderStatuses";
import { Mobile } from "@/components/Responsive";
import cn from "classnames";

export const TestingDetailsNavItem = ({
  tabIndex,
  handleTabNavClick,
  orderDetails
}) => {
  const { application_name } = orderDetails;

  return (
    <button
      className={cn(
        "project-details__nav-item",
        tabIndex === "testing-details" && "active"
      )}
      type="button"
      disabled={!application_name}
      onClick={() => handleTabNavClick("testing-details")}>
      {application_name ? (
        <CheckCircleSuccessIcon className="project-details__nav-icon" />
      ) : (
        <CheckCircleDisabledIcon className="project-details__nav-icon" />
      )}
      Testing details
    </button>
  );
};

const TestingDetails = ({ orderDetails, tabIndex, handleTabNavClick }) => {
  const navigate = useNavigate();

  const {
    id,
    application_name,
    package_name,
    has_login,
    app_login_decrypted,
    app_password_decrypted,
    app_filename,
    app_size,
    status: { code }
  } = orderDetails;

  const onTestingDetailsClick = () => {
    navigate(generatePath(routes.ORDER_APPLICATION_INFO, { id }));
  };

  const renderFileView = (fileName, fileSize) => {
    return (
      <div className="project-details__file">
        <div className="project-details__file-icon">
          <img
            className="project-details__file-icon-i project-details__file-icon-i_md"
            src={apkFileIcon}
            alt="APK file"
            width="26"
            height="26"
          />
        </div>
        <div className="project-details__file-wrap">
          {fileName && (
            <div className="project-details__file-name">{fileName}</div>
          )}
          {fileSize && (
            <div className="project-details__file-size">{fileSize}</div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <Mobile>
        <TestingDetailsNavItem
          tabIndex={tabIndex}
          handleTabNavClick={handleTabNavClick}
          orderDetails={orderDetails}
        />
      </Mobile>
      {tabIndex === "testing-details" && (
        <div className="project-details__tab">
          <PanelHeader
            title="Testing details"
            description="Application and package details, app review information."
            icon={
              application_name ? (
                <CheckCircleSuccessIcon />
              ) : (
                <CircleWarningIcon />
              )
            }
            hasButton={
              code === OrderStatuses.CREATED ||
              code === OrderStatuses.PENDING_EMAIL_VERIFICATION
            }
            buttonText="Update"
            onClick={onTestingDetailsClick}
          />
          <div className="project-details__section">
            <div className="fields-group">
              <div className="fields-group-title">Application details</div>
              {package_name && (
                <InputField
                  label="Package name <sup>*</sup>"
                  name="package_name"
                  readonlyValue={package_name}
                />
              )}
              {application_name && (
                <InputField
                  label="App name <sup>*</sup>"
                  name="application_name"
                  readonlyValue={application_name}
                />
              )}
            </div>
            {has_login && (
              <div className="fields-group">
                <div className="fields-group-title">Login Credentials</div>
                {app_login_decrypted && (
                  <InputField
                    label="Email <sup>*</sup>"
                    name="app_login_decrypted"
                    readonlyValue={app_login_decrypted}
                  />
                )}
                {app_password_decrypted && (
                  <InputField
                    label="Password <sup>*</sup>"
                    name="app_password_decrypted"
                    readonlyValue={app_password_decrypted}
                    hasPasswordShow
                  />
                )}
              </div>
            )}
            {app_filename && (
              <div className="fields-group">
                <div className="fields-group-title">APK file</div>
                <div className="form-group">
                  {renderFileView(app_filename, app_size)}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

TestingDetails.propTypes = {
  orderDetails: PropTypes.object.isRequired,
  tabIndex: PropTypes.string,
  handleTabNavClick: PropTypes.func
};

TestingDetailsNavItem.propTypes = {
  tabIndex: PropTypes.string,
  handleTabNavClick: PropTypes.func,
  orderDetails: PropTypes.object.isRequired
};

export default TestingDetails;
