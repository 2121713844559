import React from "react";
import { CheckIcon } from "@/icons";
import PropTypes from "prop-types";
import ErrorMessage from "@/components/ErrorMessage";
import cn from "classnames";

// eslint-disable-next-line react/display-name
const Checkbox = React.forwardRef(
  ({ text, onChange, onBlur, errors, name }, ref) => {
    return (
      <div
        className={cn("checkbox-wrapper form-group", {
          "has-error": !!errors[name]
        })}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="checkbox-container">
          <span className="checkbox-container-text">{text}</span>
          <input
            ref={ref}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            className="checkbox-control"
            type="checkbox"
          />
          <span className="checkmark">
            <CheckIcon />
          </span>
        </label>
        <ErrorMessage name={name} errors={errors} />
      </div>
    );
  }
);

Checkbox.defaultProps = {
  errors: {}
};

Checkbox.propTypes = {
  text: PropTypes.string.isRequired,
  errors: PropTypes.object,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
};

export default Checkbox;
